import { useNavigate } from 'react-router-dom';
// import { getOneIncompleteConf } from '../../utility/commonUtil';
import BackIcon from '../../icons/BackIcon';
import EditIcon from '../../icons/EditIcon';
import '../../conference/confDetailsTop.styles.scss';
import { getOneIncompleteVideoCourse } from '../../../utility/commonUtil';

export default function VideoCourseDetailsTop({ videoId, status }) {
  const navigate = useNavigate();
  return (
    <div className="video-preview-top-container md-grid ">
      <div className="flex-vc">
        <i
          style={{ cursor: 'pointer' }}
          className="position-relative pt-4"
          onClick={() => navigate(-1)}
        >
          <BackIcon fill="#08415c" className="icon-xl" />
        </i>

        <div className="flex-vc">
          <h4 className="mr-16 ml-8">{status}</h4>
          <i
            style={{ cursor: 'pointer' }}
            className="position-relative pt-3"
            onClick={() => getOneIncompleteVideoCourse(videoId, navigate)}
          >
            <EditIcon fill="#08415c" className="icon-size" />
          </i>
        </div>
      </div>
    </div>
  );
}
