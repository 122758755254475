import Select, { components } from "react-select";
import "../reselect/searchAndAdd.styles.scss";
import "./searchSpeaker.styles.scss";

const confemyWhite = "#ffffff";
const confemyBlac = "#000000";
const shade1 = "#ced9de";
const shade2 = "#ecf0f2";
const shade4 = "#aabdc7";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

// function to set up Select Value. If options array not given, it sets empty value
const getValue = (options, value, isMulti) => {
  if (isMulti) {
    return value;
  } else {
    return options ? options?.find((option) => option.value === value) : "";
  }
};

const customStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? "1px solid #55a0fa" : `2px solid ${shade1}`,
      padding: "13px 0px 13px 16px",
      fontFamily: "Avenir-Roman",
      fontSize: 16,

      ":hover": {
        border: state.isFocused ? "1px solid #55a0fa" : `solid 3px ${shade4}`,
      },
    };
  },

  menuPortal: (base) => ({ ...base, zIndex: 9999 }),

  placeholder: (provided) => ({
    ...provided,
    // fontSize: "1em",
    // color: confemyBlac,
    // fontWeight: 400,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    width: "160px",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade2 : "#fff",
      fontSize: 16,
      fontFamily: "Avenir-Roman",
      padding: 16,
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ":hover": {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      // margin: "0 4px",
      height: 52,
      backgroundColor: confemyWhite,
      fontSize: 18,
    };
  },
};

export default function SearchSpeaker({
  options,
  value,
  isMulti,
  onChange,
  placeholder,
  isDisabled,
  name,
  handleOpenSpeakerForm,
}) {
  return (
    <Select
      value={getValue(options, value, isMulti)}
      // onChange={handleChange}
      onChange={(value) => {
        onChange(value);
      }}
      options={options}
      className="basic-single"
      classNamePrefix="select"
      components={{ Placeholder }}
      placeholder={placeholder}
      // defaultValue={defaultValue}
      isClearable
      isSearchable
      isDisabled={isDisabled}
      name={name}
      noOptionsMessage={() => {
        return (
          <div className="sns-buttons-wrap">
            <span
              type="button"
              className="conf-add-newcredit-btn caption-1-heavy-primary m-4"
              onClick={handleOpenSpeakerForm}
            >
              Add new speaker
            </span>
          </div>
        );
      }}
      menuPortalTarget={document.body}
      styles={customStyles}
      isMulti={isMulti}
    />
  );
}
