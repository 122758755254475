import { useState } from 'react';
import AudioCourseAttendeeCredits from '../../components/audio-course/AudioCourseAttendeeCredits';

export default function AudioCourseAttendeeCreditsPage() {
  const [confTotalStartDate, setConfTotalStartDate] = useState(null);
  const [confTotalEndDate, setConfTotalEndDate] = useState(null);
  return (
    <div>
      <AudioCourseAttendeeCredits
        confTotalStartDate={confTotalStartDate}
        confTotalEndDate={confTotalEndDate}
      />
    </div>
  );
}
