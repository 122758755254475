import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  UPDATE_USER,
  SWITCH_TO_ORGANIZER,
  SWITCH_TO_ATTENDEE,
} from "./authTypes";

const initialState = {
  isAuthenticated: null,
  isLoading: true,
  auth: null,
  user: null,
  userProfile: null,
  isAttendee: true,
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };

    case UPDATE_USER:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: payload,
      };
    case SWITCH_TO_ORGANIZER:
      return {
        ...state,
        isAttendee: false,
      };

    case SWITCH_TO_ATTENDEE:
      return {
        ...state,
        isAttendee: true,
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        auth: null,
        user: null,
        userProfile: null,
        isAttendee: true,
      };
    case LOGIN_FAILURE:
    case AUTH_ERROR:
    case REGISTER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        auth: null,
        user: null,
        userProfile: null,
        isAttendee: true,
      };
    default:
      return state;
  }
}

export default authReducer;
