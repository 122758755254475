import { useNavigate } from 'react-router-dom';
import BackIcon from '../icons/BackIcon';
import EditIcon from '../icons/EditIcon';
import '../conference/confDetailsTop.styles.scss';
import { getOneIncompletePodcast } from '../../utility/commonUtil';

export default function PodcastDetailsTop({ podcastId, status }) {
  const navigate = useNavigate();
  return (
    <div className="video-preview-top-container md-grid ">
      <div className="flex-vc">
        <i
          style={{ cursor: 'pointer' }}
          className="position-relative pt-4"
          onClick={() => navigate(-1)}
        >
          <BackIcon fill="#08415c" className="icon-xl" />
        </i>

        <div className="flex-vc">
          <h4 className="mr-16 ml-8">{status}</h4>
          <i
            style={{ cursor: 'pointer' }}
            className="position-relative pt-3"
            onClick={() =>
              getOneIncompletePodcast(
                podcastId,
                navigate,
                `/dashboard/create-pod/step-1`
              )
            }
          >
            <EditIcon fill="#08415c" className="icon-size" />
          </i>
        </div>
      </div>
    </div>
  );
}
