import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { loadCreditTypesListAction } from '../../redux/list/listAction';
import api from '../../utility/api';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';

const validationSchema = yup.object().shape({
  newCreditType: yup.string().required('Required'),
});
export default function NewCreditForm({ setAddNewCredit }) {
  const dispatch = useDispatch();
  const newConference = useSelector((state) => state.conference.newConference);
  const orgId = newConference?.hostedBy?.organization?._id;
  const userId = newConference?.hostedBy?.user?._id;
  const { completedStep1 } = newConference;

  // submit function
  const onSubmit = async (values, actions) => {
    if (!completedStep1) {
      actions.setFieldError('newCreditType', 'Submit step 1 first');
      return;
    }
    let url;
    if (newConference?.host === 'organization') {
      url = `conferences/credits?orgId=${orgId}&type=org`;
    } else {
      url = `conferences/credits?userId=${userId}&type=user`;
    }
    const data = {
      creditDetails: {
        name: values.newCreditType,
      },
    };
    try {
      const response = await api.post(url, data);
      setAddNewCredit(false);
      dispatch(loadCreditTypesListAction(response.data.data.credits));
    } catch (err) {
      actions.setFieldError('newCreditType', err.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      newCreditType: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    setAddNewCredit(false);
  };

  return (
    <form
      className="form-type-1 mt-40"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <p className="caption-1-regular-gray3 ml-4 mb-16">
        New credit type will be added to the dropdown
      </p>
      <div className="material-textfield mb-18">
        <input
          id="newCreditType"
          type="text"
          name="newCreditType"
          value={formik.values.newCreditType}
          onChange={formik.handleChange}
          placeholder=" "
        />
        <label>New credit type</label>
      </div>
      <div className="mb-24">
        {formik.touched.newCreditType &&
          Boolean(formik.errors.newCreditType) && (
            <TextError>{formik.errors.newCreditType}</TextError>
          )}
      </div>
      <div>
        <SubmitCancelButtonWithLoader
          isSubmitting={formik.isSubmitting}
          onCancel={() => handleCancel()}
        />
      </div>
    </form>
  );
}
