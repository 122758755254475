import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import { loadUserTotalCreditsAction } from "../../redux/user-profile/userProfileAction";
import api from "../../utility/api";
import DateFilter from "./DateFilter";
import { getQuery } from "../../utility/commonUtil";
import "./totalConfemyCreditsTable.scss";

const TotalConfemyCreditsTable = ({
  confTotalStartDate,
  setConfTotalStartDate,
  confTotalEndDate,
  setConfTotalEndDate,
}) => {
  const userTotalCredits = useSelector(
    (state) => state.userProfile.userTotalCredits
  );
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const getTotalConfCredits = async (userId, startDate, endDate) => {
    const parameters = [
      { name: "startDate", value: startDate?.toISOString() },
      { name: "endDate", value: endDate?.toISOString() },
    ];
    const query = getQuery(parameters);
    let baseURL = `/attendees/credits/users/${userId}?getAllCreditTypes=true`;
    const url = query?.length > 0 ? baseURL + "&" + query : baseURL;
    try {
      const response = await api.get(url);
      dispatch(loadUserTotalCreditsAction(response.data.data.allCredits));
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  useEffect(() => {
    getTotalConfCredits(user?._id, confTotalStartDate, confTotalEndDate);
  }, [user?._id, confTotalStartDate, confTotalEndDate]);

  return (
    <>
      <div>
        <h2 className="color-primary mb-16">Total Confemy Credits</h2>
        <div className="mb-16">
          <DateFilter
            startDate={confTotalStartDate}
            endDate={confTotalEndDate}
            setStartDate={setConfTotalStartDate}
            setEndDate={setConfTotalEndDate}
          />
        </div>
        <div className="grid-table-wrap">
          <ul className="grid-table-4-600 tcc-grid-table">
            <li>Credit Type</li>
            <li>Total Credits</li>
            <li>Approved Credits</li>
            <li>Pending Approval</li>
            {userTotalCredits?.length > 0 &&
              userTotalCredits?.map((credit) => {
                return (
                  <Fragment key={credit?._id}>
                    <li>{credit?.creditName}</li>
                    <li>{credit?.totalCreditQuantity}</li>
                    <li>{credit?.earnedCreditQuantity}</li>
                    <li>{credit?.pendingCreditQuantity}</li>
                  </Fragment>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TotalConfemyCreditsTable;
