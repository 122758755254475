import './ongoingLearning.scss';
import '../create-video-course/CourseCardWithOptions.scss';
import { useNavigate } from 'react-router-dom';
const OngoingLearningCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (data.media.type === 'VIDEO') {
          navigate(`/video-courses/${data?._id}`);
        } else if (data.media.type === 'AUDIO') {
          navigate(`/view/audio-courses/${data?._id}`);
        } else {
          navigate(`/podcasts/${data?._id}`);
        }
      }}
      class="learningCard mt-16"
    >
      <img
        src={data?.media?.banner?.[0].Location}
        alt="Image"
        className="learningCard-image"
      />
      <div className="learningCard-text">
        {' '}
        <p className="learningCard-tag ">{`${
          data?.media?.type === 'VIDEO'
            ? 'Video course'
            : data?.media?.type === 'AUDIO'
            ? 'Audio Course'
            : 'Podcast'
        }`}</p>
      </div>

      <p className="learningCard-title">{data?.media?.title}</p>
      <progress
        value={data?.media?.watchPercentage}
        max="100"
        style={{ backgroundColor: '#08415c' }}
        className="ongoingLearning-progressBar"
      />
    </div>
  );
};

export default OngoingLearningCard;
