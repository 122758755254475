import "./bdfoCounts.scss";

// Bdfo = booking details for organizer

export default function BdfoCounts({
  ticketDetails,
  tickets,
  soldTickets,
  grossPrice,
  refundRequests,
  refunded,
}) {
  // get currency from tickets array. Not ideal
  const currency = ticketDetails[0]?.currency;
  return (
    <div className="bdfo-counts-wrap">
      <table>
        <thead>
          <tr>
            <th>Tickets Sold</th>
            <th>Tickets Cancelled</th>
            <th>Refunded</th>
            <th>Net Sale</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {soldTickets}/{tickets}
            </td>
            <td>{refundRequests}</td>
            <td>{refunded}</td>
            <td>
              {currency && <span>{currency?.toString().toUpperCase()}-</span>}
              {grossPrice}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
