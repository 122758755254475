import "./mediaTag.scss";

export default function MediaTag({ tag, bg }) {
  const getBackgroundColor = (bg) => {
    switch (bg) {
      case "yellow":
        return "#eceb98";
      case "blue":
        return "#dceef8";
      default:
        return "#dceef8";
    }
  };

  return (
    <div
      className="media-tag media-tag-text"
      style={{
        backgroundColor: getBackgroundColor(bg),
      }}
    >
      <p>{tag}</p>
    </div>
  );
}
