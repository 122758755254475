import { useSelector } from "react-redux";
import GuestDetails from "./GuestDetails";
import SubmitButtonWithLoader from "../../components/button/SubmitButtonWithLoader";

export default function BookingPage2({
  cart,
  setCart,
  onFormSubmit,
  isLoading,
  total,
  conf,
}) {
  // function to change the cart based on guest inputs
  const user = useSelector((state) => state.auth.user);

  function handleSetCart(guest, key, value) {
    if (key === "isMe" && value === true) {
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.guestId === guest.guestId
            ? {
                ...item,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                isMe: true,
              }
            : item
        )
      );
    }
    if (key === "isMe" && value === false) {
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.guestId === guest.guestId
            ? {
                ...item,
                firstName: "",
                lastName: "",
                email: "",
                isMe: false,
              }
            : item
        )
      );
    }
    if (key !== "isMe") {
      setCart((prevState) => {
        const updatedCart = prevState.map((item) =>
          item.guestId === guest.guestId ? { ...item, [key]: value } : item
        );
        return updatedCart;
      });
    }
  }

  return (
    <>
      <div className="text-align-center mb-72">
        <h2 className="color-primary mb-24">Book Tickets</h2>
        <h4 className="color-primary mb-24">{conf?.title}</h4>
      </div>
      <div className="mb-40">
        <h3 className="color-primary mb-16">Order Summery</h3>
      </div>
      <div className="bp-tickets-table-wrap bp-tickets-table">
        <table className="mb-24">
          <thead>
            <tr>
              <th>Guest</th>
              <th>Ticket</th>
              <th>QTY</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {cart?.map((item, indx) => (
              <tr key={item.guestId}>
                <td>Guest {indx + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.quantity}</td>
                <td>
                  <span>{item?.currency} - </span>
                  <span>{item?.price}</span>
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {conf?.tickets?.[0]?.currency && (
                <td style={{ whiteSpace: "nowrap" }}>
                  <p className="caption-1-regular mb-8">
                    Total all tickets: {conf?.tickets?.[0].currency} - {total}
                  </p>
                  <p className="caption-1-regular mb-8">
                    Service charge: {conf?.tickets?.[0].currency} -{" "}
                    {total * 0.05}
                  </p>
                  <p className="caption-1-heavy-cblack">
                    Subtotal: {conf?.tickets?.[0].currency} - {""}
                    {total * 0.05 + total}
                  </p>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>

      <form onSubmit={onFormSubmit} autoComplete="off">
        <div className="mb-80">
          <h3 className="color-primary mb-40 mt-30">Guest Details</h3>
          {cart.map((guest, indx) => (
            <div key={guest.guestId} className="mb-60">
              <GuestDetails
                indx={indx}
                guest={guest}
                cart={cart}
                setCart={setCart}
                onInputChange={(e, guest) => {
                  //   const value = e.target.value;
                  const key = e.target.name;
                  const value =
                    e.target.type === "checkbox"
                      ? e.target.checked
                      : e.target.value;
                  handleSetCart(guest, key, value);
                }}
              />
            </div>
          ))}
        </div>
        <SubmitButtonWithLoader
          isSubmitting={isLoading}
          text="Checkout"
          className="button button-primary"
        />
      </form>
    </>
  );
}
