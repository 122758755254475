import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfDetails from '../../components/conference/ConfDetails';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

export default function ConfDetailsPage() {
  const [conf, setConf] = useState(null);
  const confId = useParams().confId;
  const dispatch = useDispatch();
  const addRecentlyViewed = (Id) => {
    const data = {
      recentlyViewedConferenceDetails: {
        conferenceId: Id,
      },
    };
    try {
      api.post('homePage/recentlyviewed', data);
    } catch (err) {}
  };

  const loadConferenceDetails = async (Id) => {
    try {
      const response = await api.get(`common/conferences/${Id}`);
      setConf(response.data.data.conferences);
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadConferenceDetails(confId);
    addRecentlyViewed(confId);
  }, [confId]);

  return (
    <div className="container pt-64">
      <ConfDetails conf={conf} preview={false} />
    </div>
  );
}
