import "./saleByTickets.scss";
export default function SalesByTickets({ ticketDetails }) {
  return (
    <div className="sbt-wrap">
      <h4 className="color-primary mb-24">Sales By Ticket Type</h4>
      <div className="table-container">
        <table className="sbt-table">
          <thead>
            <tr>
              <th>Ticket Type</th>
              <th>Price</th>
              <th>Sold</th>
              <th>Remaining</th>
            </tr>
          </thead>
          <tbody>
            {ticketDetails?.map((ticket) => (
              <tr key={ticket?.name}>
                <td className="cell-hyphens">{ticket?.name}</td>
                <td>
                  {ticket?.currency.toString().toUpperCase()}-{ticket?.price}
                </td>
                <td>{`${ticket?.sold}/${ticket?.quantity}`}</td>
                <td>
                  {ticket?.left === 0
                    ? "Sold out"
                    : `${ticket?.left}/${ticket?.quantity}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
