import Select from "react-select";
import { getValue } from "../../utility/commonUtil";
import { formType1Styles } from "./selectUtil";
export default function ReloadableFormikSelect1({
  options,
  field,
  form,
  placeholder,
}) {
  return (
    <Select
      key={field.value}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(option) => {
        form.setFieldValue(field.name, option?.value);
      }}
      options={options}
      value={getValue(options, field.value)}
      placeholder={placeholder}
      styles={formType1Styles}
      //   isClearable
    />
  );
}
