export default function PurchaseInfoIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 17.75v-8h1.5v8h-1.5zM11.25 6v2h1.5V6h-1.5z"
        fill="#507282"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25s9.75 4.365 9.75 9.75zM12 20.25a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5z"
        fill="#507282"
      />
    </svg>
  );
}
