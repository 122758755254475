import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import SuccessTickIcon from '../icons/SuccessTickIcon';
import './linkBankAccount.style.scss';
import { openInNewTab } from '../../utility/commonUtil';
import Loader from '../loader/Loader';
import ModalX from '../modal/ModalX';
import BankDetails from '../user-settings/BankDetails';
import { renderRowStyle } from '../../utility/commonUtil';
import ErrorIcon from '../icons/ErrorIcon';

const LinkBankAccount = () => {
  const user = useSelector((state) => state.auth.user);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetailsLoading, setBankDetailsLoading] = useState(false);
  const [openModalX, setModalX] = useState(false);
  const [stripe, setStripe] = useState({});
  const dispatch = useDispatch();

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell
  function onRowClick(data) {
    if (
      data?.isOrg &&
      data?.isConnectedAcountCreated &&
      data?.connectedAccount?.detailsSubmitted
    ) {
      getStripeConnectedAccount(data._id, 'org');
    } else if (
      data?.isConnectedAcountCreated &&
      data?.connectedAccount?.detailsSubmitted
    ) {
      getStripeConnectedAccount(data.user._id, 'user');
    }
  }

  const getConnectedAccounts = async (userId) => {
    try {
      const response = await api.get(
        `/organizers/connectedAccounts?userId=${user?._id}`
      );
      setBankAccountData(response.data.data.resArray);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const getStripeConnectedAccount = async (organizationId, type) => {
    let url = '';

    if (type === 'org') {
      url = `/organizers/accounts?organizationId=${organizationId}&type=org`;
    } else {
      url = `/organizers/accounts?userId=${user?._id}&type=user`;
    }

    setBankDetailsLoading(true);
    try {
      const response = await api.get(url);

      setStripe({
        connectedAccount: response?.data?.data?.connectedAccount,
        stripeAccount: response?.data?.data?.stripeAccount,
        balance: response?.data?.data?.balace,
        isConnectedAccount: response?.data?.data?.isConnectedAccount,
      });
      setBankDetailsLoading(false);
      setModalX(true);
    } catch (err) {
      setBankDetailsLoading(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };
  const getConnectedAccountLink = async (id, type) => {
    setIsLoading(true);
    const url = `/organizers/accounts`;

    let accountDetails = {
      returnUrl: `${window.location.origin}/dashboard/link-bank-account`,
      refreshUrl: `${window.location.origin}/dashboard/link-bank-account`,
      userId: user?._id,
    };

    if (type === 'org') {
      accountDetails = {
        ...accountDetails,
        type: 'org',
        organizationId: id,
      };
    } else {
      accountDetails = {
        ...accountDetails,
        type: 'user',
      };
    }

    try {
      const response = await api.post(url, { accountDetails });

      if (response) {
        openInNewTab(response?.data?.data?.accountLink?.url);
        // window.location.replace(response?.data?.data?.accountLink?.url);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const getAccountStatus = (data) => {
    if (
      data?.isConnectedAcountCreated &&
      !data?.connectedAccount?.detailsSubmitted &&
      data?.connectedAccount?.email
    ) {
      return 'Initiated';
    } else if (
      data?.isConnectedAcountCreated &&
      data?.connectedAccount?.detailsSubmitted
    ) {
      return 'Linked';
    } else {
      return 'Not Linked';
    }
  };

  useEffect(() => {
    getConnectedAccounts(user?._id);
  }, [user?._id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mb-80">
          <div className="mb-24">
            <h2 className="color-primary mb-24">Link Bank Account</h2>
            <p className="body-regular-gray3">
              When you publish paid a conference under organization or under
              your own name, to collect payment on sale of tickets you need link
              your bank account through a third party (Stripe Inc). You can link
              an existing stripe account or open a new one. You can link one
              stripe account to multiple organizations.
            </p>
          </div>

          <div className="grid-table-wrap">
            <ul className="grid-table-7-950 lb-gridtable">
              <li>Host</li>
              <li>Status</li>
              <li>Account Id</li>
              <li>Email</li>
              <li>Bank</li>
              <li>Bank Status</li>
              <li>Actions</li>

              {bankAccountData?.length > 0 &&
                bankAccountData?.map((data, indx) => {
                  return (
                    <Fragment key={data?._id}>
                      <li
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                      >
                        {`${
                          data?.isOrg
                            ? data?.name
                            : `${data?.user.firstName} ${data?.user.lastName}`
                        } `}
                      </li>

                      <li
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                      >
                        {getAccountStatus(data)}
                      </li>

                      <li
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                      >
                        {`${
                          data?.isConnectedAcountCreated &&
                          data?.connectedAccount &&
                          data?.connectedAccount?.detailsSubmitted
                            ? data?.connectedAccount?.account
                            : ''
                        }`}
                      </li>

                      <li
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                      >
                        {`${
                          data?.isConnectedAcountCreated &&
                          data?.connectedAccount &&
                          data?.connectedAccount?.detailsSubmitted
                            ? data?.connectedAccount?.email
                            : ''
                        }`}
                      </li>

                      <li
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                      >
                        {`${
                          data?.isConnectedAcountCreated &&
                          data?.connectedAccount &&
                          data?.connectedAccount?.detailsSubmitted
                            ? data?.connectedAccount?.externalAccount?.data[0]
                                ?.bank_name
                              ? data?.connectedAccount?.externalAccount?.data[0]
                                  ?.bank_name
                              : ''
                            : ''
                        }`}
                      </li>
                      <li
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                      >
                        {`${
                          data?.isConnectedAcountCreated &&
                          data?.connectedAccount &&
                          data?.connectedAccount?.detailsSubmitted &&
                          data?.connectedAccount?.chargesEnabled
                            ? 'Verified'
                            : data?.isConnectedAcountCreated &&
                              data?.connectedAccount?.detailsSubmitted &&
                              !data?.connectedAccount?.chargesEnabled
                            ? 'Bank verification failed. Follow instructions on your stripe account to verify bank account.'
                            : ''
                        }`}
                      </li>
                      <li
                        style={renderRowStyle(indx, data?._id, isHover, rowId)}
                        onClick={() => onRowClick(data)}
                        onMouseEnter={() => handleMouseEnter(data?._id)}
                        onMouseLeave={() => handleMouseLeave(data?._id)}
                      >
                        {!data?.isConnectedAcountCreated ||
                        !data?.connectedAccount ||
                        !data?.connectedAccount?.detailsSubmitted ? (
                          <button
                            style={{
                              ...(isLoading && {
                                backgroundColor: '#c4c4c4',
                                borderColor: '#c4c4c4',
                              }),
                            }}
                            disabled={isLoading}
                            className="small-button small-button-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (data?.isOrg) {
                                getConnectedAccountLink(data._id, 'org');
                              } else {
                                getConnectedAccountLink(data.user._id, 'user');
                              }
                            }}
                          >
                            Add
                          </button>
                        ) : !data?.connectedAccount?.chargesEnabled ? (
                          <i
                            style={{
                              display: 'inline-block',
                              cursor: 'default',
                              width: '64px',
                              textAlign: 'center',
                            }}
                          >
                            <ErrorIcon className="icon-xl" fill="#bb2124" />
                          </i>
                        ) : (
                          <i
                            style={{
                              display: 'inline-block',
                              cursor: 'default',
                              width: '64px',
                              textAlign: 'center',
                            }}
                          >
                            <span onClick={(e) => e.stopPropagation()}>
                              <SuccessTickIcon
                                className="icon-xl"
                                fill="#45a93e"
                              />
                            </span>
                          </i>
                        )}
                      </li>
                    </Fragment>
                  );
                })}
            </ul>
            {bankDetailsLoading && <div className="ab-overlay"></div>}
          </div>
          {openModalX && !isLoading && (
            <ModalX
              onDismiss={() => setModalX(false)}
              open={openModalX && !isLoading}
            >
              <div className="modalx-container-md mb-32 px-20">
                <h4>Bank Account Details</h4>
                <BankDetails stripe={stripe} />
              </div>
            </ModalX>
          )}
        </div>
      )}
    </>
  );
};

export default LinkBankAccount;
