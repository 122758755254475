import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfDetails from "../../components/conference/ConfDetails";
import ConfDetailsTop from "../../components/conference/ConfDetailsTop";
import Loader from "../../components/loader/Loader";
import api from "../../utility/api";

export default function MyConfDetailsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [conf, setConf] = useState(null);
  const confId = useParams().confId;

  const loadConferenceDetails = async (Id) => {
    try {
      const response = await api.get(`common/conferences/${Id}`);
      setConf(response.data.data.conferences);
      setIsLoading(false);
    } catch (err) {}
  };

  function getConfStatus(conf) {
    const hasExpired = Date.now() > Date.parse(conf.endDate);
    const active = Date.parse(conf.activeDate) > Date.parse(conf.inActiveDate);
    if (!conf.completedStep6) {
      return "Draft";
    } else {
      if (conf.activeDate && conf.inActiveDate) {
        if (active && !hasExpired) {
          return "Published";
        }
        if (active && hasExpired) {
          return "Expired";
        }
        if (!active) {
          return "Not published";
        }
      }
      if (conf.activeDate && !conf.inActiveDate && !hasExpired) {
        return "Published";
      }
      if (conf.activeDate && !conf.inActiveDate && hasExpired) {
        return "Expired conference";
      }
      if (!conf.activeDate && conf.inActiveDate) {
        return "Not published";
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadConferenceDetails(confId);
  }, [confId]);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ConfDetailsTop confId={confId} status={getConfStatus(conf)} />
          <ConfDetails conf={conf} preview={false} />
        </>
      )}
    </div>
  );
}
