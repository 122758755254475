import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';
import NextIcon from '../icons/NextIcon';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { useState } from 'react';
import Dialogue from '../dialogue/Dialogue';
import Loader from '../loader/Loader';
import { createAudioCourseAction } from '../../redux/audio-course/audioCourseAction';

export default function AudioCourseStep5() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openErrorDialogue, setErrorDialogue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const newAudioCourse = useSelector((state) => state.audio.newAudioCourse);

  const onPublish = async () => {
    if (
      !newAudioCourse?.completedStep1 ||
      !newAudioCourse?.completedStep2 ||
      !newAudioCourse?.completedStep3 ||
      !newAudioCourse?.completedStep4
    ) {
      dispatch(alertAction(`Complete required steps first`, 'danger'));
      return;
    }
    const formData = {
      mediaDetails: {
        whenToPublish: 'now',
        mediaId: newAudioCourse?._id,
        // publishDate: new Date().toUTCString(),
        publishDate: new Date().toISOString(),
      },
    };

    try {
      const res = await api.get(
        `organizers/accounts/videoCourse/${newAudioCourse?._id}`
      );

      if (
        !res?.data?.data?.connectedAccount?.detailsSubmitted &&
        res?.data?.data?.isMediaPaid &&
        !res?.data?.data?.connectedAccount?.chargesEnabled
      ) {
        setOpenDialogue(true);
        return;
      } else if (
        !res?.data?.data?.connectedAccount?.chargesEnabled &&
        res?.data?.data?.connectedAccount?.detailsSubmitted &&
        res?.data?.data?.isMediaPaid
      ) {
        setErrorDialogue(true);
        return;
      }
      const response = await api.post('audioCourses/step5', formData);
      if (response) {
        dispatch(createAudioCourseAction(response.data.data.media));
        navigate('/dashboard/my-audio-courses');
        dispatch(alertAction(response.data.message, 'success'));
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const getConnectedAccountLink = async () => {
    const url = `/organizers/accounts`;

    let accountDetails = {
      returnUrl: `${window.location.origin}/dashboard/create-video`,
      refreshUrl: `${window.location.origin}/dashboard/create-video`,
      userId: user?._id,
    };

    if (newAudioCourse?.host === 'user') {
      accountDetails = {
        ...accountDetails,
        type: 'user',
      };
    } else {
      accountDetails = {
        ...accountDetails,
        type: 'org',
        organizationId: newAudioCourse?.hostedBy.organization?._id,
      };
    }

    try {
      setIsLoading(true);
      const response = await api.post(url, { accountDetails });

      if (response) {
        window.location.replace(response?.data?.data?.accountLink?.url);
        // setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  return newAudioCourse?.completedStep1 &&
    newAudioCourse?.completedStep2 &&
    newAudioCourse?.completedStep3 &&
    newAudioCourse?.completedStep4 ? (
    isLoading ? (
      <Loader />
    ) : (
      <div>
        <div className="ce-preview-gradient"></div>
        <div className="ce-preview-grid">
          <div className="ce-preview-col1">
            <h1 className="color-primary mb-32">{newAudioCourse?.title}</h1>
            <p
              className="caption-2-regular-primary mb-24"
              style={{
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.43,
                letterSpacing: 'normal',
              }}
            >
              {newAudioCourse?.description}
            </p>
            <h4 className="color-primary mb-20">Sections and Chapters</h4>
            <div className="cep-sections-wrap">
              {newAudioCourse?.sections?.map((section, sectionIndex) => {
                return (
                  <div key={section?._id}>
                    <div className="cep-section-box avenir-heavy-18 color-primary mb-8">
                      <p style={{ fontSize: 16 }}>
                        <span>{sectionIndex + 1}</span>
                        <span>. </span>
                        <span>{section?.title}</span>
                      </p>
                    </div>
                    <div>
                      {section?.episodes?.map((episode, episodeIndex) => {
                        return (
                          <div className="flex-vc mb-8" key={episode?._id}>
                            <i className="L-indent mr-12"></i>
                            <div className="cep-section-box ">
                              <p className="color-primary caption-1-regular-gray3">
                                <span>{sectionIndex + 1}</span>
                                <span>.</span>
                                <span>{episodeIndex + 1} </span>
                                <span>{episode?.title}</span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="ce-preview-col2">
            <div className="ce-preview-card ">
              <div className="cep-card-topbox">
                <div style={{ flexGrow: 1 }}>
                  <div className="ce-banner-wrap">
                    {newAudioCourse?.banner?.map((item) => (
                      <img
                        className="ce-banner-img"
                        alt="event banner"
                        src={item?.Location}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          // URL.revokeObjectURL(image.Location);
                        }}
                      />
                    ))}
                  </div>
                  <div className="pt-24">
                    <h4 className="mb-16">{newAudioCourse?.title}</h4>
                    <div
                      style={{
                        height: 60,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        // whiteSpace: "nowrap",
                      }}
                    >
                      <p
                        className="caption-2-regular-primary"
                        style={{
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: 1.43,
                          letterSpacing: 'normal',
                          fontSize: 14,
                        }}
                      >
                        {newAudioCourse?.description}
                      </p>
                    </div>
                    <div className="flex-vc">
                      <span
                        className="avenir-heavy-18"
                        style={{ fontSize: 14, cursor: 'pointer' }}
                        onClick={() => console.log('Say it')}
                      >
                        Read more
                      </span>
                      <i className="position-relative pt-5">
                        <NextIcon className="icon-size" />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="flex-vc">
                  <button
                    className="small-button small-button-green mr-10"
                    style={{ flexGrow: 1, height: 54 }}
                    onClick={() => onPublish()}
                  >
                    Publish Now
                  </button>
                  <span className="primary-color-box bg-primary">
                    <LikeInactiveIcon className="icon-size" />
                  </span>
                </div>
              </div>
              <div className="cep-card-bottombox">
                <div className="flex-vc-sb mb-8">
                  <p className="avenir-roman-18 color-primary">Validity</p>
                  <p className="avenir-heavy-18 color-primary">
                    Lifetime Access
                  </p>
                </div>
                <div className="flex-vc-sb  mb-8">
                  <p className="avenir-roman-18 color-primary">Course fee</p>
                  <p
                    className="avenir-heavy-18 color-primary"
                    style={{
                      ...(newAudioCourse?.discount && {
                        textDecoration: 'line-through',
                        color: '#4cb944',
                      }),
                    }}
                  >
                    <span>{newAudioCourse?.currency} </span>
                    <span>{newAudioCourse?.price}</span>
                  </p>
                </div>
                {newAudioCourse?.discount && (
                  <div className="flex-vc-sb  mb-8">
                    <p className="avenir-roman-18 color-primary">Offer</p>
                    <p className="avenir-heavy-18 color-primary">
                      {' '}
                      <span>{newAudioCourse?.discount}</span> % Off
                    </p>
                  </div>
                )}
                {newAudioCourse?.discount && (
                  <div className="flex-vc-sb  mb-8">
                    <p className="avenir-roman-18 color-primary">You pay</p>
                    <p className="avenir-heavy-18 color-primary">
                      <span>{newAudioCourse?.currency} </span>
                      <span>
                        {(newAudioCourse?.price *
                          (100 - newAudioCourse?.discount)) /
                          100}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {openDialogue && (
          <Dialogue
            msg="Add bank account to collect Payment."
            title="Bank Account Onboard"
            closeDialogue={() => {
              setOpenDialogue(false);
            }}
            yesAction={() => getConnectedAccountLink()}
          />
        )}
        {openErrorDialogue && (
          <Dialogue
            msg="Please follow intructions on your stripe account to verify bank account."
            title="Bank Account Verification Failed."
            closeDialogue={() => {
              setErrorDialogue(false);
            }}
            yesAction={() => setErrorDialogue(false)}
          />
        )}
      </div>
    )
  ) : (
    <div>
      <p className="body-regular-gray3 color-primary">
        Please complete step-1 through step-4 first.
      </p>
    </div>
  );
}
