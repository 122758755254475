import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';
import Dropzone from 'react-dropzone';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import SelectFormType1 from '../reselect/SelectFormType1';
import DeleteIcon from '../icons/DeleteIcon';
import CheckTickIcon from '../icons/CheckTickIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import { alertAction } from '../../redux/alert/alertAction';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import ImageGalleryIcon from '../icons/ImageGalleryIcon';
import Loader from '../loader/Loader';
import { professions, subspecialties } from '../../utility/commonUtil';
import { createPodcastAction } from '../../redux/podcast/podcastAction';
import './createpodcast.scss';

const validationSchema = yup.object().shape({
  title: yup.string().trim().required('Required'),
  host: yup.string().required('Required. Choose who is organizing the course.'),
  organizationId: yup.string().when('host', {
    is: 'organization',
    then: yup.string().required('Required'),
  }),
  description: yup.string().trim().required('Required'),
  banner: yup
    .array()
    // .of(yup.object())
    .min(1, 'Required')
    .compact(),

  professions: yup
    .array()
    .of(yup.object())
    .min(1, 'Choose professions')
    .compact(),
  specialities: yup
    .array()
    .of(yup.object())
    .min(1, 'Choose specialitities')
    .compact(),
  tags: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string(),
        tag: yup
          .string()
          // .matches(websiteRegax, "Enter correct url")
          .required('Required')
          .test(
            'isFormTest',
            'Save key point using tick icon on right',
            function (value) {
              if (
                // this.parent.credits?.find((e) => e.value === value)
                this.parent.isForm
              ) {
                return false;
              } else {
                return true;
              }
            }
          ),
        isForm: yup.bool(),
      })
    )
    .min(1, 'Add at least one tag'),
  sortOrder: yup.string().required('Required'),
  visibility: yup.string().required('Required'),
});

export default function CreatePodcastStep1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const podcast = useSelector((state) => state.podcast);
  const { newPodcast } = podcast;

  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );

  async function onSubmit(values, actions) {
    const {
      banner,
      title,
      host,
      description,
      mediaId,
      author,
      visibility,
      sortOrder,
      professions,
      specialities,
      tags,
    } = values;

    const formData = {
      mediaDetails: {
        title: title,
        host: host,
        userId: user?._id,
        organizationId: values?.organizationId,
        description: description,
        banner: banner,
        author: author ? author : user?._id,
        mediaId: mediaId,
        visibility,
        sortOrder,
        professions,
        specialities,
        tags,
      },
    };

    //  Submit banner image and add image url to formData object

    try {
      const response = await api.post('podcast/step1', formData);

      if (response) {
        dispatch(createPodcastAction(response.data.data.media));
        actions.resetForm({ values: initialValues });
        navigate('/dashboard/create-pod/step-2');
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }
  // end of submit

  const initialValues = {
    title: newPodcast?.title || '',
    host: newPodcast?.host || '',
    organizationId: newPodcast?.hostedBy?.organization?._id || '',
    description: newPodcast?.description || '',
    banner: newPodcast?.banner || [],
    isBannerLoading: false,
    deletedBanner: [],
    author: newPodcast?.author?._id || '',
    mediaId: newPodcast?._id || '',
    professions: newPodcast?.professions || [],
    specialities: newPodcast?.specialities || [],
    tags: !newPodcast?.tags?.length > 0 ? [] : newPodcast?.tags,
    visibility: newPodcast?.visibility || '',
    sortOrder: newPodcast?.sortOrder || '',
  };

  const deleteFile = async (key, fieldName, fieldValue, setFieldValue) => {
    try {
      const response = await api.delete('courses/files', {
        data: {
          files: [
            {
              Key: key,
            },
          ],
        },
      });
      setFieldValue(
        fieldName,
        fieldValue?.filter((item) => item.Key !== response.data.data.Key)
      );
    } catch (err) {
      dispatch(alertAction('File failed to delet', 'danger'));
    }
  };

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(
            response?.data?.data?.organization
          )
        );
      }
    } catch (err) {
      dispatch(alertAction(err?.response?.data?.message, 'danger'));
    }
  };

  useEffect(() => {
    loadMyOrgnizations(user?._id);
  }, [user?._id]);

  return (
    <>
      <main className="create-event-wrap">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <form
                className="form-type-4"
                autoComplete="off"
                onSubmit={props.handleSubmit}
              >
                <div className="create-event-grid">
                  <div>
                    <p
                      className="body-regular-gray3 mb-24"
                      style={{ color: '#aabdc7' }}
                    >
                      All mandatory fields are makred by *
                    </p>
                    <h2 className="color-primary mb-36">Basic Information</h2>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Podcast Title *</h4>
                      <Field
                        name="title"
                        // value="title"
                        type="text"
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                      />
                      <ErrorMessage name="title" component={TextError} />
                    </div>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Hosted by *</h4>
                      <div role="group" className="mb-16">
                        <Field
                          type="radio"
                          style={{ display: 'none' }}
                          id="conf-org"
                          name="host"
                          value="organization"
                        />
                        <label htmlFor="conf-org">
                          <div
                            className={`mr-24 basicInfo-btn ${
                              props.values.host === 'organization'
                                ? 'button-outlined-active'
                                : 'button-outlined-inactive'
                            }`}
                          >
                            Organization
                          </div>
                        </label>
                        <Field
                          type="radio"
                          style={{ display: 'none' }}
                          name="host"
                          id="conf-myself"
                          value="user"
                        />
                        <label htmlFor="conf-myself">
                          <div
                            className={`basicInfo-btn ${
                              props.values.host === 'user'
                                ? 'button-outlined-active'
                                : 'button-outlined-inactive'
                            }`}
                          >
                            Myself
                          </div>
                        </label>
                        <ErrorMessage name="host" component={TextError} />
                      </div>
                      <AnimatePresence initial={false} mode="wait">
                        {props.values.host === 'organization' && (
                          <motion.div
                            key="organization"
                            layout
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.5, opacity: 0 }}
                            transition={{ type: 'spring', duration: 0.5 }}
                          >
                            <SelectFormType1
                              label="organizationId"
                              options={organizationsListForSelect}
                              name="organizationId"
                              onChange={(value) =>
                                props.setFieldValue(
                                  'organizationId',
                                  value?.value
                                )
                              }
                              placeholder="Select organization"
                              value={props.values.organizationId}
                              isDisabled={props.values.host !== 'organization'}
                            />
                            <ErrorMessage
                              name="organizationId"
                              component={TextError}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Description *</h4>
                      <Field
                        id="description"
                        name="description"
                        as="textarea"
                        type="text"
                        cols="10"
                        rows="5"
                        placeholder="Description"
                      />
                      <ErrorMessage name="description" component={TextError} />
                    </div>

                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Professions *</h4>
                      <p
                        className="caption-1-regular-gray3 mb-16"
                        style={{ color: '#aabdc7' }}
                      >
                        Add all professions to which this course is applicable.
                      </p>
                      <SelectFormType1
                        options={professions}
                        label="professions"
                        value={props.values.professions}
                        onChange={(value) => {
                          return props.setFieldValue('professions', value);
                        }}
                        placeholder="Choose Professions"
                        isMulti={true}
                      />
                      <div>
                        {props.touched.professions &&
                          Boolean(props.errors.professions) && (
                            <TextError>{props.errors.professions}</TextError>
                          )}
                      </div>
                    </div>
                    <div className="mb-24">
                      <h4 className="avenir-20-500 mb-8">Specialties *</h4>
                      <p
                        className="caption-1-regular-gray3 mb-16"
                        style={{ color: '#aabdc7' }}
                      >
                        Add all specialties to which this course is applicable.
                      </p>
                      <SelectFormType1
                        options={subspecialties}
                        label="specialities"
                        name="specialities"
                        placeholder="Choose specialities"
                        value={props.values.specialities}
                        onChange={(value) =>
                          props.setFieldValue('specialities', value)
                        }
                        isMulti={true}
                      />
                      <div>
                        {props.touched.specialities &&
                          Boolean(props.errors.specialities) && (
                            <TextError>{props.errors.specialities}</TextError>
                          )}
                      </div>
                    </div>
                    <div className="mb-40">
                      <h4 className="avenir-20-500 mb-8">
                        Improve Searchability with Tags *
                      </h4>
                      <p
                        className="caption-1-regular-gray3 mb-8"
                        style={{ color: '#aabdc7' }}
                      >
                        Tag is a key word or combination of words if searched by
                        user, will bring up this video course.
                      </p>
                      <p
                        className="caption-1-regular-gray3 mb-16"
                        style={{ color: '#aabdc7' }}
                      >
                        Add multiple tags.
                      </p>
                      <AnimatePresence initial={false} mode="wait">
                        <FieldArray
                          name="tags"
                          render={(arrayHelpers) => {
                            const tags = arrayHelpers.form.values.tags;
                            let indxOfLastItem = 0;
                            if (tags?.length > 0) {
                              indxOfLastItem = tags?.length - 1;
                            }
                            return (
                              <Fragment>
                                {tags?.length > 0 && (
                                  <div
                                    // grid for tags
                                    className="ce-points-grid mb-16"
                                  >
                                    {tags?.map((tag, index) => {
                                      return (
                                        <motion.div
                                          // grid item
                                          key={index}
                                          className={
                                            tag.isForm
                                              ? 'full-width-griditem'
                                              : ''
                                          }
                                          layout
                                          initial={{ scale: 0.5, opacity: 0 }}
                                          animate={{ scale: 1, opacity: 1 }}
                                          exit={{ scale: 0.5, opacity: 0 }}
                                          transition={{
                                            type: 'spring',
                                            duration: 0.5,
                                          }}
                                        >
                                          <div className="position-relative">
                                            <Field
                                              disabled={!tag.isForm}
                                              style={{ paddingRight: 36 }}
                                              type="text"
                                              name={`tags.${index}.tag`}
                                              placeholder="improve searchability with tags *"
                                              // validate={validateTag}
                                            />
                                            <i
                                              style={{
                                                right: 8,
                                                cursor: 'pointer',
                                              }}
                                              className="right-input-icon"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <CrossCancelIcon className="icon-size" />
                                            </i>

                                            <div
                                              className={
                                                !tag.isForm
                                                  ? 'display-none'
                                                  : 'ce-hanging-buttons-wrap'
                                              }
                                            >
                                              <i
                                                style={{ cursor: 'pointer' }}
                                                onMouseDown={() => {
                                                  props.setFieldTouched(
                                                    `tags.${index}.tag`
                                                  );
                                                  props.validateField('tags');
                                                  if (tag.tag !== '') {
                                                    const points = tags?.map(
                                                      (item) =>
                                                        item.uuid === tag.uuid
                                                          ? {
                                                              ...item,
                                                              isForm: false,
                                                            }
                                                          : item
                                                    );
                                                    arrayHelpers.form.setFieldValue(
                                                      'tags',
                                                      points
                                                    );
                                                  }
                                                }}
                                              >
                                                <CheckTickIcon className="icon-size" />
                                              </i>
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            name={`tags.${index}.tag`}
                                            component={TextError}
                                          />
                                        </motion.div>
                                      );
                                    })}
                                  </div>
                                )}
                                <div
                                  // outside kyepoints grid
                                  // this div controls size of the button
                                  style={{
                                    width: '24rem',
                                  }}
                                >
                                  <motion.button
                                    disabled={
                                      tags[indxOfLastItem]?.tag === '' ||
                                      tags[indxOfLastItem]?.isForm
                                    }
                                    className="ce-insert-button flex-vc"
                                    whileTap={{ scale: 0.95 }}
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        uuid: uuid(),
                                        tag: '',
                                        isForm: true,
                                      })
                                    }
                                  >
                                    + Add tags
                                  </motion.button>
                                </div>
                              </Fragment>
                            );
                          }}
                        />
                      </AnimatePresence>
                      {props.touched.tags &&
                        Boolean(props.errors.tags) &&
                        (typeof props.errors.tags === 'string' ? (
                          <TextError>{props.errors.tags}</TextError>
                        ) : null)}
                    </div>
                    <h4 className="avenir-20-500 mb-8">
                      Visibility and sort order *
                    </h4>
                    <div className="pod-flex-vc">
                      <div className="mb-24">
                        <p
                          className="caption-1-regular-gray3 mb-16"
                          style={{ color: '#aabdc7' }}
                        >
                          Visibility
                        </p>
                        <SelectFormType1
                          options={[
                            { label: 'Public', value: 'PUBLIC' },
                            { label: 'Private', value: 'PRIVATE' },
                          ]}
                          label="professions"
                          value={props.values.visibility}
                          onChange={(value) => {
                            return props.setFieldValue(
                              'visibility',
                              value.value
                            );
                          }}
                          placeholder="Choose Visibility"
                          isMulti={false}
                        />
                        <div>
                          {props.touched.visibility &&
                            Boolean(props.errors.visibility) && (
                              <TextError>{props.errors.visibility}</TextError>
                            )}
                        </div>
                      </div>
                      <div className="mb-24 pod-margin">
                        {/* <h4 className="avenir-20-500 mb-8">Privacy *</h4> */}
                        <p
                          className="caption-1-regular-gray3 mb-16"
                          style={{ color: '#aabdc7' }}
                        >
                          Default order
                        </p>
                        <SelectFormType1
                          options={[
                            { label: 'Date published (newest)', value: 1 },
                            { label: 'Date published (oldes)', value: 2 },
                            { label: 'Date added (newest)', value: 3 },
                            { label: 'Date added (oldest)', value: 4 },
                            { label: 'Manually sorted in Confemy', value: 5 },
                          ]}
                          label="Sorting order"
                          value={props.values.sortOrder}
                          onChange={(value) => {
                            return props.setFieldValue(
                              'sortOrder',
                              value.value
                            );
                          }}
                          placeholder="Choose sorting"
                          isMulti={false}
                        />
                        <div>
                          {props.touched.sortOrder &&
                            Boolean(props.errors.sortOrder) && (
                              <TextError>{props.errors.sortOrder}</TextError>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-32">
                      <h4 className="avenir-20-500 mb-8">
                        Add podcast banner image
                      </h4>
                      {/* className="confbanner-dropzone-container " controls
                        the size of SingleImageUploader */}
                      <div className="event-banner-dropzone-container position-relative">
                        {props.values?.banner?.length > 0 ? (
                          props.values.banner?.map((image) => (
                            <div
                              key={image.Key}
                              className="confbanner-container"
                            >
                              <div className="confbanner-wrap">
                                <img
                                  className="confbanner"
                                  alt="course banner"
                                  src={image.Location}
                                  // Revoke data uri after image is loaded
                                  // onLoad={() => {
                                  //   URL.revokeObjectURL(image.Location);
                                  // }}
                                />
                              </div>
                              <div className="confbanner-overlay"></div>
                              <div
                                onClick={() =>
                                  deleteFile(
                                    image?.Key,
                                    'banner',
                                    props.values.banner,
                                    props.setFieldValue
                                  )
                                }
                                className="confbanner-delete-circle"
                              >
                                <DeleteIcon className="icon-size" />
                              </div>
                            </div>
                          ))
                        ) : props.values.isBannerLoading ? (
                          <div className="singleimage-dropzone">
                            <Loader />
                          </div>
                        ) : (
                          <Dropzone
                            // accept="video/mp4"
                            multiple={false}
                            // maxSize={524288000}
                            maxFiles={1}
                            onDrop={async (acceptedFiles) => {
                              if (acceptedFiles?.length > 0) {
                                const dataObj = new FormData();
                                let oldMedia = [];
                                acceptedFiles.map((item) =>
                                  !item.Key
                                    ? dataObj.append('file', item)
                                    : oldMedia.push(item)
                                );

                                if (dataObj.has('file')) {
                                  props.setFieldValue('isBannerLoading', true);
                                  try {
                                    const mediaResponse = await api.post(
                                      'media/upload',
                                      dataObj
                                    );
                                    if (mediaResponse) {
                                      props.setFieldValue(
                                        'banner',
                                        mediaResponse.data.data
                                      );
                                    }
                                    props.setFieldValue(
                                      'isBannerLoading',
                                      false
                                    );
                                  } catch (err) {
                                    props.setFieldValue(
                                      'isBannerLoading',
                                      false
                                    );
                                    dispatch(
                                      alertAction(
                                        'Media failed to save',
                                        'danger'
                                      )
                                    );
                                  }
                                }
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className: 'singleimage-dropzone',
                                })}
                              >
                                <div className="text-align-center">
                                  <i>
                                    <ImageGalleryIcon className="icon-lg" />
                                  </i>
                                  <p className="caption-1-medium-primary">
                                    <u> Add file</u>
                                  </p>
                                </div>
                                <input {...getInputProps()} />
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </div>
                      <ErrorMessage name="banner" component={TextError} />
                    </div>
                    <div className="mb-60">
                      <div className="mb-72">
                        <SubmitCancelButtonWithLoader
                          isSubmitting={props.isSubmitting}
                          onCancel={() => props.resetForm({})}
                          fullWidth={true}
                        />
                      </div>
                    </div>
                    <div>{/* keep empty */}</div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </main>
    </>
  );
}
