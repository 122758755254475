import ConfCard from "../cards/ConfCard";
import NothingMatches from "./NothingMatches";

export default function SearchResult({
  result,
  isLoading,
  potentialUserForm,
  setConferenceId,
}) {
  return (
    <>
      {!isLoading && result?.length === 0 ? (
        <div className="mt-80">
          <NothingMatches />
        </div>
      ) : (
        <div className="sr-container">
          {result?.map((conf) => (
            <div key={conf._id}>
              <ConfCard
                conf={conf}
                src={conf?.banner[0]?.Location}
                title={conf.title}
                startDate={conf.startDate}
                endDate={conf.endDate}
                timezone={conf.timezone}
                mode={conf.mode}
                city={conf.city}
                credits={conf.credits}
                currency={conf.currency}
                basePrice={conf.basePrice}
                confId={conf._id}
                apiIsLiked={conf.isLiked}
                scraped={conf?.scraped}
                potentialUserForm={potentialUserForm}
                url={conf?.url}
                setConferenceId={setConferenceId}
              />
            </div>
          ))}
          <div
            className={
              isLoading && result?.length > 0
                ? "sr-container-overlay"
                : "display-none"
            }
          ></div>
        </div>
      )}
    </>
  );
}
