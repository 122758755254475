import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import { loadUserCustomExternalCreditsTypeAction } from '../../redux/user-profile/userProfileAction';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import MaterialTextInput from '../formik/MaterialTextInput';

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
});

const ExternalCreditsTypeForm = ({
  editMode,
  setEditMode,
  setShowExternalCreditForm,
  editData,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const onSubmit = async (values) => {
    const { name } = values;

    const data = {
      creditDetails: {
        name: name,
        user: user?._id,
      },
    };

    async function apiCall() {
      try {
        let response;
        if (editMode) {
          response = await api.patch(
            `attendees/credits/${editData?.value}/external?userId=${user?._id}&&type=ownCredit`,
            data
          );
        } else {
          response = await api.post(
            `/attendees/credits/external?&&userId=${user?._id}&&type=user`,
            data
          );
        }

        if (response) {
          if (editMode) {
            dispatch(
              loadUserCustomExternalCreditsTypeAction(
                response.data.data.credits
              )
            );
            setEditMode(false);
          } else {
            dispatch(
              loadUserCustomExternalCreditsTypeAction(
                response.data.data.userCredits
              )
            );
            setShowExternalCreditForm(false);
          }
          dispatch(alertAction(response.data.message, 'success'));
        }
      } catch (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
      }
    }

    apiCall();
  };
  // onSubmit Ends

  const initialValues = {
    name: editData?.label || '',
  };

  return (
    <>
      <div className="modalx-container-sm pb-40">
        <h2 className="text-align-center color-primary mb-24">
          {editMode ? 'Update credit Type' : 'Add credit type'}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div className="form-type-1">
                  <div className="mb-24">
                    <MaterialTextInput
                      id="creditName"
                      type="text"
                      label="Name*"
                      name="name"
                      placeholder=" "
                    />
                  </div>

                  <div>
                    <SubmitCancelButtonWithLoader
                      fullWidth={true}
                      isSubmitting={props.isSubmitting}
                      onCancel={() => {
                        if (editMode) {
                          setEditMode(false);
                        } else {
                          setShowExternalCreditForm(false);
                        }
                      }}
                      cancelButtonClass="button button-green"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ExternalCreditsTypeForm;

ExternalCreditsTypeForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func,
  setShowExternalCreditForm: PropTypes.func,
};
