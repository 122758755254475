import { useState } from 'react';
import '../organizer-dashboard/welcome.styles.scss';
import UpcomingConfCard from './UpcomingConfCard';
// import required modules
// Import Swiper styles

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './upcomingConfCard.scss';

export default function UpcomingConfs({ upcomingConfs }) {
  const [showPotentialUserForm, setShowPotentialUserForm] = useState(false);

  return (
    <div className="upcomingConf-card">
      <p className="caption-1-heavy-primary mb-12 ml-16">
        Upcoming conferences
      </p>
      <Swiper
        slidesPerView={1}
        // // spaceBetween={40}
        spaceBetween={8}
        // cssMode={true}
        // // navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        // // scrollbar={{ draggable: true }}
        // // mousewheel={true}
        // keyboard={true}
        // modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="courseSwiper"
        breakpoints={{
          1: {
            slidesPerView: 1,
          },
          650: {
            slidesPerView: 1,
          },
          980: {
            slidesPerView: 1,
          },
          1280: {
            slidesPerView: 1,
          },
        }}
      >
        {upcomingConfs?.length > 0 &&
          upcomingConfs?.map((item) => {
            return (
              <SwiperSlide key={item?._id}>
                <UpcomingConfCard
                  key={item._id}
                  data={item}
                  potentialUserForm={setShowPotentialUserForm}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
