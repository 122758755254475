import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrganizationAction } from '../../redux/organization/organizationAction';
import api from '../../utility/api';
import CloseIcon from '../icons/CloseIcon';
import SettingsIcon from '../icons/SettingsIcon';

import './showOrganizers.styles.scss';

export default function ShowOrganizers({ organizers, organizationId }) {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const removeOrganizer = async (orgnizerId) => {
    const url = `organizations/${organizationId}/organizers/${orgnizerId}/users/${user._id}`;

    try {
      const response = await api.delete(url);
      // if (response) loadOrganization(organizationId, user._id);
      if (response)
        dispatch(loadOrganizationAction(response.data.data.organization));
    } catch (err) {}
  };

  return (
    <>
      {organizers &&
        organizers.map((org) => (
          <div key={org._id} className="show-organizers-grid">
            <div>
              <p className="body-bold ellipsis-text">
                {org?.user?.firstName} {org?.user?.lastName}
              </p>
              <div className="body-regular-gray3 ellipsis-text">
                {org?.user?.email}
              </div>
            </div>
            <div>
              {!org?.user?.userRegistered ? (
                <span className="caption-2-regular-gray3">Pending invite</span>
              ) : (
                <span className="caption-2-regular-gray3">Active</span>
              )}
            </div>
            <div className="flex-vc">
              {org?.isAdmin ? (
                <div className="flex-vc">
                  <span className="caption-2-regular-gray3 mr-8">Admin</span>
                  <SettingsIcon className="icon-xs" />{' '}
                </div>
              ) : org?.active ? (
                <button
                  onClick={() => removeOrganizer(org._id)}
                  type="button"
                  className="button button-primary"
                >
                  Remove
                </button>
              ) : (
                <button
                  onClick={() => removeOrganizer(org._id)}
                  type="button"
                  className="social-delete-button"
                >
                  <CloseIcon className="icon-size" fill="#000000" />
                </button>
              )}
            </div>
          </div>
        ))}
    </>
  );
}

ShowOrganizers.propTypes = {
  organizers: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
};
