import { useEffect } from 'react';
import LinkBankAccount from '../../components/link-bank-account/LinkBankAccount';
export default function LinkBankAccountPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <LinkBankAccount />
    </>
  );
}
