import PropTypes from "prop-types";
import Loader from "../loader/Loader";
import "./button.styles.scss";

export default function ButtonWithLoader({
  isSubmitting,
  text,
  className,
  fullWidth,
  handleClick,
  icon,
}) {
  return (
    <div
      className={fullWidth ? "fullwidth-submit-loader" : "submit-loader-wrap"}
    >
      <button
        style={{
          ...(isSubmitting && {
            backgroundColor: "#c4c4c4",
            borderColor: "#c4c4c4",
          }),
        }}
        type="button"
        disabled={isSubmitting}
        className={className}
        onClick={() => handleClick()}
      >
        {/* {!isSubmitting && `${text} `} */}
        {!isSubmitting && (
          <div className="flex-vchc">
            <i
              style={{
                ...(icon && {
                  marginRight: 8,
                }),
              }}
            >
              {icon}
            </i>
            <span>{text}</span>
          </div>
        )}
      </button>
      {isSubmitting && <Loader />}
    </div>
  );
}

ButtonWithLoader.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.node,
};
