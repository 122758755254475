import React from "react";
import "./howItWorks.styles.scss";

function HowItWorks() {
  return (
    <section className="how-it-works ">
      <h2 className="how-it-works-heading">What is CONFEMY?</h2>
      <p className="body-regular-gray3 ">
        Organizers can create Conferences or CME and publish it on Confemy.
        <br />
        One can also Sell conference tickets and interested attendees can Buy
        them.
        <br />
        Managing Bookings, Cancelling tickets and Refunding tickets can all be
        done through Organizers Dashboard.
        <br />
        Attendees can also Track CME Credits and Receive CME Certificates here
        itself.
        <br />
        Keep all your Bookings, Cerfiticates & Credits on CONFEMY for records.
      </p>
    </section>
  );
}

export default HowItWorks;
