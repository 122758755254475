import { useState } from "react";

import VideoCourseCredits from "../../components/user-credits/VideoCourseCredits";

export default function VideoCreditsPage() {
  const [confTotalStartDate, setConfTotalStartDate] = useState(null);
  const [confTotalEndDate, setConfTotalEndDate] = useState(null);
  return (
    <div>
      <VideoCourseCredits
        confTotalStartDate={confTotalStartDate}
        confTotalEndDate={confTotalEndDate}
      />
    </div>
  );
}
