import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import CelebrationIcon from '../icons/CelebrationIcon';
import PassesIcon from '../icons/PassesIcon';
import ErrorIcon from '../icons/ErrorIcon';
import PaymentStatusDisplay from './PaymentStatusDisplay';

import Loader from '../loader/Loader';
import './paymentStatus.scss';

const StripePaymentStatus = ({ confId }) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);
  const [icon, setIcon] = useState(null);
  const [url, setUrl] = useState('/');
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Success! Payment received.');
          setMessageTitle('Success!');
          setIcon(<CelebrationIcon className="celebration-icon-size" />);
          setUrl('/attendee-dashboard/purchase-history');
          setIsLoading(false);
          break;

        case 'processing':
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          setMessageTitle('Processing');
          setIcon(<PassesIcon className="icon-xxl" />);
          setUrl('/attendee-dashboard/purchase-history');
          setIsLoading(false);
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessageTitle('Failed Payment');
          setMessage('Payment failed. Please try another payment method.');
          setIcon(<ErrorIcon className="icon-xxl" />);
          setUrl(`/book-conference/${confId}`);
          setIsLoading(false);
          break;

        default:
          setMessage('Something went wrong.');
          setMessageTitle('Something Went Wrong');
          setIcon(<ErrorIcon className="icon-xxl" />);
          setUrl(`/book-conference/${confId}`);
          setIsLoading(false);
          break;
      }
    });
  }, [stripe]);

  return isLoading ? (
    <Loader />
  ) : (
    <PaymentStatusDisplay
      message={message}
      messageTitle={messageTitle}
      url={url}
      icon={icon}
    />
  );
};

export default StripePaymentStatus;
