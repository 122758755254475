import React from 'react';

function SettingsIcon({ className }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 24 24'
      width="2rem"
    >
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <path
                  d='M0 0H24V24H0z'
                  transform='translate(-1139.000000, -489.000000) translate(1115.000000, 64.000000) translate(0.000000, 404.000000) translate(24.000000, 21.000000)'
                />
                <path
                  fill='#08415C'
                  d='M19.5 12c0-.23-.01-.45-.03-.68l1.86-1.41c.4-.3.51-.86.26-1.3l-1.87-3.23c-.25-.44-.79-.62-1.25-.42l-2.15.91c-.37-.26-.76-.49-1.17-.68l-.29-2.31c-.06-.5-.49-.88-.99-.88h-3.73c-.51 0-.94.38-1 .88l-.29 2.31c-.41.19-.8.42-1.17.68l-2.15-.91c-.46-.2-1-.02-1.25.42L2.41 8.62c-.25.44-.14.99.26 1.3l1.86 1.41c-.02.22-.03.44-.03.67 0 .23.01.45.03.68l-1.86 1.41c-.4.3-.51.86-.26 1.3l1.87 3.23c.25.44.79.62 1.25.42l2.15-.91c.37.26.76.49 1.17.68l.29 2.31c.06.5.49.88.99.88h3.73c.5 0 .93-.38.99-.88l.29-2.31c.41-.19.8-.42 1.17-.68l2.15.91c.46.2 1 .02 1.25-.42l1.87-3.23c.25-.44.14-.99-.26-1.3l-1.86-1.41c.03-.23.04-.45.04-.68zm-7.46 3.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z'
                  transform='translate(-1139.000000, -489.000000) translate(1115.000000, 64.000000) translate(0.000000, 404.000000) translate(24.000000, 21.000000)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SettingsIcon;
