import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import ConfSteps from '../../components/create-conference/ConfSteps';
import VideoCourseSteps from '../../components/create-video-course/VideoCourseSteps';

import ModalX from '../../components/modal/ModalX';
import { removeConferenceStateAction } from '../../redux/conference/conferenceAction';
import { removeVideoCourseStateAction } from '../../redux/video-course/videoCourseAction';
import AudioCourseSteps from '../../components/create-audio-course/AudioCourseSteps';
import { removeAudioStateAction } from '../../redux/audio-course/audioCourseAction';

export default function CreateAudioCourseLayoutPage() {
  const [skip, setskip] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDismiss = () => {
    setskip(true);
  };

  const onSetupOrganizationClick = () => {
    navigate('/dashboard/create-organization');
  };

  useEffect(() => {
    return () => {
      dispatch(removeAudioStateAction());
    };
  }, []);

  if (!user?.hasOrganization && !skip) {
    return (
      <>
        <ModalX onDismiss={onDismiss} open={!user?.hasOrganization && !skip}>
          <div className="modalx-container-md pl-32 pr-32 pb-32">
            <div>
              <h2 className="color-primary text-align-center mb-32">
                Create Organization
              </h2>
              <div className="text-align-center body-regular-gray3 mb-32">
                <p>
                  We recommend to host video cme course under an organization.
                </p>
                <p>Set up your organization before creating a conference.</p>
                <p>Skip to host it under your name.</p>
              </div>
              <div className="text-align-center">
                <button
                  onClick={onSetupOrganizationClick}
                  className="button button-primary mx-4"
                >
                  Setup organization now
                </button>
                <button
                  onClick={onDismiss}
                  className="button-text button-text-primary mx-4"
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>
        </ModalX>
      </>
    );
  }

  return (
    <div>
      <AudioCourseSteps />
      <div>
        <Outlet />
      </div>
    </div>
  );
}
