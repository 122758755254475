import CameraIcon from '../icons/CameraIcon';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import { getFormattedDateInTz2 } from '../../utility/commonUtil';
import './myConfsCard.styles.scss';

export default function MyConfsCard({
  banner,
  title,
  startDate,
  endDate,
  timezone,
  city,
  country,
  mode,
  confId,
  onCardClick,
}) {
  const getLocationString = () => {
    let locationStrig = 'Location';
    if (mode?.length > 0) {
      if (mode?.includes('venue') && city && country) {
        locationStrig = `${city}, ${country}`;
      }

      if (mode?.includes('onlineConf')) {
        locationStrig = 'Online';
      }

      if (mode?.includes('venue') && mode?.includes('onlineConf')) {
        locationStrig = `${city}, ${country} & Online`;
      }
    }
    return locationStrig;
  };

  return (
    <div className="myconfs-imgcard-wrap" onClick={() => onCardClick()}>
      <div className="myconfs-img-wrap">
        {banner?.length > 0 ? (
          <img
            className="myconfs-img"
            alt="preview"
            src={banner[0]?.Location}
          />
        ) : (
          <div className="myconfs-no-img">
            <div className="text-align-center">
              <i>
                <CameraIcon className="icon-size" />{' '}
              </i>
            </div>
          </div>
        )}
      </div>
      <div className="myconfs-card">
        <div className="myconfs-card-header mb-6">
          <p style={{ fontSize: 16 }}>{title}</p>
        </div>
        <div className="myconfs-card-body caption-2-regular-gray3">
          {startDate && (
            <div className="flex-vc  mb-6">
              <DateIcon className="icon-xxs mr-12" />
              {/* <span>Start: {formattedStartDate}</span> */}
              <span style={{ whiteSpace: 'nowrap' }}>
                Start: {getFormattedDateInTz2(startDate, timezone)}{' '}
              </span>
            </div>
          )}

          {endDate && (
            <div className="flex-vc  mb-6">
              <DateIcon className="icon-xxs mr-12" />
              <span style={{ whiteSpace: 'nowrap' }}>
                End: {getFormattedDateInTz2(endDate, timezone)}
              </span>
            </div>
          )}

          {city && (
            <div className="flex-vc  mb-4">
              <LocationIcon className="icon-xxs mr-12" />
              <span>{getLocationString()}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
