import PropTypes from "prop-types";
import Loader from "../loader/Loader";
import "./button.styles.scss";

export default function SubmitCancelButtonWithLoader({
  isSubmitting,
  onCancel,
  cancelButtonClass,
  fullWidth,
}) {
  return (
    <section
      className={
        fullWidth ? "fullwidth-submit-cancel-wrap" : "submit-cancel-loader-wrap"
      }
    >
      <div className="position-relative mr-4">
        <button
          style={{
            ...(isSubmitting && {
              backgroundColor: "#c4c4c4",
              borderColor: "#c4c4c4",
            }),
          }}
          type="submit"
          disabled={isSubmitting}
          className="button button-green"
        >
          {!isSubmitting && "Submit"}
        </button>
        {isSubmitting && <Loader />}
      </div>
      <div className="ml-4">
        <button
          onClick={() => onCancel()}
          type="button"
          className={
            cancelButtonClass ? cancelButtonClass : "button button-primary"
          }
        >
          Cancel
        </button>
      </div>
    </section>
  );
}

SubmitCancelButtonWithLoader.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelButtonClass: PropTypes.string,
  fullWidth: PropTypes.bool,
};
