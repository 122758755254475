import axios from 'axios';
import {
  AUDIO_COURSE_ERROR,
  AC_SEARCH_DONE,
  AC_SEARCH_INITIATED,
  CREATE_AUDIO_COURSE,
  GET_AUDIO_COURSE,
  LOAD_ALL_MY_ACS,
  LOAD_INCOMPLETE_AC,
  LOAD_INCOMPLETE_ACS,
  REMOVE_NEWAC_STATE,
  GET_SINGLE_AUDIO_COURSE,
  LOAD_USER_AUDIO_COURSE_CREDIT,
} from './audioCourseTypes';

// Creat a videoCourse
export const createAudioCourseAction = (data) => (dispatch) => {
  dispatch({
    type: CREATE_AUDIO_COURSE,
    payload: data,
  });
};

// remove new videoCourse redux state
export const removeAudioStateAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_NEWAC_STATE,
  });
};

//  load one incomplete videoCourse
export const loadOneIncopleteACAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_INCOMPLETE_AC,
    payload: data,
  });
};
//  load all incomplete videoCourses

export const loadIncopleteACsAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_INCOMPLETE_ACS,
    payload: data,
  });
};

export const loadAllMyACsAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_ALL_MY_ACS,
    payload: data,
  });
};

export const searchACsAction = (data) => (dispatch) => {
  dispatch({
    type: AC_SEARCH_DONE,
    payload: data,
  });
};

export const loadSingleAudioCourseAction = (data) => (dispatch) => {
  dispatch({
    type: GET_SINGLE_AUDIO_COURSE,
    payload: data,
  });
};

export const loadUserAudioCourseCreditAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_USER_AUDIO_COURSE_CREDIT,
    payload: data,
  });
};

export const ACSearchInitiatedAction = () => (dispatch) => {
  dispatch({
    type: AC_SEARCH_INITIATED,
  });
};

// get a videoCourse

export const getAudioCourseAction = () => async (dispatch) => {
  try {
    const res = await axios({
      method: 'GET',
      withCredentials: true,
      url: 'http://localhost:5000/api/audioCourse/:videoCourse_id',
    });
    dispatch({ type: GET_AUDIO_COURSE, payload: res.data });
  } catch (err) {
    dispatch({
      type: AUDIO_COURSE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
