import { GET_USER_LOCATION } from "./locationTypes";

const initialState = {
  userLocation: null,
};
function userLocationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_LOCATION:
      return {
        ...state,
        userLocation: payload,
      };
    default:
      return state;
  }
}
export default userLocationReducer;
