export const GET_AUDIO_COURSE = 'GET_AUDIO_COURSE';
// export const REMOVE_VIDEO_COURSE = 'REMOVE_VIDEO_COURSE';
export const AUDIO_COURSE_ERROR = 'AUDIO_COURSE_ERROR';
export const CREATE_AUDIO_COURSE = 'CREATE_AUDIO_COURSE';
export const REMOVE_NEWAC_STATE = 'REMOVE_NEWAC_STATE';
export const LOAD_INCOMPLETE_ACS = 'LOAD_INCOMPLETE_ACS';
export const LOAD_INCOMPLETE_AC = 'LOAD_INCOMPLETE_AC';
export const LOAD_ALL_MY_ACS = 'LOAD_ALL_MY_ACS';
export const AC_SEARCH_DONE = 'AC_SEARCH_DONE';
export const AC_SEARCH_INITIATED = 'AC_SEARCH_INITIATED';
export const GET_SINGLE_AUDIO_COURSE = 'GET_SINGLE_AUDIO_COURSE';
export const LOAD_USER_AUDIO_COURSE_CREDIT = 'LOAD_USER_AUDIO_COURSE_CREDIT';

// VC is video course
