import { useNavigate } from 'react-router-dom';
import { AttendeeSideMenuOptions } from './navbarUtil';
import MenuItem from './MenuItem';

import './organizersNavbar.styles.scss';

export default function AttendeeSideNavbar({}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="sidemenu-inner pb-64">
        <h2
          style={{
            backgroundColor: '#ecf0f2',
            // backgroundColor: '#dceef8',
            fontSize: '18px',
            fontWeight: 900,
            lineHeight: 1.44,
            textAlign: 'center',
            color: '#08415c',
            padding: '25px 12px 25px',
            borderRadius: '6px',
            // backgroundColor: '#dceef8',
          }}
          onClick={() => {
            navigate('/attendee-dashboard/dashboard');
          }}
          className="mb-30"
        >
          Attendee Dashboard
        </h2>
        <ul>
          {AttendeeSideMenuOptions.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
        </ul>
      </div>
    </>
  );
}
