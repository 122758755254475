import { useEffect, useState } from 'react';
import api from '../../utility/api';
import ConfCard from '../cards/ConfCard';
import './trendingConfs.scss';
import SeeAll from '../see-all/SeeAll';

export default function TrendingConfs() {
  const [data, setData] = useState();
  // const [data1, setData1] = useState();
  // const [data2, setData2] = useState();

  const loadData = async () => {
    try {
      const response = await api.get('/homePage/trendings');
      setData(response.data.data.trendingConferences);
      // if (response) {
      //   const confset1 = response.data.data.trendingConferences;
      //   let confset2 = confset1?.splice(10);
      //   setData1(confset1);
      //   setData2(confset2);
      // }
    } catch (err) {}
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    data?.length > 0 && (
      <div className="ccah-container bg-background">
        <div className="ccah-header-wrap">
          <h2>Trending Conferences & CME</h2>
        </div>
        <div className="tc-container mb-8 mt-16">
          <div className="home-conf-grid">
            {data?.map((item) => (
              <div key={item.conference?._id}>
                <ConfCard conf={item.conference} />
              </div>
            ))}
          </div>
        </div>
        <SeeAll />
      </div>
    )
  );
}
