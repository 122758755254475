import { useSelector } from "react-redux";
import Tabs from "../tabs/Tabs";

export default function ConfSteps() {
  const newConference = useSelector((state) => state.conference?.newConference);

  const steps = [
    {
      label: "Basic Info",
      path: "/dashboard/create-conf/step-1",
      completed: newConference?.completedStep1,
    },
    {
      label: "Details 1",
      path: "/dashboard/create-conf/step-2",
      completed: newConference?.completedStep2,
    },
    {
      label: "Details 2",
      path: "/dashboard/create-conf/step-3",
      completed: newConference?.completedStep3,
    },
    {
      label: "Livestream",
      path: "/dashboard/create-conf/step-4",
      completed: newConference?.completedStep4,
    },
    {
      label: "Tickets",
      path: "/dashboard/create-conf/step-5",
      completed: newConference?.completedStep5,
    },
    {
      label: "Preview",
      path: "/dashboard/create-conf/step-6",
      completed: newConference?.completedStep6,
    },
  ];

  return (
    // <div className="conf-steps-container">
    //   <h2>Create Conference Steps</h2>
    //   <Tabs tabs={steps} showRadioButtons={true} />
    // </div>
    <div>
      <h2 className="color-primary mb-16">Create Conference</h2>
      <div
        style={{
          marginBottom: 24,
          borderBottom: "1px solid #ced9de",
        }}
      ></div>
      <div className="mb-24">
        <Tabs tabs={steps} showRadioButtons={true} />
      </div>
    </div>
  );
}
