export default function ImageGalleryIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3333 4H6.66667C5.19391 4 4 5.19391 4 6.66667V25.3333C4 26.8061 5.19391 28 6.66667 28H25.3333C26.8061 28 28 26.8061 28 25.3333V6.66667C28 5.19391 26.8061 4 25.3333 4Z"
        stroke="#CED9DE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.334 13.334C12.4386 13.334 13.334 12.4386 13.334 11.334C13.334 10.2294 12.4386 9.33398 11.334 9.33398C10.2294 9.33398 9.33398 10.2294 9.33398 11.334C9.33398 12.4386 10.2294 13.334 11.334 13.334Z"
        stroke="#CED9DE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9993 20.0007L21.3327 13.334L6.66602 28.0007"
        stroke="#CED9DE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
