import { useState } from "react";
import ExternalCreditsTable from "../../components/user-credits/ExternalCreditsTable";

export default function ExternalCreditsPage() {
  const [totalStartDate, setTotalStartDate] = useState(null);
  const [totalEndDate, setTotalEndDate] = useState(null);
  const [externalStartDate, setExternalStartDate] = useState(null);
  const [externalEndDate, setExternalEndDate] = useState(null);

  return (
    <div>
      <ExternalCreditsTable
        totalStartDate={totalStartDate}
        totalEndDate={totalEndDate}
        externalStartDate={externalStartDate}
        externalEndDate={externalEndDate}
        setExternalStartDate={setExternalStartDate}
        setExternalEndDate={setExternalEndDate}
      />
    </div>
  );
}
