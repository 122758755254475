import { useNavigate } from 'react-router-dom';

import './exploreEvents.scss';

export default function ExploreEvents() {
  const navigate = useNavigate();

  return (
    <div className="attendee-event-welcome">
      <div>
        <div>
          <h2 className="caption-1-medium-primary mb-16">
            Explore conferences, video courses or podcast from here
          </h2>

          <button
            // style={{
            //   background: 'rgb(80, 72, 229)',
            //   border: 'none',
            // }}
            className="button button-primary "
            onClick={() => navigate('/search-conference')}
          >
            Explore events
          </button>
        </div>
      </div>
    </div>
  );
}
