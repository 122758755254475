import { useLocation } from "react-router";
import PaymentStatusDisplay from "../../components/booking/PaymentStatusDisplay";

export default function FreeBookingStatusPage() {
  const { state } = useLocation();

  return (
    <div className="container pt-64">
      {state?.bookingStatus && (
        <PaymentStatusDisplay
          message="Booked successfully"
          messageTitle="Success"
          icon=<div className="success-tick-wrap">
            <span className="success-tick">✓</span>
          </div>
          url="/user-profile/tickets"
        />
      )}
    </div>
  );
}
