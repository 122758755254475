import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseMenu from '../../components/icons/CloseMenu';
import DashMenuIcon from '../../components/icons/DashMenuIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import Loader from '../../components/loader/Loader';
import PaginationForSearch from '../../components/pagination/PaginationForSearch';
import SearchFilters from '../../components/search/SearchFilters';
import SearchResult from '../../components/search/SearchResult';
import api from '../../utility/api';
import './searchPage.styles.scss';
import {
  initialFilterValues,
  loadSearchResults,
} from '../../utility/commonUtil';

export default function SearchPage() {
  const [filters, setFilters] = useState(initialFilterValues);
  const [query, setQuery] = useState('');
  const [mode, setMode] = useState('allConferences');

  const [currentPageNumber, setCurrentPage] = useState({ currentPage: 1 });

  const [scrollPosition, setScrollPosition] = useState(0);

  const search = useSelector((state) => state.conference.search);

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (!e.target.value.length > 0) {
      setCurrentPage({ currentPage: 1 });
      loadSearchResults(e.target.value, filters, mode, 1);
    }
  };

  // const onLocationChange = (location) => setLocation(location)
  const onLocationChange = (location) => {
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({ ...prev, location: location }));
    loadSearchResults(query, { ...filters, location: location }, mode, 1);
  };

  const onFilterDateChange = (date, dateName) => {
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({ ...prev, [dateName]: date }));
    loadSearchResults(query, { ...filters, [dateName]: date }, mode, 1);
  };

  const onProfessionChange = (value) => {
    if (filters.profession !== value?.value) {
      setFilters((prev) => ({ ...prev, specialities: [] }));
    }
    if (!value?.value) {
      // setSpltDisabled(true);
      setFilters((prev) => ({ ...prev, specialityDisabled: true }));
    } else {
      // setSpltDisabled(false);
      setFilters((prev) => ({ ...prev, specialityDisabled: false }));
    }
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({ ...prev, profession: value?.value }));
    loadSearchResults(query, { ...filters, profession: value?.value }, mode, 1);
  };

  const onSpecialitiesChange = (speciality) => {
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({ ...prev, specialities: speciality }));
    loadSearchResults(query, { ...filters, specialities: speciality }, mode, 1);
  };

  const onCreditTypeChange = (credit) => {
    if (!credit?.value) {
      // setCreditAmountDisabled(true);
      setFilters((prev) => ({
        ...prev,
        creditAmount: '',
        creditAmountDisabled: true,
      }));
    } else {
      // setCreditAmountDisabled(false);
      setFilters((prev) => ({ ...prev, creditAmountDisabled: false }));
    }
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({ ...prev, creditType: credit?.value }));
    loadSearchResults(
      query,
      { ...filters, creditType: credit?.value },
      mode,
      1
    );
  };
  const onCreditAmountChange = (e) => {
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({
      ...prev,
      creditAmount: e.target.value ? parseInt(e.target.value) : '',
    }));
    loadSearchResults(
      query,
      {
        ...filters,
        creditAmount: e.target.value ? parseInt(e.target.value) : '',
      },
      mode,
      1
    );
  };

  const onCurrencyChange = (currency) => {
    if (!currency?.value) {
      // setPriceDisabled(true);
      // setMinPrice(0);
      // setMaxPrice(0);
      setFilters((prev) => ({
        ...prev,
        priceDisabled: true,
        minPrice: '',
        maxPrice: '',
      }));
    } else {
      // setPriceDisabled(false);
      setFilters((prev) => ({
        ...prev,
        priceDisabled: false,
      }));
    }
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({ ...prev, currency: currency?.value }));
    loadSearchResults(
      query,
      { ...filters, currency: currency?.value },
      mode,
      1
    );
  };

  const onFilterPriceChange = (e, filterName) => {
    setCurrentPage({ currentPage: 1 });
    setFilters((prev) => ({
      ...prev,
      [filterName]: e.target.value ? parseInt(e.target.value) : '',
    }));
    loadSearchResults(
      query,
      {
        ...filters,
        [filterName]: e.target.value ? parseInt(e.target.value) : '',
      },
      mode,
      1
    );
  };
  // async function to get locations. its called on every key change in location filter
  const loadLocations = async (searchText, callback) => {
    const response = await api.get(`venues/search?venue=${searchText}`);
    callback(response.data.data.venue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCurrentPage({ currentPage: 1 });
    loadSearchResults(query, filters, mode, 1);
  };

  function clearAllFilters() {
    setCurrentPage({ currentPage: 1 });
    setFilters(initialFilterValues);
    // setSpltDisabled(true);
    // setCreditAmountDisabled(true);
    // setPriceDisabled(true);
    loadSearchResults(query, initialFilterValues, mode, 1);
  }

  const renderSearchTotal = (mode) => {
    switch (mode) {
      case 'allConferences':
        return search.allTotal;
      case 'venue':
        return search.atVenueTotal;
      case 'livestreamConfs':
        return search.onlineTotal;
      case 'video':
        return search.videoTotal;
      case 'audio':
        return search.audioTotal;
      default:
        break;
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilters((prev) => ({ ...prev, openFilterDrawer: false }));
      }
    };
    document.body.addEventListener('click', onBodyClick, { capture: true });
    return () => {
      document.body.removeEventListener('click', onBodyClick, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    setScrollPosition(window.scrollY);
    window.scrollTo(0, scrollPosition);
  }, []);

  useEffect(() => {
    const savedFilters = JSON.parse(window.localStorage.getItem('CS_FILTERS'));
    const savedQuery = JSON.parse(window.localStorage.getItem('CS_QUERY'));
    const savedMode = JSON.parse(window.localStorage.getItem('CS_MODE'));
    const savedCurrentPageNumber = JSON.parse(
      window.localStorage.getItem('CS_CURRENTPAGENUMBER')
    );

    if (savedFilters !== null) {
      // prepare filters object to have date object before rehydrating sate
      const filtersWithDateObj = {
        ...savedFilters,
        startDate: savedFilters?.startDate
          ? new Date(savedFilters?.startDate)
          : null,
        endDate: savedFilters?.endDate ? new Date(savedFilters?.endDate) : null,
      };
      setFilters(filtersWithDateObj);
    }
    if (savedQuery !== null) setQuery(savedQuery);
    if (savedMode !== null) setMode(savedMode);
    if (savedCurrentPageNumber !== null) setCurrentPage(savedCurrentPageNumber);

    loadSearchResults(
      savedQuery ? savedQuery : '',
      savedFilters ? savedFilters : initialFilterValues,
      savedMode ? savedMode : 'allConferences',
      savedCurrentPageNumber ? savedCurrentPageNumber.currentPage : 1
    );
  }, []);

  useEffect(() => {
    window.localStorage.setItem('CS_FILTERS', JSON.stringify(filters));
    window.localStorage.setItem('CS_QUERY', JSON.stringify(query));
    window.localStorage.setItem('CS_MODE', JSON.stringify(mode));

    return () => {
      window.localStorage.removeItem('CS_FILTERS');
      window.localStorage.removeItem('CS_MODE');
      window.localStorage.removeItem('CS_QUERY');
    };
  }, [filters, query, mode]);

  useEffect(() => {
    window.localStorage.setItem(
      'CS_CURRENTPAGENUMBER',
      JSON.stringify(currentPageNumber)
    );
    return () => {
      window.localStorage.removeItem('CS_CURRENTPAGENUMBER');
    };
  }, [currentPageNumber]);

  return (
    <div className="container pt-64">
      <div className="sp-container">
        <div className="sf-container">
          <SearchFilters
            filters={filters}
            onLocationChange={onLocationChange}
            onFilterDateChange={onFilterDateChange}
            loadLocations={loadLocations}
            onProfessionChange={onProfessionChange}
            onSpecialitiesChange={onSpecialitiesChange}
            onCreditTypeChange={onCreditTypeChange}
            onCreditAmountChange={onCreditAmountChange}
            onCurrencyChange={onCurrencyChange}
            onFilterPriceChange={onFilterPriceChange}
            clearAllFilters={clearAllFilters}
          />
        </div>
        <div className="container-off-sf">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="sb-container">
              <div
                style={{ flexGrow: 1, position: 'relative' }}
                className="form-type-2"
              >
                <input
                  type="text"
                  name="query"
                  value={query}
                  onChange={onQueryChange}
                  placeholder="Search by conference title or tags..."
                />
                <i
                  className={
                    query?.length > 0
                      ? 'display-none'
                      : 'conf-search-input-icon'
                  }
                >
                  <SearchIcon width="2.4rem" height="2.4rem" />
                </i>
              </div>
              <button
                type="submit"
                style={{
                  padding: '1.4rem 2.4rem',
                  maxHeight: '4.6rem',
                  marginRight: '1.6rem',
                }}
                className="button button-primary ml-16"
              >
                Search
              </button>
            </div>
            <div className="sr-tabs-container ">
              <div
                className={`${
                  mode === 'allConferences'
                    ? 'active-search-type'
                    : 'inactive-search-type '
                }`}
              >
                <input
                  type="radio"
                  style={{ display: 'none' }}
                  id="searchAllConf"
                  name="mode"
                  value="allConferences"
                  checked={mode === 'allConferences'}
                  onChange={(e) => {
                    setCurrentPage({ currentPage: 1 });
                    setMode(e.target.value);
                    loadSearchResults(query, filters, e.target.value, 1);
                  }}
                />
                <label style={{ cursor: 'pointer' }} htmlFor="searchAllConf">
                  All ({search.allTotal})
                </label>
              </div>
              <div
                className={`${
                  mode === 'venue'
                    ? 'active-search-type'
                    : 'inactive-search-type '
                }`}
              >
                <input
                  type="radio"
                  style={{ display: 'none' }}
                  name="mode"
                  id="searchVenue"
                  value="venue"
                  checked={mode === 'venue'}
                  onChange={(e) => {
                    setCurrentPage({ currentPage: 1 });
                    setMode(e.target.value);
                    loadSearchResults(query, filters, e.target.value, 1);
                  }}
                />
                <label style={{ cursor: 'pointer' }} htmlFor="searchVenue">
                  At Venue ({search.atVenueTotal || 0})
                </label>
              </div>
              <div
                className={`${
                  mode === 'livestreamConfs'
                    ? 'active-search-type'
                    : 'inactive-search-type '
                }`}
              >
                <input
                  type="radio"
                  style={{ display: 'none' }}
                  name="mode"
                  id="searchLivestreamConfs"
                  value="livestreamConfs"
                  checked={mode === 'livestreamConfs'}
                  onChange={(e) => {
                    setCurrentPage({ currentPage: 1 });
                    setMode(e.target.value);
                    loadSearchResults(query, filters, e.target.value, 1);
                  }}
                />
                <label
                  style={{ cursor: 'pointer' }}
                  htmlFor="searchLivestreamConfs"
                >
                  Online ({search.onlineTotal || 0})
                </label>
              </div>
              <div
                className={`${
                  mode === 'video'
                    ? 'active-search-type'
                    : 'inactive-search-type '
                }`}
              >
                <input
                  type="radio"
                  style={{ display: 'none' }}
                  name="mode"
                  id="searchVideo"
                  value="video"
                  checked={mode === 'video'}
                  onChange={(e) => {
                    setCurrentPage({ currentPage: 1 });
                    setMode(e.target.value);
                    loadSearchResults(query, filters, e.target.value, 1);
                  }}
                />
                <label style={{ cursor: 'pointer' }} htmlFor="searchVideo">
                  Video({search.videoTotal || 0})
                </label>
              </div>
              <div
                className={`${
                  mode === 'audio'
                    ? 'active-search-type'
                    : 'inactive-search-type '
                }`}
              >
                <input
                  type="radio"
                  style={{ display: 'none' }}
                  name="mode"
                  id="searchAudio"
                  value="audio"
                  checked={mode === 'audio'}
                  onChange={(e) => {
                    setCurrentPage({ currentPage: 1 });
                    setMode(e.target.value);
                    loadSearchResults(query, filters, e.target.value, 1);
                  }}
                />
                <label style={{ cursor: 'pointer' }} htmlFor="searchAudio">
                  Audio({search.audioTotal || 0})
                </label>
              </div>
            </div>
          </form>
          {search.isLoading && search.conferences?.length === 0 ? (
            <Loader />
          ) : (
            <>
              <SearchResult
                result={search.conferences}
                isLoading={search.isLoading}
              />
              <div className="pagination-wrap">
                <PaginationForSearch
                  currentPageNumber={currentPageNumber}
                  setCurrentPage={setCurrentPage}
                  totalRecords={renderSearchTotal(mode)}
                  pageLimit={9}
                  pageNeighbours={1}
                  onPageChanged={(data) => {
                    loadSearchResults(query, filters, mode, data?.currentPage);
                  }}
                />
              </div>
            </>
          )}
        </div>

        {filters?.openFilterDrawer ? (
          <div ref={ref} className="sf-drawer">
            <div className="sf-drawer-closer">
              <i
                className="mr-16"
                onClick={() =>
                  setFilters((prev) => ({ ...prev, openFilterDrawer: false }))
                }
              >
                <CloseMenu className="icon-sm" />
              </i>
            </div>
            <SearchFilters
              filters={filters}
              onLocationChange={onLocationChange}
              onFilterDateChange={onFilterDateChange}
              loadLocations={loadLocations}
              onProfessionChange={onProfessionChange}
              onSpecialitiesChange={onSpecialitiesChange}
              onCreditTypeChange={onCreditTypeChange}
              onCreditAmountChange={onCreditAmountChange}
              onCurrencyChange={onCurrencyChange}
              onFilterPriceChange={onFilterPriceChange}
              clearAllFilters={clearAllFilters}
            />
          </div>
        ) : (
          <div className="mobile-sf-button">
            <i
              className="mr-16"
              onClick={() =>
                setFilters((prev) => ({ ...prev, openFilterDrawer: true }))
              }
            >
              <DashMenuIcon className="icon-sm" />
            </i>
            <h4
              onClick={() =>
                setFilters((prev) => ({ ...prev, openFilterDrawer: true }))
              }
            >
              Filters
            </h4>
          </div>
        )}
      </div>
    </div>
  );
}
