import MediaIncludesCard from './MediaIncludesCard';
import './mediaIncludes.scss';

export default function MediaIncludes({ courseIncludes }) {
  return (
    <div>
      <p className="avenir-18-500 mb-8 include-title-centre">This includes</p>
      <div className="mi-body">
        {courseIncludes?.map((item, indx) => (
          <MediaIncludesCard text={item.text} icon={item.icon} key={indx} />
        ))}
        {/* <MediaIncludesCard
          text="90 sec video"
          icon={<PlusIcon className="icon-lg" />}
        />
        <MediaIncludesCard
          text="Certificate"
          icon={<DocumentIcon className="icon-lg" />}
        />
        <MediaIncludesCard
          text="Credits"
          icon={<DocumentIcon className="icon-lg" />}
        /> */}
      </div>
    </div>
  );
}
