import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import api from '../../utility/api';
import { getFormattedDateInTz2 } from '../../utility/commonUtil';
import DateIcon from '../icons/DateIcon';
import { renderRowStyle } from '../../utility/commonUtil';
import './earnings.scss';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';

export default function Earnings() {
  const [earningData, setEarningData] = useState([]);
  const user = useSelector((state) => state.auth.user);

  // for pagination
  const [activePage, setCurrentPage] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell nothing happening right now
  function onRowClick(data) {}

  const getEarningsData = async (userId, page, limit) => {
    try {
      const response = await api.get(
        `organizers/earnings/users/${userId}?limit=${limit}&page=${page}`
      );

      if (response) {
        if (response.data.data?.isSetPage) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        setEarningData(response.data.data.earnings);
        setTotalRecords(response.data.data.earningCount);
      }
    } catch (error) {}
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;

    getEarningsData(user?._id, currentPage, pageLimit);
    setCurrentPage(currentPage);
    // setActivePage(currentPage);
  };

  useEffect(() => {
    getEarningsData(user._id, activePage, pageLimit);
  }, [user._id]);

  return (
    <>
      <h1 className="mb-24">Conference Earnings</h1>
      <div className="grid-table-wrap">
        <ul className="grid-table-3-600 et-gridtable ">
          <li>Conference</li>
          <li>Tickets Sold</li>
          <li>Gross Earnings</li>

          {earningData.length > 0 &&
            earningData?.map((item, indx) => {
              return (
                <Fragment key={item?._id}>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    onClick={() => onRowClick(item)}
                  >
                    <p
                      className="avenir-heavy-18 mb-8"
                      style={{ color: '#000', fontSize: 16 }}
                    >
                      {item?.title}
                    </p>
                    <div className="flex-vc  mb-6 ">
                      <DateIcon className="icon-xxs mr-12" />
                      <span
                        style={{ whiteSpace: 'nowrap' }}
                        className="caption-2-regular-gray3"
                      >
                        Start:{' '}
                        {getFormattedDateInTz2(item?.startDate, item?.timezone)}{' '}
                      </span>
                    </div>
                    <div className="flex-vc  mb-6">
                      <DateIcon className="icon-xxs mr-12" />
                      <span
                        style={{ whiteSpace: 'nowrap' }}
                        className="caption-2-regular-gray3"
                      >
                        End:{' '}
                        {getFormattedDateInTz2(item?.endDate, item?.timezone)}{' '}
                      </span>
                    </div>
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    onClick={() => onRowClick(item)}
                  >
                    {item.soldTickets}/{item?.totalTickets}
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                    onClick={() => onRowClick(item)}
                  >
                    {item.grossPrice}
                  </li>
                </Fragment>
              );
            })}
        </ul>
      </div>
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={currentPageNumber}
            setCurrentPage={setCurrentPageNumber}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={currentPageNumber}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </>
  );
}
