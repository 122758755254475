import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import PrinterIcon from '../icons/PrinterIcon';
import './attendeeTickets.scss';
import {
  getFormattedDateInTz,
  getFormattedDateInTz2,
} from '../../utility/commonUtil';

const AttendeeTickets = ({ bookingDetails }) => {
  //   const [bookingDetails, setbookingDetails] = useState([]);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Print The Ticket',
  });

  const getLocationString = () => {
    let locationStrig = 'Location';
    if (bookingDetails?.conference?.mode?.length > 0) {
      if (
        bookingDetails?.conference?.mode?.includes('venue') &&
        bookingDetails?.conference?.location
      ) {
        locationStrig = bookingDetails?.conference?.location;
      }

      if (bookingDetails?.conference?.mode?.includes('onlineConf')) {
        locationStrig = 'Online';
      }

      if (
        bookingDetails?.conference?.mode?.includes('venue') &&
        bookingDetails?.conference?.mode?.includes('onlineConf')
      ) {
        locationStrig = `${bookingDetails?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  //   const getBookingDetails = async (id) => {
  //     try {
  //       const response = await api.get(`/conferences/bookings/${id}`);

  //       if (response) {
  //         setbookingDetails(response.data.data.bookingDetails);
  //         getFormatedDate(response.data.data.bookingDetails);
  //       }
  //     } catch (err) {
  //       dispatch(alertAction(err.response.data.message, "danger"));
  //     }
  //   };

  const ticketComponent = (
    <div>
      {bookingDetails?.attendees?.map((data) => {
        return (
          <div key={data?.registrationNumber} className="mb-24">
            <div className="at-wrap">
              <div>
                <div className="barcode-container">
                  <Barcode
                    value={data?.registrationNumber}
                    // width={3}
                    height={50}
                    background="#ccffff"
                  />
                </div>
              </div>
              <div className="at-body">
                <h3 className="mb-16">
                  {bookingDetails?.conference?.title
                    ? bookingDetails?.conference?.title
                    : 'Ticket title'}
                </h3>
                <div className="at-content">
                  <div className="at-column-grid">
                    <h4>Ticket</h4>
                    <p>{data.ticket?.name}</p>
                    <h4>STATUS </h4>
                    <p
                      style={{
                        color: `${
                          data?.ticketBooked === 1 ? '#08415c' : '#d8000c'
                        }`,
                      }}
                    >
                      {data?.ticketBooked === 1 ? 'Booked' : 'Cacelled'}
                    </p>
                    <h4>NAME </h4>
                    <p>{`${data.firstName}  ${data.lastName}`}</p>
                    <h4>START DATE </h4>
                    <p>
                      {getFormattedDateInTz(
                        bookingDetails?.conference?.startDate,
                        bookingDetails?.conference?.timezone
                      )
                        ? `${getFormattedDateInTz(
                            bookingDetails?.conference?.startDate,
                            bookingDetails?.conference?.timezone
                          )}`
                        : `Ongoing event`}
                    </p>
                    <h4>END DATE</h4>
                    <p>
                      {getFormattedDateInTz(
                        bookingDetails?.conference?.endDate,
                        bookingDetails?.conference?.timezone
                      )
                        ? `${getFormattedDateInTz(
                            bookingDetails?.conference?.endDate,
                            bookingDetails?.conference?.timezone
                          )}`
                        : `Ongoing event`}
                    </p>
                    <h4>LOCATION</h4>
                    <p>{getLocationString()}</p>
                    <h4>BOOKING DATE</h4>

                    <p>
                      {' '}
                      {getFormattedDateInTz2(
                        data?.bookingDate,
                        data?.timezone
                      ) &&
                        getFormattedDateInTz2(
                          data?.bookingDate,
                          data?.timezone
                        )}
                    </p>
                    <h4>BOOKING NO </h4>
                    <p>{bookingDetails?.bookingNumber}</p>
                  </div>
                  <div className="at-column2">
                    <div className="at-column-grid">
                      <h4 className="mr-16"> BASE PRICE </h4>
                      <p>
                        {data.originalPrice === 0 ? 'FREE' : data.originalPrice}
                      </p>
                      <h4 className="mr-16">TOTAL</h4>
                      <p className="caption-1-regular-gray2 ">
                        {data.price === 0 ? 'FREE' : data.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="at-container">
      <div className="flex-vc" style={{ justifyContent: 'flex-end' }}>
        <button
          className="small-button-text small-button-text-green"
          onClick={() => handlePrint()}
        >
          <div className="flex-vc">
            <i
              style={{ position: 'relative', paddingTop: 5 }}
              className="mr-12"
            >
              <PrinterIcon className="icon-sm" />
            </i>
            <span>Print</span>
          </div>
        </button>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="print-margins">
          {ticketComponent}
        </div>
      </div>
      {ticketComponent}
    </div>
  );
};

export default AttendeeTickets;
