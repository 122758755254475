export default function UserOnBenchSketch({ className }) {
  return (
    <svg
      className={className}
      width="359px"
      height="541px"
      viewBox="0 0 359 541"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 7@2x</title>
      <g
        id="Creatives"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-7" transform="translate(3.000000, 0.000000)">
          <g
            id="Group-5"
            transform="translate(38.000000, 0.000000)"
            fill="#D8D8D8"
          >
            <polygon
              id="Rectangle-Copy-4"
              transform="translate(17.000000, 16.445374) scale(-1, 1) translate(-17.000000, -16.445374) "
              points="13 3.78217015 19.3063515 0.445373861 21 31.4638442 18.4251914 32.4453739"
            ></polygon>
            <polygon
              id="Rectangle-Copy-5"
              transform="translate(16.989114, 16.004018) rotate(102.000000) translate(-16.989114, -16.004018) "
              points="12.9118456 2.99662344 19.2076973 -0.389570524 21.0663816 31.4053324 18.493857 32.3976069"
            ></polygon>
          </g>
          <g
            id="Group-5-Copy-2"
            transform="translate(25.000000, 65.000000)"
            fill="#D8D8D8"
          >
            <polygon
              id="Rectangle-Copy-4"
              transform="translate(17.000000, 16.445374) scale(-1, 1) translate(-17.000000, -16.445374) "
              points="13 3.78217015 19.3063515 0.445373861 21 31.4638442 18.4251914 32.4453739"
            ></polygon>
            <polygon
              id="Rectangle-Copy-5"
              transform="translate(16.989114, 16.004018) rotate(102.000000) translate(-16.989114, -16.004018) "
              points="12.9118456 2.99662344 19.2076973 -0.389570524 21.0663816 31.4053324 18.493857 32.3976069"
            ></polygon>
          </g>
          <g
            id="Group-5-Copy"
            transform="translate(91.000000, 39.000000)"
            fill="#D8D8D8"
          >
            <polygon
              id="Rectangle-Copy-4"
              transform="translate(17.000000, 16.445374) scale(-1, 1) translate(-17.000000, -16.445374) "
              points="13 3.78217015 19.3063515 0.445373861 21 31.4638442 18.4251914 32.4453739"
            ></polygon>
            <polygon
              id="Rectangle-Copy-5"
              transform="translate(16.989114, 16.004018) rotate(102.000000) translate(-16.989114, -16.004018) "
              points="12.9118456 2.99662344 19.2076973 -0.389570524 21.0663816 31.4053324 18.493857 32.3976069"
            ></polygon>
          </g>
          <g
            id="A-Human/Standing-Copy"
            transform="translate(178.000000, 278.445374) scale(-1, 1) translate(-178.000000, -278.445374) translate(0.000000, 16.445374)"
          >
            <g id="Group" transform="translate(113.920000, 22.524887)">
              <g
                id="Head/Front"
                transform="translate(80.693333, 61.647059) rotate(18.000000) translate(-80.693333, -61.647059) "
              >
                <g
                  id="Head"
                  transform="translate(64.080000, 36.751131)"
                  fill="#B28B67"
                >
                  <path
                    d="M45.2634318,10.8284635 C52.1399405,23.6334133 51.3228325,56.1142304 42.4355577,58.4028321 C38.8912571,59.3155407 31.3407667,57.07971 23.6562017,52.4522609 L28.48,86.5429864 L0,86.5429864 L9.80432426,40.8884652 C4.33640373,34.4763326 0.635629032,26.6182138 1.25404125,17.8129815 C3.0358852,-7.5577167 38.386923,-1.97648634 45.2634318,10.8284635 Z"
                    id="Head-[skin]"
                  ></path>
                </g>
                <path
                  d="M87.8133333,34.3800905 C103.084631,34.3800905 108.874969,40.6707193 109.173333,46.2352941 C102.629286,48.0320743 91.1944432,48.723958 74.76,48.6063348 L74.76,49.7918552 L89,50.9773756 L91.3733333,58.0904977 C87.5975721,57.8088997 84.8993924,60.3961315 82.7462664,63.8601093 C81.815073,63.2211267 80.7021598,62.8325792 79.5066667,62.8325792 C76.2297772,62.8325792 73.5733333,65.7518447 73.5733333,68.760181 C73.5733333,71.889557 75.0524041,74.3092585 77.1646636,75.3359838 C76.0369202,77.5399626 74.9023958,79.1393735 73.5733333,79.4298643 C68.4447256,78.9115648 61.7066667,66.1763632 61.7066667,55.719457 C61.7066667,44.2738609 70.8226197,34.3800905 87.8133333,34.3800905 Z"
                  id="Combined-Shape"
                  fill="#191847"
                ></path>
              </g>
            </g>
            <g
              id="Bottom/Skinny-Jeans-1"
              transform="translate(0.000000, 241.000000)"
            >
              <g id="Group" transform="translate(11.866667, 49.732218)">
                <g id="Seat-Stuff" transform="translate(65.266667, 0.000000)">
                  <polygon
                    fill="#C5CFD6"
                    points="32.7788679 0 124.454269 0 136.466667 146.828452 0 146.828452"
                  ></polygon>
                  <polygon
                    fillOpacity="0.1"
                    fill="#000000"
                    points="32.7788679 0 60.9742573 0 77.8498113 146.828452 0 146.828452"
                  ></polygon>
                </g>
              </g>
              <path
                d="M306.105116,24.5779102 L306.447043,24.3790903 C316.256813,18.8953221 332.277797,26.34232 332.266672,34.6212199 C332.232978,59.6821075 309.312596,177.793981 308.115892,185.260457 C306.919189,192.726932 294.099492,194.69911 293.352959,185.284925 C292.165099,170.305368 289.958203,118.449261 292.166292,94.4469898 C293.077773,84.539055 294.059391,75.1030831 295.025771,66.6849186 C283.000063,88.3557224 264.398952,119.386436 239.223845,159.775143 L225.557533,153.544876 C236.289263,118.26333 245.089121,93.3554974 251.957105,78.821378 C263.716763,53.9354371 274.743364,32.6239464 279.384104,25.1171466 C286.684187,13.3086312 299.391291,17.4292215 306.105116,24.5779102 Z"
                id="Legs-[skin]"
                fill="#B28B67"
              ></path>
              <path
                d="M247.756361,146.078452 L253.911375,25.8455158 C258.301692,5.05324005 295.331305,14.6757797 295.308327,25.8167361 C295.256125,51.1101562 279.9764,138.495613 279.960851,146.031367 L247.756361,146.078452 Z"
                id="LegLower"
                fill="#1F28CF"
                transform="translate(271.532344, 79.837773) rotate(20.000000) translate(-271.532344, -79.837773) "
              ></path>
              <g
                id="Group"
                transform="translate(219.533333, 133.803347)"
                fill="#E4E4E4"
              >
                <g
                  id="Left-[shoe]"
                  transform="translate(36.193333, 23.682008) rotate(30.000000) translate(-36.193333, -23.682008) "
                >
                  <path
                    d="M3.32415896,29.6926576 C1.8991641,33.4228724 1.18666667,36.1974326 1.18666667,38.0163383 C1.18666667,40.1516102 1.54315819,43.267503 2.25614123,47.3640167 C4.73857499,47.3640167 27.0486251,47.3640167 69.1862917,47.3640167 C70.9255691,41.6264667 69.9215573,38.5289481 66.1742564,38.0714609 C62.4269556,37.6139737 59.3140091,37.2008993 56.835417,36.8322378 L25.550394,22.91538 C25.0457841,22.6909089 24.4547471,22.9180062 24.230276,23.4226161 C24.2266483,23.430771 24.22313,23.4389742 24.219722,23.4472233 L22.1470129,28.4642289 L22.1470129,28.4642289 C18.6779924,30.079291 15.8590531,30.8868221 13.690195,30.8868221 C11.8621992,30.8868221 9.31169468,30.1226212 6.03868132,28.5942192 L6.03867408,28.5942347 C5.0378571,28.1268823 3.84767065,28.5593408 3.38031825,29.5601578 C3.36001502,29.6036364 3.34128313,29.6478316 3.32415896,29.6926576 Z"
                    id="shoe"
                  ></path>
                </g>
              </g>
              <g
                id="Right-[shoe]"
                transform="translate(287.173333, 149.196653)"
                fill="#E4E4E4"
              >
                <path
                  d="M3.32415896,29.6926576 C1.8991641,33.4228724 1.18666667,36.1974326 1.18666667,38.0163383 C1.18666667,40.1516102 1.54315819,43.267503 2.25614123,47.3640167 C4.73857499,47.3640167 27.0486251,47.3640167 69.1862917,47.3640167 C70.9255691,41.6264667 69.9215573,38.5289481 66.1742564,38.0714609 C62.4269556,37.6139737 59.3140091,37.2008993 56.835417,36.8322378 L25.550394,22.91538 C25.0457841,22.6909089 24.4547471,22.9180062 24.230276,23.4226161 C24.2266483,23.430771 24.22313,23.4389742 24.219722,23.4472233 L22.1470129,28.4642289 L22.1470129,28.4642289 C18.6779924,30.079291 15.8590531,30.8868221 13.690195,30.8868221 C11.8621992,30.8868221 9.31169468,30.1226212 6.03868132,28.5942192 L6.03867408,28.5942347 C5.0378571,28.1268823 3.84767065,28.5593408 3.38031825,29.5601578 C3.36001502,29.6036364 3.34128313,29.6478316 3.32415896,29.6926576 Z"
                  id="shoe"
                ></path>
              </g>
              <path
                d="M335.554176,43.1403635 C335.554176,74.5983264 320.582272,147.977377 320.572797,154.904149 L288.36,154.928839 C293.074956,89.4534357 294.746844,56.363053 293.375664,55.6576904 C291.318894,54.5996465 209.061994,63.8526513 184.391811,63.8526513 C148.817286,63.8526513 134.100817,41.4676669 132.906667,-5.21639449e-13 L203.378478,-5.21639449e-13 C217.727171,1.53206838 292.571273,16.5954714 320.366888,21.7737274 C332.266667,23.9906274 335.554176,34.4745045 335.554176,43.1403635 Z"
                id="Leg-and-Butt"
                fill="#2B44FF"
              ></path>
            </g>
            <g
              id="Body/Long-Sleeve-1"
              transform="translate(66.000000, 111.000000)"
            >
              <path
                d="M41.1156646,115.430032 L65.9313173,115.527592 C50.6087968,164.977401 42.3369125,191.21516 41.1156646,194.240869 C38.3678567,201.048714 44.1825877,211.416944 46.6691975,216.988753 C43.3731721,218.463211 41.5614806,217.115294 39.8832006,215.318371 L39.5965503,215.006371 C39.5489091,214.953786 39.5013137,214.900945 39.4537327,214.847903 L39.1681673,214.52769 L39.1681673,214.52769 L38.8814346,214.205323 C36.7707518,211.839894 34.4439118,209.51503 29.2169037,211.946135 C19.8918248,216.283272 12.7982605,224.141874 2.02447931,217.495992 C0.699906216,216.67892 -0.751610096,213.602495 2.75543682,211.198432 C5.16969984,209.543467 7.87819393,207.54545 10.5698786,205.447526 L11.3382053,204.845611 C18.1163038,199.508391 24.5986463,193.682308 25.7850415,191.276105 C28.1049942,186.570863 33.2152019,161.288838 41.1156646,115.430032 L41.1156646,115.430032 Z M186.552681,114.47032 L224.431877,142.278014 C234.705496,145.049427 243.288771,148.497532 250.181705,152.62233 C251.809936,154.105581 253.777805,157.109411 247.025139,156.590274 C246.314332,156.535628 245.599387,156.484048 244.887964,156.438179 L244.177999,156.394312 C238.394171,156.053215 233.015557,156.151859 232.214591,158.131271 C231.282854,160.433853 235.369202,163.187488 233.485756,166.294274 C232.230126,168.365465 226.298023,163.172031 215.689448,150.713974 L178.179106,135.163761 L186.552681,114.47032 Z"
                id="Arms-[skin]"
                fill="#B28B67"
              ></path>
              <path
                d="M105.745651,16.374775 L115.821574,13.7257536 C156.643234,43.1827899 177.741432,104.788598 227.18207,134.076043 L217.198543,148.931537 C135.616955,146.002533 108.893062,70.9447785 105.745651,16.374775 Z"
                id="Coat-Back"
                fill="#052E41"
                transform="translate(166.463860, 81.328645) rotate(5.000000) translate(-166.463860, -81.328645) "
              ></path>
              <path
                d="M66.875,140.336898 C108.04771,140.336898 138.333493,140.336898 157.732348,140.336898 C161.884065,140.336898 161.101753,134.343655 160.501597,131.319431 C153.575073,96.4162359 126.536549,58.7922804 126.536549,9.10846057 L100.329013,5 C78.6520134,39.8532958 71.1557312,82.7647598 66.875,140.336898 Z"
                id="Shirt"
                fill="#DDE3E9"
              ></path>
              <path
                d="M98.3556622,5 C99.2392705,5 100.048851,5.05515787 100.784404,5.16547361 L108.99751,5.63482496 C132.536608,85.3891612 139.028551,138.600013 128.473339,165.26738 L128.473339,165.26738 L56.9611345,165.2683 C52.7242787,175.144962 48.6699601,183.454816 44.7981787,190.197861 L44.7981787,190.197861 L25.3125,190.197861 C23.4786237,130.919215 37.5761675,80.6063184 67.6051315,39.2591704 C63.7995758,38.1660111 60.7848832,35.6147443 58.5625,31.605798 C48.9835989,14.3264643 52.4429884,4.81291124 62.6797958,1.68575263 C72.9166031,-1.44140599 82.0456916,5 98.3556622,5 Z"
                id="Coat-Front"
                fill="#08415C"
              ></path>
              <path
                d="M83.5,165.26738 L56.9607033,165.269305 C61.9031614,153.747882 67.0940202,140.094313 72.5332794,124.308596 C74.7826652,143.119358 78.438119,156.772897 83.5,165.26738 Z"
                id="Shade"
                fillOpacity="0.1"
                fill="#000000"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
