import { useEffect } from "react";
import PropTypes from "prop-types";
import CloseIcon from "../icons/CloseIcon";

function ModalX(props) {
  useEffect(() => {
    if (props?.open) {
      document.body.style.overflow = "hidden";
    }

    return () => (document.body.style.overflow = "unset");
  }, [props?.open]);

  return (
    <div className="modal-background">
      <div className="modalx-body">
        <div className="modalx-header">
          <i onClick={props.onDismiss}>
            <CloseIcon className="icon-size" fill="#c4c4c4" />
          </i>
        </div>
        <div className="modalx-content">{props.children}</div>
      </div>
    </div>
  );
}

export default ModalX;

ModalX.propTypes = {
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
};
