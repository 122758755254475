import { useDispatch } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import {
  getFormattedDateInTz,
  getLocationString,
} from "../../utility/commonUtil";
import SelectTickets from "./SelectTickets";

export default function BookingPage1({
  setcurrentPage,
  tickets,
  total,
  cart,
  setCart,
  title,
  mode,
  startDate,
  endDate,
  city,
  timezone,
}) {
  const dispatch = useDispatch();

  return (
    <>
      <div className="text-align-center mb-60">
        <h2 className="color-primary mb-24">Book Tickets</h2>
        <h4 className="mb-16 color-primary">{title}</h4>
        {mode && (
          <p className="body-regular-gray3">
            <span> {getLocationString(mode, city)}</span>
          </p>
        )}
        {startDate && endDate && timezone && (
          <p className="body-regular-gray3">
            <span>{getFormattedDateInTz(startDate, timezone)} - </span>
            <span>{getFormattedDateInTz(endDate, timezone)}</span>
          </p>
        )}
      </div>
      <div>
        <h4 className="color-primary mb-40">
          Choose type of ticket and number of tickets you want to purchase.
        </h4>
        {tickets?.length > 0 &&
          tickets?.map((ticket) => (
            <SelectTickets
              ticket={ticket}
              key={ticket._id}
              setCart={setCart}
              cart={cart}
            />
          ))}
        <div className="bp-total">
          {tickets?.[0]?.currency && (
            <div className="mr-16">
              <i className="caption-1-heavy-cblack mr-16">
                Total: {tickets?.[0].currency} - {total}
              </i>
            </div>
          )}
          <div>
            <button
              onClick={() => {
                if (!cart.length > 0) {
                  return dispatch(
                    alertAction("Pick at least one ticket", "danger")
                  );
                }
                setcurrentPage(1);
              }}
              className="button button-green"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
