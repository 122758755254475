import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Timer from "../timer/Timer";
import PropTypes from "prop-types";
import "./paymentStatus.scss";

// set expiry minutes in seconds
const expiryMinutes = 1 / 10;
export default function PaymentStatusDisplay({
  icon,
  message,
  messageTitle,
  url,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // redirect in 15 sec
    setTimeout(() => navigate(url), expiryMinutes * 60 * 1000);
  }, []);

  return (
    <div className="payment-status-container">
      <div className="payment-status-box">
        <i className="mb-8">{icon}</i>
        <h2 className="color-primary mb-16">{messageTitle}</h2>
        <p className="body-regular-gray3 mb-40">{message}</p>
        <button
          onClick={() => navigate(url)}
          className="small-button small-button-primary"
        >
          Okay
        </button>
      </div>
      <div className="flex-vchc">
        <p className="caption-1-regular-gray3">Redirects: </p>
        {/* redirect timer 15 sec ie 1/4 min */}
        <Timer expiryMinutes={expiryMinutes} />
      </div>
    </div>
  );
}

PaymentStatusDisplay.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
  messageTitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
