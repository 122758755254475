import { Link } from 'react-router-dom';

export default function CountCard({ header, num, icon, path, subHeader }) {
  return (
    <div
      // onClick={() => navigate(`${path}`)}
      style={{
        // padding: 12,
        height: '100%',
        width: '100%',
        // boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.08)',
        // border: '2px solid #ced9de',
        // borderRadius: 8,
        // '&:hover': { cursor: 'pointer', backgroundColor: 'action.hover' },
        // textDecoration: Link,
        // width: '180.4px',
        // height: '117px',
        margin: '0 30px 0 0',
        padding: '15px 0px 14px 17px',
        borderRadius: '6px',
        border: 'solid 1px #ecf0f2',
        backgroundColor: '#fff',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
        }}
      >
        <h4
          className="color-primary"
          style={{
            fontWeight: 900,
            letterSpacing: 1,
            fontSize: '31.6px',
            margin: '0 94.5px 3.6px 0',
            //  margin: '0', '94.5px', '3.6px', '0',
          }}
        >
          {num ?? num}
        </h4>
        {/* <div className="flex-vc-sb my-8">
          <TrendingIcon className="icon-xxl" fill="#4cb944" />
          {icon}
        </div> */}
        <div style={{ height: 32 }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 500,
              color: '#08415c',
              margin: '3.6px 0 0.7px 0.4px',
              textAlign: 'left',
            }}
            // className="color-primary body-regular-gray3"
          >
            {header}
          </p>
        </div>
        <div>
          <Link
            style={{
              fontSize: '13px',
              letterSpacing: '0.13px',
              textAlign: 'left',
              color: '#2a87b2',
              margin: '0.7px 62.5px 0 0',
              textDecoration: 'underline',
            }}
            to={path}
          >
            {subHeader}
          </Link>
        </div>
      </div>
    </div>
  );
}
