import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../utility/api';
import Spinner from '../spinner/Spinner';
import LogoUploader from './LogoUploader';
import SocialMedia from './SocialMedia';

import FacebookBlueCircle from '../icons/FacebookBlueCircle';
import InstagramGradientIcon from '../icons/InstagramGradientIcon';
import LinkedinBlueIcon from '../icons/LinkedinBlueIcon';
import TwitterBlueIcon from '../icons/TwitterBlueIcon';

import Dialogue from '../dialogue/Dialogue';
import AddOrganizer from './AddOrganizer';
import ShowOrganizers from './ShowOrganizers';

import { alertAction } from '../../redux/alert/alertAction';
import { removeOrganizationAction } from '../../redux/organization/organizationAction';
import { store } from '../../redux/store';
import BankIcon from '../icons/BankIcon';
import EditIcon from '../icons/EditIcon';
import ModalX from '../modal/ModalX';
import BankDetails from '../user-settings/BankDetails';
import EditOrgBasicInfo from './EditOrgBasicInfo';
import './organizationDetails.styles.scss';
import { loadOrganization } from './organizationUtil';
import ButtonWithLoader from '../button/ButtonWithLoader';
import { updateUserAction } from '../../redux/auth/authAction';

export default function OrganizationDetails() {
  const [open, setopen] = useState(false);
  const [stripe, setStripe] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openLogoUploader, setOpenLogoUploader] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const { organization } = useSelector((state) => state.organization);

  const { organizationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const yesAction = async () => {
    const url = `organizations/${organizationId}/users/${user?._id}`;
    const response = await api.delete(url);
    if (response) {
      dispatch(updateUserAction(response?.data?.data?.user));
      setopen(false);
      navigate('/dashboard/my-organizations');
    }
  };

  const openDialogue = () => {
    setopen(true);
  };
  const closeDialogue = () => {
    setopen(false);
  };

  const getConnectedAccountLink = async () => {
    setIsSubmitting(true);

    const url = `/organizers/accounts`;

    let formData = {
      accountDetails: {
        returnUrl: `${window.location.origin}/dashboard/my-organizations/${organizationId}`,
        refreshUrl: `${window.location.origin}/dashboard/my-organizations/${organizationId}`,
        organizationId: organizationId,
        userId: user?._id,
        type: 'org',
      },
    };

    try {
      const response = await api.post(url, formData);

      if (response) {
        setIsSubmitting(false);
        window.location.replace(response?.data?.data?.accountLink?.url);
      }
    } catch (err) {
      setIsSubmitting(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const getConnectedAccount = async () => {
    const url = `/organizers/accounts?organizationId=${organizationId}&type=org`;

    try {
      const response = await api.get(url);

      setStripe({
        connectedAccount: response?.data?.data?.connectedAccount,
        stripeAccount: response?.data?.data?.stripeAccount,
        balance: response?.data?.data?.balace,
        isConnectedAccount: response?.data?.data?.isConnectedAccount,
      });
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    getConnectedAccount();
    loadOrganization(organizationId, user._id);
    return () => store.dispatch(removeOrganizationAction());
  }, [organizationId, user._id]);

  return (
    <>
      {organization ? (
        <div className="create-org-wrap">
          <section className="mb-40">
            {organization?.logo?.length > 0 ? (
              <div className="org-logo-wrap">
                <div className="org-logo-innerwrap">
                  <img
                    alt="organization logo"
                    src={organization?.logo[0].Location}
                  />
                  <div className="org-logo-overlay"></div>
                  <div
                    onClick={() => {
                      setOpenLogoUploader(true);
                    }}
                    className="org-logo-edit-circle"
                  >
                    <EditIcon className="icon-size" />
                  </div>
                </div>
              </div>
            ) : (
              <LogoUploader
                organizationId={organizationId}
                setOpenLogoUploader={setOpenLogoUploader}
              />
            )}
          </section>
          <section>
            <EditOrgBasicInfo
              organization={organization}
              organizationId={organizationId}
            />
          </section>

          <section>
            <h2 className="mb-10 mt-56">Organisers</h2>
            <p className="mb-40 caption-1-regular-gray3">
              Organisers can review, edit or publish conferences.
            </p>
            <AddOrganizer organizationId={organizationId} />
            <ShowOrganizers
              organizers={organization?.organizers}
              organizationId={organizationId}
            />
          </section>
          <section className="mb-80">
            <h2 className="mb-10 mt-56">Social Media</h2>
            <p className="caption-1-regular-gray3">
              Connect your social media accounts for better reachability.
            </p>
            <SocialMedia
              socialMediaIcon={<FacebookBlueCircle className="icon-lg" />}
              name="facebook"
              removeName="removeFacebook"
              label="Facebook link"
              socialMediaApiValue={organization?.facebook}
              organizationId={organizationId}
            />
            <SocialMedia
              socialMediaIcon={<LinkedinBlueIcon className="icon-lg" />}
              name="linkedin"
              removeName="removeLinkedin"
              label="Linkedin link"
              socialMediaApiValue={organization?.linkedin}
              organizationId={organizationId}
            />
            <SocialMedia
              socialMediaIcon={<TwitterBlueIcon className="icon-lg" />}
              name="twitter"
              removeName="removeTwitter"
              label="Twitter link"
              socialMediaApiValue={organization?.twitter}
              organizationId={organizationId}
            />
            <SocialMedia
              socialMediaIcon={<InstagramGradientIcon className="icon-lg" />}
              name="instagram"
              removeName="removeInstagram"
              label="Instagram link"
              socialMediaApiValue={organization?.instagram}
              organizationId={organizationId}
            />
          </section>
          <section>
            {!stripe?.connectedAccount?.detailsSubmitted ? (
              <div>
                <h2 className="color-primary mb-16">
                  Add Bank info to collect payment
                </h2>
                <p className="caption-1-regular-gray3">
                  When you publish paid conferences under this organization, you
                  need to open or link a third party (Stripe Inc) account and
                  add your bank information to collect payment. You can link an
                  existing stripe account to this organization as well.
                </p>
              </div>
            ) : (
              <h2 className="color-primary">Bank Account Details</h2>
            )}
            {
              <>
                {stripe?.connectedAccount?.detailsSubmitted &&
                stripe?.connectedAccount?.chargesEnabled ? (
                  <BankDetails stripe={stripe} isOrganization={true} />
                ) : (
                  <div className="mt-16">
                    <ButtonWithLoader
                      isSubmitting={isSubmitting}
                      text="Add Bank Account"
                      icon=<BankIcon className="icon-size" />
                      className="small-button-outlined small-button-outlined-primary"
                      handleClick={() => getConnectedAccountLink()}
                    />
                    {/* <button
                      disabled={isSubmitting}
                      onClick={() => getConnectedAccountLink()}
                      className="flex-vc small-button-outlined small-button-outlined-primary mt-24 "
                    >
                      <span>Add Bank Account</span>
                      <i className="ml-8">
                        <BankIcon className="icon-size" />
                      </i>
                    </button> */}
                  </div>
                )}
              </>
            }
          </section>
          <h2 className="mt-80 mb-16">Danger Zone</h2>
          <section className="delete-organization-wrap">
            <div style={{ flexGrow: 1 }}>
              <h4 className="mb-4">Delete this organization</h4>
              <p className="caption-1-regular-gray3">
                Once you delete the organization, it can not be recovered.
              </p>
            </div>
            <button
              onClick={openDialogue}
              className="button-text button-text-red"
            >
              Delete
            </button>
          </section>
          {open && (
            <Dialogue
              msg="Are you sure you want to delete the organization?"
              title="Confirm Delete !!"
              closeDialogue={closeDialogue}
              yesAction={yesAction}
            />
          )}
          {openLogoUploader && (
            <ModalX
              onDismiss={() => setOpenLogoUploader(false)}
              open={openLogoUploader}
            >
              <div className="px-40 pb-40">
                <LogoUploader
                  setOpenLogoUploader={setOpenLogoUploader}
                  organizationId={organizationId}
                />
              </div>
            </ModalX>
          )}
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
}
