function PriceIcon({ className, fill }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0L20 0 20 20 0 20z"
                transform="translate(-893.000000, -434.000000) translate(893.000000, 431.000000) translate(0.000000, 3.000000)"
              />
              <path
                // fill="#08415C"
                fill={fill ? fill : "#08415C"}
                d="M17.842 9.508L10.483 2.15c-.308-.308-.733-.483-1.175-.483H3.333c-.916 0-1.666.75-1.666 1.666v5.975c0 .442.175.867.491 1.175l7.359 7.359c.65.65 1.708.65 2.358 0l5.975-5.975c.65-.65.65-1.7-.008-2.359zM5.417 6.667c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .691-.559 1.25-1.25 1.25z"
                transform="translate(-893.000000, -434.000000) translate(893.000000, 431.000000) translate(0.000000, 3.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PriceIcon;
