import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';

import {
  openInNewTab,
  likeConference,
  unlikeConference,
  getPrice,
} from '../../utility/commonUtil';

import CreditsIcon from '../icons/CreditsIcon';
import MediaTag from '../tags/MediaTag';

export default function CourseHoverCard({ conf, tag }) {
  const [isLiked, setIsLiked] = useState(false);

  // const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const like = (confId, userId, setIsLiked) => {
    if (!isLiked && isAuthenticated && user) {
      likeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to like a conference.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to unlike a conference.', 'info'));
    }
  };

  useEffect(() => {
    setIsLiked(conf?.isLiked);
  }, [conf?.isLiked]);

  return (
    <div className="cc-hovcard">
      <div className="cc-hovcard-inner">
        <div className="cc-hovcard-content mb-12">
          <div className="mb-12">
            <MediaTag tag={tag} bg={'yellow'} />
          </div>
          <p className="avenir-heavy-16 mb-16">{conf?.title}</p>
          <div className="mb-8">
            <div className="flex-vc mb-8">
              <CreditsIcon className="icon-xxs mr-8" />
              <p className="caption-2-regular-gray3">Credits</p>
            </div>
            <div className="cc-hovcard-credits">
              {conf?.credits?.length > 0 ? (
                conf?.credits.map((credit, indx) => {
                  return (
                    <Fragment key={indx}>
                      <div className="caption-2-regular-gray3 line-clamp">
                        {credit.creditId.name}
                      </div>
                      <div className="caption-2-regular-gray3">
                        {credit.quantity}
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <div className="caption-2-regular-gray3">Credits not added</div>
              )}
            </div>
          </div>
          <div
            className="card-description mb-16"
            style={{
              WebkitLineClamp: 10,
            }}
          >
            {conf?.description}
          </div>
        </div>
        <div className="px-16">
          {conf?.type === 'AUDIO' || conf?.type === 'VIDEO' ? (
            <div
              className="flex-vc caption-2-bold-cblack "
              style={{
                fontSize: 16,
                gap: '8px',
                wordBreak: 'break-word',
              }}
            >
              {getPrice(conf?.currency, conf?.actualPrice, conf?.price)}
            </div>
          ) : (
            <div
              className="flex-vc caption-2-bold-cblack "
              style={{
                fontSize: 16,
                gap: '8px',
                wordBreak: 'break-word',
              }}
            >
              {getPrice(
                conf?.currency,
                conf?.monthlyPodcastPrice?.actualPrice,
                conf?.monthlyPodcastPrice?.price
              )}
            </div>
          )}
          <button
            style={{ width: '100%' }}
            className="small-button  small-button-green"
            onClick={() => {
              if (tag === 'Video Course') {
                navigate(`/book-course/${conf?._id}`);
              } else if (tag === 'Audio Course') {
                navigate(`/book-course/${conf?._id}`);
              } else if (tag === 'Podcast')
                navigate(`/podcast/booking-price/${conf?._id}`);
              else {
                navigate(`/book-conference/${conf?._id}`);
              }
            }}
          >
            Book
          </button>
        </div>
      </div>
    </div>
  );
}
