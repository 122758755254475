import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alertAction } from '../../redux/alert/alertAction';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import SearchIcon from '../icons/SearchIcon';
import { customSelectStyle3 } from '../reselect/selectUtil';
import Select from 'react-select';
import { getValue } from '../../utility/commonUtil';
import MyConfsCard from '../conference/MyConfsCard';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import { loadAllMyACsAction } from '../../redux/audio-course/audioCourseAction';
import NextIcon from '../icons/NextIcon';

export default function PodcastBooking() {
  const [searchText, setSearchText] = useState('');
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [splicedList, setSplicedList] = useState([]);
  const [actionMenu, setActionMenu] = useState({ open: false, id: null });
  const videoCourses = useSelector((state) => state.video.myAudioCourses);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  let organizationsList = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );

  const options1 = [
    { value: 'all', label: 'All' },
    // { value: "drafts", label: "Draft" },
    { value: 'published', label: 'Published' },
    { value: 'unpublished', label: 'Unpublished' },
  ];
  const options2 = [
    { value: 'all', label: 'All' },
    { value: 'user', label: 'User' },
    ...organizationsList,
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handlFilter1 = (value) => {
    if (value === 'all') {
      setList1(videoCourses);
    }
    if (value === 'drafts') {
      setList1(() => videoCourses?.filter((item) => item.status === 'Draft'));
    }
    if (value === 'published') {
      setList1(() =>
        videoCourses?.filter((item) => item.status === 'Published')
      );
    }
    if (value === 'expired') {
      setList1(() => videoCourses?.filter((item) => item.status === 'Expired'));
    }
    if (value === 'unpublished') {
      setList1(() =>
        videoCourses?.filter((item) => item.status === 'Unpublished')
      );
    }
  };
  const handlFilter2 = (value) => {
    if (value === 'all') {
      setList2(videoCourses);
    } else if (value === 'user') {
      setList2(videoCourses?.filter((item) => item.host === 'user'));
    } else {
      setList2(() =>
        videoCourses?.filter(
          (element) =>
            element.host === 'organization' &&
            element.hostedBy.organization._id === value
        )
      );
    }
  };

  const getMyVideoCourses = async (userId) => {
    const url = `/podcast/earnings/users/${userId}`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(loadAllMyACsAction(response.data.data.earnings));
        setList1(response.data.data.earnings);
        setList2(response.data.data.earnings);
        setFilteredList(response.data.data.earnings);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);
      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  function closeActionMenu() {
    setActionMenu({ open: false, id: null });
  }

  //code for frotnend pagination
  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    let comonList = list1?.filter((obj) =>
      list2.some(({ _id, title }) => obj._id === _id && obj.title === title)
    );

    let filterResult = comonList?.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredList(filterResult);
    setTotalRecords(filterResult?.length);
  }, [list1, list2, filter1, filter2, searchText]);

  useEffect(() => {
    loadMyOrgnizations(user?._id);
    getMyVideoCourses(user?._id);
  }, []);

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);

  return (
    <div>
      <div className="myconfs-header mb-24">
        <h1>Podcast Bookings</h1>
      </div>
      <div className="myconfs-sort mb-32">
        <div className="form-type-3">
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              id="myConfsSearchText"
              placeholder="Search podcast"
              name="searchText"
              value={searchText}
              onChange={handleSearchText}
              autoComplete="off"
            />
            <i
              className={
                searchText?.length > 0
                  ? 'display-none'
                  : 'conf-search-input-icon'
              }
            >
              <SearchIcon width="2.4rem" height="2.4rem" />
            </i>
          </div>
        </div>

        <div>
          <Select
            value={getValue(options1, filter1, false)}
            options={options1}
            name="filter1"
            onChange={(option) => {
              setFilter1(option.value);
              handlFilter1(option.value);
            }}
            styles={customSelectStyle3}
          />
        </div>
        <div>
          <Select
            value={getValue(options2, filter2, false)}
            options={options2}
            name="filter2"
            onChange={(option) => {
              setFilter2(option.value);
              handlFilter2(option.value);
            }}
            styles={customSelectStyle3}
          />
        </div>
      </div>

      <div className="table-container">
        <table className="myconfs-table ">
          <thead>
            <tr>
              <th>Podcast</th>
              <th>Course Status</th>
              <th>Sold</th>
              <th>Gross</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {splicedList?.map((conf) =>
              conf.status !== 'Draft' ? (
                <tr
                  key={conf?._id}
                  style={{
                    ...(actionMenu.id == conf?._id && actionMenu.open
                      ? {
                          backgroundColor: '#ffffff',
                          border: '0.2px solid #ced9de',
                        }
                      : conf?.status === 'Expired'
                      ? { opacity: 0.6 }
                      : null),
                  }}
                >
                  <td>
                    <MyConfsCard
                      banner={conf.banner}
                      title={conf.title}
                      timezone={conf.timezone}
                      startDate={conf.startDate}
                      endDate={conf.endDate}
                      city={conf.city}
                      country={conf.country}
                      mode={conf.mode}
                      confId={conf?._id}
                      onCardClick={() =>
                        navigate(`/podcast/bookings/${conf?._id}`)
                      }
                    />
                  </td>
                  <td>{conf.status}</td>
                  <td>{conf.totalSold ? conf.totalSold : 0}</td>

                  <td>{conf.grossPrice ? conf.grossPrice : 0}</td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(`/audioCourse/bookings/${conf?._id}`)
                    }
                  >
                    {' '}
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <i>
                        <NextIcon className="icon-xl" />
                      </i>
                    </div>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
}
