import './welcome.styles.scss';
import './createEventCard.scss';
import AddIcon from '../icons/AddIcon';
import { removePodcastStateAction } from '../../redux/podcast/podcastAction';
import { useDispatch } from 'react-redux';
export default function CreateEventCard() {
  const dispatch = useDispatch();

  return (
    <div className="ad-event-welcome">
      <div>
        <div>
          <h2 className="caption-1-medium-primary mb-16">
            Create conferences, video courses or podcast from here
          </h2>

          <div className="od-menu-wrap">
            <ul className="od-menu">
              <li className="od-menu-item">
                <div className="od-createEvent-btn">
                  <AddIcon fill={'#fff'} />
                  <a className="od-create-event-text" href="#">
                    Create event
                  </a>
                </div>
                <ul className="od-drop-menu ">
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      <div
                        style={{ backgroundColor: 'rgba(235, 148, 129, 0.26)' }}
                        className="ellipse-podcast"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 2a4.25 4.25 0 1 0 0 8.5A4.25 4.25 0 0 0 8 2zM5.25 6.25a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0zM8 12.5a7.75 7.75 0 0 0-7.75 7.75v1.25h15.5v-1.25A7.75 7.75 0 0 0 8 12.5zM8 14a6.25 6.25 0 0 1 6.245 6H1.755A6.25 6.25 0 0 1 8 14z"
                            fill="#8C5A2B"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 2a4.25 4.25 0 1 0 0 8.5A4.25 4.25 0 0 0 16 2zm-2.75 4.25a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0zM16 12.5a7.75 7.75 0 0 0-7.75 7.75v1.25h15.5v-1.25A7.75 7.75 0 0 0 16 12.5zm0 1.5a6.25 6.25 0 0 1 6.245 6H9.755A6.25 6.25 0 0 1 16 14z"
                            fill="#8C5A2B"
                          />
                        </svg>
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-conf/step-1"
                      >
                        Conference
                      </a>
                    </div>
                  </li>
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      <div className="ellipse-podcast">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="m15.75 8.39 6-3.464v14.148l-6-3.465v2.141H2.25V6.25h13.5v2.14zm0 1.733v3.754l4.5 2.599V7.524l-4.5 2.599zm-12 6.127v-8.5h10.5v8.5H3.75z"
                            fill="#363AA1"
                          />
                        </svg>
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-vc/step-1"
                      >
                        Video course
                      </a>
                    </div>
                  </li>
                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      {/* <AddIcon fill={'rgba(93, 213, 239, 0.29)'} /> */}
                      <div
                        style={{ backgroundColor: 'rgba(93, 213, 239, 0.29)' }}
                        className="ellipse-podcast"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.75 2.25h-7.5v11.5h7.5V2.25zm-6 10v-8.5h4.5v8.5h-4.5z"
                            fill="#08415C"
                          />
                          <path
                            d="M5.75 12v4.25h12.5V12h1.5v5.75h-7V22h-1.5v-4.25h-7V12h1.5z"
                            fill="#08415C"
                          />
                        </svg>
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-ac/step-1"
                      >
                        Audio course
                      </a>
                    </div>
                  </li>

                  <li className="od-drop-menu-item">
                    <div className="od-dd-item p-12">
                      {/* <AddIcon fill={'rgba(93, 213, 239, 0.29)'} /> */}
                      <div
                        style={{ backgroundColor: ' #ffcc9c' }}
                        className="ellipse-podcast"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.75 2.25h-7.5v11.5h7.5V2.25zm-6 10v-8.5h4.5v8.5h-4.5z"
                            fill="#08415C"
                          />
                          <path
                            d="M5.75 12v4.25h12.5V12h1.5v5.75h-7V22h-1.5v-4.25h-7V12h1.5z"
                            fill="#08415C"
                          />
                        </svg>
                      </div>
                      <a
                        className="od-course-text"
                        href="/dashboard/create-pod/step-1"
                        onClick={() => dispatch(removePodcastStateAction())}
                      >
                        Podcast
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
