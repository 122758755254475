import AudioCourseCreditRequests from '../../components/audio-course/AudioCourseCredits';
import VideoCourseCreditRequests from '../../components/video-course/video-course-credits/VideoCourseCredits';

export default function AudioCourseCreditRequestsPage() {
  return (
    <div>
      <AudioCourseCreditRequests />
    </div>
  );
}
