import PropTypes from 'prop-types';
import './dialogue.styles.scss';

export default function Dialogue({ closeDialogue, title, msg, yesAction }) {
  return (
    <div className="dialogue-background">
      <div className="dialogue-container">
        <div className="dialogue-title">
          <h3>{title}</h3>
        </div>
        <div className="dialogue-body">{msg}</div>
        <div className="dialogue-actions">
          <button
            className="button-text button-text-primary"
            onClick={closeDialogue}
          >
            CANCEL
          </button>
          <button
            className="button-text button-text-primary"
            onClick={yesAction}
          >
            YES
          </button>
        </div>
      </div>
    </div>
  );
}

Dialogue.propTypes = {
  closeDialogue: PropTypes.func.isRequired,
  yesAction: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  title: PropTypes.string,
};
