import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import api from "../../utility/api";
import BookingPage1 from "./BookingPage1";
import BookingPage2 from "./BookingPage2";
import { alertAction } from "../../redux/alert/alertAction";
import "./bookingPage.styles.scss";
import { zonedTimeToUtc } from "date-fns-tz";

export default function BookingPage() {
  const [conf, setConf] = useState(null);
  const [currentPage, setcurrentPage] = useState(0);
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { confId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // validate email
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const emailValidation = emailRegex.test(email.toLowerCase());

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const countedCart = cart.reduce((accumulator, item) => {
      const currCount = accumulator[item._id] ?? 0;
      return {
        ...accumulator,
        [item._id]: currCount + 1,
      };
    }, {});

    // convert countedCart to array
    const bookingTickets = Object.keys(countedCart).map((ticketId) => ({
      _id: ticketId,
      quantity: countedCart[ticketId],
    }));

    const err = cart.find((item) => !emailRegex.test(item.email.toLowerCase()));

    if (err) {
      return dispatch(alertAction("Provide Valid Email", "danger"));
    }

    const url = "conferences/bookings";

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formData = {
      ticketDetails: {
        conference: confId,
        bookedBy: user?._id,
        guests: cart,
        bookingTickets: bookingTickets,
        bookingDate: new Date().toISOString(),
        timezone,
      },
    };

    try {
      setIsLoading(true);
      const response = await api.post(url, formData);
      if (response) {
        if (!response.data.data.bookingStatus) {
          setIsLoading(false);
          // const paymentUrl = response.data.data.orderResponseObj.paymentLink;
          // window.location.assign(paymentUrl);
          navigate("/book-conference/checkout", {
            state: {
              // clientSecret: response.data.data.clientSecret,
              paymentData: response.data.data,
            },
          });
        } else {
          setIsLoading(false);
          navigate("/free-booking-status", {
            state: {
              bookingStatus: response.data.data.bookingStatus,
            },
          });
        }
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data?.message, "danger"));
    }
  };

  function renderPageContent(page) {
    switch (page) {
      case 0:
        return (
          <BookingPage1
            setcurrentPage={setcurrentPage}
            tickets={conf?.tickets}
            title={conf?.title}
            mode={conf?.mode}
            startDate={conf?.startDate}
            endDate={conf?.endDate}
            timezone={conf?.timezone}
            city={conf?.city}
            total={total}
            setTotal={setTotal}
            cart={cart}
            setCart={setCart}
          />
        );
      case 1:
        return (
          <BookingPage2
            backPage={backPage}
            cart={cart}
            setCart={setCart}
            onFormSubmit={onFormSubmit}
            conf={conf}
            total={total}
            isLoading={isLoading}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  function backPage() {
    setcurrentPage(currentPage - 1);
  }

  const loadConferenceDetails = async (Id) => {
    try {
      const response = await api.get(`common/conferences/${Id}`);
      setConf(response.data.data.conferences);
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, "danger"));
      }
    }
  };

  useEffect(() => {
    setTotal(
      cart.reduce(
        (accumalatedQuantity, cartItem) =>
          accumalatedQuantity + cartItem.quantity * cartItem.price,
        0
      )
    );
  }, [cart]);

  useEffect(() => {
    loadConferenceDetails(confId);
  }, [confId]);

  return (
    <div className="container pt-64">
      <div className="bookingpage-container">
        {conf && renderPageContent(currentPage)}
      </div>
    </div>
  );
}
