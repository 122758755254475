export default function PurchasePrintIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.25 2.25h11.5v5h4v11.5h-4v3H6.25v-3h-4V7.25h4v-5zm0 15v-4h11.5v4h2.5v-8.5H3.75v8.5h2.5zm10-13.5v3.5h-8.5v-3.5h8.5zm-8.5 16.5v-5.5h8.5v5.5h-8.5z"
        fill="#507282"
      />
    </svg>
  );
}
