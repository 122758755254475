import { getFormattedDateInTz2 } from "../../utility/commonUtil";
import "./bdfoConfTitle.scss";

export default function BdfoConfTitle({ title, startDate, timeZone }) {
  return (
    <div className="bdfoConf-title-wrap">
      <h4 className="color-primary mb-8">{title}</h4>
      <p className="caption-1-regular-gray3">
        {getFormattedDateInTz2(startDate, timeZone)}
      </p>
    </div>
  );
}
