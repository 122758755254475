import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { getValue } from '../../utility/commonUtil';
import { formType1Styles } from './selectUtil';

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

export default function SelectFormType1(props) {
  // function to set up Select Value. If options array not given, it sets empty value

  //  key prop if given to Select, it renders a new component in dom after its value cahnges.
  //  Setting key equal to default value. renders a new component when default value changes

  return (
    <div>
      <Select
        // key={getValue(props.options, props.value)}
        value={getValue(props.options, props.value, props.isMulti)}
        onChange={(value) => {
          props.onChange(value);
        }}
        options={props.options}
        className="basic-single"
        classNamePrefix="select"
        components={{ Placeholder }}
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        name={props.name}
        onKeyDown={(e) => {
          if (e.keyCode === 32 && !this.selectRef.state.inputValue)
            e.preventDefault();
        }}
        noOptionsMessage={() => 'No option found'}
        styles={formType1Styles}
        isMulti={props.isMulti}
        ref={props.ref}
        formatOptionLabel={props.optionLabel}
      />
    </div>
  );
}

SelectFormType1.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  optionLabel: PropTypes.func,
  // value: PropTypes.string,
};
