import Privacy from "../../components/footer/Privacy";
import { useEffect } from "react";

export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container pt-64">
      <Privacy />
    </div>
  );
}
