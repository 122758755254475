function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <h1>Something went wrong!</h1>
      {/* <pre>{error.message}</pre> */}
      <button className="button button-green mt-4" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

export default ErrorFallback;
