import {
  CREATE_AUDIO_COURSE,
  REMOVE_NEWAC_STATE,
  LOAD_INCOMPLETE_ACS,
  LOAD_INCOMPLETE_AC,
  LOAD_ALL_MY_ACS,
  AC_SEARCH_INITIATED,
  GET_SINGLE_AUDIO_COURSE,
  LOAD_USER_AUDIO_COURSE_CREDIT,
  //   VIDEO_COURSE_ERROR,
  //   VC_SEARCH_DONE,
  //   GET_VIDEO_COURSE,
} from './audioCourseTypes';

const initialState = {
  isLoading: true,
  newAudioCourse: null,
  incompleteAudioCourses: null,
  myAudioCourses: null,
  userCreditAudioCourses: [],
  audioCourse: null,
  error: false,
  search: {
    isLoading: false,
    error: false,
    audioCourses: [],
    allTotal: null,
    atVenueTotal: null,
    onlineTotal: null,
    videoTotal: null,
    audioTotal: null,

    // liveStreamVideoCourses: [],
  },
};

function audioCourseReducer(state = initialState, action) {
  const { type, payload } = action;
  let searchObj = state.search;

  switch (type) {
    case CREATE_AUDIO_COURSE:
    case LOAD_INCOMPLETE_AC:
      return {
        ...state,
        isLoading: false,
        newAudioCourse: payload,
        error: false,
      };

    case GET_SINGLE_AUDIO_COURSE:
      return {
        ...state,
        isLoading: false,
        audioCourse: payload,
        error: false,
      };

    case LOAD_USER_AUDIO_COURSE_CREDIT:
      return {
        ...state,
        isLoading: false,
        userCreditAudioCourses: payload,
        error: false,
      };

    case REMOVE_NEWAC_STATE:
      return {
        ...state,
        isLoading: true,
        newAudioCourse: {},
        error: false,
      };

    case LOAD_INCOMPLETE_ACS:
      return {
        ...state,
        incompleteAudioCourses: payload,
      };

    case LOAD_ALL_MY_ACS:
      return {
        ...state,
        myAudioCourses: payload,
      };

    case AC_SEARCH_INITIATED:
      return {
        ...state,
        search: {
          ...searchObj,
          isLoading: true,
        },
      };

    case LOAD_ALL_MY_ACS:
      return {
        ...state,
        search: {
          isLoading: false,
          error: false,
          audio: payload?.audioCourses,
          allTotal: payload?.totalConfCount,
          atVenueTotal: payload?.venueBasedConfCount,
          onlineTotal: payload?.onlineConfCount,
          videoTotal: payload?.videoConfCount,
          audioTotal: payload?.audioConfCount,
        },
      };
    default:
      return state;
  }
}

export default audioCourseReducer;
