import { Fragment } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { format } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import {
  loadUserExternalCreditsAction,
  loadUserTotalExternalCreditsAction,
} from "../../redux/user-profile/userProfileAction";
import api from "../../utility/api";
import Dialogue from "../dialogue/Dialogue";
import AddIcon from "../icons/AddIcon";
import DeleteIcon from "../icons/DeleteIcon";
import DucumentIcon from "../icons/DocumentIcon";
import EditIcon from "../icons/EditIcon";
import UploadArrowIcon from "../icons/UploadArrowIcon";
import ModalX from "../modal/ModalX";
import ExternalCreditsForm from "./ExternalCreditsForm";
import { useNavigate } from "react-router-dom";
import { getQuery, getValue } from "../../utility/commonUtil";
import DateIcon from "../icons/DateIcon";
import DateFilter from "./DateFilter";
import PageLimitSelect from "../pagination/PageLimitSelect";
import CustomPagination from "../pagination/CustomPagination";
import "./externalCreditsTable.scss";

const formattedDate = (date) => {
  if (date) {
    const formattedDate = format(new Date(date), "MMM do, yyyy");
    return <span> {formattedDate}</span>;
  }
};

const ExternalCreditsTable = ({
  totalStartDate,
  totalEndDate,
  externalStartDate,
  externalEndDate,
  setExternalStartDate,
  setExternalEndDate,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [showExternalCreditForm, setShowExternalCreditForm] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);
  const [creditToEdit, setCreditToEdit] = useState(null);
  const [filterText, setFilterText] = useState("all");

  // for pagination
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const userExternalCredits = useSelector(
    (state) => state.userProfile.userExternalCredits
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewCertificate = (certificate) => {
    window.open(certificate.location);
  };

  const getAllExternalCredits = async (currentPage, limit) => {
    const parameters = [
      { name: "startDate", value: externalStartDate?.toISOString() },
      { name: "endDate", value: externalEndDate?.toISOString() },
    ];
    //const url= `attendees/:userId/credits/externals?page=${activePage}&limit=${pageLimit}&startDate=""&endDate=""`
    const baseURL = `attendees/${user?._id}/credits/externals?page=${currentPage}&limit=${limit}`;
    const query = getQuery(parameters);
    let url = baseURL;
    if (query.length > 0) {
      url = url + "&" + query;
    }
    try {
      let response = await api.get(url);

      if (response.data.data?.page === 1 && response.data.data?.isSetPage) {
        setCurrentPageNumber({ currentPage: response.data.data?.page });
        setCurrentPage(response.data.data?.page);
      }
      dispatch(
        loadUserExternalCreditsAction(response.data.data.externalCredits)
      );
      setTotalRecords(response.data.data.externalCreditCount);
    } catch (error) {
      dispatch(alertAction(error.response.data.message, "danger"));
    }
  };

  const deleteExternalCredit = async (creditId) => {
    const parameters = [
      { name: "startDate", value: externalStartDate?.toISOString() },
      { name: "endDate", value: externalEndDate?.toISOString() },
      { name: "totalCountStartDate", value: totalStartDate?.toISOString() },
      { name: "totalCountEndDate", value: totalEndDate?.toISOString() },
    ];
    const query = getQuery(parameters);
    const baseURL = `attendees/${user._id}/credits/externals/${creditId}?page=${activePage}&limit=${pageLimit}`;
    const url = query?.length > 0 ? baseURL + "&" + query : baseURL;

    try {
      const response = await api.delete(url);
      if (response) {
        if (response.data.data?.page === 1 && response.data.data?.isSetPage) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        dispatch(
          loadUserExternalCreditsAction(response.data.data.externalCredits)
        );
        dispatch(
          loadUserTotalExternalCreditsAction(
            response.data.data.savedExternalCreditCount
          )
        );
        setTotalRecords(response.data.data.externalCreditCount);
        setOpenDialogue(false);
        dispatch(alertAction(response.data.message, "success"));
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;
    getAllExternalCredits(currentPage, pageLimit);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    getAllExternalCredits(activePage, pageLimit);
  }, [user?._id, externalStartDate, externalEndDate]);

  return (
    <div>
      <div className="mb-24">
        <h2 className="color-primary mb-16">External Credits</h2>
        <div className="credit-flex-vc-sb mb-16">
          <div className="flex-vc">
            <button
              onClick={() => {
                setShowExternalCreditForm(true);
              }}
              className="circle-button mr-4"
            >
              <AddIcon />
            </button>
            <p className="caption-1-regular-gray3 ml-5">Add external credits</p>
          </div>
        </div>
        <p className="caption-1-regular-gray3 mb-8">
          Filter with From and To Date.
        </p>
        <div>
          <DateFilter
            startDate={externalStartDate}
            endDate={externalEndDate}
            setStartDate={setExternalStartDate}
            setEndDate={setExternalEndDate}
          />
        </div>
      </div>
      <div className="scrollbar-grid-table-wrap mb-16">
        <ul className="grid-table-5-800 ec-grid-table">
          <li>Activity Completed</li>
          <li>Credit Type</li>
          <li>Credit Amount</li>
          <li>Certificates</li>
          <li>
            <div className="text-align-center">Action</div>
          </li>

          {userExternalCredits?.length > 0 &&
            userExternalCredits?.map((data) => (
              <Fragment key={data._id}>
                <li>
                  <p
                    className="avenir-heavy-18"
                    style={{ color: "#000", fontSize: 16 }}
                  >
                    {data?.conferenceTitle}
                  </p>
                  <div className="flex-vc">
                    <DateIcon className="icon-xxs mr-4" />
                    <span
                      style={{ whiteSpace: "nowrap" }}
                      className="caption-2-regular-gray3 mr-4"
                    >
                      Start:
                    </span>
                    {formattedDate(data?.startDate)}
                  </div>
                  <div className="flex-vc">
                    <DateIcon className="icon-xxs mr-4" />
                    <span
                      style={{ whiteSpace: "nowrap" }}
                      className="caption-2-regular-gray3 mr-4"
                    >
                      End:
                    </span>
                    {formattedDate(data?.endDate)}
                  </div>
                </li>
                <li>{data.credit.name}</li>
                <li>{data.quantity}</li>
                <li>
                  <div>
                    {data?.certificate ? (
                      <div
                        className="flex-vc"
                        onClick={() => viewCertificate(data.certificate)}
                      >
                        <i className="position-relative pt-8 mr-8">
                          <DucumentIcon className="icon-sm" />
                        </i>{" "}
                        <span
                          style={{ cursor: "pointer", padding: 2 }}
                          className="hover-bg"
                        >
                          View certificate
                        </span>
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setCreditToEdit(data);
                          setOpenEditModal(true);
                        }}
                        style={{ padding: "2px 4px", borderColor: "#dae2e9" }}
                        className="button-outlined button-outlined-primary hover-bg"
                      >
                        <div className="flex-vc">
                          <i className="position-relative pt-5 mr-8">
                            <UploadArrowIcon className="icon-sm" />
                          </i>{" "}
                          <span>Upload certificate</span>
                        </div>
                      </button>
                    )}
                  </div>
                </li>
                <li style={{ display: "flex" }}>
                  <i
                    className="mr-10"
                    onClick={() => {
                      setCreditToEdit(data);
                      setOpenEditModal(true);
                    }}
                  >
                    <EditIcon />
                  </i>
                  <i
                    className="ml-10"
                    onClick={() => {
                      setCreditToDelete(data);
                      setOpenDialogue(true);
                    }}
                  >
                    <DeleteIcon />
                  </i>
                </li>
              </Fragment>
            ))}
        </ul>
      </div>
      {openEditModal && (
        <ModalX
          onDismiss={() => {
            setCreditToEdit(null);
            setOpenEditModal(false);
          }}
          open={openEditModal}
        >
          <div className="pl-16 pr-16 pb-32">
            <ExternalCreditsForm
              totalStartDate={totalStartDate}
              totalEndDate={totalEndDate}
              externalStartDate={externalStartDate}
              externalEndDate={externalEndDate}
              pageLimit={pageLimit}
              //
              editMode={openEditModal}
              setEditMode={setOpenEditModal}
              editData={creditToEdit}
              currentPage={activePage}
              setExternalCredits={setTotalRecords}
              filter={filterText}
            />
          </div>
        </ModalX>
      )}
      {openDialogue && (
        <Dialogue
          msg="Are you sure you want to delete the external credit?"
          title="Confirm Delete !!"
          closeDialogue={() => {
            setOpenDialogue(false);
            setCreditToDelete(null);
          }}
          yesAction={() => deleteExternalCredit(creditToDelete?._id)}
        />
      )}
      {showExternalCreditForm && (
        <ModalX
          onDismiss={() => setShowExternalCreditForm(false)}
          open={showExternalCreditForm}
        >
          <ExternalCreditsForm
            totalStartDate={totalStartDate}
            totalEndDate={totalEndDate}
            externalStartDate={externalStartDate}
            externalEndDate={externalEndDate}
            pageLimit={pageLimit}
            //
            editMode={false}
            currentPage={activePage}
            setShowExternalCreditForm={setShowExternalCreditForm}
            setExternalCredits={setTotalRecords}
            filter={filterText}
          />
        </ModalX>
      )}
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={currentPageNumber}
            setCurrentPage={setCurrentPageNumber}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={currentPageNumber}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalCreditsTable;
