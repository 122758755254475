import { Fragment, useRef } from 'react';
import { format } from 'date-fns';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction, localAlertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

import { useNavigate } from 'react-router-dom';
import {
  getFormattedDateInTz2,
  getValue,
  renderRowStyle,
} from '../../utility/commonUtil';

import PageLimitSelect from '../pagination/PageLimitSelect';
import CustomPagination from '../pagination/CustomPagination';
import Select from 'react-select';
import { customSelectStyle3 } from '../reselect/selectUtil';

import '../conference/myConfs.styles.scss';

import DatePicker from 'react-datepicker';
import './purchaseHistory.scss';
import Dialogue from '../dialogue/Dialogue';

import ModalX from '../modal/ModalX';
import AttendeeBookingDetails from './AttendeeBookingDetails';
import PurchaseDetails from './PurchaseDetails';
import DownloadIcon from '../icons/DownloadIcon';
import PurchaseInfoIcon from '../icons/PurchaseInfoIcon';
import PurchasePrintIcon from '../icons/PurchasePrintIcon';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import CreditsIcon from '../icons/CreditsIcon';

const options1 = [
  { label: 'All', value: 'all' },
  { label: 'Video Course', value: 'Video Course' },
  { label: 'Conference', value: 'Conference' },
];

const generateAndSavePDF = async (printIframe) => {
  const iframeDocument =
    printIframe.contentDocument || printIframe.contentWindow.document;
  const elementToPrint = iframeDocument.body;
  // Simulate asynchronous work, e.g., fetching data or generating PDF

  const options = {
    margin: 1,
    filename: 'myDocument.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  await html2pdf().from(elementToPrint).set(options).save();
};

const PurchaseHistoryTable = ({}) => {
  const [bookingToCancel, setBookingToCancel] = useState(null);

  const [openDialogue, setOpenDialogue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [bookings, setBookings] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filter1, setFilter1] = useState('all');

  const [openModalX, setOpenModalX] = useState(false);
  const [openPurchaseModal, setPurchaseModal] = useState(false);
  const [openPodcastModal, setPodcastModal] = useState(false);
  const [showCancelButtons, setShowCancelButtons] = useState(false);

  // for pagination
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Booking Receipt',
    // print: async (printIframe) => {
    //   // Custom asynchronous logic
    //   await generateAndSavePDF(printIframe);
    // },
  });

  const currency = bookingDetails?.orderCurrency?.toString().toUpperCase();
  const receiptComponent = (
    <div
      // ref={componentRef}
      className="grid-table-wrap"
      style={{
        border: '.5px solid #ced9de',
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
        padding: '24px 4px 0px 4px',
      }}
    >
      <div className="px-20 mb-8">
        <h4 className="mb-24">Confemy Booking Details</h4>
        <div>
          <h4 className="mb-12">
            {bookingDetails?.media
              ? bookingDetails?.media?.title
              : 'Media title'}
          </h4>
          <div className="caption-2-regular-gray3 preview-text mb-16">
            <p className="mb-8">
              <span className="caption-1-heavy-cblack">Booking number : </span>
              <span>{bookingDetails?.serialNumber} </span>
            </p>
            <p>
              <span className="caption-1-heavy-cblack">Booking Date : </span>
              <span>
                {' '}
                {getFormattedDateInTz2(
                  bookingDetails?.bookingDate,
                  bookingDetails?.timezone
                )}
              </span>
            </p>
          </div>
          <div className="mb-12">
            <div className="flex-vc  mb-6">
              <CreditsIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {bookingDetails?.media?.credits?.length > 0
                  ? `${bookingDetails?.media?.credits[0]?.quantity} credits`
                  : 'Credits not offered'}
              </span>
            </div>
          </div>
          <p>
            <span className="caption-1-heavy-cblack">
              Total Booking Amount:{' '}
              {bookingDetails?.organiserAmount === 0
                ? 'FREE'
                : `${bookingDetails?.currency}-${bookingDetails?.organiserAmount}`}
            </span>
          </p>
        </div>
      </div>
      <div>
        <div
          className="p-20 mb-8 mt-8"
          style={{
            backgroundColor: showCancelButtons ? '#ecf0f2' : null,
          }}
        >
          <div>
            {bookingDetails?.mediaBooked === 4 ? (
              <span
                style={{ color: '#4cb944' }}
                className="caption-1-heavy-cblack"
              >
                Cancelled
              </span>
            ) : (
              showCancelButtons && (
                // <button
                //   className="small-button small-button-green"
                //   disabled={!showCancelButtons}
                //   onClick={() => {
                //     cancelTicket(bookingDetails);
                //   }}
                // >
                //   Cancel
                // </button>

                <Dialogue
                  msg="Are you sure you want to cancel your purchase?"
                  title="Confirm Cancel !!"
                  closeDialogue={() => {
                    setShowCancelButtons(false);
                  }}
                  yesAction={() => cancelTicket(bookingDetails)}
                />
              )
            )}
          </div>
          <div className="abd-grid-item mt-16">
            <p className="mb-4">
              <span className="caption-1-heavy-cblack">First Name: </span>
              <span className="caption-2-regular-gray3">
                {bookingDetails?.user?.firstName}
              </span>
            </p>
            <p className="mb-4">
              <span className="caption-1-heavy-cblack">Last Name: </span>
              <span className="caption-2-regular-gray3">
                {bookingDetails?.user?.lastName}
              </span>
            </p>
            <p className="mb-4">
              <span className="caption-1-heavy-cblack">Email: </span>
              <span className="caption-2-regular-gray3">
                {bookingDetails?.user?.email}
              </span>
            </p>
            {/* <p className="caption-1-heavy-cblack mb-4">Ticket Details</p> */}

            <p className="caption-2-regular-gray3 mb-4">
              <span> Price: </span>
              <span>
                {bookingDetails?.organiserAmount > 0
                  ? `${bookingDetails?.currency} -`
                  : null}
              </span>
              <span>
                {' '}
                {bookingDetails?.organiserAmount > 0
                  ? bookingDetails?.organiserAmount
                  : 'FREE'}
              </span>
            </p>
            <p className="caption-2-regular-gray3 mb-4">
              <span>Service Charges: </span>
              <span>
                {bookingDetails?.convFee > 0
                  ? `${bookingDetails?.currency} -  `
                  : null}
              </span>
              <span>{bookingDetails?.convFee}</span>
            </p>
            {/* <p>
              <span className="caption-1-heavy-cblack">
                Total Ticket Price:{' '}
              </span>
              <span className="caption-2-regular-gray3">
                {guest.price > 0 ? `${currency} -` : null}
              </span>
              <span className="caption-2-regular-gray3">
                {' '}
                {guest.price > 0 ? guest.price : 'FREE'}
              </span>
            </p> */}
          </div>
          <div
            className="caption-2-regular-gray3"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {bookingDetails?.refunded && (
              <p className="caption-1-heavy-cblack mb-4">Refunded </p>
            )}
            {bookingDetails?.refunded && bookingDetails?.refundAmount && (
              <p>
                <span>{currency}-</span>
                <span>{bookingDetails?.refundAmount}</span>
              </p>
            )}
            {bookingDetails?.refundStatus === 5 && <p>Refund request denied</p>}
          </div>
        </div>
      </div>
    </div>
  );

  //  on clicking a row cell edit the speaker
  async function onRowClick(booking, open) {
    try {
      let response = '';
      if (booking?.eventType === 'Conference') {
        let response = await api.get(`/conferences/bookings/${booking?._id}`);
        setBookingDetails(response.data.data.bookingDetails);
        setIsLoading(false);
        setOpenModalX(true);
      } else {
        response = await api.get(
          `/courses/videoCourse/purchaseHistory/${booking?._id}/users/${user?._id}`
        );

        setBookingDetails(response.data.data.videoCourse);
        setIsLoading(false);

        if (open) {
          if (
            booking?.eventType === 'Video Course' ||
            booking?.eventType === 'Audio Course'
          ) {
            setPurchaseModal(true);
          } else {
            setPodcastModal(true);
          }
        }
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
      setIsLoading(false);
      setOpenModalX(false);
    }
    // setBookingToShow(booking);
    // setShowBooking(true);
  }

  const cancelTicket = async (guest) => {
    try {
      let formData = {
        atteendeeDetails: {
          transactionId: guest.bookingDetails,
          attendeeIds: [guest?._id],
          userId: user?._id,
        },
      };
      const response = await api.post(`/attendees/refunds`, formData);
      if (response) {
        getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
        setBookingDetails(response.data.data.booking);
        setShowCancelButtons(false);
      }
    } catch (err) {
      dispatch(localAlertAction(err.response.data.message, 'danger'));
      setShowCancelButtons(false);
    }
  };

  const cancelPurchase = async (guest) => {
    try {
      let formData = {
        purchaseDetails: {
          purchaseId: guest?._id,
          userId: user?._id,
        },
      };
      const response = await api.post(
        `/attendees/videoCourses/refunds?pageLimit=${pageLimit}&currentPage=${activePage}&eventType=${filter1}&startDate=${startDate}&endDate=${endDate}`,
        formData
      );
      if (response) {
        setShowCancelButtons(false);
        setBookingDetails(response.data.data.purchase);
        // getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
        //  setBookingDetails(response.data.data.booking);
        if (response.data.data?.page === 1) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        setBookings(response.data.data.bookings);
        setTotalRecords(response.data.data.totalBookingCount);
      }
    } catch (err) {
      dispatch(localAlertAction(err.response.data.message, 'danger'));
      setShowCancelButtons(false);
    }
  };

  const cancelPodcast = async (guest) => {
    try {
      let formData = {
        purchaseDetails: {
          purchaseId: guest?._id,
          userId: user?._id,
        },
      };
      const response = await api.post(
        `/attendees/videoCourses/refunds?pageLimit=${pageLimit}&currentPage=${activePage}&eventType=${filter1}&startDate=${startDate}&endDate=${endDate}`,
        formData
      );
      if (response) {
        setShowCancelButtons(false);
        setBookingDetails(response.data.data.purchase);
        // getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
        //  setBookingDetails(response.data.data.booking);
        if (response.data.data?.page === 1) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        setBookings(response.data.data.bookings);
        setTotalRecords(response.data.data.totalBookingCount);
      }
    } catch (err) {
      dispatch(localAlertAction(err.response.data.message, 'danger'));
      setShowCancelButtons(false);
    }
  };

  const getAllBookings = async (
    currentPage,
    limit,
    filter1,
    startDate,
    endDate
  ) => {
    try {
      let response = await api.get(
        `/courses/videoCourse/users/${user?._id}/bookings?pageLimit=${limit}&currentPage=${currentPage}&eventType=${filter1}&startDate=${startDate}&endDate=${endDate}`
      );

      if (response.data.data?.page === 1) {
        setCurrentPageNumber({ currentPage: response.data.data?.page });
        setCurrentPage(response.data.data?.page);
      }
      setBookings(response.data.data.bookings);
      setTotalRecords(response.data.data.totalBookingCount);
    } catch (error) {
      dispatch(alertAction(error.response.data.message, 'danger'));
    }
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;
    getAllBookings(currentPage, pageLimit, filter1);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
  }, [user?._id, filter1, startDate, endDate]);

  return (
    <div className="mb-24">
      <h2 className="color-primary mb-16">Purchase History</h2>
      <div className="credit-flex-vc-sb mb-16">
        <div className="myconfs-sort mb-16">
          <div>
            <div>
              <p className="caption-1-regular-gray3 ml-2 mb-8">Event Type</p>
              <Select
                value={getValue(options1, filter1, false)}
                options={options1}
                name="filter1"
                placeholder="Select event type."
                onChange={(option) => {
                  setFilter1(option.value);
                }}
                styles={customSelectStyle3}
              />
            </div>
          </div>
          <div>
            <div className="form-type-3">
              <p className="caption-1-regular-gray3 ml-2 mb-8">From Date</p>
              <DatePicker
                selected={startDate}
                onChange={(val) => setStartDate(val)}
                isClearable
                placeholderText="From"
              />
            </div>
          </div>
          <div>
            <div className="form-type-3">
              <p className="caption-1-regular-gray3 ml-2 mb-8">To Date</p>
              <DatePicker
                selected={endDate}
                onChange={(val) => setEndDate(val)}
                isClearable
                placeholderText="Until"
                minDate={startDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="table-container">
        <ul className="ph-gridtable grid-table-5-800">
          <li>Event Title</li>
          <li>Event Type</li>
          <li>Date</li>
          <li>Status</li>
          <li>
            <div className="text-align-center">Actions</div>
          </li>

          {bookings?.length > 0 &&
            bookings?.map((data, indx) => (
              <Fragment key={data._id}>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  onClick={() => onRowClick(data, true)}
                >
                  <p
                    className="avenir-heavy-18"
                    style={{ color: '#000', fontSize: 16 }}
                  >
                    {data?.title}
                  </p>
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  // onClick={() => onRowClick(data)}
                >
                  {data.eventType}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  // onClick={() => onRowClick(data)}
                >
                  {getFormattedDateInTz2(data?.bookingDate, data?.timezone)}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  // onClick={() => onRowClick(data)}
                >
                  {data?.booked === 1 ? (
                    <span
                      style={{
                        color: '#4CB944',
                      }}
                    >
                      Payment complete{' '}
                    </span>
                  ) : (
                    <span style={{ color: '#bb2124' }}>Cancelled</span>
                  )}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                >
                  <div
                    className="flex-vc"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        onRowClick(data, true);
                        // handlePrint();
                      }}
                    >
                      <PurchasePrintIcon />
                    </i>
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        onRowClick(data, false);
                        // downloadPdf();
                        handlePrint();
                      }}
                      className="mx-8"
                    >
                      <DownloadIcon />
                    </i>
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        setBookingToCancel(data);
                        setOpenDialogue(true);
                      }}
                    >
                      <PurchaseInfoIcon />
                    </i>
                  </div>
                </li>
              </Fragment>
            ))}
        </ul>
      </div>
      {openModalX && !isLoading && (
        <ModalX
          onDismiss={() => setOpenModalX(false)}
          open={openModalX && !isLoading}
        >
          <div className="modalx-container-md mb-32 px-20">
            <AttendeeBookingDetails
              bookingDetails={bookingDetails}
              cancelTicket={cancelTicket}
              showCancelButtons={showCancelButtons}
              setShowCancelButtons={setShowCancelButtons}
              setBookingDetails={setBookingDetails}
            />
          </div>
        </ModalX>
      )}
      {(openPurchaseModal || openPodcastModal) && !isLoading && (
        <ModalX
          onDismiss={() =>
            openPurchaseModal ? setPurchaseModal(false) : setPodcastModal(false)
          }
          open={openPurchaseModal && !isLoading}
        >
          <div className="modalx-container-md mb-32 px-20">
            <PurchaseDetails
              refValue={componentRef}
              generateAndSavePDF={generateAndSavePDF}
              bookingDetails={bookingDetails}
              cancelTicket={openPodcastModal ? cancelPurchase : cancelPodcast}
              showCancelButtons={showCancelButtons}
              setShowCancelButtons={setShowCancelButtons}
              setBookingDetails={setBookingDetails}
            />
          </div>
        </ModalX>
      )}
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={currentPageNumber}
            setCurrentPage={setCurrentPageNumber}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={currentPageNumber}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
      {openDialogue && (
        <Dialogue
          msg="Are you sure you want to cancel the purchase?"
          title="Confirm cancel purchase !!"
          closeDialogue={() => {
            setOpenDialogue(false);
            setBookingToCancel(null);
          }}
          yesAction={() => {}}
        />
      )}
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="print-margins">
          {receiptComponent}
        </div>
      </div>
    </div>
  );
};

export default PurchaseHistoryTable;
