import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import "./localAlert.styles.scss";
import CloseIcon from "../icons/CloseIcon";
import ErrorIcon from "../icons/ErrorIcon";
import SuccessTickIcon from "../icons/SuccessTickIcon";

import { REMOVE_LOCAL_ALERT } from "../../redux/alert/alertTypes";

export default function LocalAlert() {
  const localAlert = useSelector((state) => state.alert.localAlert);
  const dispatch = useDispatch();

  const removeAlert = () => {
    dispatch({
      type: REMOVE_LOCAL_ALERT,
    });
  };

  const renderIcon = (alertType) => {
    switch (alertType) {
      case "danger":
        return <ErrorIcon className="icon-sm" fill="#fff" />;
      case "success":
        return <SuccessTickIcon className="icon-sm" fill="#fff" />;
      default:
        return null;
    }
  };

  return (
    localAlert &&
    Object.keys(localAlert)?.length > 0 && (
      <div className="local-alert-container">
        <div className={`local-alert local-alert-${localAlert?.alertType}`}>
          <div className="flex-vc">
            <i className="mr-24">{renderIcon(localAlert?.alertType)}</i>
            {localAlert?.msg}
          </div>
          <i className="ml-24" onClick={() => removeAlert()}>
            <CloseIcon className="icon-sm" fill="#fff" />
          </i>
        </div>
      </div>
    )
  );
}

LocalAlert.propTypes = {
  alert: PropTypes.object,
};
