export default function AboutUs() {
  return (
    <div style={{ padding: "4% 5% 5%" }} className="container-lg">
      <div>
        <h4 className="color-primary mb-16">About Confemy</h4>
        <h2 className="color-primary mb-24">
          Your Digital Transformation Partner
        </h2>
        <div className="body-regular-gray3">
          <p className="mb-16">
            Confemy was conceived to bring new advances in medicine & life
            sciences to health professionals seamlessly. With Confemy, one can
            host, publish, register and attend or livestream medical
            conferences, video content or audio podcast effortlessly. It's great
            tool to deliver or consume continued medical education.
          </p>
          <p className="mb-16">
            Designed by doctors and nurses, we strive to make our platform
            better every day. Honesty and Integrity are our core values.
          </p>

          <p className="mb-16">
            It's our endeavor to innovate in healthcare to bring about positive
            meaningful changes in peoples lives.
          </p>
        </div>
      </div>
      <div className="body-regular-gray3">
        <h4 className="color-primary mb-16">Our mission</h4>
        <h2 className="color-primary mb-24">
          Bring Modern Technology to Medicine
        </h2>
        <div className="privacy">
          <p className="mb-16">
            It is a unique platform to enrich medical education.
          </p>
        </div>
      </div>
      <div className="body-regular-gray3">
        <h4 className="color-primary mb-16">Our vision</h4>
        <h2 className="color-primary mb-24">Improve Health outcomes</h2>
        <div className="privacy">
          <p className="mb-16">
            We strive to minimize errors in healthcare and improve health
            outcomes.
          </p>
        </div>
      </div>
    </div>
  );
}
