import { useSelector } from "react-redux";
import Tabs from "../tabs/Tabs";

export default function VideoCourseSteps() {
  const newVideoCourse = useSelector((state) => state.video?.newVideoCourse);

  const steps = [
    {
      label: "Basic Info",
      path: "/dashboard/create-vc/step-1",
      completed: newVideoCourse?.completedStep1,
    },
    {
      label: "Details",
      path: "/dashboard/create-vc/step-2",
      completed: newVideoCourse?.completedStep2,
    },
    {
      label: "Structure",
      path: "/dashboard/create-vc/step-3",
      completed: newVideoCourse?.completedStep3,
    },
    {
      label: "Pricing",
      path: "/dashboard/create-vc/step-4",
      completed: newVideoCourse?.completedStep4,
    },

    {
      label: "Publish",
      path: "/dashboard/create-vc/step-5",
      completed: newVideoCourse?.completedStep6,
    },
  ];

  return (
    <div>
      <h2 className="color-primary mb-18">Create Video Course</h2>
      <div
        style={{
          marginBottom: 24,
          borderBottom: "1px solid #ced9de",
        }}
      ></div>
      <div className="mb-32">
        <Tabs tabs={steps} showRadioButtons={true} />
      </div>
    </div>
  );
}
