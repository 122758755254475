import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alertAction } from '../../redux/alert/alertAction';
import { loadOneIncopleteConfAction } from '../../redux/conference/conferenceAction';
import api from '../../utility/api';
import { getOneIncompleteConf } from '../../utility/commonUtil';
import ButtonWithLoader from '../button/ButtonWithLoader';
import ConfDetails from '../conference/ConfDetails';
import Dialogue from '../dialogue/Dialogue';
import BackIcon from '../icons/BackIcon';
import CloseIcon from '../icons/CloseIcon';
import Modal from '../modal/Modal';
import './confDetailsActionButtons.scss';

// Bdfo = booking details for organizer
export default function ConfDetailsActionButtons({ confDetails }) {
  const [open, setopen] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newConference = useSelector((state) => state.conference.newConference);

  const openPreview = async (conferenceId) => {
    setPreviewLoading(true);
    try {
      const response = await api.get(`conferences/${conferenceId}`);
      if (response) {
        dispatch(loadOneIncopleteConfAction(response.data.data.conferences));
        setopen(true);
        setPreviewLoading(false);
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
        setPreviewLoading(false);
      }
    }
  };

  const duplicateConf = async (confId) => {
    setDuplicateLoading(true);
    try {
      const response = await api.post(
        `organizers/conferences/${confId}/duplicateConference`
      );
      if (response) {
        dispatch(loadOneIncopleteConfAction(response.data.data.conference));
        navigate('/dashboard/create-conf/step-1');
        setDuplicateLoading(false);
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
        setDuplicateLoading(false);
      }
    }
  };

  const closeModal = () => {
    setopen(false);
    setPreviewLoading(false);
  };

  return (
    <>
      <div className="cdab-grid">
        <div className="flex-vc">
          <button
            style={{ height: 52, paddingRight: 20 }}
            className="flex-vc small-button small-button-primary"
            onClick={() => navigate(-1)}
          >
            <i
              style={{
                position: 'relative',
                paddingRight: 4,
                paddingTop: 5,
              }}
            >
              <BackIcon className="icon-size" fill="#fff" />
            </i>
            <span>Back</span>
          </button>
          <p className="caption-1-regular-gray3 ml-24">Published</p>
        </div>
        <div
          style={{
            display: 'grid',
            // width: "100%",
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 8,
          }}
        >
          <div style={{ width: '100%' }}>
            <ButtonWithLoader
              isSubmitting={previewLoading}
              text="Preview"
              className="small-button small-button-primary"
              fullWidth={true}
              handleClick={() => openPreview(confDetails?._id)}
            />
          </div>
          <div style={{ width: '100%' }}>
            <ButtonWithLoader
              isSubmitting={duplicateLoading}
              text="Duplicate"
              className="small-button small-button-primary"
              fullWidth={true}
              handleClick={() => setOpenDialogue(true)}
            />
          </div>
          <div style={{ width: '100%' }}>
            <ButtonWithLoader
              isSubmitting={editLoading}
              text="Edit"
              className="small-button small-button-green"
              fullWidth={true}
              handleClick={async () => {
                setEditLoading(true);
                await getOneIncompleteConf(confDetails?._id, navigate);
                setEditLoading(false);
              }}
            />
          </div>
        </div>
      </div>
      {open && (
        <Modal onDismiss={closeModal}>
          <div className="conf-preview-wrap">
            <div className="modalx-header">
              <i style={{ cursor: 'pointer' }} onClick={closeModal}>
                <CloseIcon className="icon-size" fill="#c4c4c4" />
              </i>
            </div>
            <ConfDetails conf={newConference} preview={true} />
            <div className="flex-hc mb-16">
              <button onClick={closeModal} className="button button-primary">
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
      {openDialogue && (
        <Dialogue
          closeDialogue={() => setOpenDialogue(false)}
          title="Duplicate Conference"
          msg="Data will be auto populated for each step except tickets which need to be created new. Please make changes as necessary, review each step and resubmit it."
          yesAction={() => duplicateConf(confDetails?._id)}
        />
      )}
    </>
  );
}
