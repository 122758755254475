import { useNavigate } from 'react-router-dom';
import CourseCardWithOptions from './CourseCardWithOptions';
import './purchasevideolist.scss';
// import css from "./MyVideoCourses.module.css";
// import shareIcon from "./video-course-icons/share.png";
// import starIcon from "./video-course-icons/star-b.png";

export default function PurchasedVideoList({ courses, type }) {
  const navigate = useNavigate();

  // const optionsComp = [
  //   <div className={css.opt}>
  //     <Link to="/" className={css.txtBox}>
  //       <span className={css.iconBox}>
  //         <img src={shareIcon} alt="icon" className={css.icon} />
  //       </span>
  //       <span className={css.txt}>Share</span>
  //     </Link>
  //     <Link to="/" className={css.txtBox}>
  //       <span className={css.iconBox}>
  //         <img src={starIcon} alt="icon" className={css.icon} />
  //       </span>
  //       <span className={css.txt}>Favorite</span>
  //     </Link>
  //   </div>,
  // ];

  return (
    <div className="purchase-video-con flex flex-wrap" style={{ gap: '2rem' }}>
      {courses.map((course) => {
        return (
          <CourseCardWithOptions
            Key={course._id}
            data={course}
            isOptions={true}
            openPage={() => navigate(`/video-courses/${course._id}`)}
            // options={optionsComp}
            id={course?._id}
            type={type}
          />
        );
      })}
    </div>
  );
}
