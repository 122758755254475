import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';

import {
  openInNewTab,
  likeConference,
  unlikeConference,
  getFormattedDateInTz,
  getFullLocationString,
} from '../../utility/commonUtil';
import CreditsIcon from '../icons/CreditsIcon';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import MediaTag from '../tags/MediaTag';
import { useNavigate } from 'react-router-dom';

export default function HoverCard({ conf }) {
  const [isLiked, setIsLiked] = useState(false);

  // const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLiked(conf?.isLiked);
  }, [conf?.isLiked]);

  return (
    <div className="cc-hovcard">
      <div className="cc-hovcard-inner">
        <div className="cc-hovcard-content mb-8">
          <div className="mb-12">
            <MediaTag tag="Conference" />
          </div>
          <p className="avenir-heavy-16 mb-16">{conf?.title}</p>
          <div className="flex-vc  mb-8">
            <DateIcon className="icon-xxs mr-8" />
            <span className="caption-2-regular-gray3 mr-4">Start: </span>
            <span className="caption-2-regular-gray3  cc-truncitem-wrap">
              {getFormattedDateInTz(conf?.startDate, conf?.timezone)}
            </span>
          </div>
          <div className="flex-vc  mb-8">
            <DateIcon className="icon-xxs mr-8" />
            <span className="caption-2-regular-gray3 mr-4">End: </span>
            <span className="caption-2-regular-gray3  cc-truncitem-wrap">
              {getFormattedDateInTz(conf?.endDate, conf?.timezone)}
            </span>
          </div>
          <div className="flex-vc  mb-8">
            <LocationIcon className="icon-xxs mr-8" />
            <span className="caption-2-regular-gray3 cc-truncitem-wrap">
              Location:{' '}
              {getFullLocationString(
                conf?.mode,
                conf?.city,
                conf?.state,
                conf?.country
              )}
            </span>
          </div>
          <div className="mb-8">
            <div className="flex-vc mb-8">
              <CreditsIcon className="icon-xxs mr-8" />
              <p className="caption-2-regular-gray3">Credits</p>
            </div>
            <div className="cc-hovcard-credits">
              {conf?.credits?.length > 0 ? (
                conf?.credits.map((credit) => {
                  return (
                    <Fragment key={credit.creditId._id}>
                      <div className="caption-2-regular-gray3">
                        {credit.creditId.name}
                      </div>
                      <div className="caption-2-regular-gray3">
                        {credit.quantity}
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <div className="caption-2-regular-gray3">Credits not added</div>
              )}
            </div>
          </div>
          <div className="mb-16">
            <p className="caption-2-regular-gray3 mb-8">Tickets</p>
            <div className="cc-hovcard-credits">
              {conf?.tickets?.length > 0 ? (
                conf?.tickets.map((ticket) => {
                  return (
                    <Fragment key={ticket._id}>
                      <div className="caption-2-regular-gray3">
                        {ticket.name}
                      </div>
                      <div className="caption-2-regular-gray3">
                        {conf?.currency} {ticket.price}
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <div className="caption-2-regular-gray3">Tickets not added</div>
              )}
            </div>
          </div>
          <div
            className="card-description"
            style={{
              WebkitLineClamp: 4,
            }}
          >
            {conf?.description}
          </div>
        </div>
        <div className="px-16">
          <button
            style={{ width: '100%' }}
            className="small-button  small-button-green"
            onClick={() => {
              navigate(`/book-conference/${conf?._id}`);
            }}
          >
            Book
          </button>
        </div>
      </div>
    </div>
  );
}
