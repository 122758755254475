export default function ErrorIcon({ className, fill }) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 295.428 295.428"
      style={{
        enableBackground: "new 0 0 295.428 295.428",
      }}
      fill={fill ? fill : "#fff"}
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M147.714,0C66.264,0,0,66.264,0,147.714s66.264,147.714,147.714,147.714s147.714-66.264,147.714-147.714
		S229.164,0,147.714,0z M147.714,265.428C82.807,265.428,30,212.621,30,147.714S82.807,30,147.714,30
		s117.714,52.807,117.714,117.714S212.621,265.428,147.714,265.428z"
        />
        <path
          d="M147.714,61.68c-8.284,0-15,6.716-15,15v79c0,8.284,6.716,15,15,15s15-6.716,15-15v-79
		C162.714,68.396,155.998,61.68,147.714,61.68z"
        />
        <circle cx="147.714" cy="217.68" r="15" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
