import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from '../../components/loader/Loader';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

import '../booking-for-organizer-pages/bdfoPage.styles.scss';
import BdfoVideoCourseTitle from '../../components/video-course/organiser-bookings/BdfoVideoCourseTitle';
import BdfoVideoCourseCounts from '../../components/video-course/organiser-bookings/BdfoVideoCourseCounts';
import BdfoVideoCourseBooking from '../../components/video-course/organiser-bookings/BdfoVideoCourseBooking';
import VideoCourseDetailsActionButtons from '../../components/video-course/organiser-bookings/VideoCourseDetailsActionButton';
import AudioCourseDetailsActionButtons from '../../components/audio-course/AudioCourseDetailsAction';
// booking details for organizer
export default function BdfoAudioCoursePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);

  const dispatch = useDispatch();
  const videoCourseId = useParams().audioCourseId;

  async function getBookings(videoCourseId) {
    try {
      const response = await api.get(
        `/courses/${videoCourseId}/videoCourse/earnings`
      );

      setDetails(response.data.data.videoCourse);
      setIsLoading(false);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBookings(videoCourseId);
  }, [videoCourseId]);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="bdfo-container">
            <AudioCourseDetailsActionButtons audioDetails={details} />
            <section className="mb-32">
              <BdfoVideoCourseTitle title={details?.title} />
            </section>
            <section className="mb-32">
              <BdfoVideoCourseCounts
                currency={details?.currency}
                sold={details?.totalSold}
                grossPrice={details?.netSale}
                refundRequests={details?.totalCancelled}
                refunded={details?.totalRefunded}
              />
            </section>

            <section className="mb-60">
              <BdfoVideoCourseBooking
                getBookings={getBookings}
                attendees={details?.bookings}
                videoCourse={details}
              />
            </section>
            {/* <section>
              <BdfoLinks confId={confId} />
            </section> */}
          </div>
        </>
      )}
    </div>
  );
}
