function VideoconfSketch({ className }) {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 132 132"
      >
        <g fill="none" fillRule="evenodd">
          <g fillRule="nonzero">
            <g transform="translate(-654.000000, -962.000000) translate(654.000000, 962.000000)">
              <path
                fill="#FFF"
                d="M119.252 101H12.748C7.917 101 4 97.084 4 92.252V12.748C4 7.916 7.917 4 12.748 4h106.504C124.083 4 128 7.916 128 12.748v79.504c0 4.831-3.917 8.748-8.748 8.748z"
              />
              <path
                fill="#08415C"
                d="M119.254 4H66v97h53.254c4.83 0 8.746-3.916 8.746-8.748V12.748C128 7.916 124.084 4 119.254 4z"
              />
              <path
                fill="#4CB944"
                d="M4 52v40.162C4 97.043 7.917 101 12.748 101h106.504c4.831 0 8.748-3.957 8.748-8.838V52H4z"
              />
              <path
                fill="#FFF"
                d="M66 52v49h53.254c4.83 0 8.746-3.957 8.746-8.838V52H66z"
              />
              <path
                fill="#042C3F"
                d="M45 128L49.181 101 83.248 101 87 127.997z"
              />
              <path
                fill="#08415C"
                d="M97.128 132H35.872c-2.138 0-3.872-1.79-3.872-4s1.734-4 3.872-4h61.256c2.138 0 3.872 1.79 3.872 4s-1.734 4-3.872 4z"
              />
              <circle cx="96" cy="74" r="9" fill="#F9CCAA" />
              <path
                fill="#08415C"
                d="M101.886 84H90.042v.002c-5 .038-9.042 3.937-9.042 8.744v1.964c0 1.265 1.068 2.29 2.387 2.29h25.226c1.319 0 2.387-1.025 2.387-2.29v-1.964c0-4.83-4.08-8.746-9.114-8.746z"
              />
              <circle cx="36" cy="74" r="9" fill="#FFF" />
              <path
                fill="#08415C"
                d="M41.886 84H30.042v.002c-5 .038-9.042 3.937-9.042 8.744v1.964c0 1.265 1.068 2.29 2.387 2.29h25.226C49.932 97 51 95.975 51 94.71v-1.964C51 87.916 46.92 84 41.886 84z"
              />
              <circle cx="96" cy="29" r="9" fill="#FFF" />
              <path
                fill="#4CB944"
                d="M101.886 39H90.042v.002c-5 .038-9.042 3.937-9.042 8.744v1.964c0 1.265 1.068 2.29 2.387 2.29h25.226c1.319 0 2.387-1.025 2.387-2.29v-1.964c0-4.83-4.08-8.746-9.114-8.746z"
              />
              <circle cx="36" cy="29" r="9" fill="#F9CCAA" />
              <path
                fill="#08415C"
                d="M41.886 39H30.042v.002c-5 .038-9.042 3.937-9.042 8.744v1.964c0 1.265 1.068 2.29 2.387 2.29h25.226C49.932 52 51 50.975 51 49.71v-1.964C51 42.916 46.92 39 41.886 39z"
              />
              <path
                fill="#08415C"
                d="M120.388 105H11.612C5.199 105 0 99.799 0 93.383V11.617C0 5.201 5.199 0 11.612 0h108.777C126.8 0 132 5.201 132 11.617v81.766c0 6.416-5.199 11.617-11.612 11.617zM11.612 7.735c-2.143 0-3.88 1.738-3.88 3.882v81.766c0 2.144 1.737 3.882 3.88 3.882h108.776c2.143 0 3.88-1.738 3.88-3.882V11.617c0-2.144-1.737-3.882-3.88-3.882H11.612z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export default VideoconfSketch;
