import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { alertAction, localAlertAction } from "../../redux/alert/alertAction";
import { createConferenceAction } from "../../redux/conference/conferenceAction";
import api from "../../utility/api";
import { currencylist, prodUrl } from "../../utility/commonUtil";
import LocalAlert from "../alert/LocalAlert";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import TextError from "../formik/TextError";
import CustomDatepicker from "../react-datepicker/CustomDatepicker";
import ReloadableFormikSelect1 from "../reselect/ReloadableFormikSelect1";
// import Switch from "../switch/Switch";
// import AnimateHeight from "react-animate-height";
import Dialogue from "../dialogue/Dialogue";
import "./ticketForm.styles.scss";
import Loader from "../loader/Loader";

const validationSchema = yup.object({
  name: yup.string("Required").trim().required("Required"),
  info: yup.string(),
  currency: yup.string("Required").required("Required"),
  quantity: yup
    .number()
    .typeError("Enter valid quantity")
    .required("Required")
    .positive("Enter amount more than 0"),
  price: yup
    .number()
    .typeError("Enter valid amount")
    .required("Required")
    .min(0, "Price can only be 0 or more"),
  saleStartDate: yup.date().required("Required").nullable(),
});

const todaysDate = new Date();

export default function TicketForm({ ticket, onClose }) {
  const newConference = useSelector((state) => state.conference.newConference);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [openDialogue, setOpenDialogue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorDialogue, setErrorDialogue] = useState();

  let apiSaleStartDate = null;
  if (ticket?.saleStart && newConference?.timezone) {
    apiSaleStartDate = utcToZonedTime(
      ticket?.saleStart,
      newConference?.timezone
    );
  }

  async function onSubmit(values, actions) {
    if (!newConference?.completedStep1) {
      // dispatch(alertAction("Complete step-1 first", "danger"));
      dispatch(localAlertAction("Complete step-1 first", "danger"));
      return;
    }

    if (newConference?.completedStep6 && values?.price > 0) {
      const res = await api.get(
        `organizers/accounts/conferences/${newConference._id}`
      );

      if (
        !res?.data?.data?.connectedAccount?.detailsSubmitted &&
        !res?.data?.data?.connectedAccount?.chargesEnabled
      ) {
        setOpenDialogue(true);
        return;
      } else if (
        !res?.data?.data?.connectedAccount?.chargesEnabled &&
        res?.data?.data?.connectedAccount?.detailsSubmitted
      ) {
        setErrorDialogue(true);
        return;
      }
    }

    const formData = {
      ticketDetails: {
        name: values.name,
        info: values.info,
        currency: values.currency,
        quantity: values.quantity,
        price: values.price,
        saleStartDate: zonedTimeToUtc(
          values.saleStartDate,
          newConference?.timezone
        ).toISOString(),
        conferenceId: newConference?._id,
        ticketId: ticket?._id,
        // following data is hard coded for now
        isRefundable: true,
        refundPolicy: "This is our refund policy",
      },
    };

    try {
      const url = "conferences/step5/tickets";
      const response = await api.post(url, formData);

      dispatch(createConferenceAction(response.data.data.conference));
      dispatch(alertAction(response.data.message, "success"));
      onClose();
    } catch (err) {
      dispatch(localAlertAction(err.response.data.message, "danger"));
    }
  }

  const initialValues = {
    name: ticket?.name || "",
    info: ticket?.info || "",
    currency: ticket?.currency || "",
    quantity: ticket?.quantity || 1,
    price: ticket?.price || 0,
    saleStartDate: apiSaleStartDate,
    isRefundable: true,
    refundPolicy: "",
  };

  // const formik = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: validationSchema,
  //   onSubmit: onSubmit,
  //   enableReinitialize: true,
  // });

  const getConnectedAccountLink = async () => {
    const url = `/organizers/accounts`;

    let accountDetails = {
      returnUrl: `${prodUrl}/dashboard/create-conference`,
      refreshUrl: `${prodUrl}/dashboard/create-conference`,
      userId: user?._id,
    };

    if (newConference?.host === "user") {
      accountDetails = {
        ...accountDetails,
        type: "user",
      };
    } else {
      accountDetails = {
        ...accountDetails,
        type: "org",
        organizationId: newConference?.hostedBy.organization?._id,
      };
    }

    try {
      setIsLoading(true);
      const response = await api.post(url, { accountDetails });

      if (response) {
        window.location.replace(response?.data?.data?.accountLink?.url);
        // setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  // useEffect(() => {
  //   return () => {
  //     formik.resetForm({ values: initialValues });
  //   };
  // }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <h2
            className={`mb-24 color-primary ${
              newConference?.isRegularTicketCreated ? "text-align-center" : ""
            }`}
          >
            {newConference?.isRegularTicketCreated
              ? "Create ticket"
              : "Create base price ticket"}
          </h2>
          <LocalAlert />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                <Form className="form-type-1" autoComplete="off">
                  <div className="mb-8">
                    <div className="material-textfield">
                      <Field
                        id="name"
                        type="text"
                        name="name"
                        placeholder=" "
                      />
                      <label>Ticket Name*</label>
                    </div>
                    <ErrorMessage name="name" component={TextError} />
                  </div>
                  <div className="mb-8">
                    <Field
                      id="info"
                      as="textarea"
                      name="info"
                      placeholder="Ticket Info"
                    />
                    <ErrorMessage name="info" component={TextError} />
                  </div>
                  <div className="mb-8">
                    <div className="material-textfield">
                      <Field
                        id="quantity"
                        type="number"
                        name="quantity"
                        min={1}
                        placeholder=" "
                      />
                      <label>No of tickets of this type*</label>
                    </div>
                    <ErrorMessage name="quantity" component={TextError} />
                  </div>
                  <div>
                    <div className="mb-8">
                      <Field
                        name="currency"
                        component={ReloadableFormikSelect1}
                        options={currencylist}
                        placeholder="Currency*"
                      />
                      <ErrorMessage name="currency" component={TextError} />
                    </div>
                    <div className="mb-8">
                      <div className="material-textfield">
                        <Field
                          id="price"
                          type="number"
                          name="price"
                          min={0}
                          placeholder=" "
                        />
                        <label>Price*</label>
                      </div>
                      <ErrorMessage name="price" component={TextError} />
                    </div>
                  </div>
                  {/* <div className="flex-vc mb-24">
                  <p className="caption-1-regular-gray3 mr-16">
                    Is the ticket refundable?
                  </p>
                  <Switch
                    id="isRefundable"
                    name="isRefundable"
                    value="isRefundable"
                    checked={formik.values.isRefundable}
                    onChange={formik.handleChange}
                    disable={formik.values.isFree}
                  />
                </div>
                  <div
                   className={`${formik.values.isRefundable ? "" : "display-none"}`}
                  >
                    Refund policy goes here
                  </div>
                 */}
                  <div className="mb-40">
                    <Field name="saleStartDate">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <CustomDatepicker
                            id="saleStartDate"
                            name="saleStartDate"
                            selected={value}
                            onChange={(date) =>
                              setFieldValue("saleStartDate", date)
                            }
                            minDate={todaysDate}
                            placeholder="Pick sale's start date & time*"
                            disabled={false}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="saleStartDate" component={TextError} />
                  </div>
                  <div
                    className={
                      newConference?.isRegularTicketCreated ? "flex-hc" : ""
                    }
                  >
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => {
                        props.resetForm({ values: initialValues });
                        onClose();
                      }}
                      cancelButtonClass=""
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
          {openDialogue && (
            <Dialogue
              msg="Add bank account to collect Payment for paid tickets."
              title="Bank Account Onboard"
              closeDialogue={() => {
                setOpenDialogue(false);
              }}
              yesAction={() => getConnectedAccountLink()}
            />
          )}
          {openErrorDialogue && (
            <Dialogue
              msg="Please follow intructions on your stripe account to verify bank account."
              title="Bank Account Verification Failed."
              closeDialogue={() => {
                setErrorDialogue(false);
              }}
              yesAction={() => setErrorDialogue(false)}
            />
          )}
        </>
      )}
    </div>
  );
}
