import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '../icons/AddIcon';
import FacebookBlueCircle from '../icons/FacebookBlueCircle';
import InstagramGradientIcon from '../icons/InstagramGradientIcon';
import LinkedinBlueIcon from '../icons/LinkedinBlueIcon';
import TwitterBlueIcon from '../icons/TwitterBlueIcon';
import SocialMedia from './SocilaMedia';

import BasicProfileInfo from './BasicProfileInfo';
import License from './License';
import LicenseForm from './LicenseForm';
import PracticeAddress from './PracticeAddress';
import PracticeAddressForm from './PracticeAddressForm';
import { alertAction } from '../../redux/alert/alertAction';
import { loginAction } from '../../redux/auth/authAction';
import { loadUserProfileAction } from '../../redux/user-profile/userProfileAction';
import api from '../../utility/api';
import UpdatePassword from './UpdatePassword';
import './userSettings.scss';

export default function AccountSettings() {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showLicenseForm, setShowLicenseForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [stripe, setStripe] = useState({});

  const {
    auth: { user },
    userProfile,
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const fetchUserProfile = async (userId) => {
    try {
      const response = await api.get(`/users/${userId}`);
      if (response) {
        dispatch(loginAction(response.data.data.authUser));
        dispatch(loadUserProfileAction(response.data.data.user));
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    fetchUserProfile(user._id);
  }, [user._id]);

  return (
    <div className="as-container mt-40">
      <section className="as-section-gap">
        {/* Basic Info */}
        <BasicProfileInfo />
      </section>
      <section className="as-section-gap">
        {/* Practice Address */}
        <h2 className="my-24 color-primary">Practice Address</h2>
        <div className="my-24">
          {showAddressForm ? (
            <PracticeAddressForm
              setShowAddressForm={setShowAddressForm}
              editMode={false}
            />
          ) : (
            <div className="flex-vc">
              <button
                onClick={() => {
                  setShowAddressForm(true);
                }}
                className="circle-button mr-4"
              >
                <AddIcon />
              </button>
              <p className="caption-1-regular-gray3 ml-5">
                Add practice address
              </p>
            </div>
          )}
        </div>
        {userProfile?.userProfile?.practiceAddress?.map((practice, indx) => (
          <div key={practice.name}>
            <PracticeAddress practice={practice} indx={indx} />
          </div>
        ))}
      </section>

      <section className="as-section-gap">
        {/* License */}
        <h2 className="mb-24 color-primary">License Information</h2>
        {showLicenseForm ? (
          <LicenseForm
            setShowLicenseForm={setShowLicenseForm}
            editMode={false}
          />
        ) : (
          <div className="flex-vc my-24">
            <button
              onClick={() => {
                setShowLicenseForm(true);
              }}
              className="circle-button mr-4"
            >
              <AddIcon />
            </button>
            <p className="caption-1-regular-gray3 ml-5">Add license</p>
          </div>
        )}
        {userProfile?.userProfile?.licenses?.map((license, indx) => (
          <div key={license.licenseNumber}>
            <License license={license} indx={indx} />
          </div>
        ))}
      </section>

      <section className="as-section-gap">
        {/*Password*/}
        <h2 className="my-24 color-primary">Update Password</h2>
        {showPasswordForm ? (
          <UpdatePassword setShowPasswordForm={setShowPasswordForm} />
        ) : (
          <div className="flex-vc my-24">
            <button
              onClick={() => {
                setShowPasswordForm(true);
              }}
              className="circle-button mr-4"
            >
              <AddIcon />
            </button>
            <p className="caption-1-regular-gray3 ml-5">Update Password</p>
          </div>
        )}
      </section>
      <section className="as-section-gap">
        {/* social media */}
        <h2 className="color-primary">Add Users Social Media</h2>
        <SocialMedia
          socialMediaIcon={<FacebookBlueCircle className="icon-lg" />}
          name="facebook"
          removeName="removeFacebook"
          label="Facebook link"
          socialMediaApiValue={userProfile?.userProfile?.facebook}
          userId={userProfile?.userProfile?._id}
        />
        <SocialMedia
          socialMediaIcon={<LinkedinBlueIcon className="icon-lg" />}
          name="linkedin"
          removeName="removeLinkedin"
          label="Linkedin link"
          socialMediaApiValue={userProfile?.userProfile?.linkedin}
          userId={userProfile?.userProfile?._id}
        />
        <SocialMedia
          socialMediaIcon={<TwitterBlueIcon className="icon-lg" />}
          name="twitter"
          removeName="removeTwitter"
          label="Twitter link"
          socialMediaApiValue={userProfile?.userProfile?.twitter}
          userId={userProfile?.userProfile?._id}
        />
        <SocialMedia
          socialMediaIcon={<InstagramGradientIcon className="icon-lg" />}
          name="instagram"
          removeName="removeInstagram"
          label="Instagram link"
          socialMediaApiValue={userProfile?.userProfile?.instagram}
          userId={userProfile?.userProfile?._id}
        />
      </section>
    </div>
  );
}
