import BackIcon from '../../icons/BackIcon';
import oppositeArrows from '../../video-course/video-course-icons/videoPlayer/opposite-arrows.png';
export default function CourseTitle({
  title,
  navigate,
  courseContent,
  setCourseContent,
}) {
  return (
    <div className=" bg-primary pt-16 pb-16 pl-60  vm-title-wrapper flex-vc ">
      {' '}
      <i
        className="mr-12"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(-1)}
      >
        <BackIcon fill="#fff" />
      </i>
      <p style={{ color: '#fff' }} className="avenir-20-500 vm-title flex-vchc">
        {title}
      </p>{' '}
      {!courseContent && (
        <div
          className="vm-content-hide-icon"
          onClick={() => setCourseContent(true)}
        >
          <img src={oppositeArrows} />
        </div>
      )}
    </div>
  );
}
