import axios from 'axios';
import {
  VIDEO_COURSE_ERROR,
  VC_SEARCH_DONE,
  VC_SEARCH_INITIATED,
  CREATE_VIDEO_COURSE,
  GET_VIDEO_COURSE,
  LOAD_ALL_MY_VCS,
  LOAD_INCOMPLETE_VC,
  LOAD_INCOMPLETE_VCS,
  REMOVE_NEWVC_STATE,
  GET_SINGLE_VIDEO_COURSE,
  LOAD_USER_VIDEO_COURSE_CREDIT,
} from './videoCourseTypes';

// Creat a videoCourse
export const createVideoCourseAction = (data) => (dispatch) => {
  dispatch({
    type: CREATE_VIDEO_COURSE,
    payload: data,
  });
};

// remove new videoCourse redux state
export const removeVideoCourseStateAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_NEWVC_STATE,
  });
};

//  load one incomplete videoCourse
export const loadOneIncopleteVCAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_INCOMPLETE_VC,
    payload: data,
  });
};
//  load all incomplete videoCourses

export const loadIncopleteVCsAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_INCOMPLETE_VCS,
    payload: data,
  });
};

export const loadAllMyVCsAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_ALL_MY_VCS,
    payload: data,
  });
};

export const searchVCsAction = (data) => (dispatch) => {
  dispatch({
    type: VC_SEARCH_DONE,
    payload: data,
  });
};

export const loadSingleVideoCourseAction = (data) => (dispatch) => {
  dispatch({
    type: GET_SINGLE_VIDEO_COURSE,
    payload: data,
  });
};

export const loadUserVideoCourseCreditAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_USER_VIDEO_COURSE_CREDIT,
    payload: data,
  });
};

export const VCSearchInitiatedAction = () => (dispatch) => {
  dispatch({
    type: VC_SEARCH_INITIATED,
  });
};

// get a videoCourse

export const getVideoCourseAction = () => async (dispatch) => {
  try {
    const res = await axios({
      method: 'GET',
      withCredentials: true,
      url: 'http://localhost:5000/api/videoCourses/:videoCourse_id',
    });
    dispatch({ type: GET_VIDEO_COURSE, payload: res.data });
  } catch (err) {
    dispatch({
      type: VIDEO_COURSE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
