import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ExploreConfs from '../../components/explore-confs/ExploreConfs';
import Hero from '../../components/hero/Hero';
import HostYourConfs from '../../components/host-your-confs/HostYourConfs';
import HowItWorks from '../../components/how-it-works/HowItWorks';
import RecentlyViewed from '../../components/recently-viewed/RecentlyViewed';
import TrendingConfs from '../../components/trending/TrendingConfs';
import TrendingCourses from '../../components/trending/TrendingCourses';
import api from '../../utility/api';

function HomePage() {
  const [vData, setVData] = useState();
  const [aData, setAData] = useState();
  const [pData, setPData] = useState();

  const userID = useSelector((state) => state.auth.user?._id);

  const loadVideoData = async () => {
    try {
      const response = await api.get('/homePage/trendings/video/courses');
      setVData(response.data.data.trendingVideoCourses);
    } catch (err) {}
  };

  const loadAudioData = async () => {
    try {
      const response = await api.get('/homePage/trendings/audio/courses');
      setAData(response.data.data.trendingAudioCourses);
    } catch (err) {}
  };
  const loadPodcastData = async () => {
    try {
      const response = await api.get('/homePage/trendings/podcast');
      setPData(response.data.data.trendingPodcasts);
    } catch (err) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadVideoData();
    loadAudioData();
    loadPodcastData();
  }, []);

  return (
    <main className="container pt-64">
      <Hero />
      <HowItWorks />
      <ExploreConfs />
      <TrendingConfs />
      <TrendingCourses header="Video Courses" data={vData} tag="Video Course" />
      <TrendingCourses header="Audio Courses" data={aData} tag="Audio Course" />
      <TrendingCourses header="Podcast" data={pData} tag="Podcast" />
      {userID && <RecentlyViewed />}
      <HostYourConfs />
    </main>
  );
}

export default HomePage;
