import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Loader from '../../components/loader/Loader';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

export default function ConnectedAccountPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripeReturnUrl, setStripeReturnUrl] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { state } = useLocation();

  let bookingId = state?.bookingId;
  let returnUrl = state?.returnUrl;

  let type = state?.type;
  let organizationId = state?.organizationId;
  const getConnectedAccountLink = async (returnUrl) => {
    setIsSubmitting(true);
    setStripeReturnUrl(returnUrl);

    const url = `/organizers/accounts`;

    let formData = {
      accountDetails: {
        returnUrl: returnUrl ? returnUrl : stripeReturnUrl,
        refreshUrl: 'http://localhost:3000/accounts',
        userId: user?._id,
        type,
        organizationId,
      },
    };

    try {
      const response = await api.post(url, formData);

      if (response) {
        setIsSubmitting(false);
        window.location.replace(response?.data?.data?.accountLink?.url);
      }
    } catch (err) {
      setIsSubmitting(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    getConnectedAccountLink(returnUrl);
  }, []);
  return (
    <div className="container pt-64">
      <Loader />
    </div>
  );
}
