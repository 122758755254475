import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getFormattedDateInTz2,
  getGoalsCreditsList,
} from '../../utility/commonUtil';

// import PropTypes from "prop-types";

import GoalDatesForm from './GoalDatesForm';
import EditIcon from '../icons/EditIcon';
import ModalX from '../modal/ModalX';
import AddIcon from '../icons/AddIcon';
import DeleteIcon from '../icons/DeleteIcon';
import GoalCreditsForm from './GoalCreditsForm';
import api from '../../utility/api';
import { alertAction } from '../../redux/alert/alertAction';
import Dialogue from '../dialogue/Dialogue';
import { AnimatePresence, motion } from 'framer-motion';
import './goalsTable.scss';

export default function GoalsTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [showGoalDates, setShowGoalDates] = useState(false);
  const [showGoalCreditsForm, setShowGoalCreditsForm] = useState(false);
  const [showDialogue, setShowDialogue] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [goals, setGoals] = useState(null);
  const [goalToEdit, setGoalToEdit] = useState(null);
  const [goalToDelete, setGoalToDelete] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const closeDialogue = () => {
    setShowDialogue(false);
    setGoalToDelete(null);
  };

  const closeGoalCreditsForm = () => {
    setShowGoalCreditsForm(false);
    setEditMode(false);
    setGoalToEdit(null);
  };

  const yesAction = async () => {
    const url = `attendees/creditType/goals/${goals?._id}/credits/${goalToDelete?._id}?userId=${user?._id}`;
    try {
      const response = await api.delete(url);

      setGoals(response.data.data.creditGoal);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
    setShowDialogue(false);
  };

  const getGoals = async (userId) => {
    try {
      const response = await api.get(
        `attendees/goals/duration?userId=${userId}`
      );

      setGoals(response.data.data.creditGoal);
      setIsLoading(false);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGoals(user?._id);
    getGoalsCreditsList(user?._id);
  }, [user?._id]);

  return (
    <div>
      <h2 className="color-primary mb-16">Set Credits Goal</h2>
      {/* <div className="caption-1-regular-gray3 mb-24">
        <p>1. First set a time period in which you want to earn credits.</p>
        <p>
          2. Once a duration is set, select a credit type and how many of these
          credits you want to earn in the set time period.
        </p>
        <p>3. Add as many credits types as you want.</p>
        <p>
          4. If you don't see a credit type in the dropdown, you can add a new
          credit type to dropdown by using Add new credit button. All external
          credit types added by you will be shown in{" "}
          <Link
            className="color-primary"
            to="/user-profile/external-credit-type"
          >
            <em>Attendee Resources</em>
          </Link>{" "}
        </p>
      </div> */}
      <AnimatePresence initial={false} mode="wait">
        {!isLoading && (
          <motion.div
            key="goals-table"
            layout
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            transition={{ type: 'spring', duration: 0.5 }}
          >
            {goals?.startDate ? (
              <div>
                <div className="flex-vc mb-16">
                  <h4 className="color-primary mr-8">Goal Duration:</h4>
                  <p className="body-regular-gray3 color-primary mr-8">
                    <span>
                      {getFormattedDateInTz2(goals?.startDate, goals?.timezone)}
                    </span>
                    <span> - </span>
                    <span>
                      {getFormattedDateInTz2(
                        goals?.endDate ? goals?.endDate : new Date(),
                        goals?.timezone
                      )}
                    </span>
                  </p>
                  <i
                    style={{
                      position: 'relative',
                      paddingTop: 3,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setShowGoalDates(true);
                    }}
                  >
                    <EditIcon className="icon-sm" />
                  </i>
                </div>
                <div className="flex-vc mb-16">
                  <button
                    onClick={() => {
                      setEditMode(false);
                      setGoalToEdit(null);
                      setShowGoalCreditsForm(true);
                    }}
                    className="circle-button mr-4"
                  >
                    <AddIcon />
                  </button>
                  <p className="caption-1-regular-gray3 ml-6">Add credit</p>
                </div>
                {goals?.counts?.length > 0 && (
                  <div className="grid-table-wrap">
                    <ul className="grid-table-6-800 goals-table">
                      <li>Credit</li>
                      <li>Goal</li>
                      <li>Credits Earned</li>
                      <li>Credits Pending</li>
                      <li>Short by</li>
                      <li>Action</li>
                      {goals?.counts?.map((goal, indx) => {
                        return (
                          <Fragment key={indx}>
                            <li>{goal?.credit}</li>
                            <li>{goal?.goal}</li>
                            <li>{goal?.earnedCreditQuantity}</li>
                            <li>{goal?.pendingCreditQuantity}</li>
                            <li>
                              {goal?.earnedCreditQuantity - goal?.goal >= 0
                                ? 'Goal Met!'
                                : goal?.goal - goal?.earnedCreditQuantity}
                            </li>
                            <li>
                              <div>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className="mr-2"
                                  onClick={() => {
                                    setEditMode(true);
                                    setGoalToEdit(goal);
                                    setShowGoalCreditsForm(true);
                                  }}
                                >
                                  <EditIcon />
                                </i>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className="ml-2"
                                  onClick={() => {
                                    setGoalToDelete(goal);
                                    setShowDialogue(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </i>
                              </div>
                            </li>
                          </Fragment>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              <GoalDatesForm goals={goals} setGoals={setGoals} isEdit={false} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {showGoalDates && (
        <ModalX
          open={showGoalDates}
          onDismiss={() => {
            setShowGoalDates(false);
          }}
        >
          <div className="modalx-container-sm mb-24">
            <GoalDatesForm
              goals={goals}
              setGoals={setGoals}
              isEdit={true}
              setShowGoalDates={setShowGoalDates}
            />
          </div>
        </ModalX>
      )}
      {showGoalCreditsForm && (
        <ModalX
          open={showGoalCreditsForm}
          onDismiss={() => closeGoalCreditsForm()}
        >
          <div className="modalx-container-md px-16 mb-24">
            <GoalCreditsForm
              editMode={editMode}
              goals={goals}
              setGoals={setGoals}
              goalToEdit={goalToEdit}
              closeGoalCreditsForm={closeGoalCreditsForm}
            />
          </div>
        </ModalX>
      )}
      {showDialogue && (
        <Dialogue
          msg="You are sure you want to delet the goal?"
          title="Delete Goal"
          closeDialogue={closeDialogue}
          yesAction={yesAction}
        />
      )}
    </div>
  );
}
