import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { alertAction } from '../../redux/alert/alertAction';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import SearchIcon from '../icons/SearchIcon';
import ModalX from '../modal/ModalX';
import { customSelectStyle3 } from '../reselect/selectUtil';
import Select from 'react-select';
import { getValue } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import { renderRowStyle } from '../../utility/commonUtil';
import '../refund/refunds.scss';
import PageLimitSelect from '../pagination/PageLimitSelect';
import VideoCourseRefundDetails from '../video-course/video-course-refund/VideoCourseRefundDetails';
import NextIcon from '../icons/NextIcon';

const options = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
];

const refundObj = {
  1: 'Refunded',
  2: 'Initiated',
  5: 'Rejected',
};

export default function AudioCourseRefunds() {
  // const [filterText1, setFilterText1] = useState("");
  const [filterText2, setFilterText2] = useState('all');
  const [filterText3, setFilterText3] = useState('all');
  const [searchText, setSearchText] = useState('');

  const [apiData, setApiData] = useState([]);
  const [listForPagination, setListForPagination] = useState([]);
  const [displayList, setDisplayList] = useState([]);

  const [showDetails, setShowDetails] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell
  async function onRowClick(id) {
    try {
      setDetailsLoading(true);
      const response = await api.get(`/courses/videoCourse/purchases/${id}`);

      setBookingDetails(response.data.data?.purchaseDetails);
      setShowDetails(true);
      setDetailsLoading(false);
    } catch (err) {
      setShowDetails(false);
      setDetailsLoading(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }

  const onInputChange = (e) => setSearchText(e.target.value);

  const organizationsList = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );

  const options2 = [
    { value: 'all', label: 'All' },
    { value: 'self', label: 'User' },
    ...organizationsList,
  ];

  const closeBookingDetailsModal = () => {
    getRefundsData(user?._id);
    setShowDetails(false);
  };

  const loadMyOrgnizations = async (id) => {
    try {
      const response = await api.get(
        `organizations/users/${id}?orgForConference=true`
      );

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const getRefundsData = async (userId) => {
    try {
      const response = await api.get(
        `organizers/${userId}/audioCourse/refunds`
      );

      if (response) {
        setApiData(response.data.data.refundDetails);
      }
    } catch (error) {
      dispatch(alertAction(error.response.data.message, 'danger'));
    }
  };

  // search filters
  const searchFilter = (list, text, filterText, filterText3) => {
    let listToSearch = [];
    if (filterText === 'all') {
      listToSearch = list;
    } else if (filterText === 'self') {
      listToSearch = list?.filter((item) => item?.media?.host === 'user');
    } else {
      listToSearch = list?.filter(
        (element) =>
          element?.media?.host === 'organization' &&
          element?.media?.hostedBy?.organization.toString() ===
            filterText.toString()
      );
    }
    let listToSearchFinal = [];
    if (filterText3 === 'all') {
      listToSearchFinal = listToSearch;
    } else if (filterText3 === 'pending') {
      listToSearchFinal = listToSearch?.filter(
        (item) => item?.refundStatus === 2
      );
    } else {
      listToSearchFinal = listToSearch?.filter(
        (element) => element?.refundStatus == 1
      );
    }

    let filteredList = listToSearchFinal?.filter((item) => {
      if (
        item.media.title.toLowerCase().indexOf(text.toLowerCase()) >= 0 ||
        item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0 ||
        item.serialNumber.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
      ) {
        return item;
      }
    });
    setTotalRecords(filteredList?.length);
    setListForPagination(filteredList);
  };

  //code for frotnend pagination
  const loadPaginationData = (data) => {
    const { currentPage, pageLimit, totalRecords } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const refundData = listForPagination.slice(0, pageLimit);
      setDisplayList(refundData);
    } else {
      const refundData = listForPagination.slice(
        indexOfFirstElement,
        indexOflastElement
      );
      setDisplayList(refundData);
    }
  };

  // first get data from api, set to apiList
  // then searchFilter function gets list for pagination
  // then it slices list to be shown per page no

  useEffect(() => {
    getRefundsData(user._id);
    loadMyOrgnizations(user._id);
  }, [user._id]);

  useEffect(() => {
    searchFilter(apiData, searchText, filterText2, filterText3);
  }, [apiData, searchText, filterText2, filterText3]);

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [listForPagination]);

  return (
    <>
      <div className="mb-32">
        <h1 className="color-primary mb-24">Audio Course Refund Request</h1>
        <div className="myconfs-sort">
          <div className="form-type-3">
            <div className="position-relative ">
              <input
                type="text"
                id="searchtickets"
                placeholder="Search name, course or booking no"
                name="searchText"
                value={searchText}
                onChange={onInputChange}
              />

              <i
                className={
                  searchText?.length > 0
                    ? 'display-none'
                    : 'conf-search-input-icon'
                }
              >
                <SearchIcon width="2.4rem" height="2.4rem" />
              </i>
            </div>
          </div>
          <div>
            <Select
              value={getValue(options2, filterText2, false)}
              name="filuterText1"
              options={options2}
              onChange={(value) => setFilterText2(value?.value)}
              styles={customSelectStyle3}
            />
          </div>
          <div>
            <Select
              value={getValue(options, filterText3, false)}
              name="filuterText3"
              options={options}
              onChange={(value) => setFilterText3(value?.value)}
              styles={customSelectStyle3}
            />
          </div>
        </div>
      </div>
      <div className="grid-table-wrap position-relative">
        <ul className="grid-table-7-1000 ">
          <li>Name</li>
          <li>Course</li>
          <li>Booking No</li>
          <li>Book Date</li>
          <li>Price</li>
          <li>Refund Status</li>
          <li>Action</li>

          {displayList?.map((val, indx) => {
            return (
              <Fragment key={val?._id}>
                <li
                  data-pending="name"
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  {val.name}
                </li>
                <li
                  data-pending="conf title"
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  {val?.media?.title}
                </li>

                <li
                  data-pending="ticket id"
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  {val?.serialNumber}
                </li>

                <li
                  data-pending="booking date"
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  {new Date(val?.bookingDate).toLocaleDateString()}
                </li>
                <li
                  data-pending="ticket price"
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  {val?.currency && (
                    <span>{val?.currency.toString().toUpperCase()}-</span>
                  )}
                  {val.organiserAmount}
                </li>
                <li
                  data-pending="refund status"
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  <p style={{ color: '#4cb944' }}>
                    {val?.mediaBooked === 4 && (
                      <span>
                        {refundObj[val?.refundStatus]
                          ? refundObj[val?.refundStatus]
                          : 'Pending'}
                      </span>
                    )}
                  </p>
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(val?._id)}
                  onMouseLeave={() => handleMouseLeave(val?._id)}
                  style={renderRowStyle(indx, val?._id, isHover, rowId)}
                  onClick={() => onRowClick(val?._id)}
                >
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <i>
                      <NextIcon className="icon-xl" />
                    </i>
                  </div>
                </li>
              </Fragment>
            );
          })}
        </ul>
        {detailsLoading && !showDetails && <div className="ab-overlay"></div>}
        {showDetails && !detailsLoading && (
          <ModalX onDismiss={closeBookingDetailsModal} open={showDetails}>
            <div className="modalx-container-md pb-24">
              <VideoCourseRefundDetails
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
              />
            </div>
          </ModalX>
        )}
      </div>
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => {
              loadPaginationData(data);
            }}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </>
  );
}
