import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import { loadUserCreditConferencesAction } from "../../redux/user-profile/userProfileAction";
import api from "../../utility/api";
import {
  getFormattedDateInTz2,
  getQuery,
  openInNewTab,
} from "../../utility/commonUtil";
import CustomPagination from "../pagination/CustomPagination";
import DateIcon from "../icons/DateIcon";
import DocumentIcon from "../icons/DocumentIcon";
import ModalX from "../modal/ModalX";
import RequestCreditForm from "./RequestCreditForm";
import DateFilter from "./DateFilter";
import "./confemyCredits.scss";
import PageLimitSelect from "../pagination/PageLimitSelect";

const ConfemyCredits = ({
  confStartDate,
  setConfStartDate,
  confEndDate,
  setConfEndDate,
}) => {
  // for pagination
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const viewCertificate = (certificate) => {
    openInNewTab(certificate.location);
  };

  const user = useSelector((state) => state.auth.user);
  const userConfs = useSelector(
    (state) => state.userProfile.userCreditConferences
  );
  const dispatch = useDispatch();

  const closeModal = () => {
    // getAttendees();
    setShowModal(false);
  };

  const getConfs = async (userID, currentPage, limit) => {
    const parameters = [
      { name: "startDate", value: confStartDate?.toISOString() },
      { name: "endDate", value: confEndDate?.toISOString() },
    ];
    const baseURL = `/attendees/credits/users/${userID}?page=${currentPage}&limit=${limit}`;
    const query = getQuery(parameters);
    let url = baseURL;
    if (query?.length > 0) {
      url = url + "&" + query;
    }
    try {
      const response = await api.get(url);

      if (response.data.data?.page === 1 && response.data.data?.isSetPage) {
        setCurrentPageNumber({ currentPage: response.data.data?.page });
        setCurrentPage(response.data.data?.page);
      }
      setTotalRecords(response.data.data?.creditCount);
      dispatch(loadUserCreditConferencesAction(response.data.data.allCredits));
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };
  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;
    getConfs(user._id, currentPage, pageLimit);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    getConfs(user._id, activePage, pageLimit);
  }, [user._id, confStartDate, confEndDate]);

  return (
    <div>
      <div className="mb-16">
        <h2 className="color-primary mb-32">Credits & Certificates</h2>
        <div className="mb-16">
          <DateFilter
            startDate={confStartDate}
            endDate={confEndDate}
            setStartDate={setConfStartDate}
            setEndDate={setConfEndDate}
          />
        </div>
      </div>
      <div className="scrollbar-grid-table-wrap">
        <ul className="grid-table-5-700 cc-grid-table">
          <li>Activity Completed</li>
          <li>Requested Credit</li>
          <li>Approved Credits</li>
          <li>Certificates</li>
          <li>
            <div className="text-align-center">Action</div>
          </li>
          {userConfs?.map((data) => (
            <Fragment key={data?._id}>
              <li>
                <p
                  className="avenir-heavy-18 mb-8"
                  style={{ color: "#000", fontSize: 16 }}
                >
                  {data?.conference?.title}
                </p>
                <div className="flex-vc  mb-6 ">
                  <DateIcon className="icon-xxs mr-4" />
                  <span
                    style={{ whiteSpace: "nowrap" }}
                    className="caption-2-regular-gray3"
                  >
                    Start:{" "}
                    {getFormattedDateInTz2(
                      data?.conference?.startDate,
                      data?.conference?.timezone
                    )}{" "}
                  </span>
                </div>
                <div className="flex-vc  mb-6">
                  <DateIcon className="icon-xxs mr-4" />
                  <span
                    style={{ whiteSpace: "nowrap" }}
                    className="caption-2-regular-gray3"
                  >
                    End:{" "}
                    {getFormattedDateInTz2(
                      data?.conference?.endDate,
                      data?.conference?.timezone
                    )}{" "}
                  </span>
                </div>
              </li>
              <li>
                {data?.conference?.isAccredited ? (
                  data?.requestedCredits?.length > 0 ? (
                    data?.requestedCredits?.map((credit) => (
                      <div key={credit?._id}>
                        <p>
                          <span>{credit?.credit.name}</span>
                        </p>{" "}
                        <p>
                          <span>Amt:</span>
                          <span> {credit?.quantity}</span>
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No requests</p>
                  )
                ) : (
                  <p>No credits offered</p>
                )}
              </li>
              <li>
                {data?.conference?.isAccredited ? (
                  data?.approvedCredits?.length > 0 ? (
                    data?.approvedCredits?.map((credit) => (
                      <div key={credit?._id}>
                        <p>{credit?.credit?.name}</p>
                        <p>
                          <span>Amt:</span>
                          <span> {credit?.quantity}</span>
                        </p>
                      </div>
                    ))
                  ) : (
                    data?.requestedCredits?.length > 0 && (
                      <p>Pending approval</p>
                    )
                  )
                ) : (
                  <p>No credits offered</p>
                )}
              </li>
              <li>
                {data.approvedCredits?.length > 0
                  ? data.approvedCredits.map((credit) => {
                      return (
                        <div
                          key={credit?._id}
                          className="flex-vc-sb  caption-1-regular-gray2"
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className="flex-vc hover-bg"
                            onClick={() => viewCertificate(credit)}
                          >
                            <i className="position-relative mr-4">
                              <DocumentIcon className="icon-sm " />
                            </i>
                            <span>{credit?.certificateName}</span>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {data.otherCertificateUploaded ? (
                  <div className="flex-vc-sb  caption-1-regular-gray2">
                    <div
                      style={{ cursor: "pointer" }}
                      className="flex-vc hover-bg"
                      onClick={() => viewCertificate(data?.otherCertificate)}
                    >
                      <i className="position-relative mr-4">
                        <DocumentIcon className="icon-sm " />
                      </i>
                      <span>{data?.otherCertificate?.certificateName}</span>
                    </div>
                  </div>
                ) : null}
              </li>
              <li>
                <div className="text-align-center">
                  {data?.conference?.isAccredited ? (
                    data?.creditStatus === 2 && data?.creditRequest ? (
                      <span className="caption-1-heavy-primary">
                        Pending clearance
                      </span>
                    ) : data?.creditStatus === 1 && data?.creditRequest ? (
                      <span className="caption-1-heavy-primary">Success</span>
                    ) : (
                      <button
                        style={{ padding: "6px", borderColor: "#dae2e9" }}
                        onClick={() => {
                          setRowData(data);
                          setShowModal(true);
                        }}
                        className="button-outlined button-outlined-primary hover-bg"
                      >
                        Request Credits
                      </button>
                    )
                  ) : (
                    <button
                      style={{ padding: "6px", borderColor: "#dae2e9" }}
                      onClick={() => {
                        setRowData(data);
                        setShowModal(true);
                      }}
                      className="button-outlined button-outlined-primary hover-bg"
                    >
                      Request Certift
                    </button>
                  )}
                </div>
              </li>
            </Fragment>
          ))}
        </ul>
        {showModal && (
          <ModalX onDismiss={closeModal} open={showModal}>
            <div className="modalx-container-lg">
              <RequestCreditForm
                confStartDate={confStartDate}
                confEndDate={confEndDate}
                data={rowData}
                setRowData={setRowData}
                setShowModal={setShowModal}
                page={activePage}
                pageLimit={pageLimit}
                setCreditsCount={setTotalRecords}
              />
            </div>
          </ModalX>
        )}
      </div>
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={currentPageNumber}
            setCurrentPage={setCurrentPageNumber}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={currentPageNumber}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfemyCredits;
