import { useState, Fragment } from 'react';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import { currencylist, getValue } from '../../utility/commonUtil';
import { modalStyleformType4 } from '../reselect/selectUtil';
import CheckTickIcon from '../icons/CheckTickIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import Dialogue from '../dialogue/Dialogue';
import Loader from '../loader/Loader';
import { createPodcastAction } from '../../redux/podcast/podcastAction';

const validationSchema = yup.object().shape({
  currency: yup.string('Required').trim().required('Choose a currency'),
  monthlyPrice: yup
    .number()
    .typeError('Enter valid amount')
    .required('Required')
    .min(0, 'Price can only be 0 or more'),
  monthlyDiscount: yup
    .number()
    .nullable()
    .typeError('Enter a valid number without % sign')
    .positive('Enter amount more than 0 without % sign')
    .max(100, 'Amount can not exceed 100%'),
  monthlyCoupons: yup.array().of(
    yup.object().shape({
      uuid: yup.string(),
      code: yup
        .string()
        // .matches(websiteRegax, "Enter correct url")
        .required('Required')
        .test(
          'isCreditTest',
          'Save credit using check icon on right',
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      discount: yup
        .number()
        .nullable()
        // .matches(websiteRegax, "Enter correct url")
        .required('Required')
        .min(1, 'Credit amount can not be less than 1')
        .test(
          'isAmountTest',
          'Save amount using check icon on right',
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      isForm: yup.bool(),
    })
  ),

  yearlyPrice: yup
    .number()
    .typeError('Enter valid amount')
    .required('Required')
    .min(0, 'Price can only be 0 or more'),
  yearlyDiscount: yup
    .number()
    .nullable()
    .typeError('Enter a valid number without % sign')
    .positive('Enter amount more than 0 without % sign')
    .max(100, 'Amount can not exceed 100%'),
  yearlyCoupons: yup.array().of(
    yup.object().shape({
      uuid: yup.string(),
      code: yup
        .string()
        // .matches(websiteRegax, "Enter correct url")
        .required('Required')
        .test(
          'isCreditTest',
          'Save credit using check icon on right',
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      discount: yup
        .number()
        .nullable()
        // .matches(websiteRegax, "Enter correct url")
        .required('Required')
        .min(1, 'Credit amount can not be less than 1')
        .test(
          'isAmountTest',
          'Save amount using check icon on right',
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      isForm: yup.bool(),
    })
  ),
});

export default function CreatePodcastStep3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const newPodcast = useSelector((state) => state.podcast.newPodcast);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openErrorDialogue, setErrorDialogue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    currency: newPodcast?.currency || '',
    monthlyPrice: newPodcast?.monthlyPodcastPrice?.price || null,
    monthlyDiscount: newPodcast?.monthlyPodcastPrice?.discount || null,
    monthlyCoupons: newPodcast?.monthlyPodcastPrice?.coupons || [],
    yearlyPrice: newPodcast?.yearlyPodcastPrice?.price || null,
    yearlyDiscount: newPodcast?.yearlyPodcastPrice?.discount || null,
    yearlyCoupons: newPodcast?.yearlyPodcastPrice?.coupons || [],
  };

  async function onSubmit(values, actions) {
    if (!newPodcast?.completedStep1) {
      dispatch(alertAction('Complete step-1 first', 'danger'));
      return;
    }
    const {
      currency,
      monthlyPrice,
      monthlyDiscount,
      monthlyCoupons,
      yearlyPrice,
      yearlyCoupons,
      yearlyDiscount,
    } = values;

    const formData = {
      mediaDetails: {
        mediaId: newPodcast?._id,
        currency,
        monthlyPrice: {
          price: monthlyPrice,
          discount: monthlyDiscount,
          coupons: monthlyCoupons,
        },

        yearlyPrice: {
          price: yearlyPrice,
          discount: yearlyDiscount,
          coupons: yearlyCoupons,
        },
      },
    };

    //  Submit banner image and add image url to formData object

    try {
      if (newPodcast?.completedStep4) {
        if (monthlyPrice?.price || (0 && yearlyPrice?.price > 0)) {
          const res = await api.get(
            `podcast/${newPodcast?._id}/connectedAccount`
          );

          if (
            !res?.data?.data?.connectedAccount?.detailsSubmitted &&
            !res?.data?.data?.connectedAccount?.chargesEnabled
          ) {
            setOpenDialogue(true);
            return;
          } else if (
            !res?.data?.data?.connectedAccount?.chargesEnabled &&
            res?.data?.data?.connectedAccount?.detailsSubmitted
          ) {
            setErrorDialogue(true);
            return;
          }
        }
      }

      const response = await api.post(
        `podcast/${newPodcast?._id}/step3`,
        formData
      );

      if (response) {
        dispatch(createPodcastAction(response.data.data.media));
        actions.resetForm({ values: initialValues });
        navigate('/dashboard/create-pod/step-4');
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }

  const getConnectedAccountLink = async () => {
    const url = `/organizers/accounts`;

    let accountDetails = {
      returnUrl: `${window.location.origin}/dashboard/create-video`,
      refreshUrl: `${window.location.origin}/dashboard/create-video`,
      userId: user?._id,
    };

    if (newPodcast?.host === 'user') {
      accountDetails = {
        ...accountDetails,
        type: 'user',
      };
    } else {
      accountDetails = {
        ...accountDetails,
        type: 'org',
        organizationId: newPodcast?.hostedBy.organization?._id,
      };
    }

    try {
      setIsLoading(true);
      const response = await api.post(url, { accountDetails });

      if (response) {
        window.location.replace(response?.data?.data?.accountLink?.url);
        // setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="create-e-inner-wrap">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                <form
                  className="form-type-4"
                  autoComplete="off"
                  onSubmit={props.handleSubmit}
                >
                  <p
                    className="body-regular-gray3 mb-24"
                    style={{ color: '#aabdc7' }}
                  >
                    All mandatory fields are makred by *
                  </p>
                  <h2 className="color-primary mb-36">Podcast Price</h2>
                  {/* <h4 className="avenir-20-500 mb-8">
                    Currency & Podcast price *
                  </h4> */}
                  <p
                    className="caption-1-regular-gray3 mb-16"
                    style={{ color: '#757575' }}
                  >
                    For FREE podcast, choose your currency and set price as 0
                  </p>
                  <Select
                    options={currencylist}
                    label="Currency"
                    name="currency"
                    value={getValue(currencylist, props.values.currency, false)}
                    onChange={(value) => {
                      props.setFieldValue('currency', value?.value);
                    }}
                    placeholder="Select Currency *"
                    menuPortalTarget={document.body}
                    styles={modalStyleformType4}
                  />
                  <ErrorMessage name="currency" component={TextError} />
                  <div className="mb-16 mt-16">
                    <h4 className="avenir-20-500 mb-8">Monthly price *</h4>
                  </div>
                  <div className="ce-price-grid mb-16">
                    <div>
                      <Field
                        type="number"
                        name="monthlyPrice"
                        min={0}
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Price *"
                      />
                      <ErrorMessage name="monthlyPrice" component={TextError} />
                    </div>
                  </div>
                  <h4 className="avenir-20-500 mb-8">Offer Discount</h4>
                  <p
                    className="caption-1-regular-gray3 mb-16"
                    style={{ color: '#757575' }}
                  >
                    If you want to offer a promotional discount, you can give as
                    a percentage off the course price. Ex. 20%
                  </p>
                  <div className="mb-16">
                    <div className="ce-price-grid">
                      <div
                        className="form-type-4-box"
                        style={{
                          borderColor: '#ced9de',
                        }}
                      >
                        % off
                      </div>
                      <Field
                        type="number"
                        name="monthlyDiscount"
                        min={0}
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Amount"
                      />
                    </div>
                    <ErrorMessage
                      name="monthlyDiscount"
                      component={TextError}
                    />
                  </div>
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">Promo code</h4>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#757575' }}
                    >
                      You can release one or multiple promo codes with flat
                      price off with each code. When purchasing the course, a
                      user can apply one code at a time.
                    </p>
                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="monthlyCoupons"
                        render={(arrayHelpers) => {
                          const coupons =
                            arrayHelpers.form.values?.monthlyCoupons;

                          let indxOfLastItem = 0;
                          if (coupons?.length > 0) {
                            indxOfLastItem = coupons?.length - 1;
                          }
                          return (
                            <Fragment>
                              <div
                                // section above add button
                                className="mb-16"
                              >
                                {coupons?.map((coupon, index) => {
                                  return (
                                    <motion.div
                                      // each coupon
                                      className="mb-8"
                                      key={index}
                                      layout
                                      initial={{ scale: 0.5, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.5, opacity: 0 }}
                                      transition={{
                                        type: 'spring',
                                        duration: 0.5,
                                      }}
                                    >
                                      <div
                                        className={
                                          !coupon.isForm
                                            ? 'form-type-4-box position-relative'
                                            : 'display-none '
                                        }
                                      >
                                        <div>
                                          <span
                                            style={{
                                              paddingRight: '12px',
                                              borderRight: '2px solid #08415c',
                                            }}
                                          >
                                            {coupon?.code}
                                          </span>
                                          <span className="ml-8">
                                            {coupon?.discount}{' '}
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            coupon.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          !coupon.isForm
                                            ? 'display-none'
                                            : 'ce-points-grid position-relative'
                                        }
                                        // className="ce-points-grid position-relative"
                                      >
                                        <div>
                                          <Field
                                            className="dark-placeholder"
                                            type="text"
                                            name={`monthlyCoupons.${index}.code`}
                                            placeholder="Enter a coupon code"
                                          />
                                          <ErrorMessage
                                            name={`monthlyCoupons.${index}.code`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div>
                                          <Field
                                            className="dark-placeholder"
                                            type="number"
                                            min={1}
                                            name={`monthlyCoupons.${index}.discount`}
                                            placeholder="Flat discount amount"
                                          />
                                          <ErrorMessage
                                            name={`monthlyCoupons.${index}.discount`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div
                                          className={
                                            !coupon.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                          <i
                                            style={{ cursor: 'pointer' }}
                                            onMouseDown={() => {
                                              props.setFieldTouched(
                                                `monthlyCoupons.${index}.code`
                                              );
                                              props.setFieldTouched(
                                                `monthlyCoupons.${index}.discount`
                                              );
                                              props.validateField(
                                                'monthlyCoupons'
                                              );

                                              if (
                                                coupon.code !== '' &&
                                                coupon.discount > 0
                                              ) {
                                                const mycoupons = coupons?.map(
                                                  (item) =>
                                                    item.uuid === coupon.uuid
                                                      ? {
                                                          ...item,
                                                          isForm: false,
                                                        }
                                                      : item
                                                );

                                                arrayHelpers.form.setFieldValue(
                                                  'monthlyCoupons',
                                                  mycoupons
                                                );
                                              }
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                    </motion.div>
                                  );
                                })}
                              </div>
                              <div
                                // section for add button
                                // this div controls size of the button
                                style={{
                                  width: '24rem',
                                }}
                              >
                                <motion.button
                                  disabled={
                                    coupons[indxOfLastItem]?.code === '' ||
                                    coupons[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      code: '',
                                      discount: null,
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add coupons
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                    {props.touched.monthlyCoupons &&
                      Boolean(props.errors.monthlyCoupons) &&
                      (typeof props.errors.monthlyCoupons === 'string' ? (
                        <TextError>{props.errors.monthlyCoupons}</TextError>
                      ) : null)}
                  </div>

                  <div className="mb-16 mt-16">
                    <h4 className="avenir-20-500 mb-8">Yearly price *</h4>
                  </div>
                  <div className="ce-price-grid mb-16">
                    <div>
                      <Field
                        type="number"
                        name="yearlyPrice"
                        min={0}
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Price *"
                      />
                      <ErrorMessage name="yearlyPrice" component={TextError} />
                    </div>
                  </div>
                  <h4 className="avenir-20-500 mb-8">Offer Discount</h4>
                  <p
                    className="caption-1-regular-gray3 mb-16"
                    style={{ color: '#757575' }}
                  >
                    If you want to offer a promotional discount, you can give as
                    a percentage off the course price. Ex. 20%
                  </p>
                  <div className="mb-16">
                    <div className="ce-price-grid">
                      <div
                        className="form-type-4-box"
                        style={{
                          borderColor: '#ced9de',
                        }}
                      >
                        % off
                      </div>
                      <Field
                        type="number"
                        name="yearlyDiscount"
                        min={0}
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Amount"
                      />
                    </div>
                    <ErrorMessage name="yearlyDiscount" component={TextError} />
                  </div>
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">Promo code</h4>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#757575' }}
                    >
                      You can release one or multiple promo codes with flat
                      price off with each code. When purchasing the course, a
                      user can apply one code at a time.
                    </p>
                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="yearlyCoupons"
                        render={(arrayHelpers) => {
                          const coupons =
                            arrayHelpers.form.values?.yearlyCoupons;
                          let indxOfLastItem = 0;
                          if (coupons?.length > 0) {
                            indxOfLastItem = coupons?.length - 1;
                          }
                          return (
                            <Fragment>
                              <div
                                // section above add button
                                className="mb-16"
                              >
                                {coupons?.map((coupon, index) => {
                                  return (
                                    <motion.div
                                      // each coupon
                                      className="mb-8"
                                      key={index}
                                      layout
                                      initial={{ scale: 0.5, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.5, opacity: 0 }}
                                      transition={{
                                        type: 'spring',
                                        duration: 0.5,
                                      }}
                                    >
                                      <div
                                        className={
                                          !coupon.isForm
                                            ? 'form-type-4-box position-relative'
                                            : 'display-none '
                                        }
                                      >
                                        <div>
                                          <span
                                            style={{
                                              paddingRight: '12px',
                                              borderRight: '2px solid #08415c',
                                            }}
                                          >
                                            {coupon?.code}
                                          </span>
                                          <span className="ml-8">
                                            {coupon?.discount}{' '}
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            coupon.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          !coupon.isForm
                                            ? 'display-none'
                                            : 'ce-points-grid position-relative'
                                        }
                                        // className="ce-points-grid position-relative"
                                      >
                                        <div>
                                          <Field
                                            className="dark-placeholder"
                                            type="text"
                                            name={`yearlyCoupons.${index}.code`}
                                            placeholder="Enter a coupon code"
                                          />
                                          <ErrorMessage
                                            name={`yearlyCoupons.${index}.code`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div>
                                          <Field
                                            className="dark-placeholder"
                                            type="number"
                                            min={1}
                                            name={`yearlyCoupons.${index}.discount`}
                                            placeholder="Flat discount amount"
                                          />
                                          <ErrorMessage
                                            name={`yearlyCoupons.${index}.discount`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div
                                          className={
                                            !coupon.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                          <i
                                            style={{ cursor: 'pointer' }}
                                            onMouseDown={() => {
                                              props.setFieldTouched(
                                                `yearlyCoupons.${index}.code`
                                              );
                                              props.setFieldTouched(
                                                `yearlyCoupons.${index}.discount`
                                              );
                                              props.validateField(
                                                'yearlyCoupons'
                                              );
                                              if (
                                                coupon.code !== '' &&
                                                coupon.discount > 0
                                              ) {
                                                const mycoupons = coupons?.map(
                                                  (item) =>
                                                    item.uuid === coupon.uuid
                                                      ? {
                                                          ...item,
                                                          isForm: false,
                                                        }
                                                      : item
                                                );
                                                arrayHelpers.form.setFieldValue(
                                                  'yearlyCoupons',
                                                  mycoupons
                                                );
                                              }
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                    </motion.div>
                                  );
                                })}
                              </div>
                              <div
                                // section for add button
                                // this div controls size of the button
                                style={{
                                  width: '24rem',
                                }}
                              >
                                <motion.button
                                  disabled={
                                    coupons[indxOfLastItem]?.code === '' ||
                                    coupons[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      code: '',
                                      discount: null,
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add coupons
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                    {props.touched.coupons &&
                      Boolean(props.errors.yearlyCoupons) &&
                      (typeof props.errors.yearlyCoupons === 'string' ? (
                        <TextError>{props.errors.coupons}</TextError>
                      ) : null)}
                  </div>
                  <div className="mb-72">
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                      fullWidth={true}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
          {openDialogue && (
            <Dialogue
              msg="Add bank account to collect Payment."
              title="Bank Account Onboard"
              closeDialogue={() => {
                setOpenDialogue(false);
              }}
              yesAction={() => getConnectedAccountLink()}
            />
          )}
          {openErrorDialogue && (
            <Dialogue
              msg="Please follow intructions on your stripe account to verify bank account."
              title="Bank Account Verification Failed."
              closeDialogue={() => {
                setErrorDialogue(false);
              }}
              yesAction={() => setErrorDialogue(false)}
            />
          )}
          <div>{/* keep empty */}</div>
        </div>
      )}
    </>
  );
}
