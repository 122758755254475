import '../organizer-dashboard/welcome.styles.scss';
import EventChart from './EventChart';

export default function MyEvents({ myEvents }) {
  return (
    <div className="ongoingLearning-con">
      <p className="caption-1-heavy-primary mb-12 ml-16">My events</p>
      <div>
        <EventChart eventsCount={myEvents} />
      </div>
    </div>
  );
}
