import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import {
  loadCityList,
  loadCountryList,
  loadStateList,
  professions,
} from '../../utility/commonUtil';
import '../../utility/form.styles.scss';
import MaterialTextInput from '../formik/MaterialTextInput';
import SelectOne from '../formik/SelectOne';
import TextError from '../formik/TextError';
import DropdownIcon from '../icons/DropdownIcon';
import ReloadableSelectFormType1 from '../reselect/ReloadableSelectFormType1';
import SelectFormType1 from '../reselect/SelectFormType1';

function PotentialUserForm({ props }) {
  let [countryCodeList, setCountryCodeList] = useState();
  const { countryList, stateList, cityList } = useSelector(
    (state) => state.list
  );
  useEffect(() => {
    if (!countryList.length > 0) {
      loadCountryList();
    }
  }, []);

  const dispatch = useDispatch();

  const loadCountryCode = async () => {
    const url = `venues/countryListUserAccount`;
    try {
      const response = await api.get(url);
      if (response) {
        setCountryCodeList(response.data.data.countries);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    loadCountryCode();
  }, []);

  //   useEffect(() => {
  //     let myCountryId;
  //     if (countryList.length > 0) {
  //       myCountryId = countryList.find(
  //         (country) => country.value === newConference?.country
  //       )?.countryId;
  //     }
  //     if (myCountryId) {
  //       loadStateList(myCountryId);
  //     }
  //   }, [countryList]);

  //   useEffect(() => {
  //     let myStateId;
  //     if (stateList.length > 0) {
  //       myStateId = stateList.find(
  //         (state) => state.value === newConference?.state
  //       )?.stateId;
  //     }
  //     if (myStateId) {
  //       loadCityList(myStateId);
  //     }
  //   }, [stateList]);
  return (
    <Fragment>
      <div className="grid-col-2">
        <div className="material-textfield input-container">
          <MaterialTextInput
            label="First Name*"
            name="firstName"
            type="text"
            placeholder=" "
          />
        </div>

        <div className="material-textfield input-container">
          <MaterialTextInput
            label="Last Name*"
            name="lastName"
            type="text"
            placeholder=" "
          />
        </div>
      </div>
      <div className="material-textfield input-container">
        <MaterialTextInput
          name="email"
          type="email"
          label="Email*"
          placeholder=" "
        />
      </div>
      <div className="grid-col-2">
        <div>
          <ReloadableSelectFormType1
            label="countryCode"
            name="countryCode"
            options={countryCodeList}
            value={props.values.countryCode}
            isMulti={false}
            onChange={(value) => {
              props.setFieldValue('countryCode', value?.value);
              // setDisplayButton(true);
            }}
            placeholder="Country Code"
          />
          <div className="mb-24">
            {Boolean(props.errors.countryCode) && (
              <TextError>{props.errors.countryCode}</TextError>
            )}
          </div>
        </div>
        <div>
          <div className="material-textfield input-container">
            <MaterialTextInput
              label="Mobile*"
              name="mobile"
              type="text"
              placeholder=" "
            />
          </div>
          {/* <div className="mb-24">
            {Boolean(props.errors.mobile) && (
              <TextError>{props.errors.mobile}</TextError>
            )}
          </div> */}
        </div>
      </div>

      <div className="input-container position-relative">
        <SelectOne name="profession">
          <option value="">Select a Profession*</option>
          {professions.map((profession) => (
            <option key={profession.value} value={profession.value}>
              {profession.label}
            </option>
          ))}
        </SelectOne>
        <DropdownIcon className=" right-input-icon icon-lg" />
      </div>
      {/* <div>
        <div className="position-relative material-textfield input-container">
          <SelectFormType1
            options={countryList || []}
            value={props?.values?.country || ''}
            onChange={(value) => {
              if (props.values.country !== value?.value) {
                props.setFieldValue('state', '');
                props.setFieldValue('city', '');
              }
              props.setFieldValue('country', value?.value);
              loadStateList(value?.countryId);
            }}
            placeholder="Select country"
            isDisabled={false}
            name="country"
          />
          <div className="mb-24">
            {props.touched.country && Boolean(props.errors.country) && (
              <TextError>{props.errors.country}</TextError>
            )}
          </div>
        </div>

        <div className="position-relative material-textfield input-container">
          <ReloadableSelectFormType1
            options={stateList || []}
            value={props?.values?.state || ''}
            onChange={(value) => {
              if (props.values.state !== value?.value) {
                props.setFieldValue('city', '');
              }
              props.setFieldValue('state', value?.value);
              loadCityList(value?.stateId);
            }}
            placeholder="Select state"
            isDisabled={false}
            name="state"
          />

          <div className="mb-24">
            {props.touched.state && Boolean(props.errors.state) && (
              <TextError>{props.errors.state}</TextError>
            )}
          </div>
        </div>

        <div className="position-relative material-textfield input-container">
          <ReloadableSelectFormType1
            options={cityList || []}
            value={props?.values?.city || ''}
            onChange={(value) => {
    
              props.setFieldValue('city', value?.value);
            }}
            placeholder="Select city"
            isDisabled={false}
            name="city"
          />

          <div className="mb-24">
            {props.touched.city && Boolean(props.errors.city) && (
              <TextError>{props.errors.city}</TextError>
            )}
          </div>
        </div>
      </div> */}
    </Fragment>
  );
}

export default PotentialUserForm;
