import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import alertReducer from './alert/alertReducer';
import authReducer from './auth/authReducer';
import userLocationReducer from './location/userLocationReducer';
import myOrganizationsReducer from './organization/myOrganizationsReducer';
import organizationReducer from './organization/organizationReducer';
import conferenceReducer from './conference/conferenceReducer';
import userProfileReducer from './user-profile/userProfileReducer';
import listReducer from './list/listReducer';
import assetReducer from './asset/assetReducer';
import videoCourseReducer from './video-course/videoCourseReducer';
import audioCourseReducer from './audio-course/audioCourseReducer';
import podcastReducer from './podcast/podcastReducre';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile', 'conference', 'userLocation'],
};

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  userLocation: userLocationReducer,
  myOrganizations: myOrganizationsReducer,
  organization: organizationReducer,
  conference: conferenceReducer,
  video: videoCourseReducer,
  userProfile: userProfileReducer,
  list: listReducer,
  assets: assetReducer,
  audio: audioCourseReducer,
  podcast: podcastReducer,
});

export default persistReducer(persistConfig, rootReducer);

// import { combineReducers } from 'redux';
// import alertReducer from './alert/alertReducer';
// import authReducer from './auth/authReducer';
// import messageReducer from './message/messageReducer';
// import profileReducer from './profile/profileReducer';

// export default combineReducers({
//   alert: alertReducer,
//   auth: authReducer,
//   message: messageReducer,
//   profile: profileReducer,
// });
