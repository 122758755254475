import { LOAD_DEFAULT_REFUND_POLICY, LOAD_PREMADE_BANNERS } from "./assetTypes";

export const loadDefaultRefundPolicyAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_DEFAULT_REFUND_POLICY,
    payload: data,
  });
};

export const loadPremadeBannersAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_PREMADE_BANNERS,
    payload: data,
  });
};
