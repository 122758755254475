import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import LogoDark from '../icons/LogoDark';
import AuthDropdown from '../auth-dropdown/AuthDropdown';
import HamburgerIcon from '../icons/HamburgerIcon';
import CloseMenu from '../icons/CloseMenu';
import LocationDropdown from '../location-dropdown/LocationDropdown';
import Alert from '../alert/Alert';
import { loadUserLocationAction } from '../../redux/location/userLocationAction';
import './navbar.styles.scss';
import SearchIcon from '../icons/SearchIcon';

export default function Navbar({ onClickOutside }) {
  const [menu, setMenuOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const userLocation = useSelector((state) => state.userLocation.userLocation);
  const location = useLocation();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const getGeoLocation = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/');

      dispatch(loadUserLocationAction(response?.data));
    } catch (err) {}
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuOpen(false);
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    if (!userLocation?.country_name) {
      getGeoLocation();
    }
  }, [userLocation?.country_name]);

  return (
    <div className="navbar-container ">
      <div className="navbar-responsive position-relative">
        <div className="navbar-left-item">
          <div className="logo-container">
            <Link to="/">
              <LogoDark className="logo" />
            </Link>
          </div>
          <Link
            style={
              location.pathname === '/search-conference'
                ? { cursor: 'default' }
                : { cursor: 'pointer' }
            }
            to="search-conference"
          >
            <div className="search-box">
              <i className="position-relative mr-8" style={{ paddingTop: 4 }}>
                <SearchIcon width="20px" height="20px" />
              </i>
              <span className="explore-conferences">Search </span>
            </div>
          </Link>
        </div>
        <div>
          {isAuthenticated && user ? (
            <div className="navbar-mid-item">
              <Link className="create-conference mr-24" to="/list-conferences">
                Host on confemy
              </Link>
              <AuthDropdown className={'auth-dropdown'} />
            </div>
          ) : (
            <div className="navbar-mid-item">
              <div className="mr-24">
                <Link className="create-conference" to="/list-conferences">
                  Host on confemy
                </Link>
              </div>
              <div>
                <Link to="/signin" className="signin">
                  Sign in
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="navbar-right-item">
          <div className="auth-cart">
            {isAuthenticated && user ? (
              <div className="flex">
                <AuthDropdown className={'auth-dropdown-small'} />
              </div>
            ) : (
              <div className="flex-vc">
                <Link to="/signin" className="signin">
                  Sign in
                </Link>
              </div>
            )}
          </div>
          <div className="location-flex">
            <div className="location">
              <LocationDropdown />
            </div>
          </div>
          <div className="hamburger-button">
            {!menu ? (
              <i onClick={() => setMenuOpen(true)}>
                <HamburgerIcon />
              </i>
            ) : (
              <button onClick={() => setMenuOpen(false)}>
                <CloseMenu />
              </button>
            )}
          </div>
        </div>
        {menu && (
          <div ref={ref} className="nav-menu">
            <Link
              style={
                location.pathname === '/search-conference'
                  ? { cursor: 'default' }
                  : { cursor: 'pointer' }
              }
              onClick={() => {
                setMenuOpen(false);
              }}
              to="search-conference"
            >
              <p className="explore-conferences nav-item">Search</p>
            </Link>
            <Link
              onClick={() => {
                setMenuOpen(false);
              }}
              to="dashboard/create-conference"
            >
              <p className="create-conference nav-item">Host on confemy</p>
            </Link>
          </div>
        )}
      </div>
      <Alert />
    </div>
  );
}
