import { ErrorMessage, Field, Form, FieldArray, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import TextError from '../formik/TextError';
import CheckTickIcon from '../icons/CheckTickIcon';
import Edit2Icon from '../icons/Edit2Icon';
import { createAudioCourseAction } from '../../redux/audio-course/audioCourseAction';
import TranshcanIcon from '../icons/TrashcanIcon';

const validationSchema = yup.object().shape({
  title: yup.string().trim().required('Required'),
});

export default function AudioCourseSectionForm({
  section,
  setMySections,
  sectionIndex,
}) {
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const audio = useSelector((state) => state.audio);
  const { newAudioCourse } = audio;

  async function onSubmit(values, actions) {
    if (!newAudioCourse?.completedStep1) {
      dispatch(alertAction('Complete step-1 first', 'danger'));
      return;
    }

    const { title, uuid } = values;

    const url = `audioCourses/${newAudioCourse?._id}/section`;
    const formData = {
      mediaDetails: {
        title,
        uuid,
        sectionId: section?._id,
      },
    };

    try {
      const response = await api.post(url, formData);
      const savedSection = response?.data.data?.savedSection;
      if (response) {
        dispatch(createAudioCourseAction(response.data.data.media));
        actions.resetForm({
          values: { values: initialValues },
        });
        setMySections((prev) =>
          prev.map((item) =>
            item?.uuid === savedSection?.uuid ? savedSection : item
          )
        );
        // setMySections(response.data.data?.media?.sections);
        setShowForm(false);
      }
    } catch (err) {
      dispatch(alertAction(err?.response?.data?.message, 'danger'));
    }
  }
  // end of submit

  const initialValues = {
    title: section?.title || '',
    uuid: section?.uuid || '',
  };

  const deleteSection = async (courseId, sectionId) => {
    const url = `audioCourses/${courseId}/section/${sectionId}`;
    try {
      const response = await api.delete(url);

      dispatch(createAudioCourseAction(response.data.data.media));
      setMySections(response.data.data?.media?.sections);
    } catch (err) {}
  };

  const isDisabled = section?._id ? !showForm : false;

  return (
    <>
      <main>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <>
                <Form
                  className="form-type-4"
                  autoComplete="off"
                  onSubmit={props.handleSubmit}
                  // onBlur={props.handleSubmit}
                >
                  <div className="ce-section-grid">
                    <div className="position-relative">
                      <span
                        className="left-input-icon avenir-20-500"
                        style={{ fontSize: 18 }}
                      >
                        {sectionIndex + 1}.
                      </span>
                      <Field
                        style={{
                          ...(!isDisabled && { borderColor: '#08415c' }),
                          paddingLeft: 44,
                          fontSize: 18,
                        }}
                        disabled={isDisabled}
                        name="title"
                        type="text"
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Add section title *"
                      />
                    </div>
                    <div className="flex-vc">
                      <i
                        className="mr-12"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          section?._id
                            ? deleteSection(newAudioCourse._id, section?._id)
                            : setMySections((prev) =>
                                prev.filter((obj) => obj.uuid !== section.uuid)
                              )
                        }
                      >
                        <TranshcanIcon className="icon-size" />
                      </i>
                      {!isDisabled ? (
                        <button
                          style={{
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                          }}
                          type="submit"
                        >
                          <CheckTickIcon className="icon-lg" />
                        </button>
                      ) : (
                        <i
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowForm(true)}
                        >
                          <Edit2Icon className="icon-size" />
                        </i>
                      )}
                    </div>
                  </div>
                  <ErrorMessage name="title" component={TextError} />
                </Form>
              </>
            );
          }}
        </Formik>
      </main>
    </>
  );
}
