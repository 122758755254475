import { useEffect, useState } from "react";
import api from "../../utility/api";
import ConfCarousal from "../carousal/ConfCarousal";

function RecentlyViewed() {
  const [data, setData] = useState();
  const loadData = async () => {
    try {
      const response = await api.get("/homePage/recentlyviewed?page=1&limit=5");
      setData(response.data.data.viewedConferences);
    } catch (err) {}
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="ccah-container bg-background">
      <div className="ccah-header-wrap">
        <h2>Recently Viewed</h2>
      </div>
      {!data?.length > 0 ? (
        <div className="ccah-header-wrap">
          <h3 className="color-primary mt-16 mb-16"> No recent activity !</h3>
        </div>
      ) : (
        <ConfCarousal data={data} />
      )}
    </div>
  );
}

export default RecentlyViewed;
