const options = [
  { label: "2", value: 2 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
];

export default function PageSelect({
  pageLimit,
  setPageLimit,
  activePage,
  totalRecords,
  loadPaginationData,
}) {
  return (
    <div className="flex-vc">
      <p className="caption-2-regular-gray3 mr-6">Per page</p>
      <select
        style={{
          padding: "6px",
          border: " solid 2px #ced9de",
          borderRadius: "5px",
          backgroundColor: "#4caf50",
          color: "#fff",
          transition: " 0.1s ease-out",
          cursor: "pointer",
        }}
        value={pageLimit}
        onChange={(e) => {
          setPageLimit(parseInt(e.target.value));
          const dataforPagination = {
            currentPage: activePage?.currentPage,
            pageLimit: parseInt(e.target.value),
            totalRecords,
          };
          loadPaginationData(dataforPagination);
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
