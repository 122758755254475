import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import api from '../../utility/api';
import { alertAction } from '../../redux/alert/alertAction';
import ShareModal from '../share-modal/ShareModal';
import ShareIcon from '../icons/ShareIcon';
import LikeBlueIcon from '../icons/LikeBlueIcon';
import NoImage from '../../assets/No-Image-Found-400x264.png';

export default function SavedPodcastCard({ data, getSaved }) {
  const [showPopUp, setShowPopUp] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const unLike = async (mediaId, userId) => {
    const url = `media/${mediaId}/liked/users/${userId}?type=${data?.type}
    `;
    try {
      const response = await api.delete(url);
      if (response) {
        getSaved(userId);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  return (
    <>
      <div className="savedconfs-card mb-16">
        <div className="sc-banner-container">
          <div className="sc-conf-img-wrap">
            {data?.banner[0] ? (
              <img
                className="sc-conf-img"
                alt="preview"
                src={data?.banner[0]?.Location}
                onError={(e) => {
                  e.currentTarget.src = NoImage;
                }}
                // notFoundSrc="https://placekitten.com/g/200/300"
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  padding: 40,
                  backgroundColor: '#ecf0f2',
                }}
              >
                <div className="text-align-center">
                  <p className="body-bold">Banner comming soon</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="savedconfs-title mb-8">
            {data?.title ? data?.title : 'Video course title'}
          </p>

          <div className="mb-8">
            <span className=" caption-2-regular-gray3">
              Monthly Podcast Price {data.currency}{' '}
              {data?.monthlyPodcastPrice?.price} onwords
            </span>
          </div>

          <div>
            <span className="caption-2-regular-gray3">
              Yearly Podcast Price {data.currency}{' '}
              {data?.yearlyPodcastPrice?.price} onwords
            </span>
          </div>
        </div>

        <div className="flex-vc position-relative">
          <i
            className="mr-12"
            onClick={() => setShowPopUp(!showPopUp)}
            style={{ cursor: 'pointer' }}
          >
            <ShareIcon className="icon-size" />
          </i>
          <i
            onClick={() => {
              unLike(data?._id, user?._id);
            }}
            style={{
              position: 'relative',
              paddingTop: 5,
              cursor: 'pointer',
            }}
          >
            <LikeBlueIcon className="icon-size" />
          </i>
          {showPopUp && (
            <ShareModal
              setShowPopUp={setShowPopUp}
              showPopUp={showPopUp}
              confId={data?.conference?._id}
              cardClass={'right-share-card'}
              buttonClass={'share-btn'}
            />
          )}
        </div>
      </div>
    </>
  );
}

SavedPodcastCard.propTypes = {
  data: PropTypes.object.isRequired,
};
