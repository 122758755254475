import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import { regex1000To15x9 } from "../../utility/commonUtil";
import TextError from "../formik/TextError";

import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import "./contact.styles.scss";
import Select from "react-select";
import { getValue } from "../../utility/commonUtil";
import { formType1Styles } from "../reselect/selectUtil";

const validationSchema = yup.object().shape(
  {
    name: yup.string().trim().required("Required"),
    email: yup.string().trim().email().required("Required"),
    message: yup.string().trim().required("Required"),
    countryCode: yup.string().when("mobile", {
      is: (v) => v?.length > 0,
      then: yup.string().required("Required"),
    }),
    mobile: yup
      .string()
      .matches(regex1000To15x9, "Enter valid mobile number")
      .when("countryCode", {
        is: (v) => v?.length > 0,
        then: yup.string().required("Required"),
      }),
  },
  ["countryCode", "mobile"]
);

const ContactUs = () => {
  const [countryCodeList, setCountryCodeList] = useState([]);
  const dispatch = useDispatch();
  let initialValues = {
    name: "",
    mobile: "",
    email: "",
    message: "",
    countryCode: "",
    mobile: "",
  };

  const loadCountryCode = async () => {
    const url = `venues/countryListUserAccount`;
    try {
      const response = await api.get(url);
      if (response) {
        setCountryCodeList(response.data.data.countries);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  const onInputChange = (e) => {
    // setDisplayButton(true);
    formik.handleChange(e);
  };
  async function onSubmit(values, actions) {
    const formData = {
      contact: {
        name: values.name,
        email: values?.email,
        mobile: values?.mobile,
        countryCode: values?.countryCode,
        message: values?.message,
      },
    };

    try {
      let response = await api.post("/homePage/contacts", formData);

      if (response) {
        actions.resetForm({ values: initialValues });
        dispatch(alertAction(response.data.message, "success"));
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, "danger"));
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    loadCountryCode();
  }, []);

  return (
    <div className="pt-40 pb-40">
      <div className="flex-hc mb-32 ">
        <div
          style={{
            width: "fit-content",
          }}
        >
          <h2 className="text-align-center mb-16">Contact us</h2>
          <p className="mb-8">
            <span className="caption-2-bold-cblack">Phone: </span>
            <span className="caption-2-regular-gray3">
              +91 920 992 4309; 8am to 5pm
            </span>
          </p>
          <p>
            <span className="caption-2-bold-cblack">Email: </span>
            <span className="caption-2-regular-gray3">
              portamedservices@gmail.com
            </span>
          </p>
        </div>
      </div>
      <div className="contact-us-form-wrap">
        <p className=" text-align-center body-regular-gray3 mb-32">
          Fill up the form below and we will get back to you.
        </p>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="form-type-1"
        >
          <div>
            <div className="material-textfield">
              <input
                id="name"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder=" "
                disabled={false}
              />
              <label>Name*</label>
            </div>
            <div className="mb-16">
              {formik.touched.name && Boolean(formik.errors.name) && (
                <TextError>{formik.errors.name}</TextError>
              )}
            </div>
          </div>
          <div>
            <Select
              label="countryCode"
              name="countryCode"
              options={countryCodeList}
              value={getValue(
                countryCodeList,
                formik.values.countryCode,
                false
              )}
              isMulti={false}
              onChange={(value) => {
                formik.setFieldValue("countryCode", value?.value);
                // setDisplayButton(true);
              }}
              placeholder="Country Code"
              isClearable={true}
              styles={formType1Styles}
            />
          </div>
          <div className="mb-16">
            {formik.touched.countryCode &&
              Boolean(formik.errors.countryCode) && (
                <TextError>{formik.errors.countryCode}</TextError>
              )}
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="mobile"
                type="text"
                name="mobile"
                value={formik.values.mobile}
                onChange={onInputChange}
                placeholder=" "
                disabled={false}
              />
              <label>Mobile</label>
            </div>
            <div className="mb-16">
              {formik.touched.mobile && Boolean(formik.errors.mobile) && (
                <TextError>{formik.errors.mobile}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="email"
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder=" "
                disabled={false}
              />
              <label>Email*</label>
            </div>
            <div className="mb-16">
              {formik.touched.email && Boolean(formik.errors.email) && (
                <TextError>{formik.errors.email}</TextError>
              )}
            </div>
          </div>
          <div style={{ marginBottom: 18 }}>
            <textarea
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              placeholder="Message* "
              // onFocus={onTextAreaFocus}
              // onBlur={onTextAreaBlur}
            />
          </div>
          <div className="mb-16">
            {formik.touched.message && Boolean(formik.errors.message) && (
              <TextError>{formik.errors.message}</TextError>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <SubmitCancelButtonWithLoader
              isSubmitting={formik.isSubmitting}
              onCancel={() => formik.resetForm({ values: initialValues })}
              cancelButtonClass="button button-green"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
