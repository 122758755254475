import '../../bookings-for-organizer/bdfoCounts.scss';

export default function BdfoVideoCourseCounts({
  currency,
  sold,
  grossPrice,
  refundRequests,
  refunded,
}) {
  return (
    <div className="bdfo-counts-wrap">
      <table>
        <thead>
          <tr>
            <th>Sold</th>
            <th>Cancelled</th>
            <th>Refunded</th>
            <th>Net Sale</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{sold}</td>
            <td>{refundRequests}</td>
            <td>{refunded}</td>
            <td>
              {currency && <span>{currency?.toString().toUpperCase()}-</span>}
              {grossPrice}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
