import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alertAction } from '../../../redux/alert/alertAction';
import api from '../../../utility/api';
import ButtonWithLoader from '../../button/ButtonWithLoader';
import BackIcon from '../../icons/BackIcon';
import '../../bookings-for-organizer/confDetailsActionButtons.scss';
import { loadOneIncopleteVCAction } from '../../../redux/video-course/videoCourseAction';

// Bdfo = booking details for organizer
export default function VideoCourseDetailsActionButtons({ videoDetails }) {
  const [previewLoading, setPreviewLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const openPreview = async (conferenceId) => {
  //   setPreviewLoading(true);
  //   try {
  //     const response = await api.get(`media/${conferenceId}`);
  //     if (response) {
  //       dispatch(loadOneIncopleteVCAction(response.data.data.media));
  //       setopen(true);
  //       setPreviewLoading(false);
  //     }
  //   } catch (err) {
  //     if (err) {
  //       dispatch(alertAction(err.response.data.message, 'danger'));
  //       setPreviewLoading(false);
  //     }
  //   }
  // };

  const getOneIncompleteVideoCourse = async (mediaId, step) => {
    const url = `/courses/${mediaId}/videoCourse/steps`;
    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadOneIncopleteVCAction(response.data.data.media));
        navigate(`/dashboard/create-vc/step-${step ? step : '1'}`);
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
      }
    }
  };

  return (
    <>
      <div className="cdab-grid">
        <div className="flex-vc">
          <button
            style={{ height: 52, paddingRight: 20 }}
            className="flex-vc small-button small-button-primary"
            onClick={() => navigate(-1)}
          >
            <i
              style={{
                position: 'relative',
                paddingRight: 4,
                paddingTop: 5,
              }}
            >
              <BackIcon className="icon-size" fill="#fff" />
            </i>
            <span>Back</span>
          </button>
          <p className="caption-1-regular-gray3 ml-24">Published</p>
        </div>
        <div
          style={{
            display: 'grid',
            // width: "100%",
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 8,
          }}
        >
          <div style={{ width: '100%' }}>
            <ButtonWithLoader
              isSubmitting={previewLoading}
              text="Preview"
              className="small-button small-button-primary"
              fullWidth={true}
              handleClick={() => navigate(`/courses/${videoDetails?._id}`)}
            />
          </div>

          <div style={{ width: '100%' }}>
            <ButtonWithLoader
              isSubmitting={editLoading}
              text="Edit"
              className="small-button small-button-green"
              fullWidth={true}
              handleClick={async () => {
                setEditLoading(true);
                await getOneIncompleteVideoCourse(videoDetails?._id);
                // await getOneIncompleteConf(videoDetails?._id, navigate);
                setEditLoading(false);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
