import { useEffect } from "react";
import CreateConfLanding from "../../components/create-conference/CreateConfLanding";

export default function CreateConfLandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CreateConfLanding />
    </div>
  );
}
