// import CreditRequests from "../../components/organizer-dashboard-conferences/CreditRequests";
import CreditRequests from "../../components/credit-requests/CreditRequests";

export default function CreditRequestsPage() {
  return (
    <div>
      <CreditRequests />
    </div>
  );
}
