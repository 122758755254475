import {
  CREATE_PODCAST,
  REMOVE_NEWPOD_STATE,
  LOAD_INCOMPLETE_PODS,
  LOAD_INCOMPLETE_POD,
  LOAD_ALL_MY_PODS,
  GET_SINGLE_POD,
} from './podcastTypes';

// Creat a videoCourse
export const createPodcastAction = (data) => (dispatch) => {
  dispatch({
    type: CREATE_PODCAST,
    payload: data,
  });
};

// remove new videoCourse redux state
export const removePodcastStateAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_NEWPOD_STATE,
  });
};

//  load one incomplete videoCourse
export const loadOneIncopletePodAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_INCOMPLETE_POD,
    payload: data,
  });
};
//  load all incomplete videoCourses

export const loadIncopletePodsAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_INCOMPLETE_PODS,
    payload: data,
  });
};

export const loadAllPodsAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_ALL_MY_PODS,
    payload: data,
  });
};

// export const searchPodssAction = (data) => (dispatch) => {
//   dispatch({
//     type: AC_SEARCH_DONE,
//     payload: data,
//   });
// };

export const loadSinglePodAction = (data) => (dispatch) => {
  dispatch({
    type: GET_SINGLE_POD,
    payload: data,
  });
};

// export const ACSearchInitiatedAction = () => (dispatch) => {
//   dispatch({
//     type: AC_SEARCH_INITIATED,
//   });
// };

// // get a videoCourse

// export const getAudioCourseAction = () => async (dispatch) => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       withCredentials: true,
//       url: 'http://localhost:5000/api/audioCourse/:videoCourse_id',
//     });
//     dispatch({ type: GET_AUDIO_COURSE, payload: res.data });
//   } catch (err) {
//     dispatch({
//       type: AUDIO_COURSE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };
