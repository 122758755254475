import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import MaterialTextInput from '../formik/MaterialTextInput';
import Textarea from '../formik/Textarea';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import './feedback.styles.scss';
import { useEffect } from 'react';

const initialValues = {
  title: '',
  content: '',
};

const validationSchema = yup.object({
  title: yup.string().required('Required'),
  content: yup.string().required('Required'),
});

export default function FeedbackForm() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      feedBackDetails: {
        content: values.content,
        title: values.title,
      },
    };
    try {
      const url = `/users/${user?._id}/dashboard/feedbacks`;
      const response = await api.post(url, formData);
      if (response) {
        dispatch(alertAction(response.data.message, 'success'));
        actions.resetForm({ values: initialValues });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError('content', err.response.data.message);
      }
    }
  };

  return (
    <div className="feedback-container">
      <div className="mb-24">
        <h2 className="color-primary mb-24">Feedback</h2>
        <p className="body-regular-gray3 mb-16">
          Thank you for helping us improve Confemy. Your feedback is valuable.
        </p>
        <p className="body-regular-gray3">
          Please submit feedback using following form.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { isSubmitting, resetForm } = props;
          return (
            <Form className="form-type-1">
              <div className="mb-40">
                <h4>Title*</h4>
                <div className="material-textfield input-container">
                  <MaterialTextInput
                    label="Title*"
                    name="title"
                    type="text"
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    placeholder=" "
                  />
                </div>
                <div>
                  <h4>Feedback content*</h4>
                  <Textarea
                    rows={15}
                    id="content"
                    name="content"
                    // label="Feedback content*"
                    placeholder="Write feedback here..."
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                  />
                </div>
              </div>
              <div style={{ width: '100%', maxWidth: '464px' }}>
                <SubmitCancelButtonWithLoader
                  isSubmitting={isSubmitting}
                  onCancel={() => resetForm({ values: initialValues })}
                  cancelButtonClass="button button-primary"
                  fullWidth={true}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
