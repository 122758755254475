import { utcToZonedTime, zonedTimeToUtc, format } from 'date-fns-tz';

import enGB from 'date-fns/locale/en-GB';
import api from './api';
import StoreAndPersistStore from '../redux/store';
import { alertAction } from '../redux/alert/alertAction';
import {
  loadCountryListAction,
  loadStateListAction,
  loadCityListAction,
  loadCreditTypesListAction,
  loadAmenitylistAction,
  loadGoalsCreditListAction,
  loadExternalCreditListAction,
} from '../redux/list/listAction';
import {
  loadDefaultRefundPolicyAction,
  loadPremadeBannersAction,
} from '../redux/asset/assetAction';
import { loadOneIncopleteConfAction } from '../redux/conference/conferenceAction';
import ZoomMeetingIcon from '../components/icons/ZoomMeetingIcon';
import GoogleMeetIcon from '../components/icons/GoogleMeetIcon';
import VimeoIcon from '../components/icons/VimeoIcon';
import YoutubeIcon from '../components/icons/YoutubeIcon';
import FacebookIcon from '../components/icons/FacebookIcon';
import StreamIcon from '../components/icons/StreamIcon';
import {
  confSearchInitiatedAction,
  searchConfsAction,
} from '../redux/conference/conferenceAction';
import { loadOneIncopleteVCAction } from '../redux/video-course/videoCourseAction';
import { loadOneIncopletePodAction } from '../redux/podcast/podcastAction';
const { store } = StoreAndPersistStore;

// get users external creditlist for select

export const getExternalCreditList = async (userId) => {
  const url = `/attendees/credits/external?userId=${userId}&&type=user`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(loadExternalCreditListAction(response.data.data.credits));
    }
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

// get goals credit list for select

export const getGoalsCreditsList = async (userId) => {
  try {
    const response = await api.get(
      `attendees/goalCredits/crediTypes?userId=${userId}`
    );
    // setCreditsList(response.data.data.creditTypes);
    store.dispatch(loadGoalsCreditListAction(response.data.data.creditTypes));
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

// build query
export const getQuery = (parameters) => {
  const query = parameters.reduce(function (str, item) {
    return (
      str +
      (item.value
        ? (str?.length > 0 ? '&' : '') + item.name + '=' + item.value
        : '')
    );
  }, '');
  return query;
};

// build query and get url

export const getUrl = (parameters, baseUrl) => {
  const query = parameters.reduce(function (str, item) {
    return (
      str +
      (item.value
        ? (str?.length > 0 ? '&' : '') + item.name + '=' + item.value
        : '')
    );
  }, '');
  let url = baseUrl;
  if (query?.length > 0) {
    url = baseUrl + '?' + query;
  }
  return url;
};

// function for grid-tables row style

export const renderRowStyle = (indx, dataId, isHover, rowId) => {
  return {
    // gridRow: `${indx + 2}/${indx + 3}`,
    // backgroundColor: isHover && rowId === dataId ? "#fafbfc" : null,
    backgroundColor: isHover && rowId === dataId ? '#fff' : null,
    borderBottom: '1px solid #ecf0f2',
    cursor: 'pointer',
  };
};

// function to call search api. it will send above filters array

export const initialFilterValues = {
  location: '',
  startDate: null,
  endDate: null,
  profession: '',
  specialities: [],
  creditType: '',
  creditAmount: 1,
  currency: '',
  minPrice: 0,
  maxPrice: 100000,
  //filter display states

  specialityDisabled: true,
  creditAmountDisabled: true,
  priceDisabled: true,
  openFilterDrawer: false,
};

export const initialDisplay = {
  openLocation: false,
  openDate: false,
  openProfession: false,
  openSpecialty: false,
  openCredits: false,
  openPrice: false,
};

export const loadSearchResults = async (
  searchText = '',
  filters = initialFilterValues,
  mode = 'allConferences',
  page = 1
) => {
  let dateUpdatedFilter;
  if (filters.startDate || filters.endDate) {
    let timezone =
      filters.location?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    let utcStartDate = null;
    let utcEndDate = null;
    if (filters?.startDate && timezone) {
      utcStartDate = zonedTimeToUtc(filters?.startDate, timezone).toISOString();
    }
    if (filters?.endDate && timezone) {
      utcEndDate = zonedTimeToUtc(filters?.endDate, timezone).toISOString();
    }
    dateUpdatedFilter = {
      ...filters,
      startDate: utcStartDate,
      endDate: utcEndDate,
    };
  }

  const url = `homePage/conferences/search?page=${page}&mode=${mode}&limit=9&text=${searchText}`;

  const data =
    filters.startDate || filters.endDate ? dateUpdatedFilter : filters;
  store.dispatch(confSearchInitiatedAction());

  try {
    const response = await api.post(url, { filters: data });
    if (response) {
      store.dispatch(searchConfsAction(response.data.data));
    }
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

// like a conference

export const likeConference = async (confId, userId, setIsLiked) => {
  setIsLiked(true);
  const data = {
    likedConferenceDetails: {
      conferenceId: confId,
      userId: userId,
    },
  };
  try {
    api.post('/conferences/like', data);
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
    setIsLiked(false);
  }
};

export const likeMedia = async (mediaId, userId, setIsLiked, type) => {
  try {
    setIsLiked(true);
    let formData = {
      likedMediaDetails: {
        userId: userId,
      },
    };
    await api.post(`/media/${mediaId}/liked?type=${type}`, formData);
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
    setIsLiked(false);
  }
};

export const unLikeMedia = async (mediaId, userId, setIsLiked, type) => {
  try {
    setIsLiked(false);
    await api.delete(`/media/${mediaId}/liked/users/${userId}?type=${type}`);
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
    setIsLiked(true);
  }
};

// unlike a conference
export const unlikeConference = async (confId, userId, setIsLiked) => {
  setIsLiked(false);
  const url = `conferences/like/${confId}/users/${userId}`;
  try {
    api.delete(url);
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
    setIsLiked(true);
  }
};

export const dateWiseFilter = (conf, value) => {
  let today = new Date().toISOString();
  let startDate = new Date();
  startDate.setMonth(startDate.getMonth() - value);
  let fiteredResult = conf.filter((conf) => {
    return (
      Date.parse(conf?.conference?.startDate) >=
        Date.parse(startDate.toISOString()) &&
      Date.parse(conf?.conference?.startDate) <= Date.parse(today)
    );
  });

  return fiteredResult;
};
export const getFormattedDateInTz = (date, timezone) => {
  let dateInTz;
  let formattedDate;

  if (date && timezone) {
    dateInTz = utcToZonedTime(date, timezone);
    formattedDate = format(dateInTz, 'MMM-dd-yyyy, HH:mm aa', {
      timeZone: timezone,
      locale: enGB,
    });
  } else {
    formattedDate = null;
  }
  return formattedDate;
};

export const getFormattedDateInTz2 = (date, timezone) => {
  let dateInTz;
  let formattedDate;

  if (date && timezone) {
    dateInTz = utcToZonedTime(date, timezone);
    formattedDate = format(dateInTz, 'MMM-dd-yyyy', {
      timeZone: timezone,
      locale: enGB,
    });
  } else {
    formattedDate = null;
  }
  return formattedDate;
};

// open window in new tab
export const openInNewTab = (url) => {
  if (!process.env.NODE_ENV === 'development' && !url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }
  // if (!url.match(/^https?:\/\//i)) {
  //   url = "http://" + url;
  // }
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const getLocationString = (mode, city) => {
  let locationStrig = 'Location';
  if (mode?.length > 0) {
    if (mode?.includes('venue') && city) {
      locationStrig = city;
    }

    if (mode?.includes('onlineConf')) {
      locationStrig = 'Online';
    }

    if (mode?.includes('venue') && mode?.includes('onlineConf')) {
      locationStrig = `${city} & Livestream online`;
    }
  }
  return locationStrig;
};

export const getFullLocationString = (mode, city, state, country) => {
  let locationStrig = 'Location';
  if (mode?.length > 0) {
    if (mode?.includes('venue') && city) {
      locationStrig = `${city}, ${state}, ${country}`;
    }

    if (mode?.includes('onlineConf')) {
      locationStrig = 'Online';
    }

    if (mode?.includes('venue') && mode?.includes('onlineConf')) {
      locationStrig = `${city}, ${state}, ${country} & Livestream online`;
    }
  }
  return locationStrig;
};

export const capitalize = (word) => {
  return word[0]?.toUpperCase() + word.slice(1).toLowerCase();
};

// get index for react-select components from value of key
// They array has to have value as one of the keys [{labe:"Some Lable", value:"Some Value"}]

export const getIndex = (myArray, valueOfKey) => {
  const index = myArray.map((object) => object.value).indexOf(valueOfKey);
  return index;
};

// get object for react-select components from value of key
// They array has to have value as one of the keys  [{labe:"Some Lable", value:"Some Value"}]

export const getOption = (myArray, valueOfKey) => {
  return myArray?.find((option) => option?.value === valueOfKey);
};

export const getValue = (options, value, isMulti) => {
  if (isMulti) {
    return value;
  } else {
    return options ? options?.find((option) => option.value === value) : '';
  }
};

export const loadLocations = async (searchText, callback) => {
  const response = await api.get(`venues/search?venue=${searchText}`);
  callback(response.data.data.venue);
};

export const addPotenitalUser = async (
  firstName,
  lastName,
  email,
  country,
  state,
  city,
  conferenceId,
  profession,
  mobile,
  countryCode
) => {
  try {
    let formData = {
      user: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: country,
        state: state,
        city: city,
        confId: conferenceId,
        profession: profession,
        mobile: mobile,
        countryCode: countryCode,
      },
    };
    // await addRecentlyViewed(conferenceId);
    api.post('/common/potentialUsers', formData);
  } catch (err) {}
};
export const loadCountryList = async () => {
  const url = `venues/countryList`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(loadCountryListAction(response.data.data.countries));
    }
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

export const loadStateList = async (countryId) => {
  const url = `venues/stateList?countryId=${countryId}`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(loadStateListAction(response.data.data.states));
    }
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

export const loadCityList = async (stateId) => {
  const url = `venues/cityList?stateId=${stateId}`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(loadCityListAction(response.data.data.cities));
    }
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

// add confs status to my conferences
export const addConfStatus = (conf) => {
  let hasExpired = Date.now() > Date.parse(conf.endDate);
  let active = Date.parse(conf.activeDate) > Date.parse(conf.inActiveDate);
  if (!conf.completedStep6) {
    return { ...conf, status: 'Draft' };
  } else {
    if (conf.activeDate && conf.inActiveDate) {
      if (active && !hasExpired) {
        return { ...conf, status: 'Published' };
      }
      if (active && hasExpired) {
        return { ...conf, status: 'Expired' };
      }
      if (!active) {
        return { ...conf, status: 'Unpublished' };
      }
    }
    if (conf.activeDate && !conf.inActiveDate && !hasExpired) {
      return { ...conf, status: 'Published' };
    }
    if (conf.activeDate && !conf.inActiveDate && hasExpired) {
      return { ...conf, status: 'Expired' };
    }
    if (!conf.activeDate && conf.inActiveDate) {
      return { ...conf, status: 'Unpublished' };
    }
  }
};

export const addVideoCourseStatus = (media) => {
  let active = Date.parse(media.activeDate) > Date.parse(media.inActiveDate);
  if (!media.completedStep5) {
    return { ...media, status: 'Draft' };
  } else {
    if (media.activeDate && media.inActiveDate) {
      if (active) {
        return { ...media, status: 'Published' };
      }
      if (!active) {
        return { ...media, status: 'Unpublished' };
      }
    }
    if (media.activeDate && !media.inActiveDate) {
      return { ...media, status: 'Published' };
    }

    if (!media.activeDate && media.inActiveDate) {
      return { ...media, status: 'Unpublished' };
    }
  }
};

export const addPodcastStatus = (media) => {
  let active = Date.parse(media.activeDate) > Date.parse(media.inActiveDate);
  if (!media.completedStep4) {
    return { ...media, status: 'Draft' };
  } else {
    if (media.activeDate && media.inActiveDate) {
      if (active) {
        return { ...media, status: 'Published' };
      }
      if (!active) {
        return { ...media, status: 'Unpublished' };
      }
    }
    if (media.activeDate && !media.inActiveDate) {
      return { ...media, status: 'Published' };
    }

    if (!media.activeDate && media.inActiveDate) {
      return { ...media, status: 'Unpublished' };
    }
  }
};

// for one conference get status
export const getConfStatus = (conf) => {
  const hasExpired = Date.now() > Date.parse(conf.endDate);
  const active = Date.parse(conf.activeDate) > Date.parse(conf.inActiveDate);
  if (!conf.completedStep6) {
    return 'Draft';
  } else {
    if (conf.activeDate && conf.inActiveDate) {
      if (active && !hasExpired) {
        return 'Published';
      }
      if (active && hasExpired) {
        return 'Expired';
      }
      if (!active) {
        return 'Not published';
      }
    }
    if (conf.activeDate && !conf.inActiveDate && !hasExpired) {
      return 'Published';
    }
    if (conf.activeDate && !conf.inActiveDate && hasExpired) {
      return 'Expired conference';
    }
    if (!conf.activeDate && conf.inActiveDate) {
      return 'Not published';
    }
  }
};

export const getVideoCourseStatus = (conf) => {
  const active = Date.parse(conf.activeDate) > Date.parse(conf.inActiveDate);
  if (!conf.completedStep5) {
    return 'Draft';
  } else {
    if (conf.activeDate && conf.inActiveDate) {
      if (active) {
        return 'Published';
      }
      if (!active) {
        return 'Not published';
      }
    }
    if (conf.activeDate && !conf.inActiveDate) {
      return 'Published';
    }
    if (!conf.activeDate && conf.inActiveDate) {
      return 'Not published';
    }
  }
};
//   Get one incomplete conference for edit
export const getOneIncompleteConf = async (conferenceId, navigate) => {
  const url = `conferences/${conferenceId}`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(
        loadOneIncopleteConfAction(response.data.data.conferences)
      );
      navigate('/dashboard/create-conf/step-1');
    }
  } catch (err) {
    if (err) {
      store.dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }
};
export const getOneIncompleteVideoCourse = async (mediaId, navigate) => {
  const url = `/courses/${mediaId}/videoCourse/steps`;
  try {
    const response = await api.get(url);

    if (response) {
      store.dispatch(loadOneIncopleteVCAction(response.data.data.media));
      navigate(`/dashboard/create-vc/step-1`);
    }
  } catch (err) {
    if (err) {
      store.dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }
};

export const getPodcastStatus = (conf) => {
  const active = Date.parse(conf.activeDate) > Date.parse(conf.inActiveDate);
  if (!conf.completedStep4) {
    return 'Draft';
  } else {
    if (conf.activeDate && conf.inActiveDate) {
      if (active) {
        return 'Published';
      }
      if (!active) {
        return 'Not published';
      }
    }
    if (conf.activeDate && !conf.inActiveDate) {
      return 'Published';
    }
    if (!conf.activeDate && conf.inActiveDate) {
      return 'Not published';
    }
  }
};

export const getOneIncompletePodcast = async (mediaId, navigate, navUrl) => {
  const url = `/podcast/${mediaId}/steps`;
  try {
    const response = await api.get(url);
    console.log({ response });
    if (response) {
      store.dispatch(loadOneIncopletePodAction(response.data.data.media));
      navigate(navUrl);
    }
  } catch (err) {
    if (err) {
      store.dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }
};

export const getOneIncompletePodcastEpisode = async (
  mediaId,
  navigate,
  episodeId,
  navUrl
) => {
  const url = `/podcast/${mediaId}/preview/episodes/${episodeId}`;
  try {
    const response = await api.get(url);

    if (response) {
      store.dispatch(loadOneIncopletePodAction(response.data.data.media));
      navigate(navUrl);
    }
  } catch (err) {
    if (err) {
      store.dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }
};

//   Get one incomplete conference for preview
export const getOneConf = async (conferenceId) => {
  const url = `conferences/${conferenceId}`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(
        loadOneIncopleteConfAction(response.data.data.conferences)
      );
      // navigate("/dashboard/create-conf/step-1");
    }
  } catch (err) {
    if (err) {
      store.dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }
};

export const getPrice = (currency, actualPrice, price) => {
  if (!currency) {
    return (
      <>
        <div className="line-clamp" style={{ WebkitLineClamp: 2 }}>
          <p>Price not availabe</p>
        </div>
      </>
    );
  }
  if (actualPrice === 0 || price === 0) {
    return (
      <>
        <div className="line-clamp" style={{ WebkitLineClamp: 2 }}>
          <p>FREE</p>
        </div>
        <div className="line-clamp" style={{ WebkitLineClamp: 2 }}>
          {price > 0 && (
            <p
              style={{
                textDecoration: 'line-through',
                color: '#4cb944',
                fontSize: 15,
              }}
            >
              <span>{currency} </span>
              <span>{price}</span>
            </p>
          )}
        </div>
      </>
    );
  }
  if (price > 0) {
    return (
      <>
        {actualPrice > 0 && (
          <div className="line-clamp" style={{ WebkitLineClamp: 2 }}>
            <p>
              <span>{currency} </span>
              <span>{actualPrice}</span>
            </p>
          </div>
        )}
        <div className="line-clamp" style={{ WebkitLineClamp: 2 }}>
          <p
            style={{
              ...(actualPrice > 0 && {
                textDecoration: 'line-through',
                color: '#4cb944',
                fontSize: 15,
              }),
            }}
          >
            <span>{currency} </span>
            <span>{price}</span>
          </p>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="line-clamp" style={{ WebkitLineClamp: 2 }}>
        <p>Price not availabe</p>
      </div>
    </>
  );
};

export const loadCreditTypesList = async () => {
  const url = `common/conferences/credits`;
  try {
    const response = await api.get(url);
    if (response) {
      store.dispatch(loadCreditTypesListAction(response.data.data.credits));
    }
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

export const loadAmenities = async () => {
  try {
    const response = await api.get('conferences/amenities');
    store.dispatch(loadAmenitylistAction(response.data.data.amenities));
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

export const loadPremadeBanners = async () => {
  try {
    const response = await api.get('/assets/banners/default');
    store.dispatch(loadPremadeBannersAction(response.data.data.savedBanners));
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

export const loadDefaultRefundPolicy = async () => {
  try {
    const response = await api.get('assets/refundPolicy/default');
    store.dispatch(
      loadDefaultRefundPolicyAction(
        response.data.data.savedRefundPolicy.find(
          (policy) => policy.name === 'default'
        )
      )
    );
  } catch (err) {
    store.dispatch(alertAction(err.response.data.message, 'danger'));
  }
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const platformOptions = [
  {
    label: 'Facebook',
    value: 'facebook',
    icon: <FacebookIcon className="icon-size" />,
  },
  {
    label: 'Youtube live',
    value: 'youtube',
    icon: <YoutubeIcon className="icon-size" />,
  },
  {
    label: 'Zoom',
    value: 'zoom',
    icon: <ZoomMeetingIcon className="icon-size" />,
  },
  {
    label: 'Google meet',
    value: 'googleMeet',
    icon: <GoogleMeetIcon className="icon-size" />,
  },
  {
    label: 'Vimeo',
    value: 'vimeo',
    icon: <VimeoIcon className="icon-size" />,
  },
  {
    label: 'Other',
    value: 'other',
    icon: <StreamIcon className="icon-lg" />,
  },
];

export const professions = [
  { value: 'caseManagement', label: 'Case Management' },
  {
    value: 'dentalAssistant',
    label: 'Dental Assistant',
  },
  { value: 'dentalHygienist', label: 'Dental Hygienist' },
  { value: 'dentist', label: 'Dentist' },
  { value: 'dietitian', label: 'Dietitian' },
  { value: 'nurse', label: 'Nurse' },
  { value: 'nursePractitioner', label: 'Nurse Practitioner' },
  { value: 'occupationalTherapist', label: 'Occupational Therapist' },
  { value: 'pharmacist', label: 'Pharmacist' },
  { value: 'physicalTherapist', label: 'Physical Therapist' },
  { value: 'physician', label: 'Physician' },
  { value: 'physicianAssistant', label: 'Physician Assistant' },
  { value: 'respiratoryTherapist', label: 'Respiratory Therapist' },
  { value: 'socialWorker', label: 'Social Worker' },
  { value: 'speechTherapist', label: 'Speech Therapist' },
];

export const subspecialties = [
  {
    value: 'Abdominal Imaging Radiology',
    label: 'Abdominal Imaging Radiology',
  },
  {
    value: 'Addiction Medicine',
    label: 'Addiction Medicine',
  },
  {
    value: 'Addiction Psychiatry',
    label: 'Addiction Psychiatry',
  },
  {
    value: 'Adolescent Medicine',
    label: 'Adolescent Medicine',
  },
  {
    value: 'Adult Cardiac Anesthesiology',
    label: 'Adult Cardiac Anesthesiology',
  },
  {
    value: 'Adult Congenital Heart Disease',
    label: 'Adult Congenital Heart Disease',
  },
  {
    value: 'Adult Reconstructive Orthopaedic Surgery',
    label: 'Adult Reconstructive Orthopaedic Surgery',
  },
  {
    value: 'Advanced Heart Failure and Transplant Cardiology',
    label: 'Advanced Heart Failure and Transplant Cardiology',
  },
  {
    value: 'Aerospace Medicine',
    label: 'Aerospace Medicine',
  },
  {
    value: 'Allergy and Immunology',
    label: 'Allergy and Immunology',
  },
  {
    value: 'Ambulatory care pharmacy',
    label: 'Ambulatory care pharmacy',
  },
  {
    value: 'Anesthesiology',
    label: 'Anesthesiology',
  },
  {
    value: 'Blood Banking-Transfusion Medicine',
    label: 'Blood Banking-Transfusion Medicine',
  },
  {
    value: 'Brain Injury Medicine',
    label: 'Brain Injury Medicine',
  },
  {
    value: 'Cardiology Pharmacy',
    label: 'Cardiology Pharmacy',
  },
  {
    value: 'Cardiothoracic Radiology',
    label: 'Cardiothoracic Radiology',
  },
  {
    value: 'Cardiovascular Disease',
    label: 'Cardiovascular Disease',
  },
  {
    value: 'Chemical Pathology',
    label: 'Chemical Pathology',
  },
  {
    value: 'Child Abuse Pediatrics',
    label: 'Child Abuse Pediatrics',
  },
  {
    value: 'Child and Adolescent Psychiatry',
    label: 'Child and Adolescent Psychiatry',
  },
  {
    value: 'Pediatric Neurology',
    label: 'Pediatric Neurology',
  },
  {
    value: 'Clinical Biochemical Genetics',
    label: 'Clinical Biochemical Genetics',
  },
  {
    value: 'Clinical Cardiac Electrophysiology',
    label: 'Clinical Cardiac Electrophysiology',
  },
  {
    value: 'Clinical Genetics and Genomics',
    label: 'Clinical Genetics and Genomics',
  },
  {
    value: 'Clinical Informatics',
    label: 'Clinical Informatics',
  },
  {
    value: 'Clinical Neurophysiology',
    label: 'Clinical Neurophysiology',
  },
  {
    value: 'Colon and Rectal Surgery',
    label: 'Colon and Rectal Surgery',
  },
  {
    value: 'Complex Family Planning',
    label: 'Complex Family Planning',
  },
  {
    value: 'Complex General Surgical Oncology',
    label: 'Complex General Surgical Oncology',
  },
  {
    value: 'Compounded sterile preparations pharmacy',
    label: 'Compounded sterile preparations pharmacy',
  },
  {
    value: 'Congenital Cardiac Surgery',
    label: 'Congenital Cardiac Surgery',
  },
  {
    value: 'Consultation-Liaison Psychiatry',
    label: 'Consultation-Liaison Psychiatry',
  },
  {
    value: 'Cosmetic Dermatologic Surgery',
    label: 'Cosmetic Dermatologic Surgery',
  },
  {
    value: 'Craniofacial Surgery',
    label: 'Craniofacial Surgery',
  },
  {
    value: 'Critical Care Medicine',
    label: 'Critical Care Medicine',
  },
  {
    value: 'Critical Care Pharmacy',
    label: 'Critical Care Pharmacy',
  },
  {
    value: 'Cytopathology',
    label: 'Cytopathology',
  },
  {
    value: 'Dental Public Health',
    label: 'Dental Public Health',
  },
  {
    value: 'Dentist Anesthesiologists',
    label: 'Dentist Anesthesiologists',
  },
  {
    value: 'Dermatology',
    label: 'Dermatology',
  },
  {
    value: 'Dermatopathology',
    label: 'Dermatopathology',
  },
  {
    value: 'Developmental and Behavioral Pediatrics',
    label: 'Developmental and Behavioral Pediatrics',
  },
  {
    value: 'Diagnostic Medical Physics',
    label: 'Diagnostic Medical Physics',
  },
  {
    value: 'Diagnostic Radiology',
    label: 'Diagnostic Radiology',
  },
  {
    value: 'Emergency Medicine Pharmacy',
    label: 'Emergency Medicine Pharmacy',
  },
  {
    value: 'Emergency Medical Services',
    label: 'Emergency Medical Services',
  },
  {
    value: 'Emergency Medicine',
    label: 'Emergency Medicine',
  },
  {
    value: 'Endodontist',
    label: 'Endodontist',
  },
  {
    value: 'Endocrinology, Diabetes, and Metabolism',
    label: 'Endocrinology, Diabetes, and Metabolism',
  },
  {
    value: 'Endovascular Surgical Neuroradiology',
    label: 'Endovascular Surgical Neuroradiology',
  },
  {
    value: 'Epilepsy',
    label: 'Epilepsy',
  },
  {
    value: 'Facial Plastic Surgery',
    label: 'Facial Plastic Surgery',
  },
  {
    value: 'Family Practice',
    label: 'Family Practice',
  },
  {
    value: 'Female Pelvic Medicine and Reconstructive Surgery',
    label: 'Female Pelvic Medicine and Reconstructive Surgery',
  },
  {
    value: 'Foot and Ankle Orthopaedic Surgery',
    label: 'Foot and Ankle Orthopaedic Surgery',
  },
  {
    value: 'Forensic Pathology',
    label: 'Forensic Pathology',
  },
  {
    value: 'Forensic Psychiatry',
    label: 'Forensic Psychiatry',
  },
  {
    value: 'Gastroenterology',
    label: 'Gastroenterology',
  },
  {
    value: 'General Surgery',
    label: 'General Surgery',
  },
  {
    value: 'Geriatric dentistry',
    label: 'Geriatric dentistry',
  },
  {
    value: 'Geriatric Medicine',
    label: 'Geriatric Medicine',
  },
  {
    value: 'Geriatric Pharmacy',
    label: 'Geriatric Pharmacy',
  },
  {
    value: 'Gynecologic Oncology',
    label: 'Gynecologic Oncology',
  },
  {
    value: 'Hand Surgery',
    label: 'Hand Surgery',
  },
  {
    value: 'Hematology',
    label: 'Hematology',
  },
  {
    value: 'Hematology and Medical Oncology',
    label: 'Hematology and Medical Oncology',
  },
  {
    value: 'Hematopathology',
    label: 'Hematopathology',
  },
  {
    value: 'Hospice and Palliative Medicine',
    label: 'Hospice and Palliative Medicine',
  },
  {
    value: 'Infectious Disease',
    label: 'Infectious Disease',
  },
  {
    value: 'Infectious Diseases Pharmacy',
    label: 'Infectious Diseases Pharmacy',
  },
  {
    value: 'Integrated Plastic Surgery',
    label: 'Integrated Plastic Surgery',
  },
  {
    value: 'Integrated Thoracic Surgery',
    label: 'Integrated Thoracic Surgery',
  },
  {
    value: 'Integrated Vascular Surgery',
    label: 'Integrated Vascular Surgery',
  },
  {
    value: 'Internal Medicine',
    label: 'Internal Medicine',
  },
  {
    value: 'Internal Medicine-Pediatrics',
    label: 'Internal Medicine-Pediatrics',
  },
  {
    value: 'Interventional Cardiology',
    label: 'Interventional Cardiology',
  },
  {
    value: 'Interventional Radiology',
    label: 'Interventional Radiology',
  },
  {
    value: 'Laboratory Genetics and Genomics',
    label: 'Laboratory Genetics and Genomics',
  },
  {
    value: 'Maternal-Fetal Medicine',
    label: 'Maternal-Fetal Medicine',
  },
  {
    value: 'Medical Biochemical Genetics',
    label: 'Medical Biochemical Genetics',
  },
  {
    value: 'Medical Genetics and Genomics',
    label: 'Medical Genetics and Genomics',
  },
  {
    value: 'Medical Microbiology Pathology',
    label: 'Medical Microbiology Pathology',
  },
  {
    value: 'Medical Oncology',
    label: 'Medical Oncology',
  },
  {
    value: 'Medical Physics',
    label: 'Medical Physics',
  },
  {
    value: 'Medical Toxicology',
    label: 'Medical Toxicology',
  },
  {
    value: 'Micrographic Dermatologic Surgery',
    label: 'Micrographic Dermatologic Surgery',
  },
  {
    value: 'Microsurgery',
    label: 'Microsurgery',
  },
  {
    value: 'Molecular Genetic Pathology',
    label: 'Molecular Genetic Pathology',
  },
  {
    value: 'Movement Disorders',
    label: 'Movement Disorders',
  },
  {
    value: 'Musculoskeletal Imaging Radiology',
    label: 'Musculoskeletal Imaging Radiology',
  },
  {
    value: 'Musculoskeletal Oncology',
    label: 'Musculoskeletal Oncology',
  },
  {
    value: 'Neonatal-Perinatal Medicine',
    label: 'Neonatal-Perinatal Medicine',
  },
  {
    value: 'Nephrology',
    label: 'Nephrology',
  },
  {
    value: 'Neuro-Ophthalmology',
    label: 'Neuro-Ophthalmology',
  },
  {
    value: 'Neurocritcial Care',
    label: 'Neurocritcial Care',
  },
  {
    value: 'Neurodevelopmental Disabilities',
    label: 'Neurodevelopmental Disabilities',
  },
  {
    value: 'Neuroimmunology and Multiple Sclerosis',
    label: 'Neuroimmunology and Multiple Sclerosis',
  },
  {
    value: 'Neurological Surgery',
    label: 'Neurological Surgery',
  },
  {
    value: 'Neurology',
    label: 'Neurology',
  },
  {
    value: 'Neuromuscular Medicine',
    label: 'Neuromuscular Medicine',
  },
  {
    value: 'Neuropathology,',
    label: 'Neuropathology,',
  },
  {
    value: 'Neuroradiology',
    label: 'Neuroradiology',
  },
  {
    value: 'Neurotology',
    label: 'Neurotology',
  },
  {
    value: 'Nuclear Medical Physics',
    label: 'Nuclear Medical Physics',
  },
  {
    value: 'Nuclear Medicine',
    label: 'Nuclear Medicine',
  },
  {
    value: 'Nuclear Pharmacy',
    label: 'Nuclear Pharmacy',
  },
  {
    value: 'Nuclear Radiology',
    label: 'Nuclear Radiology',
  },
  {
    value: 'Nutrition Support Pharmacy',
    label: 'Nutrition Support Pharmacy',
  },
  {
    value: 'Obstetric Anesthesiology',
    label: 'Obstetric Anesthesiology',
  },
  {
    value: 'Obstetrics and Gynecology',
    label: 'Obstetrics and Gynecology',
  },
  {
    value: 'Occupational and Environmental Medicine',
    label: 'Occupational and Environmental Medicine',
  },
  {
    value: 'Oculofacial Plastic Surgery',
    label: 'Oculofacial Plastic Surgery',
  },
  {
    value: 'Oncology Pharmacy',
    label: 'Oncology Pharmacy',
  },
  {
    value: 'Ophthalmic Pathology',
    label: 'Ophthalmic Pathology',
  },
  {
    value: 'Ophthalmic Plastic and Reconstructive Surgery',
    label: 'Ophthalmic Plastic and Reconstructive Surgery',
  },
  {
    value: 'Ophthalmology',
    label: 'Ophthalmology',
  },
  {
    value: 'Oral and Maxillofacial Surgeon',
    label: 'Oral and Maxillofacial Surgeon',
  },
  {
    value: 'Oral and Maxillofacial Radiologist',
    label: 'Oral and Maxillofacial Radiologist',
  },
  {
    value: 'Oral and Maxillofacial Pathologist',
    label: 'Oral and Maxillofacial Pathologist',
  },
  {
    value: 'Oral Medicine',
    label: 'Oral Medicine',
  },
  {
    value: 'Orofacial Pain',
    label: 'Orofacial Pain',
  },
  {
    value: 'Orthodontist',
    label: 'Orthodontist',
  },
  {
    value: 'Orthopaedic Sports Medicine',
    label: 'Orthopaedic Sports Medicine',
  },
  {
    value: 'Orthopaedic Surgery',
    label: 'Orthopaedic Surgery',
  },
  {
    value: 'Orthopaedic Surgery of the Spine',
    label: 'Orthopaedic Surgery of the Spine',
  },
  {
    value: 'Orthopaedic Trauma',
    label: 'Orthopaedic Trauma',
  },
  {
    value: 'Osteopathic Neuromusculoskeletal Medicine',
    label: 'Osteopathic Neuromusculoskeletal Medicine',
  },
  {
    value: 'Otolaryngology',
    label: 'Otolaryngology',
  },
  {
    value: 'Pain Management Pharmacy',
    label: 'Pain Management Pharmacy',
  },
  {
    value: 'Pain Medicine',
    label: 'Pain Medicine',
  },
  {
    value: 'Pathology',
    label: 'Pathology',
  },
  {
    value: 'Pediatric Anesthesiology',
    label: 'Pediatric Anesthesiology',
  },
  {
    value: 'Pediatric Cardiac Anesthesiology',
    label: 'Pediatric Cardiac Anesthesiology',
  },
  {
    value: 'Pediatric Cardiology',
    label: 'Pediatric Cardiology',
  },
  {
    value: 'Pediatric Critical Care Medicine',
    label: 'Pediatric Critical Care Medicine',
  },
  {
    value: 'Pediatric Dentist or Pedodontist',
    label: 'Pediatric Dentist or Pedodontist',
  },
  {
    value: 'Pediatric Dermatology',
    label: 'Pediatric Dermatology',
  },
  {
    value: 'Pediatric Emergency Medicine',
    label: 'Pediatric Emergency Medicine',
  },
  {
    value: 'Pediatric Endocrinology',
    label: 'Pediatric Endocrinology',
  },
  {
    value: 'Pediatric Gastroenterology',
    label: 'Pediatric Gastroenterology',
  },
  {
    value: 'Pediatric Hematology-Oncology',
    label: 'Pediatric Hematology-Oncology',
  },
  {
    value: 'Pediatric Hospital Medicine',
    label: 'Pediatric Hospital Medicine',
  },
  {
    value: 'Pediatric Infectious Disease',
    label: 'Pediatric Infectious Disease',
  },
  {
    value: 'Pediatric Nephrology',
    label: 'Pediatric Nephrology',
  },
  {
    value: 'Pediatric Ophthalmology',
    label: 'Pediatric Ophthalmology',
  },
  {
    value: 'Pediatric Orthopaedic Surgery',
    label: 'Pediatric Orthopaedic Surgery',
  },
  {
    value: 'Pediatric Pathology',
    label: 'Pediatric Pathology',
  },
  {
    value: 'Pediatric Pharmacy',
    label: 'Pediatric Pharmacy',
  },
  {
    value: 'Pediatric Pulmonology',
    label: 'Pediatric Pulmonology',
  },
  {
    value: 'Pediatric Radiology',
    label: 'Pediatric Radiology',
  },
  {
    value: 'Pediatric Rehabilitation Medicine',
    label: 'Pediatric Rehabilitation Medicine',
  },
  {
    value: 'Pediatric Rheumatology',
    label: 'Pediatric Rheumatology',
  },
  {
    value: 'Pediatric Sports Medicine',
    label: 'Pediatric Sports Medicine',
  },
  {
    value: 'Pediatric Surgery',
    label: 'Pediatric Surgery',
  },
  {
    value: 'Pediatric Transplant Hepatology',
    label: 'Pediatric Transplant Hepatology',
  },
  {
    value: 'Pediatric Urology',
    label: 'Pediatric Urology',
  },
  {
    value: 'Pediatrics',
    label: 'Pediatrics',
  },
  {
    value: 'Periodontist',
    label: 'Periodontist',
  },
  {
    value: 'Pharmacotherapy',
    label: 'Pharmacotherapy',
  },
  {
    value: 'Physical Medicine and Rehabilitation',
    label: 'Physical Medicine and Rehabilitation',
  },
  {
    value: 'Physician Assistant',
    label: 'Physician Assistant',
  },
  {
    value: 'Plastic Surgery',
    label: 'Plastic Surgery',
  },
  {
    value: 'Plastic Surgery within the Head and Neck',
    label: 'Plastic Surgery within the Head and Neck',
  },
  {
    value: 'Preventive Medicine',
    label: 'Preventive Medicine',
  },
  {
    value: 'Prosthodontist',
    label: 'Prosthodontist',
  },
  {
    value: 'Psychiatry',
    label: 'Psychiatry',
  },
  {
    value: 'Psychiatric Pharmacy',
    label: 'Psychiatric Pharmacy',
  },
  {
    value: 'Public Health and General Preventive Medicine',
    label: 'Public Health and General Preventive Medicine',
  },
  {
    value: 'Pulmonary Disease',
    label: 'Pulmonary Disease',
  },
  {
    value: 'Pulmonary Disease and Critical Care Medicine',
    label: 'Pulmonary Disease and Critical Care Medicine',
  },
  {
    value: 'Radiation Oncology',
    label: 'Radiation Oncology',
  },
  {
    value: 'Regional Anesthesiology and Acute Pain Medicine',
    label: 'Regional Anesthesiology and Acute Pain Medicine',
  },
  {
    value: 'Reproductive Endocrinology and Infertility',
    label: 'Reproductive Endocrinology and Infertility',
  },
  {
    value: 'Restorative Dentistry',
    label: 'Restorative Dentistry',
  },
  {
    value: 'Rheumatology',
    label: 'Rheumatology',
  },
  {
    value: 'Rhinology',
    label: 'Rhinology',
  },
  {
    value: 'Selective Pathology',
    label: 'Selective Pathology',
  },
  {
    value: 'Sleep Medicine',
    label: 'Sleep Medicine',
  },
  {
    value: 'Solid Organ Transplantation Pharmacy',
    label: 'Solid Organ Transplantation Pharmacy',
  },
  {
    value: 'Spinal Cord Injury Medicine',
    label: 'Spinal Cord Injury Medicine',
  },
  {
    value: 'Sports Medicine',
    label: 'Sports Medicine',
  },
  {
    value: 'Surgical Critical Care',
    label: 'Surgical Critical Care',
  },
  {
    value: 'Therapeutic Medical Physics',
    label: 'Therapeutic Medical Physics',
  },
  {
    value: 'Thoracic Surgery',
    label: 'Thoracic Surgery',
  },
  {
    value: 'Transplant Hepatology',
    label: 'Transplant Hepatology',
  },
  {
    value: 'Undersea and Hyperbaric Medicine',
    label: 'Undersea and Hyperbaric Medicine',
  },
  {
    value: 'Urology',
    label: 'Urology',
  },
  {
    value: 'Uveitis and Ocular Immunology',
    label: 'Uveitis and Ocular Immunology',
  },
  {
    value: 'Vascular Neurology',
    label: 'Vascular Neurology',
  },
  {
    value: 'Vascular Surgery',
    label: 'Vascular Surgery',
  },
];

export const websiteRegax = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
);

export const stagingUrl = 'https://staging.confemy.com';
export const prodUrl = 'https://confemy.com';
// 10 digit mobile no regex
export const regex10DigitNumber = /^[1-9][0-9]{9}$/;

// 1 to 999 number regex for countryCode
export const regex1To999 = /^([1-9]|[1-9][0-9]{1,2})$/;

// regex for number from 1000 to 999999999999999;
export const regex1000To15x9 =
  /^(100\d|10[1-9]\d|1[1-9]\d{2}|[2-9]\d{3}|[1-9]\d{4,14})$/;

// length 164
export const currencylist = [
  { label: 'Afghan Afghani', value: 'AFN' },
  { label: 'Albanian Lek', value: 'ALL' },
  { label: 'Algerian Dinar', value: 'DZD' },
  { label: 'Angolan Kwanza', value: 'AOA' },
  { label: 'Argentine Peso', value: 'ARS' },
  { label: 'Armenian Dram', value: 'AMD' },
  { label: 'Aruban Florin', value: 'AWG' },
  { label: 'Australian Dollar', value: 'AUD' },
  { label: 'Azerbaijani Manat', value: 'AZN' },
  { label: 'Bahamian Dollar', value: 'BSD' },
  { label: 'Bangladeshi Taka', value: 'BDT' },
  { label: 'Barbadian Dollar', value: 'BBD' },
  { label: 'Belize Dollar', value: 'BZD' },
  { label: 'Bermudian Dollar', value: 'BMD' },
  { label: 'Bolivian Boliviano', value: 'BOB' },
  { label: 'Bosnia & Herzegovina Convertible Mark', value: 'BAM' },
  { label: 'Botswana Pula', value: 'BWP' },
  { label: 'Brazilian Real', value: 'BRL' },
  { label: 'British Pound', value: 'GBP' },
  { label: 'Brunei Dollar', value: 'BND' },
  { label: 'Bulgarian Lev', value: 'BGN' },
  { label: 'Burundian Franc', value: 'BIF' },
  { label: 'Cambodian Riel', value: 'KHR' },
  { label: 'Canadian Dollar', value: 'CAD' },
  { label: 'Cape Verdean Escudo', value: 'CVE' },
  { label: 'Cayman Islands Dollar', value: 'KYD' },
  { label: 'Central African Cfa Franc', value: 'XAF' },
  { label: 'Cfp Franc', value: 'XPF' },
  { label: 'Chilean Peso', value: 'CLP' },
  { label: 'Chinese Renminbi Yuan', value: 'CNY' },
  { label: 'Colombian Peso', value: 'COP' },
  { label: 'Comorian Franc', value: 'KMF' },
  { label: 'Congolese Franc', value: 'CDF' },
  { label: 'Costa Rican Colón', value: 'CRC' },
  { label: 'Croatian Kuna', value: 'HRK' },
  { label: 'Czech Koruna', value: 'CZK' },
  { label: 'Danish Krone', value: 'DKK' },
  { label: 'Djiboutian Franc', value: 'DJF' },
  { label: 'Dominican Peso', value: 'DOP' },
  { label: 'East Caribbean Dollar', value: 'XCD' },
  { label: 'Egyptian Pound', value: 'EGP' },
  { label: 'Ethiopian Birr', value: 'ETB' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Falkland Islands Pound', value: 'FKP' },
  { label: 'Fijian Dollar', value: 'FJD' },
  { label: 'Gambian Dalasi', value: 'GMD' },
  { label: 'Georgian Lari', value: 'GEL' },
  { label: 'Gibraltar Pound', value: 'GIP' },
  { label: 'Guatemalan Quetzal', value: 'GTQ' },
  { label: 'Guinean Franc', value: 'GNF' },
  { label: 'Guyanese Dollar', value: 'GYD' },
  { label: 'Haitian Gourde', value: 'HTG' },
  { label: 'Honduran Lempira', value: 'HNL' },
  { label: 'Hong Kong Dollar', value: 'HKD' },
  { label: 'Hungarian Forint', value: 'HUF' },
  { label: 'Icelandic Króna', value: 'ISK' },
  { label: 'Indian Rupee', value: 'INR' },
  { label: 'Indonesian Rupiah', value: 'IDR' },
  { label: 'Israeli New Sheqel', value: 'ILS' },
  { label: 'Jamaican Dollar', value: 'JMD' },
  { label: 'Japanese Yen', value: 'JPY' },
  { label: 'Kazakhstani Tenge', value: 'KZT' },
  { label: 'Kenyan Shilling', value: 'KES' },
  { label: 'Kyrgyzstani Som', value: 'KGS' },
  { label: 'Lao Kip', value: 'LAK' },
  { label: 'Lebanese Pound', value: 'LBP' },
  { label: 'Lesotho Loti', value: 'LSL' },
  { label: 'Liberian Dollar', value: 'LRD' },
  { label: 'Macanese Pataca', value: 'MOP' },
  { label: 'Macedonian Denar', value: 'MKD' },
  { label: 'Malagasy Ariary', value: 'MGA' },
  { label: 'Malawian Kwacha', value: 'MWK' },
  { label: 'Malaysian Ringgit', value: 'MYR' },
  { label: 'Maldivian Rufiyaa', value: 'MVR' },
  { label: 'Mauritanian Ouguiya', value: 'MRO' },
  { label: 'Mauritian Rupee', value: 'MUR' },
  { label: 'Mexican Peso', value: 'MXN' },
  { label: 'Moldovan Leu', value: 'MDL' },
  { label: 'Mongolian Tögrög', value: 'MNT' },
  { label: 'Moroccan Dirham', value: 'MAD' },
  { label: 'Mozambican Metical', value: 'MZN' },
  { label: 'Myanmar Kyat', value: 'MMK' },
  { label: 'Namibian Dollar', value: 'NAD' },
  { label: 'Nepalese Rupee', value: 'NPR' },
  { label: 'Netherlands Antillean Gulden', value: 'ANG' },
  { label: 'New Taiwan Dollar', value: 'TWD' },
  { label: 'New Zealand Dollar', value: 'NZD' },
  { label: 'Nicaraguan Córdoba', value: 'NIO' },
  { label: 'Nigerian Naira', value: 'NGN' },
  { label: 'Norwegian Krone', value: 'NOK' },
  { label: 'Pakistani Rupee', value: 'PKR' },
  { label: 'Panamanian Balboa', value: 'PAB' },
  { label: 'Papua New Guinean Kina', value: 'PGK' },
  { label: 'Paraguayan Guaraní', value: 'PYG' },
  { label: 'Peruvian Nuevo Sol', value: 'PEN' },
  { label: 'Philippine Peso', value: 'PHP' },
  { label: 'Polish Złoty', value: 'PLN' },
  { label: 'Qatari Riyal', value: 'QAR' },
  { label: 'Romanian Leu', value: 'RON' },
  { label: 'Russian Ruble', value: 'RUB' },
  { label: 'Rwandan Franc', value: 'RWF' },
  { label: 'São Tomé and Príncipe Dobra', value: 'STD' },
  { label: 'Saint Helenian Pound', value: 'SHP' },
  { label: 'Salvadoran Colón', value: 'SVC' },
  { label: 'Samoan Tala', value: 'WST' },
  { label: 'Saudi Riyal', value: 'SAR' },
  { label: 'Serbian Dinar', value: 'RSD' },
  { label: 'Seychellois Rupee', value: 'SCR' },
  { label: 'Sierra Leonean Leone', value: 'SLL' },
  { label: 'Singapore Dollar', value: 'SGD' },
  { label: 'Solomon Islands Dollar', value: 'SBD' },
  { label: 'Somali Shilling', value: 'SOS' },
  { label: 'South African Rand', value: 'ZAR' },
  { label: 'South Korean Won', value: 'KRW' },
  { label: 'Sri Lankan Rupee', value: 'LKR' },
  { label: 'Surinamese Dollar', value: 'SRD' },
  { label: 'Swazi Lilangeni', value: 'SZL' },
  { label: 'Swedish Krona', value: 'SEK' },
  { label: 'Swiss Franc', value: 'CHF' },
  { label: 'Tajikistani Somoni', value: 'TJS' },
  { label: 'Tanzanian Shilling', value: 'TZS' },
  { label: 'Thai Baht', value: 'THB' },
  { label: 'Tongan Paʻanga', value: 'TOP' },
  { label: 'Trinidad and Tobago Dollar', value: 'TTD' },
  { label: 'Turkish Lira', value: 'TRY' },
  { label: 'Ugandan Shilling', value: 'UGX' },
  { label: 'Ukrainian Hryvnia', value: 'UAH' },
  { label: 'United Arab Emirates Dirham', value: 'AED' },
  { label: 'United States Dollar', value: 'USD' },
  { label: 'Uruguayan Peso', value: 'UYU' },
  { label: 'Uzbekistani Som', value: 'UZS' },
  { label: 'Vanuatu Vatu', value: 'VUV' },
  { label: 'Vietnamese Đồng', value: 'VND' },
  { label: 'West African Cfa Franc', value: 'XOF' },
  { label: 'Yemeni Rial', value: 'YER' },
  { label: 'Zambian Kwacha', value: 'ZMW' },
];

// [
//   { label: "Afghan Afghani", value: "AFA", symbol: "؋" },
//   { label: "Albanian Lek", value: "ALL", symbol: "Lek" },
//   { label: "Algerian Dinar", value: "DZD", symbol: "دج" },
//   { label: "Angolan Kwanza", value: "AOA", symbol: "Kz" },
//   { label: "Argentine Peso", value: "ARS", symbol: "$" },
//   { label: "Armenian Dram", value: "AMD", symbol: "֏" },
//   { label: "Aruban Florin", value: "AWG", symbol: "ƒ" },
//   { label: "Australian Dollar", value: "AUD", symbol: "$" },
//   { label: "Azerbaijani Manat", value: "AZN", symbol: "m" },
//   { label: "Bahamian Dollar", value: "BSD", symbol: "B$" },
//   { label: "Bahraini Dinar", value: "BHD", symbol: ".د.ب" },
//   { label: "Bangladeshi Taka", value: "BDT", symbol: "৳" },
//   { label: "Barbadian Dollar", value: "BBD", symbol: "Bds$" },
//   { label: "Belarusian Ruble", value: "BYR", symbol: "Br" },
//   { label: "Belgian Franc", value: "BEF", symbol: "fr" },
//   { label: "Belize Dollar", value: "BZD", symbol: "$" },
//   { label: "Bermudan Dollar", value: "BMD", symbol: "$" },
//   { label: "Bhutanese Ngultrum", value: "BTN", symbol: "Nu." },
//   { label: "Bitcoin", value: "BTC", symbol: "฿" },
//   { label: "Bolivian Boliviano", value: "BOB", symbol: "Bs." },
//   {
//     label: "Bosnia-Herzegovina Convertible Mark",
//     value: "BAM",
//     symbol: "KM",
//   },
//   { label: "Botswanan Pula", value: "BWP", symbol: "P" },
//   { label: "Brazilian Real", value: "BRL", symbol: "R$" },
//   { label: "British Pound Sterling", value: "GBP", symbol: "£" },
//   { label: "Brunei Dollar", value: "BND", symbol: "B$" },
//   { label: "Bulgarian Lev", value: "BGN", symbol: "Лв." },
//   { label: "Burundian Franc", value: "BIF", symbol: "FBu" },
//   { label: "Cambodian Riel", value: "KHR", symbol: "KHR" },
//   { label: "Canadian Dollar", value: "CAD", symbol: "$" },
//   { label: "Cape Verdean Escudo", value: "CVE", symbol: "$" },
//   { label: "Cayman Islands Dollar", value: "KYD", symbol: "$" },
//   { label: "CFA Franc BCEAO", value: "XOF", symbol: "CFA" },
//   { label: "CFA Franc BEAC", value: "XAF", symbol: "FCFA" },
//   { label: "CFP Franc", value: "XPF", symbol: "₣" },
//   { label: "Chilean Peso", value: "CLP", symbol: "$" },
//   { label: "Chinese Yuan", value: "CNY", symbol: "¥" },
//   { label: "Colombian Peso", value: "COP", symbol: "$" },
//   { label: "Comorian Franc", value: "KMF", symbol: "CF" },
//   { label: "Congolese Franc", value: "CDF", symbol: "FC" },
//   { label: "Costa Rican ColÃ³n", value: "CRC", symbol: "₡" },
//   { label: "Croatian Kuna", value: "HRK", symbol: "kn" },
//   { label: "Cuban Convertible Peso", value: "CUC", symbol: "$, CUC" },
//   { label: "Czech Republic Koruna", value: "CZK", symbol: "Kč" },
//   { label: "Danish Krone", value: "DKK", symbol: "Kr." },
//   { label: "Djiboutian Franc", value: "DJF", symbol: "Fdj" },
//   { label: "Dominican Peso", value: "DOP", symbol: "$" },
//   { label: "East Caribbean Dollar", value: "XCD", symbol: "$" },
//   { label: "Egyptian Pound", value: "EGP", symbol: "ج.م" },
//   { label: "Eritrean Nakfa", value: "ERN", symbol: "Nfk" },
//   { label: "Estonian Kroon", value: "EEK", symbol: "kr" },
//   { label: "Ethiopian Birr", value: "ETB", symbol: "Nkf" },
//   { label: "Euro", value: "EUR", symbol: "€" },
//   { label: "Falkland Islands Pound", value: "FKP", symbol: "£" },
//   { label: "Fijian Dollar", value: "FJD", symbol: "FJ$" },
//   { label: "Gambian Dalasi", value: "GMD", symbol: "D" },
//   { label: "Georgian Lari", value: "GEL", symbol: "ლ" },
//   { label: "German Mark", value: "DEM", symbol: "DM" },
//   { label: "Ghanaian Cedi", value: "GHS", symbol: "GH₵" },
//   { label: "Gibraltar Pound", value: "GIP", symbol: "£" },
//   { label: "Greek Drachma", value: "GRD", symbol: "₯, Δρχ, Δρ" },
//   { label: "Guatemalan Quetzal", value: "GTQ", symbol: "Q" },
//   { label: "Guinean Franc", value: "GNF", symbol: "FG" },
//   { label: "Guyanaese Dollar", value: "GYD", symbol: "$" },
//   { label: "Haitian Gourde", value: "HTG", symbol: "G" },
//   { label: "Honduran Lempira", value: "HNL", symbol: "L" },
//   { label: "Hong Kong Dollar", value: "HKD", symbol: "$" },
//   { label: "Hungarian Forint", value: "HUF", symbol: "Ft" },
//   { label: "Icelandic KrÃ³na", value: "ISK", symbol: "kr" },
//   { label: "Indian Rupee - INR (₹) ", value: "INR", symbol: "₹" },
//   { label: "Indonesian Rupiah", value: "IDR", symbol: "Rp" },
//   { label: "Iranian Rial", value: "IRR", symbol: "﷼" },
//   { label: "Iraqi Dinar", value: "IQD", symbol: "د.ع" },
//   { label: "Israeli New Sheqel", value: "ILS", symbol: "₪" },
//   { label: "Italian Lira", value: "ITL", symbol: "L,£" },
//   { label: "Jamaican Dollar", value: "JMD", symbol: "J$" },
//   { label: "Japanese Yen", value: "JPY", symbol: "¥" },
//   { label: "Jordanian Dinar", value: "JOD", symbol: "ا.د" },
//   { label: "Kazakhstani Tenge", value: "KZT", symbol: "лв" },
//   { label: "Kenyan Shilling", value: "KES", symbol: "KSh" },
//   { label: "Kuwaiti Dinar", value: "KWD", symbol: "ك.د" },
//   { label: "Kyrgystani Som", value: "KGS", symbol: "лв" },
//   { label: "Laotian Kip", value: "LAK", symbol: "₭" },
//   { label: "Latvian Lats", value: "LVL", symbol: "Ls" },
//   { label: "Lebanese Pound", value: "LBP", symbol: "£" },
//   { label: "Lesotho Loti", value: "LSL", symbol: "L" },
//   { label: "Liberian Dollar", value: "LRD", symbol: "$" },
//   { label: "Libyan Dinar", value: "LYD", symbol: "د.ل" },
//   { label: "Lithuanian Litas", value: "LTL", symbol: "Lt" },
//   { label: "Macanese Pataca", value: "MOP", symbol: "$" },
//   { label: "Macedonian Denar", value: "MKD", symbol: "ден" },
//   { label: "Malagasy Ariary", value: "MGA", symbol: "Ar" },
//   { label: "Malawian Kwacha", value: "MWK", symbol: "MK" },
//   { label: "Malaysian Ringgit", value: "MYR", symbol: "RM" },
//   { label: "Maldivian Rufiyaa", value: "MVR", symbol: "Rf" },
//   { label: "Mauritanian Ouguiya", value: "MRO", symbol: "MRU" },
//   { label: "Mauritian Rupee", value: "MUR", symbol: "₨" },
//   { label: "Mexican Peso", value: "MXN", symbol: "$" },
//   { label: "Moldovan Leu", value: "MDL", symbol: "L" },
//   { label: "Mongolian Tugrik", value: "MNT", symbol: "₮" },
//   { label: "Moroccan Dirham", value: "MAD", symbol: "MAD" },
//   { label: "Mozambican Metical", value: "MZM", symbol: "MT" },
//   { label: "Myanmar Kyat", value: "MMK", symbol: "K" },
//   { label: "Namibian Dollar", value: "NAD", symbol: "$" },
//   { label: "Nepalese Rupee", value: "NPR", symbol: "₨" },
//   {
//     label: "Netherlands Antillean Guilder",
//     value: "ANG",
//     symbol: "ƒ",
//   },
//   { label: "New Taiwan Dollar", value: "TWD", symbol: "$" },
//   { label: "New Zealand Dollar", value: "NZD", symbol: "$" },
//   { label: "Nicaraguan CÃ³rdoba", value: "NIO", symbol: "C$" },
//   { label: "Nigerian Naira", value: "NGN", symbol: "₦" },
//   { label: "North Korean Won", value: "KPW", symbol: "₩" },
//   { label: "Norwegian Krone", value: "NOK", symbol: "kr" },
//   { label: "Omani Rial", value: "OMR", symbol: ".ع.ر" },
//   { label: "Pakistani Rupee", value: "PKR", symbol: "₨" },
//   { label: "Panamanian Balboa", value: "PAB", symbol: "B/." },
//   { label: "Papua New Guinean Kina", value: "PGK", symbol: "K" },
//   { label: "Paraguayan Guarani", value: "PYG", symbol: "₲" },
//   { label: "Peruvian Nuevo Sol", value: "PEN", symbol: "S/." },
//   { label: "Philippine Peso", value: "PHP", symbol: "₱" },
//   { label: "Polish Zloty", value: "PLN", symbol: "zł" },
//   { label: "Qatari Rial", value: "QAR", symbol: "ق.ر" },
//   { label: "Romanian Leu", value: "RON", symbol: "lei" },
//   { label: "Russian Ruble", value: "RUB", symbol: "₽" },
//   { label: "Rwandan Franc", value: "RWF", symbol: "FRw" },
//   { label: "Salvadoran ColÃ³n", value: "SVC", symbol: "₡" },
//   { label: "Samoan Tala", value: "WST", symbol: "SAT" },
//   { label: "Saudi Riyal", value: "SAR", symbol: "﷼" },
//   { label: "Serbian Dinar", value: "RSD", symbol: "din" },
//   { label: "Seychellois Rupee", value: "SCR", symbol: "SRe" },
//   { label: "Sierra Leonean Leone", value: "SLL", symbol: "Le" },
//   { label: "Singapore Dollar", value: "SGD", symbol: "$" },
//   { label: "Slovak Koruna", value: "SKK", symbol: "Sk" },
//   { label: "Solomon Islands Dollar", value: "SBD", symbol: "Si$" },
//   { label: "Somali Shilling", value: "SOS", symbol: "Sh.so." },
//   { label: "South African Rand", value: "ZAR", symbol: "R" },
//   { label: "South Korean Won", value: "KRW", symbol: "₩" },
//   { label: "Special Drawing Rights", value: "XDR", symbol: "SDR" },
//   { label: "Sri Lankan Rupee", value: "LKR", symbol: "Rs" },
//   { label: "St. Helena Pound", value: "SHP", symbol: "£" },
//   { label: "Sudanese Pound", value: "SDG", symbol: ".س.ج" },
//   { label: "Surinamese Dollar", value: "SRD", symbol: "$" },
//   { label: "Swazi Lilangeni", value: "SZL", symbol: "E" },
//   { label: "Swedish Krona", value: "SEK", symbol: "kr" },
//   { label: "Swiss Franc", value: "CHF", symbol: "CHf" },
//   { label: "Syrian Pound", value: "SYP", symbol: "LS" },
//   { label: "São Tomé and Príncipe Dobra", value: "STD", symbol: "Db" },
//   { label: "Tajikistani Somoni", value: "TJS", symbol: "SM" },
//   { label: "Tanzanian Shilling", value: "TZS", symbol: "TSh" },
//   { label: "Thai Baht", value: "THB", symbol: "฿" },
//   { label: "Tongan Pa'anga", value: "TOP", symbol: "$" },
//   { label: "Trinidad & Tobago Dollar", value: "TTD", symbol: "$" },
//   { label: "Tunisian Dinar", value: "TND", symbol: "ت.د" },
//   { label: "Turkish Lira", value: "TRY", symbol: "₺" },
//   { label: "Turkmenistani Manat", value: "TMT", symbol: "T" },
//   { label: "Ugandan Shilling", value: "UGX", symbol: "USh" },
//   { label: "Ukrainian Hryvnia", value: "UAH", symbol: "₴" },
//   {
//     label: "United Arab Emirates Dirham",
//     value: "AED",
//     symbol: "إ.د",
//   },
//   { label: "Uruguayan Peso", value: "UYU", symbol: "$" },
//   { label: "US Dollar", value: "USD", symbol: "$" },
//   { label: "Uzbekistan Som", value: "UZS", symbol: "лв" },
//   { label: "Vanuatu Vatu", value: "VUV", symbol: "VT" },
//   { label: "Venezuelan BolÃvar", value: "VEF", symbol: "Bs" },
//   { label: "Vietnamese Dong", value: "VND", symbol: "₫" },
//   { label: "Yemeni Rial", value: "YER", symbol: "﷼" },
//   { label: "Zambian Kwacha", value: "ZMK", symbol: "ZK" },
// ];

export const timezones = [
  { value: 'Asia/Kolkata', label: 'India/Asia/Mumbai/Delhi/Kolkata' },
  { value: 'US/Alaska', label: 'US/Alaska' },
  { value: 'US/Aleutian', label: 'US/Aleutian' },
  { value: 'US/Arizona', label: 'US/Arizona' },
  { value: 'US/Central', label: 'US/Central' },
  { value: 'US/East-Indiana', label: 'US/East-Indiana' },
  { value: 'US/Eastern', label: 'US/Eastern' },
  { value: 'US/Hawaii', label: 'US/Hawaii' },
  { value: 'US/Indiana-Starke', label: 'US/Indiana-Starke' },
  { value: 'US/Michigan', label: 'US/Michigan' },
  { value: 'US/Mountain', label: 'US/Mountain' },
  { value: 'US/Pacific', label: 'US/Pacific' },
  { value: 'US/Pacific-New', label: 'US/Pacific-New' },
  { value: 'US/Samoa', label: 'US/Samoa' },
  { value: 'Africa/Abidjan', label: 'Africa/Abidjan' },
  { value: 'Africa/Accra', label: 'Africa/Accra' },
  { value: 'Africa/Addis_Ababa', label: 'Africa/Addis_Ababa' },
  { value: 'Africa/Algiers', label: 'Africa/Algiers' },
  { value: 'Africa/Asmara', label: 'Africa/Asmara' },
  { value: 'Africa/Asmera', label: 'Africa/Asmera' },
  { value: 'Africa/Bamako', label: 'Africa/Bamako' },
  { value: 'Africa/Bangui', label: 'Africa/Bangui' },
  { value: 'Africa/Banjul', label: 'Africa/Banjul' },
  { value: 'Africa/Bissau', label: 'Africa/Bissau' },
  { value: 'Africa/Blantyre', label: 'Africa/Blantyre' },
  { value: 'Africa/Brazzaville', label: 'Africa/Brazzaville' },
  { value: 'Africa/Bujumbura', label: 'Africa/Bujumbura' },
  { value: 'Africa/Cairo', label: 'Africa/Cairo' },
  { value: 'Africa/Casablanca', label: 'Africa/Casablanca' },
  { value: 'Africa/Ceuta', label: 'Africa/Ceuta' },
  { value: 'Africa/Conakry', label: 'Africa/Conakry' },
  { value: 'Africa/Dakar', label: 'Africa/Dakar' },
  { value: 'Africa/Dar_es_Salaam', label: 'Africa/Dar_es_Salaam' },
  { value: 'Africa/Djibouti', label: 'Africa/Djibouti' },
  { value: 'Africa/Douala', label: 'Africa/Douala' },
  { value: 'Africa/El_Aaiun', label: 'Africa/El_Aaiun' },
  { value: 'Africa/Freetown', label: 'Africa/Freetown' },
  { value: 'Africa/Gaborone', label: 'Africa/Gaborone' },
  { value: 'Africa/Harare', label: 'Africa/Harare' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg' },
  { value: 'Africa/Juba', label: 'Africa/Juba' },
  { value: 'Africa/Kampala', label: 'Africa/Kampala' },
  { value: 'Africa/Khartoum', label: 'Africa/Khartoum' },
  { value: 'Africa/Kigali', label: 'Africa/Kigali' },
  { value: 'Africa/Kinshasa', label: 'Africa/Kinshasa' },
  { value: 'Africa/Lagos', label: 'Africa/Lagos' },
  { value: 'Africa/Libreville', label: 'Africa/Libreville' },
  { value: 'Africa/Lome', label: 'Africa/Lome' },
  { value: 'Africa/Luanda', label: 'Africa/Luanda' },
  { value: 'Africa/Lubumbashi', label: 'Africa/Lubumbashi' },
  { value: 'Africa/Lusaka', label: 'Africa/Lusaka' },
  { value: 'Africa/Malabo', label: 'Africa/Malabo' },
  { value: 'Africa/Maputo', label: 'Africa/Maputo' },
  { value: 'Africa/Maseru', label: 'Africa/Maseru' },
  { value: 'Africa/Mbabane', label: 'Africa/Mbabane' },
  { value: 'Africa/Mogadishu', label: 'Africa/Mogadishu' },
  { value: 'Africa/Monrovia', label: 'Africa/Monrovia' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi' },
  { value: 'Africa/Ndjamena', label: 'Africa/Ndjamena' },
  { value: 'Africa/Niamey', label: 'Africa/Niamey' },
  { value: 'Africa/Nouakchott', label: 'Africa/Nouakchott' },
  { value: 'Africa/Ouagadougou', label: 'Africa/Ouagadougou' },
  { value: 'Africa/Porto-Novo', label: 'Africa/Porto-Novo' },
  { value: 'Africa/Sao_Tome', label: 'Africa/Sao_Tome' },
  { value: 'Africa/Timbuktu', label: 'Africa/Timbuktu' },
  { value: 'Africa/Tripoli', label: 'Africa/Tripoli' },
  { value: 'Africa/Tunis', label: 'Africa/Tunis' },
  { value: 'Africa/Windhoek', label: 'Africa/Windhoek' },
  { value: 'America/Adak', label: 'America/Adak' },
  { value: 'America/Anchorage', label: 'America/Anchorage' },
  { value: 'America/Anguilla', label: 'America/Anguilla' },
  { value: 'America/Antigua', label: 'America/Antigua' },
  { value: 'America/Araguaina', label: 'America/Araguaina' },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'America/Argentina/Buenos_Aires',
  },
  {
    value: 'America/Argentina/Catamarca',
    label: 'America/Argentina/Catamarca',
  },
  {
    value: 'America/Argentina/ComodRivadavia',
    label: 'America/Argentina/ComodRivadavia',
  },
  { value: 'America/Argentina/Cordoba', label: 'America/Argentina/Cordoba' },
  { value: 'America/Argentina/Jujuy', label: 'America/Argentina/Jujuy' },
  { value: 'America/Argentina/La_Rioja', label: 'America/Argentina/La_Rioja' },
  { value: 'America/Argentina/Mendoza', label: 'America/Argentina/Mendoza' },
  {
    value: 'America/Argentina/Rio_Gallegos',
    label: 'America/Argentina/Rio_Gallegos',
  },
  { value: 'America/Argentina/Salta', label: 'America/Argentina/Salta' },
  { value: 'America/Argentina/San_Juan', label: 'America/Argentina/San_Juan' },
  { value: 'America/Argentina/San_Luis', label: 'America/Argentina/San_Luis' },
  { value: 'America/Argentina/Tucuman', label: 'America/Argentina/Tucuman' },
  { value: 'America/Argentina/Ushuaia', label: 'America/Argentina/Ushuaia' },
  { value: 'America/Aruba', label: 'America/Aruba' },
  { value: 'America/Asuncion', label: 'America/Asuncion' },
  { value: 'America/Atikokan', label: 'America/Atikokan' },
  { value: 'America/Atka', label: 'America/Atka' },
  { value: 'America/Bahia', label: 'America/Bahia' },
  { value: 'America/Bahia_Banderas', label: 'America/Bahia_Banderas' },
  { value: 'America/Barbados', label: 'America/Barbados' },
  { value: 'America/Belem', label: 'America/Belem' },
  { value: 'America/Belize', label: 'America/Belize' },
  { value: 'America/Blanc-Sablon', label: 'America/Blanc-Sablon' },
  { value: 'America/Boa_Vista', label: 'America/Boa_Vista' },
  { value: 'America/Bogota', label: 'America/Bogota' },
  { value: 'America/Boise', label: 'America/Boise' },
  { value: 'America/Buenos_Aires', label: 'America/Buenos_Aires' },
  { value: 'America/Cambridge_Bay', label: 'America/Cambridge_Bay' },
  { value: 'America/Campo_Grande', label: 'America/Campo_Grande' },
  { value: 'America/Cancun', label: 'America/Cancun' },
  { value: 'America/Caracas', label: 'America/Caracas' },
  { value: 'America/Catamarca', label: 'America/Catamarca' },
  { value: 'America/Cayenne', label: 'America/Cayenne' },
  { value: 'America/Cayman', label: 'America/Cayman' },
  { value: 'America/Chicago', label: 'America/Chicago' },
  { value: 'America/Chihuahua', label: 'America/Chihuahua' },
  { value: 'America/Coral_Harbour', label: 'America/Coral_Harbour' },
  { value: 'America/Cordoba', label: 'America/Cordoba' },
  { value: 'America/Costa_Rica', label: 'America/Costa_Rica' },
  { value: 'America/Creston', label: 'America/Creston' },
  { value: 'America/Cuiaba', label: 'America/Cuiaba' },
  { value: 'America/Curacao', label: 'America/Curacao' },
  { value: 'America/Danmarkshavn', label: 'America/Danmarkshavn' },
  { value: 'America/Dawson', label: 'America/Dawson' },
  { value: 'America/Dawson_Creek', label: 'America/Dawson_Creek' },
  { value: 'America/Denver', label: 'America/Denver' },
  { value: 'America/Detroit', label: 'America/Detroit' },
  { value: 'America/Dominica', label: 'America/Dominica' },
  { value: 'America/Edmonton', label: 'America/Edmonton' },
  { value: 'America/Eirunepe', label: 'America/Eirunepe' },
  { value: 'America/El_Salvador', label: 'America/El_Salvador' },
  { value: 'America/Ensenada', label: 'America/Ensenada' },
  { value: 'America/Fort_Nelson', label: 'America/Fort_Nelson' },
  { value: 'America/Fort_Wayne', label: 'America/Fort_Wayne' },
  { value: 'America/Fortaleza', label: 'America/Fortaleza' },
  { value: 'America/Glace_Bay', label: 'America/Glace_Bay' },
  { value: 'America/Godthab', label: 'America/Godthab' },
  { value: 'America/Goose_Bay', label: 'America/Goose_Bay' },
  { value: 'America/Grand_Turk', label: 'America/Grand_Turk' },
  { value: 'America/Grenada', label: 'America/Grenada' },
  { value: 'America/Guadeloupe', label: 'America/Guadeloupe' },
  { value: 'America/Guatemala', label: 'America/Guatemala' },
  { value: 'America/Guayaquil', label: 'America/Guayaquil' },
  { value: 'America/Guyana', label: 'America/Guyana' },
  { value: 'America/Halifax', label: 'America/Halifax' },
  { value: 'America/Havana', label: 'America/Havana' },
  { value: 'America/Hermosillo', label: 'America/Hermosillo' },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'America/Indiana/Indianapolis',
  },
  { value: 'America/Indiana/Knox', label: 'America/Indiana/Knox' },
  { value: 'America/Indiana/Marengo', label: 'America/Indiana/Marengo' },
  { value: 'America/Indiana/Petersburg', label: 'America/Indiana/Petersburg' },
  { value: 'America/Indiana/Tell_City', label: 'America/Indiana/Tell_City' },
  { value: 'America/Indiana/Vevay', label: 'America/Indiana/Vevay' },
  { value: 'America/Indiana/Vincennes', label: 'America/Indiana/Vincennes' },
  { value: 'America/Indiana/Winamac', label: 'America/Indiana/Winamac' },
  { value: 'America/Indianapolis', label: 'America/Indianapolis' },
  { value: 'America/Inuvik', label: 'America/Inuvik' },
  { value: 'America/Iqaluit', label: 'America/Iqaluit' },
  { value: 'America/Jamaica', label: 'America/Jamaica' },
  { value: 'America/Jujuy', label: 'America/Jujuy' },
  { value: 'America/Juneau', label: 'America/Juneau' },
  {
    value: 'America/Kentucky/Louisville',
    label: 'America/Kentucky/Louisville',
  },
  {
    value: 'America/Kentucky/Monticello',
    label: 'America/Kentucky/Monticello',
  },
  { value: 'America/Knox_IN', label: 'America/Knox_IN' },
  { value: 'America/Kralendijk', label: 'America/Kralendijk' },
  { value: 'America/La_Paz', label: 'America/La_Paz' },
  { value: 'America/Lima', label: 'America/Lima' },
  { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
  { value: 'America/Louisville', label: 'America/Louisville' },
  { value: 'America/Lower_Princes', label: 'America/Lower_Princes' },
  { value: 'America/Maceio', label: 'America/Maceio' },
  { value: 'America/Managua', label: 'America/Managua' },
  { value: 'America/Manaus', label: 'America/Manaus' },
  { value: 'America/Marigot', label: 'America/Marigot' },
  { value: 'America/Martinique', label: 'America/Martinique' },
  { value: 'America/Matamoros', label: 'America/Matamoros' },
  { value: 'America/Mazatlan', label: 'America/Mazatlan' },
  { value: 'America/Mendoza', label: 'America/Mendoza' },
  { value: 'America/Menominee', label: 'America/Menominee' },
  { value: 'America/Merida', label: 'America/Merida' },
  { value: 'America/Metlakatla', label: 'America/Metlakatla' },
  { value: 'America/Mexico_City', label: 'America/Mexico_City' },
  { value: 'America/Miquelon', label: 'America/Miquelon' },
  { value: 'America/Moncton', label: 'America/Moncton' },
  { value: 'America/Monterrey', label: 'America/Monterrey' },
  { value: 'America/Montevideo', label: 'America/Montevideo' },
  { value: 'America/Montreal', label: 'America/Montreal' },
  { value: 'America/Montserrat', label: 'America/Montserrat' },
  { value: 'America/Nassau', label: 'America/Nassau' },
  { value: 'America/New_York', label: 'America/New_York' },
  { value: 'America/Nipigon', label: 'America/Nipigon' },
  { value: 'America/Nome', label: 'America/Nome' },
  { value: 'America/Noronha', label: 'America/Noronha' },
  {
    value: 'America/North_Dakota/Beulah',
    label: 'America/North_Dakota/Beulah',
  },
  {
    value: 'America/North_Dakota/Center',
    label: 'America/North_Dakota/Center',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    label: 'America/North_Dakota/New_Salem',
  },
  { value: 'America/Ojinaga', label: 'America/Ojinaga' },
  { value: 'America/Panama', label: 'America/Panama' },
  { value: 'America/Pangnirtung', label: 'America/Pangnirtung' },
  { value: 'America/Paramaribo', label: 'America/Paramaribo' },
  { value: 'America/Phoenix', label: 'America/Phoenix' },
  { value: 'America/Port-au-Prince', label: 'America/Port-au-Prince' },
  { value: 'America/Port_of_Spain', label: 'America/Port_of_Spain' },
  { value: 'America/Porto_Acre', label: 'America/Porto_Acre' },
  { value: 'America/Porto_Velho', label: 'America/Porto_Velho' },
  { value: 'America/Puerto_Rico', label: 'America/Puerto_Rico' },
  { value: 'America/Punta_Arenas', label: 'America/Punta_Arenas' },
  { value: 'America/Rainy_River', label: 'America/Rainy_River' },
  { value: 'America/Rankin_Inlet', label: 'America/Rankin_Inlet' },
  { value: 'America/Recife', label: 'America/Recife' },
  { value: 'America/Regina', label: 'America/Regina' },
  { value: 'America/Resolute', label: 'America/Resolute' },
  { value: 'America/Rio_Branco', label: 'America/Rio_Branco' },
  { value: 'America/Rosario', label: 'America/Rosario' },
  { value: 'America/Santa_Isabel', label: 'America/Santa_Isabel' },
  { value: 'America/Santarem', label: 'America/Santarem' },
  { value: 'America/Santiago', label: 'America/Santiago' },
  { value: 'America/Santo_Domingo', label: 'America/Santo_Domingo' },
  { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo' },
  { value: 'America/Scoresbysund', label: 'America/Scoresbysund' },
  { value: 'America/Shiprock', label: 'America/Shiprock' },
  { value: 'America/Sitka', label: 'America/Sitka' },
  { value: 'America/St_Barthelemy', label: 'America/St_Barthelemy' },
  { value: 'America/St_Johns', label: 'America/St_Johns' },
  { value: 'America/St_Kitts', label: 'America/St_Kitts' },
  { value: 'America/St_Lucia', label: 'America/St_Lucia' },
  { value: 'America/St_Thomas', label: 'America/St_Thomas' },
  { value: 'America/St_Vincent', label: 'America/St_Vincent' },
  { value: 'America/Swift_Current', label: 'America/Swift_Current' },
  { value: 'America/Tegucigalpa', label: 'America/Tegucigalpa' },
  { value: 'America/Thule', label: 'America/Thule' },
  { value: 'America/Thunder_Bay', label: 'America/Thunder_Bay' },
  { value: 'America/Tijuana', label: 'America/Tijuana' },
  { value: 'America/Toronto', label: 'America/Toronto' },
  { value: 'America/Tortola', label: 'America/Tortola' },
  { value: 'America/Vancouver', label: 'America/Vancouver' },
  { value: 'America/Virgin', label: 'America/Virgin' },
  { value: 'America/Whitehorse', label: 'America/Whitehorse' },
  { value: 'America/Winnipeg', label: 'America/Winnipeg' },
  { value: 'America/Yakutat', label: 'America/Yakutat' },
  { value: 'America/Yellowknife', label: 'America/Yellowknife' },
  { value: 'Antarctica/Casey', label: 'Antarctica/Casey' },
  { value: 'Antarctica/Davis', label: 'Antarctica/Davis' },
  { value: 'Antarctica/DumontDUrville', label: 'Antarctica/DumontDUrville' },
  { value: 'Antarctica/Macquarie', label: 'Antarctica/Macquarie' },
  { value: 'Antarctica/Mawson', label: 'Antarctica/Mawson' },
  { value: 'Antarctica/McMurdo', label: 'Antarctica/McMurdo' },
  { value: 'Antarctica/Palmer', label: 'Antarctica/Palmer' },
  { value: 'Antarctica/Rothera', label: 'Antarctica/Rothera' },
  { value: 'Antarctica/South_Pole', label: 'Antarctica/South_Pole' },
  { value: 'Antarctica/Syowa', label: 'Antarctica/Syowa' },
  { value: 'Antarctica/Troll', label: 'Antarctica/Troll' },
  { value: 'Antarctica/Vostok', label: 'Antarctica/Vostok' },
  { value: 'Arctic/Longyearbyen', label: 'Arctic/Longyearbyen' },
  { value: 'Asia/Aden', label: 'Asia/Aden' },
  { value: 'Asia/Almaty', label: 'Asia/Almaty' },
  { value: 'Asia/Amman', label: 'Asia/Amman' },
  { value: 'Asia/Anadyr', label: 'Asia/Anadyr' },
  { value: 'Asia/Aqtau', label: 'Asia/Aqtau' },
  { value: 'Asia/Aqtobe', label: 'Asia/Aqtobe' },
  { value: 'Asia/Ashgabat', label: 'Asia/Ashgabat' },
  { value: 'Asia/Ashkhabad', label: 'Asia/Ashkhabad' },
  { value: 'Asia/Atyrau', label: 'Asia/Atyrau' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad' },
  { value: 'Asia/Bahrain', label: 'Asia/Bahrain' },
  { value: 'Asia/Baku', label: 'Asia/Baku' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok' },
  { value: 'Asia/Barnaul', label: 'Asia/Barnaul' },
  { value: 'Asia/Beirut', label: 'Asia/Beirut' },
  { value: 'Asia/Bishkek', label: 'Asia/Bishkek' },
  { value: 'Asia/Brunei', label: 'Asia/Brunei' },
  { value: 'Asia/Calcutta', label: 'Asia/Calcutta' },
  { value: 'Asia/Chita', label: 'Asia/Chita' },
  { value: 'Asia/Choibalsan', label: 'Asia/Choibalsan' },
  { value: 'Asia/Chongqing', label: 'Asia/Chongqing' },
  { value: 'Asia/Chungking', label: 'Asia/Chungking' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo' },
  { value: 'Asia/Dacca', label: 'Asia/Dacca' },
  { value: 'Asia/Damascus', label: 'Asia/Damascus' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka' },
  { value: 'Asia/Dili', label: 'Asia/Dili' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai' },
  { value: 'Asia/Dushanbe', label: 'Asia/Dushanbe' },
  { value: 'Asia/Famagusta', label: 'Asia/Famagusta' },
  { value: 'Asia/Gaza', label: 'Asia/Gaza' },
  { value: 'Asia/Harbin', label: 'Asia/Harbin' },
  { value: 'Asia/Hebron', label: 'Asia/Hebron' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho_Chi_Minh' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong_Kong' },
  { value: 'Asia/Hovd', label: 'Asia/Hovd' },
  { value: 'Asia/Irkutsk', label: 'Asia/Irkutsk' },
  { value: 'Asia/Istanbul', label: 'Asia/Istanbul' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta' },
  { value: 'Asia/Jayapura', label: 'Asia/Jayapura' },
  { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem' },
  { value: 'Asia/Kabul', label: 'Asia/Kabul' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi' },
  { value: 'Asia/Kashgar', label: 'Asia/Kashgar' },
  { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu' },
  { value: 'Asia/Katmandu', label: 'Asia/Katmandu' },
  { value: 'Asia/Khandyga', label: 'Asia/Khandyga' },
  { value: 'Asia/Krasnoyarsk', label: 'Asia/Krasnoyarsk' },
  { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala_Lumpur' },
  { value: 'Asia/Kuching', label: 'Asia/Kuching' },
  { value: 'Asia/Kuwait', label: 'Asia/Kuwait' },
  { value: 'Asia/Macao', label: 'Asia/Macao' },
  { value: 'Asia/Macau', label: 'Asia/Macau' },
  { value: 'Asia/Magadan', label: 'Asia/Magadan' },
  { value: 'Asia/Makassar', label: 'Asia/Makassar' },
  { value: 'Asia/Manila', label: 'Asia/Manila' },
  { value: 'Asia/Muscat', label: 'Asia/Muscat' },
  { value: 'Asia/Nicosia', label: 'Asia/Nicosia' },
  { value: 'Asia/Novokuznetsk', label: 'Asia/Novokuznetsk' },
  { value: 'Asia/Novosibirsk', label: 'Asia/Novosibirsk' },
  { value: 'Asia/Omsk', label: 'Asia/Omsk' },
  { value: 'Asia/Oral', label: 'Asia/Oral' },
  { value: 'Asia/Phnom_Penh', label: 'Asia/Phnom_Penh' },
  { value: 'Asia/Pontianak', label: 'Asia/Pontianak' },
  { value: 'Asia/Pyongyang', label: 'Asia/Pyongyang' },
  { value: 'Asia/Qatar', label: 'Asia/Qatar' },
  { value: 'Asia/Qyzylorda', label: 'Asia/Qyzylorda' },
  { value: 'Asia/Rangoon', label: 'Asia/Rangoon' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh' },
  { value: 'Asia/Saigon', label: 'Asia/Saigon' },
  { value: 'Asia/Sakhalin', label: 'Asia/Sakhalin' },
  { value: 'Asia/Samarkand', label: 'Asia/Samarkand' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore' },
  { value: 'Asia/Srednekolymsk', label: 'Asia/Srednekolymsk' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei' },
  { value: 'Asia/Tashkent', label: 'Asia/Tashkent' },
  { value: 'Asia/Tbilisi', label: 'Asia/Tbilisi' },
  { value: 'Asia/Tehran', label: 'Asia/Tehran' },
  { value: 'Asia/Tel_Aviv', label: 'Asia/Tel_Aviv' },
  { value: 'Asia/Thimbu', label: 'Asia/Thimbu' },
  { value: 'Asia/Thimphu', label: 'Asia/Thimphu' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo' },
  { value: 'Asia/Tomsk', label: 'Asia/Tomsk' },
  { value: 'Asia/Ujung_Pandang', label: 'Asia/Ujung_Pandang' },
  { value: 'Asia/Ulaanbaatar', label: 'Asia/Ulaanbaatar' },
  { value: 'Asia/Ulan_Bator', label: 'Asia/Ulan_Bator' },
  { value: 'Asia/Urumqi', label: 'Asia/Urumqi' },
  { value: 'Asia/Ust-Nera', label: 'Asia/Ust-Nera' },
  { value: 'Asia/Vientiane', label: 'Asia/Vientiane' },
  { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok' },
  { value: 'Asia/Yakutsk', label: 'Asia/Yakutsk' },
  { value: 'Asia/Yangon', label: 'Asia/Yangon' },
  { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg' },
  { value: 'Asia/Yerevan', label: 'Asia/Yerevan' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores' },
  { value: 'Atlantic/Bermuda', label: 'Atlantic/Bermuda' },
  { value: 'Atlantic/Canary', label: 'Atlantic/Canary' },
  { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape_Verde' },
  { value: 'Atlantic/Faeroe', label: 'Atlantic/Faeroe' },
  { value: 'Atlantic/Faroe', label: 'Atlantic/Faroe' },
  { value: 'Atlantic/Jan_Mayen', label: 'Atlantic/Jan_Mayen' },
  { value: 'Atlantic/Madeira', label: 'Atlantic/Madeira' },
  { value: 'Atlantic/Reykjavik', label: 'Atlantic/Reykjavik' },
  { value: 'Atlantic/South_Georgia', label: 'Atlantic/South_Georgia' },
  { value: 'Atlantic/St_Helena', label: 'Atlantic/St_Helena' },
  { value: 'Atlantic/Stanley', label: 'Atlantic/Stanley' },
  { value: 'Australia/ACT', label: 'Australia/ACT' },
  { value: 'Australia/Adelaide', label: 'Australia/Adelaide' },
  { value: 'Australia/Brisbane', label: 'Australia/Brisbane' },
  { value: 'Australia/Broken_Hill', label: 'Australia/Broken_Hill' },
  { value: 'Australia/Canberra', label: 'Australia/Canberra' },
  { value: 'Australia/Currie', label: 'Australia/Currie' },
  { value: 'Australia/Darwin', label: 'Australia/Darwin' },
  { value: 'Australia/Eucla', label: 'Australia/Eucla' },
  { value: 'Australia/Hobart', label: 'Australia/Hobart' },
  { value: 'Australia/LHI', label: 'Australia/LHI' },
  { value: 'Australia/Lindeman', label: 'Australia/Lindeman' },
  { value: 'Australia/Lord_Howe', label: 'Australia/Lord_Howe' },
  { value: 'Australia/Melbourne', label: 'Australia/Melbourne' },
  { value: 'Australia/NSW', label: 'Australia/NSW' },
  { value: 'Australia/North', label: 'Australia/North' },
  { value: 'Australia/Perth', label: 'Australia/Perth' },
  { value: 'Australia/Queensland', label: 'Australia/Queensland' },
  { value: 'Australia/South', label: 'Australia/South' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney' },
  { value: 'Australia/Tasmania', label: 'Australia/Tasmania' },
  { value: 'Australia/Victoria', label: 'Australia/Victoria' },
  { value: 'Australia/West', label: 'Australia/West' },
  { value: 'Australia/Yancowinna', label: 'Australia/Yancowinna' },
  { value: 'Brazil/Acre', label: 'Brazil/Acre' },
  { value: 'Brazil/DeNoronha', label: 'Brazil/DeNoronha' },
  { value: 'Brazil/East', label: 'Brazil/East' },
  { value: 'Brazil/West', label: 'Brazil/West' },
  { value: 'CET', label: 'CET' },
  { value: 'CST6CDT', label: 'CST6CDT' },
  { value: 'Canada/Atlantic', label: 'Canada/Atlantic' },
  { value: 'Canada/Central', label: 'Canada/Central' },
  { value: 'Canada/Eastern', label: 'Canada/Eastern' },
  { value: 'Canada/Mountain', label: 'Canada/Mountain' },
  { value: 'Canada/Newfoundland', label: 'Canada/Newfoundland' },
  { value: 'Canada/Pacific', label: 'Canada/Pacific' },
  { value: 'Canada/Saskatchewan', label: 'Canada/Saskatchewan' },
  { value: 'Canada/Yukon', label: 'Canada/Yukon' },
  { value: 'Chile/Continental', label: 'Chile/Continental' },
  { value: 'Chile/EasterIsland', label: 'Chile/EasterIsland' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'EET', label: 'EET' },
  { value: 'EST', label: 'EST' },
  { value: 'EST5EDT', label: 'EST5EDT' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'Eire', label: 'Eire' },
  { value: 'Etc/GMT', label: 'Etc/GMT' },
  { value: 'Etc/GMT+0', label: 'Etc/GMT+0' },
  { value: 'Etc/GMT+1', label: 'Etc/GMT+1' },
  { value: 'Etc/GMT+10', label: 'Etc/GMT+10' },
  { value: 'Etc/GMT+11', label: 'Etc/GMT+11' },
  { value: 'Etc/GMT+12', label: 'Etc/GMT+12' },
  { value: 'Etc/GMT+2', label: 'Etc/GMT+2' },
  { value: 'Etc/GMT+3', label: 'Etc/GMT+3' },
  { value: 'Etc/GMT+4', label: 'Etc/GMT+4' },
  { value: 'Etc/GMT+5', label: 'Etc/GMT+5' },
  { value: 'Etc/GMT+6', label: 'Etc/GMT+6' },
  { value: 'Etc/GMT+7', label: 'Etc/GMT+7' },
  { value: 'Etc/GMT+8', label: 'Etc/GMT+8' },
  { value: 'Etc/GMT+9', label: 'Etc/GMT+9' },
  { value: 'Etc/GMT-0', label: 'Etc/GMT-0' },
  { value: 'Etc/GMT-1', label: 'Etc/GMT-1' },
  { value: 'Etc/GMT-10', label: 'Etc/GMT-10' },
  { value: 'Etc/GMT-11', label: 'Etc/GMT-11' },
  { value: 'Etc/GMT-12', label: 'Etc/GMT-12' },
  { value: 'Etc/GMT-13', label: 'Etc/GMT-13' },
  { value: 'Etc/GMT-14', label: 'Etc/GMT-14' },
  { value: 'Etc/GMT-2', label: 'Etc/GMT-2' },
  { value: 'Etc/GMT-3', label: 'Etc/GMT-3' },
  { value: 'Etc/GMT-4', label: 'Etc/GMT-4' },
  { value: 'Etc/GMT-5', label: 'Etc/GMT-5' },
  { value: 'Etc/GMT-6', label: 'Etc/GMT-6' },
  { value: 'Etc/GMT-7', label: 'Etc/GMT-7' },
  { value: 'Etc/GMT-8', label: 'Etc/GMT-8' },
  { value: 'Etc/GMT-9', label: 'Etc/GMT-9' },
  { value: 'Etc/GMT0', label: 'Etc/GMT0' },
  { value: 'Etc/Greenwich', label: 'Etc/Greenwich' },
  { value: 'Etc/UCT', label: 'Etc/UCT' },
  { value: 'Etc/UTC', label: 'Etc/UTC' },
  { value: 'Etc/Universal', label: 'Etc/Universal' },
  { value: 'Etc/Zulu', label: 'Etc/Zulu' },
  { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam' },
  { value: 'Europe/Andorra', label: 'Europe/Andorra' },
  { value: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
  { value: 'Europe/Athens', label: 'Europe/Athens' },
  { value: 'Europe/Belfast', label: 'Europe/Belfast' },
  { value: 'Europe/Belgrade', label: 'Europe/Belgrade' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin' },
  { value: 'Europe/Bratislava', label: 'Europe/Bratislava' },
  { value: 'Europe/Brussels', label: 'Europe/Brussels' },
  { value: 'Europe/Bucharest', label: 'Europe/Bucharest' },
  { value: 'Europe/Budapest', label: 'Europe/Budapest' },
  { value: 'Europe/Busingen', label: 'Europe/Busingen' },
  { value: 'Europe/Chisinau', label: 'Europe/Chisinau' },
  { value: 'Europe/Copenhagen', label: 'Europe/Copenhagen' },
  { value: 'Europe/Dublin', label: 'Europe/Dublin' },
  { value: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
  { value: 'Europe/Guernsey', label: 'Europe/Guernsey' },
  { value: 'Europe/Helsinki', label: 'Europe/Helsinki' },
  { value: 'Europe/Isle_of_Man', label: 'Europe/Isle_of_Man' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul' },
  { value: 'Europe/Jersey', label: 'Europe/Jersey' },
  { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
  { value: 'Europe/Kiev', label: 'Europe/Kiev' },
  { value: 'Europe/Kirov', label: 'Europe/Kirov' },
  { value: 'Europe/Lisbon', label: 'Europe/Lisbon' },
  { value: 'Europe/Ljubljana', label: 'Europe/Ljubljana' },
  { value: 'Europe/London', label: 'Europe/London' },
  { value: 'Europe/Luxembourg', label: 'Europe/Luxembourg' },
  { value: 'Europe/Madrid', label: 'Europe/Madrid' },
  { value: 'Europe/Malta', label: 'Europe/Malta' },
  { value: 'Europe/Mariehamn', label: 'Europe/Mariehamn' },
  { value: 'Europe/Minsk', label: 'Europe/Minsk' },
  { value: 'Europe/Monaco', label: 'Europe/Monaco' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow' },
  { value: 'Europe/Nicosia', label: 'Europe/Nicosia' },
  { value: 'Europe/Oslo', label: 'Europe/Oslo' },
  { value: 'Europe/Paris', label: 'Europe/Paris' },
  { value: 'Europe/Podgorica', label: 'Europe/Podgorica' },
  { value: 'Europe/Prague', label: 'Europe/Prague' },
  { value: 'Europe/Riga', label: 'Europe/Riga' },
  { value: 'Europe/Rome', label: 'Europe/Rome' },
  { value: 'Europe/Samara', label: 'Europe/Samara' },
  { value: 'Europe/San_Marino', label: 'Europe/San_Marino' },
  { value: 'Europe/Sarajevo', label: 'Europe/Sarajevo' },
  { value: 'Europe/Saratov', label: 'Europe/Saratov' },
  { value: 'Europe/Simferopol', label: 'Europe/Simferopol' },
  { value: 'Europe/Skopje', label: 'Europe/Skopje' },
  { value: 'Europe/Sofia', label: 'Europe/Sofia' },
  { value: 'Europe/Stockholm', label: 'Europe/Stockholm' },
  { value: 'Europe/Tallinn', label: 'Europe/Tallinn' },
  { value: 'Europe/Tirane', label: 'Europe/Tirane' },
  { value: 'Europe/Tiraspol', label: 'Europe/Tiraspol' },
  { value: 'Europe/Ulyanovsk', label: 'Europe/Ulyanovsk' },
  { value: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod' },
  { value: 'Europe/Vaduz', label: 'Europe/Vaduz' },
  { value: 'Europe/Vatican', label: 'Europe/Vatican' },
  { value: 'Europe/Vienna', label: 'Europe/Vienna' },
  { value: 'Europe/Vilnius', label: 'Europe/Vilnius' },
  { value: 'Europe/Volgograd', label: 'Europe/Volgograd' },
  { value: 'Europe/Warsaw', label: 'Europe/Warsaw' },
  { value: 'Europe/Zagreb', label: 'Europe/Zagreb' },
  { value: 'Europe/Zaporozhye', label: 'Europe/Zaporozhye' },
  { value: 'Europe/Zurich', label: 'Europe/Zurich' },
  { value: 'GB', label: 'GB' },
  { value: 'GB-Eire', label: 'GB-Eire' },
  { value: 'GMT', label: 'GMT' },
  { value: 'GMT+0', label: 'GMT+0' },
  { value: 'GMT-0', label: 'GMT-0' },
  { value: 'GMT0', label: 'GMT0' },
  { value: 'Greenwich', label: 'Greenwich' },
  { value: 'HST', label: 'HST' },
  { value: 'Hongkong', label: 'Hongkong' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'Indian/Antananarivo', label: 'Indian/Antananarivo' },
  { value: 'Indian/Chagos', label: 'Indian/Chagos' },
  { value: 'Indian/Christmas', label: 'Indian/Christmas' },
  { value: 'Indian/Cocos', label: 'Indian/Cocos' },
  { value: 'Indian/Comoro', label: 'Indian/Comoro' },
  { value: 'Indian/Kerguelen', label: 'Indian/Kerguelen' },
  { value: 'Indian/Mahe', label: 'Indian/Mahe' },
  { value: 'Indian/Maldives', label: 'Indian/Maldives' },
  { value: 'Indian/Mauritius', label: 'Indian/Mauritius' },
  { value: 'Indian/Mayotte', label: 'Indian/Mayotte' },
  { value: 'Indian/Reunion', label: 'Indian/Reunion' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Kwajalein', label: 'Kwajalein' },
  { value: 'Libya', label: 'Libya' },
  { value: 'MET', label: 'MET' },
  { value: 'MST', label: 'MST' },
  { value: 'MST7MDT', label: 'MST7MDT' },
  { value: 'Mexico/BajaNorte', label: 'Mexico/BajaNorte' },
  { value: 'Mexico/BajaSur', label: 'Mexico/BajaSur' },
  { value: 'Mexico/General', label: 'Mexico/General' },
  { value: 'NZ', label: 'NZ' },
  { value: 'NZ-CHAT', label: 'NZ-CHAT' },
  { value: 'Navajo', label: 'Navajo' },
  { value: 'PRC', label: 'PRC' },
  { value: 'PST8PDT', label: 'PST8PDT' },
  { value: 'Pacific/Apia', label: 'Pacific/Apia' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland' },
  { value: 'Pacific/Bougainville', label: 'Pacific/Bougainville' },
  { value: 'Pacific/Chatham', label: 'Pacific/Chatham' },
  { value: 'Pacific/Chuuk', label: 'Pacific/Chuuk' },
  { value: 'Pacific/Easter', label: 'Pacific/Easter' },
  { value: 'Pacific/Efate', label: 'Pacific/Efate' },
  { value: 'Pacific/Enderbury', label: 'Pacific/Enderbury' },
  { value: 'Pacific/Fakaofo', label: 'Pacific/Fakaofo' },
  { value: 'Pacific/Fiji', label: 'Pacific/Fiji' },
  { value: 'Pacific/Funafuti', label: 'Pacific/Funafuti' },
  { value: 'Pacific/Galapagos', label: 'Pacific/Galapagos' },
  { value: 'Pacific/Gambier', label: 'Pacific/Gambier' },
  { value: 'Pacific/Guadalcanal', label: 'Pacific/Guadalcanal' },
  { value: 'Pacific/Guam', label: 'Pacific/Guam' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
  { value: 'Pacific/Johnston', label: 'Pacific/Johnston' },
  { value: 'Pacific/Kiritimati', label: 'Pacific/Kiritimati' },
  { value: 'Pacific/Kosrae', label: 'Pacific/Kosrae' },
  { value: 'Pacific/Kwajalein', label: 'Pacific/Kwajalein' },
  { value: 'Pacific/Majuro', label: 'Pacific/Majuro' },
  { value: 'Pacific/Marquesas', label: 'Pacific/Marquesas' },
  { value: 'Pacific/Midway', label: 'Pacific/Midway' },
  { value: 'Pacific/Nauru', label: 'Pacific/Nauru' },
  { value: 'Pacific/Niue', label: 'Pacific/Niue' },
  { value: 'Pacific/Norfolk', label: 'Pacific/Norfolk' },
  { value: 'Pacific/Noumea', label: 'Pacific/Noumea' },
  { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago_Pago' },
  { value: 'Pacific/Palau', label: 'Pacific/Palau' },
  { value: 'Pacific/Pitcairn', label: 'Pacific/Pitcairn' },
  { value: 'Pacific/Pohnpei', label: 'Pacific/Pohnpei' },
  { value: 'Pacific/Ponape', label: 'Pacific/Ponape' },
  { value: 'Pacific/Port_Moresby', label: 'Pacific/Port_Moresby' },
  { value: 'Pacific/Rarotonga', label: 'Pacific/Rarotonga' },
  { value: 'Pacific/Saipan', label: 'Pacific/Saipan' },
  { value: 'Pacific/Samoa', label: 'Pacific/Samoa' },
  { value: 'Pacific/Tahiti', label: 'Pacific/Tahiti' },
  { value: 'Pacific/Tarawa', label: 'Pacific/Tarawa' },
  { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu' },
  { value: 'Pacific/Truk', label: 'Pacific/Truk' },
  { value: 'Pacific/Wake', label: 'Pacific/Wake' },
  { value: 'Pacific/Wallis', label: 'Pacific/Wallis' },
  { value: 'Pacific/Yap', label: 'Pacific/Yap' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'ROC', label: 'ROC' },
  { value: 'ROK', label: 'ROK' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'UCT', label: 'UCT' },

  { value: 'UTC', label: 'UTC' },
  { value: 'Universal', label: 'Universal' },
  { value: 'W-SU', label: 'W-SU' },
  { value: 'WET', label: 'WET' },
];
