import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from '../../components/loader/Loader';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

import '../booking-for-organizer-pages/bdfoPage.styles.scss';
import BdfoVideoCourseTitle from '../../components/video-course/organiser-bookings/BdfoVideoCourseTitle';
import BdfoVideoCourseCounts from '../../components/video-course/organiser-bookings/BdfoVideoCourseCounts';
import AudioCourseDetailsActionButtons from '../../components/audio-course/AudioCourseDetailsAction';
import BdfoPodcastBooking from '../../components/podcast/BdfoPodcastBooking';
// booking details for organizer
export default function BdfoPodcastPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);

  const dispatch = useDispatch();
  const videoCourseId = useParams().podcastId;

  async function getBookings(videoCourseId) {
    try {
      const response = await api.get(
        `/podcast/${videoCourseId}/earnings/bookings`
      );

      setDetails(response.data.data.podcast);
      setIsLoading(false);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBookings(videoCourseId);
  }, [videoCourseId]);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="bdfo-container">
            <AudioCourseDetailsActionButtons audioDetails={details} />
            <section className="mb-32">
              <BdfoVideoCourseTitle title={details?.title} />
            </section>
            <section className="mb-32">
              <BdfoVideoCourseCounts
                currency={details?.currency}
                sold={details?.totalSold}
                grossPrice={details?.netSale}
                refundRequests={details?.totalCancelled}
                refunded={details?.totalRefunded}
              />
            </section>

            <section className="mb-60">
              <BdfoPodcastBooking
                getBookings={getBookings}
                attendees={details?.bookings}
                videoCourse={details}
              />
            </section>
          </div>
        </>
      )}
    </div>
  );
}
