import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import RadioCompletedIcon from '../icons/RadioCompletedIcon';
import RadioFilledIcon from '../icons/RadioFilled';
import RadioIcon from '../icons/RadioIcon';

import './tabs.styles.scss';

export default function PodcastResTabs({ tabs, showRadioButtons }) {
  return (
    <div className="podcast-res-tabs-flex">
      {tabs.map((tab) => (
        <div key={tab.label} className=" mt-8">
          <NavLink to={tab.path}>
            {({ isActive }) => (
              <>
                {showRadioButtons && (
                  <div className="text-align-center tabs-margin">
                    {tab.completed ? (
                      <RadioCompletedIcon className="icon-size" />
                    ) : isActive ? (
                      <RadioFilledIcon className="icon-size" />
                    ) : (
                      <RadioIcon className="icon-size" />
                    )}
                  </div>
                )}
                <div
                  className={`inactive-res-shared-tab podcast-tabs-margin mt-8 ${
                    isActive ? 'active-res-shared-tab' : ''
                  }`}
                >
                  {tab.label}
                </div>
              </>
            )}
          </NavLink>
        </div>
      ))}
    </div>
  );
}

PodcastResTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  showRadioButtons: PropTypes.bool.isRequired,
};
