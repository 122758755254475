import PropTypes from "prop-types";
import BackIcon from "../icons/BackIcon";
import "./backButton.styles.scss";

export default function BackButton({ onBackButtonClick, className }) {
  return (
    <button
      onClick={() => onBackButtonClick()}
      className={`flex-vchc back-button back-button-${className}`}
    >
      <BackIcon className="icon-size" fill="#fff" />
      <p className="pr-12">Back</p>
    </button>
  );
}

BackButton.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};
