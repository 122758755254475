import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import StripePaymentStatus from '../../components/booking/StripePaymentStatus';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

export default function StripePaymentStatusPage() {
  const [stripePromise, setStripePromise] = useState(null);
  const dispatch = useDispatch();

  const { confId } = useParams();
  //get stripe promise and set it up on the state
  const getStripePromise = async () => {
    try {
      const response = await api.get('/conferences/stripe/config');
      if (response) {
        setStripePromise(loadStripe(response.data.data.publishableKey));
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    getStripePromise();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container pt-64">
      <Elements stripe={stripePromise}>
        <StripePaymentStatus confId={confId} />
      </Elements>
    </div>
  );
}
