import { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import SubmitButtonWithLoader from '../button/SubmitButtonWithLoader';
import PriceIcon from '../icons/PriceIcon';
import './checkoutForm.styles.scss';

const CheckoutForm = ({ paymentData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: "https://confemy.com/payment-status",
        return_url: `${window.location.origin}/payment-status/${paymentData?.conference?._id}`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      if ((error.type = 'card_error' || 'validation_error')) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('Payment failed');
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="checkout-grid-wrap">
      <div className="checkout-grid">
        <div className="flex-vchc checkout-total-wrap">
          <div className="text-align-center">
            <p className="flex-vchc mb-8">
              <span className="body-regular-gray3 mr-16">Total</span>
              <i>
                <PriceIcon className="icon-size" fill="#c4c4c4" />
              </i>
            </p>
            <h3>INR - {paymentData?.orderAmount / 100}</h3>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <div className="mt-16">
            <SubmitButtonWithLoader
              isSubmitting={isLoading}
              text="Pay Securely"
              className="small-button small-button-primary"
              disabled={!stripe || !elements}
              fullWidth={true}
            />
          </div>
          {/* Show error message to your customers */}
          {errorMessage && (
            <div
              style={{
                color: '#d8000c',
                fontSize: '1.5rem',
              }}
            >
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
