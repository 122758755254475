import React from "react";

function DropdownIcon({ className, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              d="M24 24L0 24 0 0 24 0z"
              transform="translate(-1266.000000, -20.000000) translate(1266.000000, 20.000000)"
            />
            <path
              fill={fill ? fill : "#08415C"}
              d="M16.292 8.876l-3.868 4.04-3.87-4.04c-.388-.406-1.016-.406-1.405 0-.39.406-.39 1.062 0 1.468l4.577 4.78c.388.406 1.017.406 1.406 0l4.576-4.78c.39-.406.39-1.062 0-1.468-.389-.396-1.027-.406-1.416 0z"
              transform="translate(-1266.000000, -20.000000) translate(1266.000000, 20.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DropdownIcon;
