export const GET_PODCAST = 'GET_PODCAST';
// export const REMOVE_VIDEO_COURSE = 'REMOVE_VIDEO_COURSE';
export const PODCAST_ERROR = 'PODCAST_ERROR';
export const CREATE_PODCAST = 'CREATE_PODCAST';
export const REMOVE_NEWPOD_STATE = 'REMOVE_NEWPOD_STATE';
export const LOAD_INCOMPLETE_PODS = 'LOAD_INCOMPLETE_PODS';
export const LOAD_INCOMPLETE_POD = 'LOAD_INCOMPLETE_POD';
export const LOAD_ALL_MY_PODS = 'LOAD_ALL_MY_PODS';
export const POD_SEARCH_DONE = 'POD_SEARCH_DONE';
export const POD_SEARCH_INITIATED = 'POD_SEARCH_INITIATED';
export const GET_SINGLE_POD = 'GET_SINGLE_POD';
