import { useSelector, useDispatch } from "react-redux";

import { ErrorMessage, Form, Formik } from "formik";
// import PropTypes from "prop-types";
import * as yup from "yup";
import TextError from "../formik/TextError";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import OnlyDatepicker from "../react-datepicker/OnlyDatePicker";
import api from "../../utility/api";
import { alertAction, localAlertAction } from "../../redux/alert/alertAction";

const validationSchema = yup.object().shape({
  startDate: yup.date().required("Required").nullable(),
  endDate: yup.date().required("Required").nullable(),
});

export default function GoalDatesForm({
  setGoals,
  goals,
  isEdit,
  setShowGoalDates,
}) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onSubmit = async (values, action) => {
    const formData = {
      goalDetails: {
        startDate: values.startDate,
        endDate: values.endDate,
        timezone: values.timezone,
      },
    };
    const url = `attendees/goals/duration?userId=${user?._id}`;
    const editUrl = `attendees/goals/${goals?._id}/duration?userId=${user?._id}`;
    try {
      let response;
      if (isEdit) {
        response = await api.patch(editUrl, formData);
      } else {
        response = await api.post(url, formData);
      }
      setGoals(response?.data?.data?.creditGoal);
      setShowGoalDates(false);
      dispatch(alertAction(response.data.message, "success"));
    } catch (err) {
      if (isEdit) {
        dispatch(localAlertAction(err.response.data.message, "danger"));
      } else {
        dispatch(alertAction(err.response.data.message, "danger"));
      }
    }
  };

  let formatedStartDate;
  let formatedEndDate;
  if (goals?.startDate) {
    formatedStartDate = new Date(goals?.startDate);
  }
  if (goals?.endDate) {
    formatedEndDate = new Date(goals?.endDate);
  }
  const initialValues = {
    startDate: formatedStartDate || null,
    endDate: formatedEndDate || null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(props) => {
        return (
          <Form autoComplete="off">
            <h2 className="text-align-center color-primary mb-24">
              Goal Duration
            </h2>
            <div className="form-type-1" style={{ maxWidth: 500 }}>
              <div>
                <OnlyDatepicker
                  id="startDate"
                  name="startDate"
                  selected={props.values.startDate}
                  onChange={(date) => props.setFieldValue("startDate", date)}
                  maxDate={props.values.endDate}
                  placeholder="Start Date*"
                  disabled={false}
                />
                <div className="mb-16">
                  <ErrorMessage name="startDate" component={TextError} />
                </div>
              </div>

              <div>
                <OnlyDatepicker
                  id="endDate"
                  name="endDate"
                  selected={props.values.endDate}
                  onChange={(date) => props.setFieldValue("endDate", date)}
                  minDate={props.values.startDate}
                  placeholder="End Date*"
                  disabled={false}
                />
                <div className="mb-16">
                  <ErrorMessage name="endDate" component={TextError} />
                </div>
              </div>
              <div>
                <SubmitCancelButtonWithLoader
                  isSubmitting={props.isSubmitting}
                  onCancel={() => {
                    if (isEdit) {
                      setShowGoalDates(false);
                    } else {
                      props.resetForm({ values: initialValues });
                    }
                  }}
                  cancelButtonClass="button button-green"
                  fullWidth={true}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
