import './CourseViewTabComponent.scss';

const CourseViewTabComponent = ({ setActiveTab, activeTab }) => {
  const tabs = [
    {
      id: 1,
      name: 'Course Content',
      icon: null,
      link: '/test/content',
    },
    {
      id: 2,
      name: 'Overview',
      icon: null,
      link: '',
    },
  ];

  return (
    <div className="courseOverview-outerDiv">
      <div className="courseOverview-tabs">
        {tabs?.map((item) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setActiveTab(item.id)}
              key={`tab-${item.id}`}
              className={
                activeTab === item.id
                  ? 'courseOverview-tab courseOverview-activeTab'
                  : 'courseOverview-tab'
              }
            >
              {item?.name ? (
                <div className="course-overview-textSm">{item.name}</div>
              ) : null}
              {item?.icon ? (
                <img
                  src={item.icon}
                  alt="icon"
                  className="courseOverview-icon"
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseViewTabComponent;
