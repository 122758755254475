import ProfileSketch from '../icons/ProfileSketch';

import './speakercard.styles.scss';

// cd = conf details. This is to be used for conf details page. no edit functionality.

export default function CdSpeakercard({ name, designation, degree, image }) {
  return (
    <div className="cd-speakercard">
      <div className="speaker-image-container">
        <div className="cd-speaker-image-wrap">
          {image && image?.length > 0 ? (
            <img
              alt="profile picture"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={image[0].Location}
            />
          ) : (
            <ProfileSketch
              className="speaker-profilesketch-size"
              fill="#c4c4c4"
            />
          )}
        </div>
      </div>
      <div className="speaker-name-container">
        <p className="speaker-name mb-8">{name}</p>
        <p className="caption-3">{degree}</p>
        <p className="caption-3">{designation}</p>
      </div>
    </div>
  );
}
