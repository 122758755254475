import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalX from "../../components/modal/ModalX";
import Register from "../../components/register/Register";

const RegisterPage = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const onDismiss = () => navigate("/");

  return (
    <section className="container">
      <ModalX onDismiss={onDismiss} open={open}>
        <Register />
      </ModalX>
    </section>
  );
};

export default RegisterPage;
