import RefundDetails from '../../components/refund/RefundDetails';
export default function RefundDetailsPage() {
  return (
    <>
      <div className="container pt-64">
        <RefundDetails />
      </div>
    </>
  );
}
