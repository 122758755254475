import '../organizer-dashboard/welcome.styles.scss';

// import required modules
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './upcomingConfCard.scss';
import OngoingLearningCard from './OngoingLearningCard';

export default function OngoingLearning({ ongoingLearning }) {
  return (
    <div className="ongoingLearning-con">
      <p className="caption-1-heavy-primary mb-12 ml-16">Ongoing learning</p>
      <Swiper
        slidesPerView={3}
        spaceBetween={8}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="courseSwiper"
        breakpoints={{
          1: {
            slidesPerView: 1,
          },
          650: {
            slidesPerView: 2,
          },
          980: {
            slidesPerView: 2,
          },
          1280: {
            slidesPerView: 3,
          },
        }}
      >
        {ongoingLearning?.length > 0 &&
          ongoingLearning?.map((item) => {
            return (
              <SwiperSlide key={item?._id}>
                <OngoingLearningCard key={item._id} data={item} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
