import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import DateFilter from "./DateFilter";

import { loadUserTotalExternalCreditsAction } from "../../redux/user-profile/userProfileAction";
import api from "../../utility/api";
import { getUrl } from "../../utility/commonUtil";
import "./totalExternalCreditsTable.scss";

const TotalExternalCreditsTable = ({
  totalStartDate,
  totalEndDate,
  setTotalStartDate,
  setTotalEndDate,
}) => {
  const user = useSelector((state) => state.auth.user);
  const userTotalExternalCredits = useSelector(
    (state) => state.userProfile.userTotalExternalCredits
  );

  const dispatch = useDispatch();

  const getTotalExternalCredits = async (userId, startDate, endDate) => {
    const parameters = [
      { name: "startDate", value: startDate?.toISOString() },
      { name: "endDate", value: endDate?.toISOString() },
    ];
    let baseUrl = `/attendees/${userId}/credits/externals/counts`;
    const url = getUrl(parameters, baseUrl);
    try {
      const response = await api.get(url);
      dispatch(
        loadUserTotalExternalCreditsAction(response.data.data.externalCredits)
      );
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  useEffect(() => {
    getTotalExternalCredits(user?._id, totalStartDate, totalEndDate);
  }, [user?._id, totalStartDate, totalEndDate]);

  return (
    <>
      <div>
        <h2 className="color-primary mb-16">Total External Credits</h2>
        <div className="mb-16">
          <DateFilter
            startDate={totalStartDate}
            endDate={totalEndDate}
            setStartDate={setTotalStartDate}
            setEndDate={setTotalEndDate}
          />
        </div>
        <div className="grid-table-wrap">
          <ul className="grid-table-3-500 tec-grid-table">
            <li>Credit Type</li>
            <li>Earned Credits</li>
            <li>Total Credits</li>
            {userTotalExternalCredits?.length > 0 &&
              userTotalExternalCredits?.map((creditData) => {
                return (
                  <Fragment key={creditData?._id?.credit}>
                    <li>{creditData?.credit?.name}</li>
                    <li>{creditData.quantity}</li>
                    <li>{creditData.quantity}</li>
                  </Fragment>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TotalExternalCreditsTable;
