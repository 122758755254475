import { useNavigate } from "react-router-dom";
import "./getStarted.scss";

export default function GetStarted() {
  const navigate = useNavigate();
  return (
    <div className="get-started-wrap bg-green">
      <h1 className="mb-32">Get Started</h1>
      <p className="body-regular-gray3 mb-4">Great customer support !!</p>
      <p className="body-regular-gray3 mb-4">
        Email or call and send a message through contact section.
      </p>
      <button
        className="button button-primary mt-34"
        onClick={() => navigate("/dashboard/create-conference")}
      >
        HOST YOUR CONFERENCE / CME
      </button>
    </div>
  );
}
