function RealTimeAnalytics({ className }) {
  return (
    <svg
      className={className}
      //   width="222px"
      //   height="206px"
      viewBox="0 0 222 206"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>clickstream@2x</title>
      <g
        id="Creatives"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="clickstream"
          transform="translate(0.142857, 0.000000)"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(15.633929, 45.982143)">
            <path
              d="M205.080357,114.298469 L0,114.298469 L0,15.2397959 C0,6.82314238 6.80135712,0 15.1911376,0 L189.88922,0 C198.279,0 205.080357,6.82314238 205.080357,15.2397959 L205.080357,114.298469 Z"
              id="Path"
              fill="#052A3C"
            ></path>
            <path
              d="M189.88922,114.298469 L0,114.298469 L0,15.2397959 C0,6.82314238 6.80135712,0 15.1911376,0 L174.698082,0 C183.087862,0 189.88922,6.82314238 189.88922,15.2397959 L189.88922,114.298469 Z"
              id="Path"
              fill="#08415C"
            ></path>
            <path
              d="M15.1911376,133.348214 L189.88922,133.348214 C198.279,133.348214 205.080357,126.525072 205.080357,118.108418 L205.080357,114.298469 L0,114.298469 L0,118.108418 C0,126.525072 6.80135712,133.348214 15.1911376,133.348214 Z"
              id="Path"
              fillOpacity="0.345006556"
              fill="#08415C"
            ></path>
            <path
              d="M174.698082,133.348214 L15.1911376,133.348214 C6.80135712,133.348214 0,126.525072 0,118.108418 L0,114.298469 L189.88922,114.298469 L189.88922,118.108418 C189.88922,126.525072 183.087862,133.348214 174.698082,133.348214 Z"
              id="Path"
              fill="#CED9DE"
            ></path>
            <polygon
              id="Path"
              fill="#052A3C"
              points="79.7534722 133.348214 113.933532 133.348214 113.933532 148.58801 79.7534722 148.58801"
            ></polygon>
            <polygon
              id="Path"
              fill="#08415C"
              points="79.7534722 140.968112 102.540179 140.968112 102.540179 148.58801 79.7534722 148.58801"
            ></polygon>
            <path
              d="M132.922454,160.017857 L56.9667659,160.017857 L56.9667659,156.207908 C56.9667659,151.999343 60.3672071,148.58801 64.5623347,148.58801 L125.326885,148.58801 C129.522013,148.58801 132.922454,151.999343 132.922454,156.207908 L132.922454,160.017857 Z"
              id="Path"
              fill="#08415C"
            ></path>
            <path
              d="M132.922454,160.017857 L110.135747,160.017857 L110.135747,156.207908 C110.135747,151.999343 113.536189,148.58801 117.731316,148.58801 L125.326885,148.58801 C129.522013,148.58801 132.922454,151.999343 132.922454,156.207908 L132.922454,160.017857 Z"
              id="Path"
              fill="#052A3C"
            ></path>
          </g>
          <path
            d="M174.490132,137.026786 L45.3045113,137.026786 C41.1074035,137.026786 37.7053571,133.61872 37.7053571,129.414187 L37.7053571,7.61259921 C37.7053571,3.40806551 41.1074035,0 45.3045113,0 L174.490132,0 C178.687239,0 182.089286,3.40806551 182.089286,7.61259921 L182.089286,129.414187 C182.089286,133.61872 178.687239,137.026786 174.490132,137.026786 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
          <path
            d="M182.089286,15.6339286 L37.7053571,15.6339286 L37.7053571,7.81696429 C37.7053571,3.4995572 41.1074035,0 45.3045113,0 L174.490132,0 C178.687239,0 182.089286,3.4995572 182.089286,7.81696429 L182.089286,15.6339286 Z"
            id="Path"
            fill="#08415C"
          ></path>
          <circle
            id="Oval"
            fill="#FFFFFF"
            cx="53.3392857"
            cy="7.35714286"
            r="3.67857143"
          ></circle>
          <circle
            id="Oval"
            fill="#FFFFFF"
            cx="68.0535714"
            cy="7.35714286"
            r="3.67857143"
          ></circle>
          <circle
            id="Oval"
            fill="#4CB944"
            cx="83.6875"
            cy="7.35714286"
            r="3.67857143"
          ></circle>
          <path
            d="M163.55495,76.3303571 L55.3200497,76.3303571 C53.2104273,76.3303571 51.5,74.6146484 51.5,72.4985119 L51.5,34.1800595 C51.5,32.063923 53.2104273,30.3482143 55.3200497,30.3482143 L163.55495,30.3482143 C165.664573,30.3482143 167.375,32.063923 167.375,34.1800595 L167.375,72.4985119 C167.375,74.6146484 165.664573,76.3303571 163.55495,76.3303571 Z"
            id="Path"
            fillOpacity="0.345006556"
            fill="#08415C"
          ></path>
          <path
            d="M102.893076,122.3125 L56.2051385,122.3125 C54.1145985,122.3125 52.4196429,120.624243 52.4196429,118.541964 L52.4196429,88.3776786 C52.4196429,86.2954002 54.1145985,84.6071429 56.2051385,84.6071429 L102.893076,84.6071429 C104.983616,84.6071429 106.678571,86.2954002 106.678571,88.3776786 L106.678571,118.541964 C106.678571,120.624243 104.983616,122.3125 102.893076,122.3125 Z"
            id="Path"
            fill="#A5C3DC"
          ></path>
          <path
            d="M163.589504,91.0446429 L116.901567,91.0446429 C114.811027,91.0446429 113.116071,89.3975625 113.116071,87.3660714 C113.116071,85.3345804 114.811027,83.6875 116.901567,83.6875 L163.589504,83.6875 C165.680044,83.6875 167.375,85.3345804 167.375,87.3660714 C167.375,89.3975625 165.680044,91.0446429 163.589504,91.0446429 Z"
            id="Path"
            fillOpacity="0.345006556"
            fill="#08415C"
          ></path>
          <path
            d="M163.589504,106.678571 L116.901567,106.678571 C114.811027,106.678571 113.116071,105.031491 113.116071,103 C113.116071,100.968509 114.811027,99.3214286 116.901567,99.3214286 L163.589504,99.3214286 C165.680044,99.3214286 167.375,100.968509 167.375,103 C167.375,105.031491 165.680044,106.678571 163.589504,106.678571 Z"
            id="Path"
            fillOpacity="0.345006556"
            fill="#08415C"
          ></path>
          <path
            d="M163.589504,122.3125 L116.901567,122.3125 C114.811027,122.3125 113.116071,120.66542 113.116071,118.633929 C113.116071,116.602437 114.811027,114.955357 116.901567,114.955357 L163.589504,114.955357 C165.680044,114.955357 167.375,116.602437 167.375,118.633929 C167.375,120.66542 165.680044,122.3125 163.589504,122.3125 Z"
            id="Path"
            fillOpacity="0.345006556"
            fill="#08415C"
          ></path>
          <g id="Group" transform="translate(0.000000, 30.348214)">
            <ellipse
              id="Oval"
              fill="#D7E6F0"
              cx="60.6964286"
              cy="160.644886"
              rx="7.58705357"
              ry="7.64975649"
            ></ellipse>
            <path
              d="M60.6964286,152.99513 L60.6964286,168.294643 C64.8868531,168.294643 68.2834821,164.869942 68.2834821,160.644886 C68.2834821,156.41983 64.8868531,152.99513 60.6964286,152.99513 Z"
              id="Path"
              fill="#A5C3DC"
            ></path>
            <path
              d="M68.2834821,107.096591 L53.109375,107.096591 C23.7778259,107.096591 0,83.1222541 0,53.5482955 C0,23.9743369 23.7778259,0 53.109375,0 L68.2834821,0 C97.6150312,0 121.392857,23.9743369 121.392857,53.5482955 C121.392857,83.1222541 97.6150312,107.096591 68.2834821,107.096591 Z"
              id="Path"
              fill="#43A23C"
            ></path>
            <ellipse
              id="Oval"
              fill="#4CB944"
              cx="53.109375"
              cy="53.5482955"
              rx="53.109375"
              ry="53.5482955"
            ></ellipse>
            <ellipse
              id="Oval"
              fill="#D7E6F0"
              cx="53.109375"
              cy="53.5482955"
              rx="36.6706009"
              ry="36.9736637"
            ></ellipse>
            <ellipse
              id="Oval"
              fill="#FFFFFF"
              cx="37.9352679"
              cy="38.2487825"
              rx="7.58705357"
              ry="7.64975649"
            ></ellipse>
            <polygon
              id="Path"
              fill="#D7E6F0"
              points="53.109375 107.096591 68.2834821 107.096591 68.2834821 114.746347 53.109375 114.746347"
            ></polygon>
            <path
              d="M53.109375,107.096591 L53.109375,110.921469 L56.9029018,110.921469 C58.997877,110.921469 60.6964286,112.634058 60.6964286,114.746347 L68.2834821,114.746347 L68.2834821,107.096591 L53.109375,107.096591 Z"
              id="Path"
              fill="#A5C3DC"
            ></path>
            <path
              d="M68.2834821,160.644886 L53.109375,160.644886 C51.0143998,160.644886 49.3158482,158.932297 49.3158482,156.820008 L49.3158482,118.571226 C49.3158482,116.458937 51.0143998,114.746347 53.109375,114.746347 L68.2834821,114.746347 C70.3784573,114.746347 72.0770089,116.458937 72.0770089,118.571226 L72.0770089,156.820008 C72.0770089,158.932297 70.3784573,160.644886 68.2834821,160.644886 Z"
              id="Path"
              fill="#4CB944"
            ></path>
            <path
              d="M68.2834821,160.644886 L60.6964286,160.644886 L60.6964286,114.746347 L68.2834821,114.746347 C70.3784573,114.746347 72.0770089,116.458937 72.0770089,118.571226 L72.0770089,156.820008 C72.0770089,158.932297 70.3784573,160.644886 68.2834821,160.644886 Z"
              id="Path"
              fill="#43A23C"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RealTimeAnalytics;
