import { useSelector } from "react-redux";
import UserOnBenchSketch from "../SVG-assets/UserOnBenchSketch";

export default function NothingMatches() {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="sr-noresult-container">
      <UserOnBenchSketch className="icon-size" />
      <h2>
        Nothing matched your search {user && `“${user && user.firstName}”`}
      </h2>
    </div>
  );
}
