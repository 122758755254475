import { Fragment } from 'react';
import '../organizer-dashboard/welcome.styles.scss';
import { useNavigate } from 'react-router-dom';

export default function RecentlyWishlisted({ wishList }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="grid-table-wrap ">
        <ul
          style={{
            border: 'solid 1px #ecf0f2',
            borderRadius: '6px',
            backgroundColor: '#fff',
            boxShadow: 'none',
          }}
          className="grid-table-3-400"
        >
          <li
            style={{
              backgroundColor: '#ecf0f2',
              fontSize: '16px',
              color: ' #08415c',
              fontWeight: 500,
            }}
          >
            Recently saved
          </li>
          <li
            style={{
              backgroundColor: '#ecf0f2',
              fontSize: '16px',
              color: ' #08415c',
              fontWeight: 500,
            }}
          >
            Event type
          </li>
          <li
            style={{
              backgroundColor: '#ecf0f2',
              fontSize: '16px',
              color: ' #08415c',
              fontWeight: 500,
            }}
          >
            Price
          </li>

          {wishList?.length > 0 &&
            wishList?.map((data) => {
              return (
                <Fragment key={data?._id}>
                  <li
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      color: '#507282',
                      fontSize: '15px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (data?.type === 'Video Course') {
                        navigate(`/courses/${data?.mediaId}`);
                      } else if (data?.type === 'Audio Course') {
                        navigate(`/courses/${data?.mediaId}`);
                      } else if (data?.type === 'Podcast') {
                        navigate(`/courses/${data?.mediaId}`);
                      } else {
                        navigate(`/search-conference/${data?.confId}`);
                      }
                    }}
                  >
                    {data?.title}
                  </li>
                  <li style={{ color: '#507282', fontSize: '15px' }}>
                    {data?.type}
                  </li>
                  <li style={{ color: '#507282', fontSize: '15px' }}>
                    {data?.price}
                  </li>
                </Fragment>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
