import SeeAll from '../see-all/SeeAll';
import CourseCard from '../cards/CourseCard';

function TrendingCourses({ header, data, tag }) {
  return (
    data?.length > 0 && (
      <div className="ccah-container bg-background">
        <div className="ccah-header-wrap">
          <h2>{header}</h2>
        </div>
        <div className="tc-container mt-16">
          <div className="home-conf-grid">
            {data?.map((item) => (
              <div key={item?._id}>
                <CourseCard data={item} tag={tag} />
              </div>
            ))}
          </div>
        </div>
        <SeeAll />
      </div>
    )
  );
}

export default TrendingCourses;
