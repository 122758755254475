import '../organizer-dashboard/welcome.styles.scss';

import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function EventChart({ eventsCount }) {
  const data = {
    labels: ['Video courses', 'Podcasts', 'Conferences'],
    datasets: [
      {
        label: 'Total',
        data: [
          eventsCount?.videoCourseBookings,
          eventsCount?.audioCourseBookings,
          eventsCount?.confBookings,
        ],
        backgroundColor: ['#153d50', '#869097', '#e97f1c'],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 0,
      },
    ],
  };

  const centerTextPlugin = {
    id: 'centerText',
    afterDraw: (chart) => {
      const { ctx, width, height } = chart;
      ctx.restore();
      //   const fontSize = (height / 214).toFixed(2);
      //   const fontSize = '32px';
      ctx.font = `${32}px sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = ' #08415c';
      let total =
        chart.data.datasets[0].data[0] +
        chart.data.datasets[0].data[1] +
        chart.data.datasets[0].data[2];
      const text = `${total ? total : 0}`;
      const textX = Math.round((width - ctx.measureText(text).width) / 3);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.font = `${14}px sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.fillStyle = ' #7c8488';

      ctx.fillText('events', textX, height / 1.5);
      ctx.save();
    },
  };

  return (
    <div style={{ position: 'relative', width: '80%', height: '150px' }}>
      <Doughnut
        id="myDoughnutChart"
        options={{
          responsive: true,
          maintainAspectRatio: true,
          maintainAspectRatio: false,

          cutout: 52,

          plugins: {
            legend: {
              display: true,
              position: 'right',

              labels: {
                usePointStyle: true,
                padding: 20,
                // font: '14px',
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((value, index) => ({
                    text: `${chart.data.labels[index]}: (${value})`,
                    fillStyle: datasets[0].backgroundColor[index],
                    strokeStyle: datasets[0].backgroundColor[index],
                    // lineWidth: datasets[0].borderWidth,
                    hidden: false,
                    index,
                  }));
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        }}
        plugins={[centerTextPlugin]}
        data={data}
      />
      ;
    </div>
  );
}
