import { formatInTimeZone } from "date-fns-tz";
import enGB from "date-fns/locale/en-GB";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import Barcode from "react-barcode";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import Dialogue from "../dialogue/Dialogue";
import "./userTickets.styles.scss";

const PrintTicket = () => {
  const [ticketData, setTicketData] = useState([]);
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(false);
  const user = useSelector((state) => state.auth.user);
  let ticketID = JSON.parse(localStorage.getItem("ticketData"));

  const getFormatedDate = (bookingData) => {
    const startDateObj = new Date(bookingData?.conference?.startDate);
    if (startDateObj) {
      const formattedStartDate = formatInTimeZone(
        startDateObj,
        bookingData?.conference?.timezone,
        "MMM-dd-yyyy, HH:mm aa",
        { locale: enGB }
      );
      setStartDate(formattedStartDate);
    }
    const endDateObj = new Date(bookingData?.conference?.endDate);
    if (endDateObj) {
      const formattedEndDate = formatInTimeZone(
        endDateObj,
        bookingData?.conference?.timezone,
        "MMM-dd-yyyy, HH:mm aa",
        { locale: enGB }
      );
      setEndDate(formattedEndDate);
    }

    const bookingDateObj = new Date(bookingData?.bookingDate);
    if (bookingDateObj) {
      const formattedBookingDate = formatInTimeZone(
        bookingDateObj,
        bookingData?.conference?.timezone,
        "MMM-dd-yyyy, HH:mm aa",
        { locale: enGB }
      );
      setBookingDate(formattedBookingDate);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Print The Ticket",
  });
  const getLocationString = () => {
    let locationStrig = "Location";
    if (ticketData?.conference?.mode?.length > 0) {
      if (
        ticketData?.conference?.mode?.includes("venue") &&
        ticketData?.conference?.location
      ) {
        locationStrig = ticketData?.conference?.location;
      }

      if (ticketData?.conference?.mode?.includes("onlineConf")) {
        locationStrig = "Online";
      }

      if (
        ticketData?.conference?.mode?.includes("venue") &&
        ticketData?.conference?.mode?.includes("onlineConf")
      ) {
        locationStrig = `${ticketData?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  const getBookingDetails = async (id) => {
    try {
      const response = await api.get(`/conferences/bookings/${id}`);

      if (response) {
        setTicketData(response.data.data.bookingDetails);
        getFormatedDate(response.data.data.bookingDetails);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };

  const cancelTicket = async () => {
    try {
      let formData = {
        atteendeeDetails: {
          transactionId: ticketDetails.bookingDetails,
          attendeeIds: [ticketDetails?._id],
          userId: user?._id,
        },
      };
      const response = await api.post(`/attendees/refunds`, formData);

      if (response) {
        setOpenDialogue(false);
        setTicketData(response.data.data.booking);
        getFormatedDate(response.data.data.booking);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, "danger"));
    }
  };
  useEffect(() => {
    getBookingDetails(ticketID);
  }, [ticketID]);

  return (
    <>
      <div className="py-92 container">
        <div
          className="print-ticket "
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div className="mb-24 " ref={componentRef}>
            {ticketData?.attendees?.map((data, idx) => {
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                    className="mb-16"
                  >
                    <div className="flex-vc">
                      <button
                        onClick={handlePrint}
                        className="small-button small-button-primary"
                      >
                        Print
                      </button>
                      <button className="small-button small-button-primary mx-4">
                        Print All
                      </button>
                      {data?.ticketBooked === 1 && (
                        <button
                          onClick={() => {
                            setTicketDetails(data);
                            setOpenDialogue(true);
                          }}
                          className="small-button small-button-green"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    className="print-ticket-grid print-ticket-container print-border mb-24 "
                    key={idx}
                  >
                    <div className="barcode-rotate">
                      <Barcode
                        className="print-ticket-barcode"
                        value={data?.registrationNumber}
                      />
                    </div>

                    <h3 className="mb-12 print-ticket-content">
                      {ticketData?.conference?.title
                        ? ticketData?.conference?.title
                        : "Ticket title"}
                    </h3>

                    <h4 className=" mb-4 mt-32 print-ticket-text-1">Ticket </h4>
                    <h5 className=" mb-4 mt-32 caption-1-regular-gray2 print-ticket-text-2">
                      {data.ticket?.name}
                    </h5>
                    <h4 className="mb-4 print-ticket-text-1">STATUS </h4>
                    <h5
                      style={{
                        color: `${
                          data?.ticketBooked == 1 ? "#08415c" : "#d8000c"
                        }`,
                      }}
                      className="mb-4 caption-1-regular-gray2 print-ticket-text-2"
                    >
                      {data?.ticketBooked === 1 ? "Booked" : "Cacelled"}
                    </h5>
                    <h4 className="mb-4 print-ticket-text-1">NAME </h4>
                    <h5 className="mb-4 caption-1-regular-gray2 print-ticket-text-2">
                      {`${data.firstName}  ${data.lastName}`}
                    </h5>
                    <h4 className="mb-4 print-ticket-text-1">START DATE </h4>
                    <h5 className="mb-4 caption-1-regular-gray2 print-ticket-text-2">
                      {startDate}
                    </h5>
                    <h4 className="mb-4 print-ticket-text-1">END DATE</h4>
                    <h5 className=" mb-4 caption-1-regular-gray2 print-ticket-text-2">
                      {endDate}
                    </h5>
                    <h4 className="mb-4 print-ticket-text-1">LOCATION</h4>
                    <h5 className="mb-4 caption-1-regular-gray2  print-ticket-text-2">
                      {getLocationString()}
                    </h5>
                    <h4 className="mb-4 print-ticket-text-1">BOOKING DATE</h4>
                    <h5 className="mb-4 caption-1-regular-gray2 print-ticket-text-2">
                      {bookingDate}
                    </h5>

                    <h4 className="mr-42 print-ticket-text-1 ">BOOKING NO </h4>
                    <h5 className="caption-1-regular-gray2">
                      {ticketData?.bookingNumber}
                    </h5>

                    <div className="flex-vc">
                      <h4 className="mr-18 text-center"> BASE PRICE </h4>
                      <h5 className="caption-1-regular-gray2 py-10">
                        {data.originalPrice === 0 ? "FREE" : data.originalPrice}
                      </h5>
                    </div>
                    <div className="flex-vc">
                      <h4 className="mr-18">TOTAL</h4>
                      <h5 className="caption-1-regular-gray2 ">
                        {data.price === 0 ? "FREE" : data.price}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
            {openDialogue && (
              <Dialogue
                msg="Are you sure you want to cancel the ticket?"
                title="Confirm Cancel !!"
                closeDialogue={() => {
                  setOpenDialogue(false);
                }}
                yesAction={() => cancelTicket(ticketData)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTicket;
