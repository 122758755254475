import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import KeyPoints from '../../components/courses-details/KeyPoints.js';
import MediaTag from '../../components/tags/MediaTag.js';
import MediaIncludes from '../../components/courses-details/MediaIncludes.js';
import MediaContent from '../../components/courses-details/MediaContent.js';
import api from '../../utility/api.js';
import { alertAction } from '../../redux/alert/alertAction.js';
import CourseDetailsCard from '../../components/cards/CourseDetailsCard.js';
import {
  getFormattedDateInTz2,
  getPrice,
  getVideoCourseStatus,
  likeMedia,
  unlikeConference,
} from '../../utility/commonUtil.js';
import ModalX from '../../components/modal/ModalX.js';
import Loader from '../../components/loader/Loader.js';
import '../course-details-page/videoCourseDetailsPage.scss';
import VideoCourseDetailsTop from '../../components/video-course/utils/VideoCourseDetailsTop.js';
import LikeIcon from '../../components/icons/LikeRedIcon.js';
import LikeInactiveIcon from '../../components/icons/LikeInactiveIcon.js';

export default function PreviewAudioCoursePage() {
  const [isLoading, setIsloading] = useState(true);
  const [media, setMedia] = useState({});
  const [moreMediaByHost, setMoreMediaByHost] = useState([]);
  const [sectionCount, setSectionCount] = useState(0);
  const [episodeCount, setEpisodeCount] = useState(0);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [video, setVideo] = useState({});
  const [previewEpisode, setPreviewEpisode] = useState({});
  const [isLiked, setIsLiked] = useState(false);

  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;

  const mediaId = useParams().mediaId;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const like = (mediaId, userId, setIsLiked, type) => {
    if (!isLiked && isAuthenticated && user) {
      likeMedia(mediaId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to like a media.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to unlike a conference.', 'info'));
    }
  };

  const modalHandler = () => {
    setVideo({});
    setShowPreviewModal(false);
  };

  const playPreview = () => {
    setVideo(previewEpisode);
    setShowPreviewModal(true);
  };

  const getMediaDetails = async () => {
    try {
      // const response = await api.get(`/media/${mediaId}`);
      const response = await api.get(`/audioCourses/common/${mediaId}`);

      setMedia(response.data.data.media);
      setPreviewEpisode(response.data.data.previewEpisode);
      setMoreMediaByHost(response.data.data.moreCourses);
      setSectionCount(response.data.data.sectionCount);
      setEpisodeCount(response.data.data.episodeCount);
      setIsloading(false);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    setIsLiked(media?.isLiked);
  }, [media?.isLiked]);
  useEffect(() => {
    getMediaDetails();
  }, []);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <VideoCourseDetailsTop
            videoId={media?._id}
            status={getVideoCourseStatus(media)}
          />
          <div className="md-top-container ">
            <div className="md-grid">
              <div>
                <div className="mb-12">
                  <MediaTag tag={media?.mediaType} />
                </div>
                <h2
                  className="mb-16"
                  style={{
                    color: '#fff',
                  }}
                >
                  {media?.title}
                </h2>
                <div className="mb-4">
                  <span className="avenir-16-500" style={{ color: '#fff' }}>
                    Created by {'  '}
                  </span>
                  <span className="avenir-16-500" style={{ color: '#fff' }}>
                    {media?.hostedBy?.user
                      ? `${media?.hostedBy?.user?.firstName} ${media?.hostedBy?.user?.lastName}`
                      : media?.hostedBy?.organization?.name}
                  </span>
                </div>
                <div className="avenir-16-500 mb-16" style={{ color: '#fff' }}>
                  <span>Last Updated </span>
                  <span>
                    {getFormattedDateInTz2(media?.updatedAt, 'Asia/Kolkata')}
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: 15 }}>{media?.description}</p>
                </div>
                <div className="media-res-details-con">
                  <div className="mt-16">
                    <div className="mb-8">
                      <div className="mb-8">
                        <p style={{ color: '#fff' }} className="body-bold ">
                          Credits
                        </p>
                      </div>
                      <div
                        className="cc-hovcard-credits"
                        style={{ padding: 0 }}
                      >
                        {media?.credits?.length > 0 ? (
                          media?.credits.map((credit, indx) => {
                            return (
                              <Fragment key={indx}>
                                <div
                                  style={{ color: '#fff' }}
                                  className="avenir-16-500 line-clamp"
                                >
                                  {credit.creditId.name}
                                </div>
                                <div
                                  style={{ color: '#fff' }}
                                  className="avenir-16-500"
                                >
                                  {credit.quantity}
                                </div>
                              </Fragment>
                            );
                          })
                        ) : (
                          <div
                            style={{ color: '#fff' }}
                            className="avenir-16-500 "
                          >
                            Credits not added
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className="flex-vc  mt-32 mb-8"
                      style={{
                        width: '100%',
                        height: '100%',
                        fontWeight: 700,
                        fontSize: 20,
                        gap: '8px',
                        wordBreak: 'break-word',
                      }}
                    >
                      {getPrice(
                        media?.currency,
                        media?.actualPrice,
                        media?.price
                      )}
                    </div>
                    <div className="mb-16">
                      {media?.discount && (
                        <p className="avenir-heavy-16">
                          <span>{media?.discount}</span> % Off
                        </p>
                      )}
                    </div>
                    <div className="flex-vc">
                      <button
                        className="small-button small-button-green mr-10"
                        style={{ flexGrow: 1, height: 54 }}
                        onClick={() => {
                          if (media?.tag === 'Video Course' || 'Audio Course') {
                            navigate(`/book-course/${media?._id}`);
                          } else if (media?.tag === 'Podcast')
                            navigate(`/podcast/booking-price/${media?._id}`);
                          else {
                            navigate(`/book-conference/${media?._id}`);
                          }
                        }}
                      >
                        Book
                      </button>

                      <span
                        className="primary-color-box "
                        style={{ cursor: 'pointer' }}
                      >
                        {isLiked ? (
                          <i
                            className="conference-card-buttons"
                            onClick={() =>
                              unlike(
                                media?._id,
                                user?._id,
                                setIsLiked,
                                media?.type
                              )
                            }
                          >
                            {/* <LikeRedIcon className="icon-sm" /> */}
                            <LikeIcon className="icon-lg" />
                          </i>
                        ) : (
                          <i
                            className="conference-card-buttons"
                            onClick={() =>
                              like(
                                media?._id,
                                user?._id,
                                setIsLiked,
                                media?.type
                              )
                            }
                          >
                            <LikeInactiveIcon className="icon-lg" />
                          </i>
                        )}
                      </span>
                      {/* <LikeInactiveIcon className="icon-size" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="media-cr-detail-card"
                style={{
                  width: 320,
                  position: 'relative',
                }}
              >
                <CourseDetailsCard media={media} playPreview={playPreview} />
              </div>
            </div>
          </div>
          <div className="md-bottom-container">
            <div className="md-grid">
              <div>
                <div className="mb-24">
                  <KeyPoints keyPoints={media?.keyPoints} />
                </div>
                <div className="mb-24">
                  <MediaIncludes courseIncludes={media?.courseIncludes} />
                </div>
                <div className="mb-24">
                  <MediaContent
                    data={media}
                    sectionCount={sectionCount}
                    episodeCount={episodeCount}
                  />
                </div>
              </div>
              <div
                style={{
                  width: 320,
                }}
              >
                {/* need this empty */}
              </div>
            </div>
          </div>
          {showPreviewModal && previewEpisode && (
            <ModalX onDismiss={() => modalHandler()} open={showPreviewModal}>
              <div className="modalxContainerPlayer mb-40 p-16">
                <ReactPlayer
                  controls={true}
                  playing={true}
                  url={video?.source?.Location}
                  width="100%"
                  height="50px"
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                  }}
                  loop={true}
                />
              </div>
            </ModalX>
          )}
        </>
      )}
    </div>
  );
}
