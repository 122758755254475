import { useEffect } from 'react';
import FeedbackForm from '../../components/feedback/FeedbackForm';

export default function FeedbackPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <FeedbackForm />
    </>
  );
}
