import { useSelector } from 'react-redux';
import Tabs from '../tabs/Tabs';

export default function CreatePodcastSteps() {
  const newPodcast = useSelector((state) => state.podcast?.newPodcast);

  const steps = [
    {
      label: 'Podcast Info',
      path: '/dashboard/create-pod/step-1',
      completed: newPodcast?.completedStep1,
    },
    {
      label: 'Episode',
      path: '/dashboard/create-pod/step-2',
      completed: newPodcast?.completedStep2,
    },
    {
      label: 'Pricing',
      path: '/dashboard/create-pod/step-3',
      completed: newPodcast?.completedStep3,
    },

    {
      label: 'Publish',
      path: '/dashboard/create-pod/step-4',
      completed: newPodcast?.completedStep4,
    },
  ];

  return (
    <div>
      <h2 className="color-primary mb-18">Create Podcast</h2>
      <div
        style={{
          marginBottom: 24,
          borderBottom: '1px solid #ced9de',
        }}
      ></div>
      <div className="mb-32">
        <Tabs tabs={steps} showRadioButtons={true} />
      </div>
    </div>
  );
}
