import { useNavigate } from "react-router-dom";
import CelebrationIcon from "../icons/CelebrationIcon";
export default function RegistrationSuccess({ msg }) {
  const navigate = useNavigate();
  return (
    <div className="text-align-center">
      <i>
        <CelebrationIcon />
      </i>
      <div className="body-regular-gray3 mb-32">{msg}</div>
      <button
        onClick={() => navigate("/signin")}
        className="button button-primary"
      >
        Login
      </button>
    </div>
  );
}
