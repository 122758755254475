import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import TextError from "../formik/TextError";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";

import { localAlertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import MaterialTextInput from "../formik/MaterialTextInput";
import ModalX from "../modal/ModalX";
import NewExternalCreditForm from "./NewExternalCreditForm";
import { modalStyleformType1 } from "../reselect/selectUtil";
import Select from "react-select";
import { getValue } from "../../utility/commonUtil";
import LocalAlert from "../alert/LocalAlert";

const validationSchema = yup.object().shape({
  creditId: yup.string().required("Required"),
  creditAmount: yup.string().required("Required"),
});

const GoalCreditsForm = ({
  editMode,
  goals,
  setGoals,
  goalToEdit,
  closeGoalCreditsForm,
}) => {
  const [addNewCredit, setAddNewCredit] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const goalsCreditList = useSelector((state) => state.list.goalsCreditList);

  const onSubmit = async (values, action) => {
    const formData = {
      goalDetails: {
        name: values.creditLabel,
        quantity: values.creditAmount,
        creditId: values.creditId,
        type: values.creditType,
      },
    };
    const url = `attendees/creditType/goals/${goals?._id}?userId=${user?._id}`;
    const editUrl = `attendees/creditType/goals/${goals?._id}/credits/${goalToEdit?._id}?userId=${user?._id}`;
    try {
      let response;
      if (editMode) {
        response = await api.patch(editUrl, formData);
      } else {
        response = await api.post(url, formData);
      }
      if (response) {
        setGoals(response?.data?.data?.creditGoal);
        closeGoalCreditsForm();
      }
    } catch (err) {
      dispatch(localAlertAction(err.response.data.message, "danger"));
    }
  };
  // onSubmit Ends

  const initialValues = {
    creditId: goalToEdit?.creditId || "",
    creditAmount: goalToEdit?.goal || "",
    creditLabel: goalToEdit?.credit || "",
    creditType: goalToEdit?.creditType || "",
  };

  return (
    <>
      <div>
        <div className="text-align-center mb-16">
          <p className="section-title-1">
            {editMode ? "Update Credit" : "Add Credit"}
          </p>
        </div>
        <LocalAlert />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div className="form-type-1">
                  <div className="mb-16">
                    <Select
                      label="creditId"
                      name="creditId"
                      options={goalsCreditList || []}
                      value={getValue(
                        goalsCreditList,
                        props.values.creditId,
                        false
                      )}
                      onChange={(value) => {
                        props.setFieldValue("creditId", value?.value);
                        props.setFieldValue("creditLabel", value?.label);
                        props.setFieldValue("creditType", value?.type);
                      }}
                      placeholder="Credit Type*"
                      menuPortalTarget={document.body}
                      styles={modalStyleformType1}
                      isDisabled={editMode}
                    />
                    <div className="mb-16">
                      <ErrorMessage name="creditId" component={TextError} />
                    </div>
                  </div>
                  <div>
                    <span
                      type="button"
                      className="conf-add-newcredit-btn caption-1-heavy-primary"
                      onClick={() => {
                        setAddNewCredit(true);
                      }}
                    >
                      Add new credit
                    </span>

                    <div className="mb-16"></div>
                  </div>
                  <div className="mb-16">
                    <MaterialTextInput
                      id="creditAmount"
                      type="number"
                      name="creditAmount"
                      min="1"
                      label="Goal amount*"
                      placeholder=" "
                    />
                  </div>

                  <div>
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => closeGoalCreditsForm()}
                      fullWidth={true}
                      cancelButtonClass="button button-green"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {addNewCredit && (
        <ModalX onDismiss={() => setAddNewCredit(false)} open={addNewCredit}>
          <div className="modalx-container-md pl-16 pr-16 pb-32">
            <NewExternalCreditForm
              setAddNewCredit={setAddNewCredit}
              isExternal={false}
            />
          </div>
        </ModalX>
      )}
    </>
  );
};

export default GoalCreditsForm;

GoalCreditsForm.propTypes = {
  //   editMode: PropTypes.bool.isRequired,
  //   setEditMode: PropTypes.func,
  //   setShowExternalCreditForm: PropTypes.func,
};
