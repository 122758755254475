import { useEffect } from 'react';
import CreateVideoLanding from '../../components/create-video-course/CreateVideoLanding';

export default function CreateVideoLandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CreateVideoLanding />
    </div>
  );
}
