import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alertAction } from '../../redux/alert/alertAction';
import { loadAllMyConfsAction } from '../../redux/conference/conferenceAction';
import { loadMyOrganizationsSelectListAction } from '../../redux/organization/myOrganizationsAction';
import api from '../../utility/api';
import { addVideoCourseStatus } from '../../utility/commonUtil';
import CloseIcon from '../icons/CloseIcon';
import SearchIcon from '../icons/SearchIcon';
import ThreeDotsVIcon from '../icons/ThreeDotsVIcon';
import { customSelectStyle3 } from '../reselect/selectUtil';
import Select from 'react-select';
import { getValue } from '../../utility/commonUtil';
import '../conference/myConfs.styles.scss';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';

import {
  loadAllMyACsAction,
  loadOneIncopleteACAction,
} from '../../redux/audio-course/audioCourseAction';
import Dialogue from '../dialogue/Dialogue';

const MyConfsCard = React.lazy(() => import('../conference/MyConfsCard'));

export default function MyAudioCourse() {
  const [searchText, setSearchText] = useState('');
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [splicedList, setSplicedList] = useState([]);
  const [actionMenu, setActionMenu] = useState({ open: false, id: null });

  const [mediaId, setMediaId] = useState('');
  const [openDeleteDialogue, setDeleteDialogue] = useState('');
  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const audioCourses = useSelector((state) => state.audio.myAudioCourses);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let organizationsList = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );

  const options1 = [
    { value: 'all', label: 'All' },
    { value: 'drafts', label: 'Draft' },
    { value: 'published', label: 'Published' },
    { value: 'unpublished', label: 'Unpublished' },
  ];
  const options2 = [
    { value: 'all', label: 'All' },
    { value: 'user', label: 'User' },
    ...organizationsList,
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handlFilter1 = (value) => {
    if (value === 'all') {
      setList1(audioCourses);
    }
    if (value === 'drafts') {
      setList1(() => audioCourses?.filter((item) => item.status === 'Draft'));
    }
    if (value === 'published') {
      setList1(() =>
        audioCourses?.filter((item) => item.status === 'Published')
      );
    }

    if (value === 'unpublished') {
      setList1(() =>
        audioCourses?.filter((item) => item.status === 'Unpublished')
      );
    }
  };
  const handlFilter2 = (value) => {
    if (value === 'all') {
      setList2(audioCourses);
    } else if (value === 'user') {
      setList2(audioCourses?.filter((item) => item.host === 'user'));
    } else {
      setList2(() =>
        audioCourses?.filter(
          (element) =>
            element.host === 'organization' &&
            element.hostedBy.organization._id === value
        )
      );
    }
  };

  const getOneIncompleteVideoCourse = async (mediaId, step) => {
    const url = `audioCourses/${mediaId}/steps`;
    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadOneIncopleteACAction(response.data.data.media));
        navigate(`/dashboard/create-ac/step-${step ? step : '1'}`);
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response.data.message, 'danger'));
      }
    }
  };

  const unpublishVideoCourse = async (mediaId) => {
    const url = `audioCourses/${mediaId}/organiser/dashboard/unpublish?userId=${user?._id}`;
    try {
      const response = await api.patch(url);

      if (response) {
        const myVideoCourseWithStatus = response.data.data.audioCourses.map(
          (media) => addVideoCourseStatus(media)
        );

        dispatch(loadAllMyACsAction(myVideoCourseWithStatus));
        setList1(myVideoCourseWithStatus);
        setList2(myVideoCourseWithStatus);
        setFilteredList(myVideoCourseWithStatus);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const getMyAudioCourse = async (userId) => {
    const url = `/audioCourses/organiser/dashboard/users/${userId}`;
    try {
      const response = await api.get(url);

      if (response) {
        const myVideoCourseWithStatus = response.data.data.audioCourses.map(
          (media) => addVideoCourseStatus(media)
        );

        dispatch(loadAllMyACsAction(myVideoCourseWithStatus));
        setList1(myVideoCourseWithStatus);
        setList2(myVideoCourseWithStatus);
        setFilteredList(myVideoCourseWithStatus);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);
      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const deleteAudioCourse = async (mediaId) => {
    const url = `/audioCourses/${mediaId}/organiser/dashboard/users/${user?._id}`;
    try {
      const response = await api.delete(url);

      if (response) {
        const myVideoCourseWithStatus = response.data.data.audioCourses.map(
          (media) => addVideoCourseStatus(media)
        );
        dispatch(loadAllMyConfsAction(myVideoCourseWithStatus));
        setList1(myVideoCourseWithStatus);
        setList2(myVideoCourseWithStatus);
        setFilteredList(myVideoCourseWithStatus);
        setDeleteDialogue(false);
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };
  // close action menu

  function closeActionMenu() {
    setActionMenu({ open: false, id: null });
  }

  // duplicate conference

  function renderButton(conf) {
    switch (conf.status) {
      case 'Published':
        return (
          <li
            onClick={() => {
              unpublishVideoCourse(conf._id);
              closeActionMenu();
            }}
          >
            Unpublish
          </li>
        );
      case 'Unpublished':
        return (
          <li
            onClick={() => {
              getOneIncompleteVideoCourse(conf?._id, 5);
              closeActionMenu();
            }}
          >
            Publish
          </li>
        );

      default:
        return null;
    }
  }
  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    let comonList = list1?.filter((obj) =>
      list2.some(({ _id, title }) => obj._id === _id && obj.title === title)
    );

    let filteredresult = comonList?.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredList(filteredresult);
    setTotalRecords(filteredresult?.length);
  }, [list1, filter1, filter2, searchText]);

  useEffect(() => {
    loadMyOrgnizations(user?._id);
    getMyAudioCourse(user?._id);
  }, [user?._id]);

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);

  return (
    <div>
      <div className="myconfs-header mb-24">
        <h1>Audio Courses</h1>
        <button
          onClick={() => navigate('/dashboard/create-ac/step-1')}
          className="button button-green mt-4"
        >
          Create Audio courses
        </button>
      </div>
      <div className="myconfs-sort mb-32">
        <div className="form-type-3">
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              id="myConfsSearchText"
              placeholder="Search Video Courses"
              name="searchText"
              value={searchText}
              onChange={handleSearchText}
              autoComplete="off"
            />
            <i
              className={
                searchText?.length > 0
                  ? 'display-none'
                  : 'conf-search-input-icon'
              }
            >
              <SearchIcon width="2.4rem" height="2.4rem" />
            </i>
          </div>
        </div>
        <div>
          <Select
            value={getValue(options1, filter1, false)}
            options={options1}
            name="filter1"
            onChange={(option) => {
              setFilter1(option.value);
              handlFilter1(option.value);
            }}
            styles={customSelectStyle3}
          />
        </div>
        <div>
          <Select
            value={getValue(options2, filter2, false)}
            options={options2}
            name="filter2"
            onChange={(option) => {
              setFilter2(option.value);
              handlFilter2(option.value);
            }}
            styles={customSelectStyle3}
          />
        </div>
      </div>

      <div className="table-container">
        <table className="myconfs-table ">
          <thead>
            <tr>
              <th>Course</th>
              <th>Status</th>
              <th>Views</th>
              <th>Saved</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* <Suspense fallback={Loader}> */}
            {splicedList?.map((conf) => {
              return (
                <tr
                  key={conf._id}
                  style={{
                    ...(actionMenu.id == conf?._id && actionMenu.open
                      ? {
                          backgroundColor: '#ffffff',
                          border: '0.2px solid #ced9de',
                        }
                      : conf.status === 'Expired'
                      ? { opacity: 0.6 }
                      : null),
                  }}
                >
                  <td>
                    <MyConfsCard
                      banner={conf.banner}
                      title={conf.title}
                      timezone={conf.timezone}
                      startDate={conf.startDate}
                      endDate={conf.endDate}
                      city={conf.city}
                      country={conf.country}
                      mode={conf.mode}
                      confId={conf?._id}
                      onCardClick={() =>
                        navigate(`/audioCourse/preview/${conf?._id}`)
                      }
                    />
                  </td>
                  <td>{conf.status}</td>
                  <td>{conf.viewsCount ? conf.viewsCount : 0}</td>
                  <td>{conf?.likedCount}</td>

                  <td className="position-relative ">
                    {actionMenu.id == conf?._id && actionMenu.open ? (
                      <div className="row-menu">
                        <ul>
                          <li onClick={() => closeActionMenu()}>
                            <div>Close</div>
                            <i>
                              <CloseIcon className="icon-xs" fill="#757575" />
                            </i>
                          </li>
                          <li
                            onClick={() => {
                              getOneIncompleteVideoCourse(conf?._id);
                              closeActionMenu();
                            }}
                          >
                            Edit
                          </li>

                          <li
                            onClick={() => {
                              setMediaId(conf?._id);
                              setDeleteDialogue(true);
                              closeActionMenu();
                            }}
                          >
                            Delete
                          </li>
                          {renderButton(conf)}
                        </ul>
                      </div>
                    ) : (
                      <i
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setActionMenu({ open: true, id: conf?._id })
                        }
                      >
                        <ThreeDotsVIcon className="icon-size" />
                      </i>
                    )}
                  </td>
                </tr>
              );
            })}
            {/* </Suspense> */}
          </tbody>
          {openDeleteDialogue && (
            <Dialogue
              msg="Are you sure you want to delete?"
              title="Delete audio course"
              closeDialogue={() => {
                setDeleteDialogue(false);
              }}
              yesAction={() => deleteAudioCourse(mediaId)}
            />
          )}
        </table>
      </div>
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
}
