// import { SHOW_ALERT, REMOVE_ALERT } from "./alertTypes";
// const initialState = [];

// function alertReducer(state = initialState, action) {
//   switch (action.type) {
//     case SHOW_ALERT:
//       return [...state, action.payload];
//     case REMOVE_ALERT:
//       return state.filter((alert) => alert.id !== action.payload);
//     default:
//       return state;
//   }
// }

// export default alertReducer;

import {
  SHOW_ALERT,
  REMOVE_ALERT,
  SHOW_LOCAL_ALERT,
  REMOVE_LOCAL_ALERT,
} from "./alertTypes";

const initialState = {
  alert: null,
  localAlert: null,
};

function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alert: null,
      };
    case SHOW_LOCAL_ALERT:
      return {
        ...state,
        localAlert: action.payload,
      };
    case REMOVE_LOCAL_ALERT:
      return {
        ...state,
        localAlert: null,
      };
    default:
      return state;
  }
}

export default alertReducer;
