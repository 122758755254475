import EditIcon from '../icons/EditIcon';
import '../organizer-dashboard/welcome.styles.scss';
import './ongoingLearning.scss';
import './dashboardCreditGoal.scss';
import { useNavigate } from 'react-router-dom';

const CreditGoalProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    height: '8px',
    width: '100%',
    // backgroundColor: '#e0e0de',
    //   borderRadius: 50,
    padding: '0 93px 0 0',
    borderRadius: '3px',
    backgroundColor: '#e3f0f5',
    // margin: 50,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    borderRadius: 'inherit',
    textAlign: 'right',
    borderRadius: '3px',
    backgroundColor: '#08415c',
  };

  //   const labelStyles = {
  //     padding: 5,
  //     color: 'white',
  //     fontWeight: 'bold',
  //   };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default function DashbardCreditGoal({ goals }) {
  const navigate = useNavigate();
  return (
    <div className="dashboard-credit-goal">
      <div className="flex-vc-sb">
        <p className="caption-1-heavy-primary mb-12 ml-16">
          {` My credit goals (${new Date(
            goals?.startDate
          ).toLocaleDateString()} - ${new Date(
            goals?.endDate
          ).toLocaleDateString()})`}
        </p>
        <i
          onClick={() => navigate('/attendee-dashboard/my-credits')}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <EditIcon fill={'#08415c'} />
        </i>
      </div>
      <div className="py-18 px-18">
        <ul style={{ listStyleType: 'none' }}>
          {goals?.counts?.length > 0 &&
            goals?.counts?.map((item) => {
              return (
                <>
                  <p
                    className="mb-4 mt-16"
                    style={{ fontSize: '14px', color: '#08415c' }}
                  >
                    {item?.credit}
                  </p>

                  <li>
                    <CreditGoalProgressBar
                      completed={item?.earnedCreditQuantity}
                    />
                  </li>
                </>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
