import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BannerWithGirlSketch from '../SVG-assets/BannerWithGirlSketch';
import Loader from '../loader/Loader';
import SavedConfCard from './SavedConfCard';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import LocalAlert from '../../components/alert/LocalAlert';
import CloseIcon from '../../components/icons/CloseIcon';
import { localAlertAction } from '../../redux/alert/alertAction';
import { openInNewTab, regex1000To15x9 } from '../../utility/commonUtil';
import SubmitButtonWithLoader from '../button/SubmitButtonWithLoader';
import Modal from '../modal/Modal';
import PotentialUserForm from '../register/PotentialUser';
import './savedconfs.styles.scss';

const potentialUserInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  profession: '',
  country: '',
  state: '',
  city: '',
};

const potentialUserFormValidation = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(24, 'Must be 24 characters or less')
    .required('Required'),
  lastName: yup
    .string()
    .trim()
    .max(24, 'Must be 24 characters or less')
    .required('Required'),

  profession: yup
    .string()
    .oneOf(
      [
        'physician',
        'physicianAssistant',
        'nursePractitioner',
        'dentist',
        'nurse',
        'pharmacist',
        'physicalTherapist',
        'occupationalTherapist',
        'speechTherapist',
        'respiratoryTherapist',
        'dietitian',
        'socialWorker',
        'caseManagement',
        'other',
      ],
      'Please choose a profession'
    )
    .required('Required'),
  email: yup
    .string()
    .email('Please provide a valid email')
    .required('Required'),
  countryCode: yup.string().required('Required'),

  mobile: yup
    .string()
    .matches(regex1000To15x9, 'Eenter valid mobile number.')
    .required('Required'),
});

export default function SavedConfs() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPotentialUserForm, setShowPotentialUserForm] = useState(false);
  const [conferenceId, setConferenceId] = useState('');

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const getSaved = async (userID) => {
    try {
      const responce = await api.get('/conferences/like/' + userID);

      setData(responce.data.data.conferences);
      setIsLoading(false);
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  const onSubmit = async (values, action) => {
    try {
      let formData = {
        user: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          country: values?.country,
          state: values?.state,
          city: values?.city,
          confId: conferenceId,
          profession: values?.profession,
          mobile: values?.mobile,
          countryCode: values.countryCode,
        },
      };

      const response = await api.post('/common/potentialUsers', formData);

      if (response) {
        openInNewTab(response?.data?.data?.conference?.url);
        setShowPotentialUserForm(false);
      }
    } catch (err) {
      dispatch(localAlertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    getSaved(user?._id);
  }, [user?._id]);

  const noSavedConfs = (
    <div className="nosaved-confs-wrap">
      <BannerWithGirlSketch className="sketch-md" />
      <div className="mt-28">
        <h2 className="color-primary mb-24">
          You haven't saved any conferences
        </h2>
        <button
          className="button button-primary"
          onClick={() => navigate('/search-conference')}
        >
          Explore Trending Conferences
        </button>
      </div>
    </div>
  );

  const savedConfs = (
    <>
      <h2 className="color-primary mb-40">Saved Conferences</h2>
      {data?.length > 0 &&
        data?.map((item) => {
          return (
            <SavedConfCard
              key={item._id}
              data={item}
              getSaved={getSaved}
              potentialUserForm={setShowPotentialUserForm}
              setConferenceId={setConferenceId}
            />
          );
        })}
    </>
  );

  return (
    <div>
      {isLoading ? <Loader /> : data?.length > 0 ? savedConfs : noSavedConfs}
      {showPotentialUserForm && (
        <>
          <Modal onDismiss={() => setShowPotentialUserForm(false)}>
            <div className="modalx-header">
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => setShowPotentialUserForm(false)}
              >
                <CloseIcon className="icon-size" fill="#c4c4c4" />
              </i>
            </div>
            <Formik
              initialValues={potentialUserInitialValues}
              validationSchema={potentialUserFormValidation}
              onSubmit={onSubmit}
            >
              {(props) => {
                const { isSubmitting } = props;
                return (
                  <Form className="form-type-1  p-16">
                    <div>
                      <h4 className="text-align-center mb-8">
                        Enter details to view Conference
                      </h4>
                      <LocalAlert />
                      <PotentialUserForm props={props} />
                    </div>
                    <div>
                      <SubmitButtonWithLoader
                        isSubmitting={isSubmitting}
                        text={'View Conference'}
                        className="button button-primary"
                        fullWidth={true}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}
    </div>
  );
}
