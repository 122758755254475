import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAction } from '../../redux/auth/authAction';
import DropdownIcon from '../icons/DropdownIcon';
import LogoutIcon from '../icons/LogoutIcon';
import ODashboardIcon from '../icons/ODashboardIcon';
import PassesIcon from '../icons/PassesIcon';
import ProfileIcon from '../icons/ProfileIcon';
import SettingsIcon from '../icons/SettingsIcon';

import api from '../../utility/api';
import './AuthDropdown.styles.scss';

const authDropdownOptions = [
  {
    icon: <ODashboardIcon className="icon-size" />,
    name: "Organizer's Dashboard",
    path: 'dashboard',
  },
  {
    icon: <ProfileIcon className="icon-size" />,
    name: 'Attendee Dashboard',
    path: 'attendee-dashboard/dashboard',
  },
  {
    icon: <PassesIcon className="icon-size" />,
    name: 'Help',
    path: '/help',
  },

  {
    icon: <SettingsIcon className="icon-size" />,
    name: 'Account Settings',
    path: '/account-settings',
  },
];

export default function AuthDropdown({ className }) {
  const [openAuthDropdown, setOpenAuthDropdown] = useState(false);
  const onDropdownClick = () => setOpenAuthDropdown(!openAuthDropdown);

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { user } = auth;

  const ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current.contains(event.target)) {
        return;
      }
      setOpenAuthDropdown(false);
    };
    document.body.addEventListener('click', onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener('click', onBodyClick, {
        capture: true,
      });
    };
  }, []);

  const onLogoutClick = async () => {
    try {
      const response = await api.get('/logout');
      if (response) {
        setOpenAuthDropdown(false);
        dispatch(logoutAction());
      }
    } catch (err) {}
  };

  return (
    <div ref={ref}>
      <div className="user-name-wrapper mr-15 " onClick={onDropdownClick}>
        <span className="signin">{user && user?.firstName}</span>
        <DropdownIcon className="icon-size" />
      </div>
      <div className={openAuthDropdown ? '' : 'display-none'}>
        <div className={className}>
          <ul>
            {authDropdownOptions.map((e) => (
              <li
                style={{ cursor: 'pointer' }}
                key={e.name}
                onClick={() => {
                  // if (
                  //   location.pathname !== "/search-conference" &&
                  //   e.path === "search-conference"
                  // ) {
                  //   loadSearchResults();
                  // }
                  navigate(e.path);
                  setOpenAuthDropdown(false);
                }}
              >
                {e.icon}
                <div className="avenir-roman-18">{e.name}</div>
              </li>
            ))}
            <li style={{ cursor: 'pointer' }} onClick={onLogoutClick}>
              <LogoutIcon className="icon-size" />
              <div className="avenir-roman-18">Logout</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
