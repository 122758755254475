import React from 'react';
import { useNavigate } from 'react-router-dom';
import CreditcardsSketch from '../icons/CreditcardsSketch';
import GlobeSketch from '../icons/GlobeSketch';
import VideoconfSketch from '../icons/VideoconfSketch';
import './exploreConfs.styles.scss';

function ExploreConfs(props) {
  const navigate = useNavigate();
  return (
    <div className="grid-col-8 mb-60" style={{ gap: 24 }}>
      <div
        onClick={() => {
          navigate('/search-conference');
        }}
        className="explore-section-1"
      >
        <GlobeSketch className="globe-sketch" />
        <h3 className="mb-8">Explore Conferences</h3>
        <p className="body-regular-gray3">
          Find the BEST medical conferences & CME around the world that fits
          your criteria.
        </p>
      </div>
      <div
        onClick={() => {
          navigate('/list-conferences');
        }}
        className="explore-section-2"
      >
        <VideoconfSketch className="videoconference-sketch" />
        <h3 className="mb-8">Host Conferences & CME </h3>
        <p className="body-regular-gray3">
          You can easily host a Conference or CME with only a few clikcs. Your
          first one starts here. Click to know more.
        </p>
      </div>
      <div
        onClick={() => {
          navigate('/track-credits');
        }}
        className="explore-section-3"
      >
        <CreditcardsSketch className="creditcards-sketch" />
        <h3 className="mb-8">Track Credits</h3>
        <p className="body-regular-gray3">
          Track of all CME Certificates and Credits earned anywhere through
          Attendee Dashbaord. Keep records for future.
        </p>
      </div>
    </div>
  );
}

export default ExploreConfs;
