import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { alertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import PodcastCheckoutForm from '../../components/podcast/PodcastCheckoutForm';

function PodcastCheckoutPage() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clintSecret, setClientSecret] = useState(null);

  const { state } = useLocation();
  const dispatch = useDispatch();

  //get stripe promise and set it up on the state
  const getStripePromise = async () => {
    try {
      const response = await api.get('/conferences/stripe/config');
      if (response) {
        setClientSecret(response.data.data.publishableKey);
        setStripePromise(loadStripe(response.data.data.publishableKey));
      }
    } catch (err) {
      dispatch(alertAction(err.response.data.message, 'danger'));
    }
  };

  useEffect(() => {
    getStripePromise();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // create options object for stripe Elements
  const options = {
    clientSecret:
      state?.paymentData?.orderResponse.latest_invoice.payment_intent
        .client_secret,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',

      variables: {
        colorPrimary: '#08415c',
        colorBackground: '#ffffff',
        colorText: '#444444',
        colorDanger: '#d8000c',
        fontFamily: 'Avenir, sans-serif',
        spacingUnit: '6px',
        borderRadius: '4px',
        // See all possible variables below
      },
      rules: {
        // '.Tab': {
        //   border: '1px solid #E0E6EB',
        //   boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        // },

        // '.Tab:hover': {
        //   color: 'var(--colorText)',
        // },

        // '.Tab--selected': {
        //   borderColor: '#E0E6EB',
        //   boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
        // },

        '.Input': {
          padding: '16px 24px 16px',
          fontSize: '16px',
          outline: 'none',
          border: 'solid 2px #ced9de',
          backgroundColor: 'ffffff',
          fontFamily: 'Avenir-Roman',
          fontSize: '16px',
          lineHeight: 1,
          letterSpacing: '0.01px',
          color: '#000000',
          transition: ' 0.1s ease-out',
        },

        '.Input:hover': {
          border: 'solid 2px #aabdc7',
        },
        '.Input:focus': {
          border: 'solid 2px #55a0fa',
          // outline: "none",
          boxShadow: 'none',
        },

        '.Input--invalid': {
          boxShadow:
            '0 .1px .1px 0 rgba(0, 0, 0, 0.07), 0 0 0 .1px var(--colorDanger)',
        },

        '.Label': {
          fontFamily: 'Avenir',
          fontWeight: 'normal',
          color: '#08415c',
          fontSize: '18px',
          letterSpacing: '0.01px',
          ontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '1',
        },

        '.Dropdown': {
          padding: '24px 24px 16px',
        },
      },
    },
  };

  return (
    <main className="container pt-64">
      {stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <PodcastCheckoutForm paymentData={state.paymentData.orderResponse} />
        </Elements>
      )}
    </main>
  );
}

export default PodcastCheckoutPage;
