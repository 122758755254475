import downArrowIcon from '../../video-course/video-course-icons/down-arrow.png';
import openFolderIcon from '../../video-course/video-course-icons/open-folder.png';
import certificate from '../../video-course/video-course-icons/certificate.png';
import { Link } from 'react-router-dom';
import { openInNewTab } from '../../../utility/commonUtil';

export default function CourseContent({
  data,
  video,
  setVideo,
  updateDuration,
  updateCompleteDuration,
  setToggleDrpDwn,
  toggleDrpDwnRes,
  setToggleDrpDwnRes,
  imageTypeHandler,
}) {
  return (
    <>
      {data?.sections?.map((item, id) => {
        return (
          <details key={item?._id} className="mc-details">
            <summary className="mc-summary">
              <span className="mc-summaryDiv">
                <span className="avenir-16-500">{`Section ${id + 1}: ${
                  item?.title
                }`}</span>
                <span className="mc-section-details">
                  <span>{item?.episodes.length} lectures</span>
                </span>
              </span>

              {/* <span>{item?.duration}</span> */}
            </summary>
            {item.episodes?.map((list, id) => {
              return (
                <div
                  key={list?._id}
                  onClick={() => {
                    updateDuration();
                    setVideo(list);
                  }}
                  className={`${'vm-courseContent-descBdy'} ${
                    video?.source?.Location === list?.source?.Location
                      ? 'vm-courseContent-active'
                      : ''
                  }`}
                >
                  <div className="mc-episode mb-6 mt-12">
                    <div className="mc-episode-left ">
                      <input
                        type="checkbox"
                        name={list._id.toString()}
                        id={list._id.toString()}
                        checked={list?.isCompleted}
                        onChange={(e) => {
                          updateCompleteDuration(list);
                          setToggleDrpDwn((prev) => {
                            return {
                              ...prev,
                              [e.target?.name]: !prev[e.target?.name],
                            };
                          });
                        }}
                        className="vm-customCheckBox-checkbox"
                      />
                    </div>

                    <div className="mc-episode-center avenir-16-500">
                      <p
                        style={{
                          lineHeight: '1.4rem',
                        }}
                        key={id}
                      >
                        {list.title}
                      </p>
                    </div>
                  </div>
                  <div className="mc-episode mb-6 mt-6">
                    <div className="mc-episode-center mt-4">
                      <div className="flex-vc">
                        <p>
                          {' '}
                          <img
                            src={imageTypeHandler(list)}
                            alt="play icon"
                            className="vm-dark-play-icon ml-32 mt-4"
                          />
                        </p>

                        <p style={{ color: '#6A6F73' }} className="ml-8">
                          {list?.duration}
                        </p>
                      </div>
                      <div className="mc-episode-right">
                        {list?.resources?.length > 0 ? (
                          <span className="vm-courseContent-subDrp">
                            <div
                              className="vm-courseContent-subDrpBox"
                              onClick={() => {
                                setToggleDrpDwnRes((p) => {
                                  return {
                                    [list?._id.toString()]: p[
                                      list?._id.toString()
                                    ]
                                      ? !p[list?._id.toString()]
                                      : true,
                                  };
                                });
                              }}
                            >
                              <img
                                src={openFolderIcon}
                                alt="icon"
                                className="vm-courseContent-subIcon"
                              />
                              <div className="vm-courseContent-subDrpTxt">
                                Resources
                              </div>
                              <img
                                src={downArrowIcon}
                                icon="dropdown icon"
                                className={[
                                  'vm-courseContent-drowDownIcon',
                                  toggleDrpDwnRes[list._id]
                                    ? 'vm-courseContent-reverseDrowDownIcon'
                                    : null,
                                ].join(' ')}
                              />
                            </div>
                            {toggleDrpDwnRes[list._id] ? (
                              <div className="vm-courseContent-subDrpItemsBox">
                                {list?.resources?.map((resItem) => {
                                  return (
                                    <Link
                                      key={`resItem-${resItem.Location}`}
                                      onClick={() =>
                                        openInNewTab(resItem.Location)
                                      }
                                      to={resItem.link}
                                      className="p-8 vm-courseContent-resItem"
                                    >
                                      <img
                                        src={certificate}
                                        alt="icon"
                                        className="vm-courseContent-resItemIcon "
                                      />
                                      <span className="ml-8 vm-courseContent-resItemTxt">
                                        {resItem?.fileNameWithExtension}
                                      </span>
                                    </Link>
                                  );
                                })}
                              </div>
                            ) : null}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </details>
        );
      })}
    </>
  );
}
